import React from "react";
import { Field } from "react-final-form";
import {
  Field as FieldStyle,
  Label,
  Control,
  Help,
  Input,
  Button
} from "bloomer";
import { deriveFieldError } from "../../utils";

/**
 * Helper for react-final-form
 */
class InnerMultipleDynamicField extends React.PureComponent {
  get val() {
    return this.props.value || [];
  }

  handleNameChange = idx => e => {
    const newResources = this.val.map((resource, sidx) => {
      if (idx !== sidx) return resource;
      return e.target.value;
    });

    this.props.onChange(newResources);
  };

  handleAdd = () => {
    this.props.onChange(this.val.concat([""]));
  };

  handleRemove = idx => () => {
    this.props.onChange(this.val.filter((s, sidx) => idx !== sidx));
  };

  render() {
    const { errors = {} } = this.props;

    return (
      <div className="has-standard-mb">
        <p>
          <Button onClick={this.handleAdd} isColor="info">
            Add
          </Button>
        </p>
        {this.val.map((resource, idx) => (
          <div key={idx} style={{ marginBottom: "0.5rem" }}>
            <FieldStyle hasAddons>
              <Control>
                <Input
                  type="text"
                  placeholder={`#${idx + 1} name`}
                  value={resource}
                  onChange={this.handleNameChange(idx)}
                  onBlur={this.props.onBlur}
                  onFocus={this.props.onFocus}
                  isColor={errors[idx] ? "danger" : null}
                />
              </Control>
              <Control>
                <Button onClick={this.handleRemove(idx)} isColor="danger">
                  -
                </Button>
              </Control>
            </FieldStyle>
            {errors[idx] && <Help isColor="danger">{errors[idx]}</Help>}
          </div>
        ))}
      </div>
    );
  }
}

class MultipleDynamicTextFieldWithErrors extends React.PureComponent {
  render() {
    const { name, label } = this.props;
    return (
      <Field
        name={name}
        subscription={{
          value: true,
          error: true,
          submitError: true,
          touched: true,
          dirtySinceLastSubmit: true
        }}
      >
        {({ input, meta }) => (
          <FieldStyle>
            <Label>{label}</Label>
            <Control>
              {React.createElement(InnerMultipleDynamicField, {
                errors: deriveFieldError(meta),
                ...input
              })}
            </Control>
          </FieldStyle>
        )}
      </Field>
    );
  }
}

export default MultipleDynamicTextFieldWithErrors;
