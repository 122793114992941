import React, { Component } from "react";
import { observer } from "mobx-react";
import { Link } from "react-router-dom";
import {
  Card,
  CardHeader,
  CardHeaderTitle,
  CardHeaderIcon,
  CardImage,
  CardFooter,
  CardFooterItem
} from "bloomer";

import { formatMoney } from "../../utils";
import ImageFromMedialibrary from "../../components/ImageFromMedialibrary";

class ItemCard extends Component {
  render() {
    const { item } = this.props;
    const { id, title, price, image } = item;

    const itemLinkProps = { to: `/store/${id}` };

    return (
      <Card>
        <CardHeader>
          <CardHeaderTitle>
            <Link {...itemLinkProps} className="has-text-dark">
              {title}
            </Link>
          </CardHeaderTitle>
          <CardHeaderIcon tag="span" style={{ cursor: "inherit" }}>
            {formatMoney(price)}
          </CardHeaderIcon>
        </CardHeader>
        {image && (
          <CardImage>
            <Link {...itemLinkProps}>
              <ImageFromMedialibrary image={image} />
            </Link>
          </CardImage>
        )}
        <CardFooter>
          <CardFooterItem
            hasTextAlign="centered"
            render={props => <Link {...props} {...itemLinkProps} />}
          >
            Details
          </CardFooterItem>
        </CardFooter>
      </Card>
    );
  }
}

export default observer(ItemCard);
