import React, { PureComponent, Fragment } from "react";
import _get from "lodash.get";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer
} from "recharts";
import { Button } from "bloomer";

const demographicCategories = {
  dneighborhood: "Neighborhood",
  dincomerange: "Income Range",
  dagerange: "Age Range",
  dgender: "Gender",
  drace: "Race",
  dnationality: "Nationality",
  dresidence: "Residence",
  dhouseholdcount: "Household Count",
  demploymentstatus: "Employment Status"
};

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload[0]) {
    return (
      <div
        style={{
          margin: 0,
          padding: 10,
          backgroundColor: "rgb(255, 255, 255)",
          border: "1px solid rgb(204, 204, 204)",
          whiteSpace: "nowrap"
        }}
      >
        {label}
        <br />
        {payload[0].payload.count} bikes
        <p className="is-size-7">({payload[0].payload.affected} affected)</p>
      </div>
    );
  }

  return null;
};

export default class BarBikesPerDemographic extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      demographic: "dneighborhood",
      period: null
    };
  }

  setDemographic = demographic => {
    this.setState({ demographic });
  };

  setPeriod = period => {
    this.setState({ period });
  };

  componentDidUpdate() {
    const { demographics } = this.props;
    const { demographic, period } = this.state;

    // Check if current demographic contains selected year. if not, reset to "all"
    if (
      period &&
      (!demographics ||
        !demographics[demographic] ||
        !demographics[demographic]["years"][period])
    ) {
      this.setPeriod(null);
    }
  }

  render() {
    const { demographics } = this.props;
    const { demographic, period } = this.state;

    if (!demographics) {
      return "n/a";
    }

    const dataRaw = _get(demographics, [
      demographic,
      ...(!period ? ["all"] : ["years", period])
    ]);
    const data =
      !dataRaw || Array.isArray(dataRaw) // quick fix for serializing empty collection as array
        ? null
        : Object.entries(dataRaw)
            .reduce(
              (acc, [name, { count, affected }]) => [
                ...acc,
                { name, count, affected }
              ],
              []
            )
            .sort((a, b) => (a.name > b.name ? 1 : -1)); // sort by name

    const availablePeriods = demographics[demographic] && [
      null,
      ...Object.keys(demographics[demographic]["years"])
    ];

    return (
      <Fragment>
        <p>
          {Object.keys(demographicCategories).map(dc => (
            <Button
              key={dc}
              onClick={() => this.setDemographic(dc)}
              isColor={dc === demographic ? "primary" : "light"}
              isSize="small"
              style={{ marginRight: "0.25rem", marginBottom: "0.25rem" }}
              disabled={!demographics[dc]}
            >
              {demographicCategories[dc]}
            </Button>
          ))}
        </p>
        {availablePeriods && (
          <p>
            {availablePeriods.map(ap => (
              <Button
                key={ap || "all"}
                onClick={() => this.setPeriod(ap)}
                isColor={ap === period ? "primary" : "light"}
                isSize="small"
                style={{ marginRight: "0.25rem", marginBottom: "0.25rem" }}
              >
                {ap || "all"}
              </Button>
            ))}
          </p>
        )}
        {!data ? (
          <p>n/a</p>
        ) : (
          <ResponsiveContainer width="100%" height={350}>
            <BarChart data={data}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="name"
                interval={0}
                angle={-45}
                textAnchor="end"
                height={100}
                tick={{ fontSize: 10 }}
              />
              <YAxis />
              <Tooltip content={<CustomTooltip />} />
              <Bar dataKey="count" fill="#8884d8" />
            </BarChart>
          </ResponsiveContainer>
        )}
      </Fragment>
    );
  }
}
