import React from "react";
import { observer, inject } from "mobx-react";
import { withRouter } from "react-router-dom";
import { Title } from "bloomer";

import { compose, idFromProps, currentIdIfChanged } from "../../../utils";
import { LOADER_TAG_ACCOUNT_ORDERS, NO_CHANGE } from "../../../constants";

import Wrapper from "./common/Wrapper";
import TitleHelmet from "../../../components/seo/TitleHelmet";
import OrderCard from "../../../components/OrderCard";
import LoadingText from "../../../components/LoadingText";
import Breadcrumbs from "../../../components/Breadcrumbs";

class _OrderInner extends React.Component {
  get store() {
    return this.props.orderOwnStore;
  }

  get loading() {
    return this.props.uiStore.loadingByTag(LOADER_TAG_ACCOUNT_ORDERS);
  }

  componentDidMount() {
    this.load();
  }

  componentDidUpdate(prevProps) {
    if (currentIdIfChanged(this.props, prevProps) !== NO_CHANGE) {
      this.load();
    }
  }

  load = () => {
    const id = idFromProps(this.props);
    this.store.loadOrder(id).catch(() => {
      // redirect
      this.props.history.push("/account/orders", { wasRedirect: true });
    });
  };

  componentWillUnmount() {
    this.store.clearAll();
  }

  render() {
    const { order } = this.store;

    const title = this.loading
      ? "Order Details"
      : order
        ? `Order #${order.id}`
        : null;

    return (
      <div>
        {this.loading ? (
          <div>
            <TitleHelmet title={title} />
            <Title isSize={3}>{title}</Title>
            <LoadingText />
          </div>
        ) : order ? (
          <div>
            <Breadcrumbs
              items={[
                { to: "/account", label: "Account" },
                { to: "/account/orders", label: "Orders" },
                {
                  to: `/account/orders/${order.id}`,
                  label: `Order #${order.id}`
                }
              ]}
            />
            <TitleHelmet title={title} />
            <Title isSize={3}>{title}</Title>
            <OrderCard order={order} showDetailsLink={false} />
          </div>
        ) : null}
      </div>
    );
  }
}

const OrderInner = compose(
  withRouter,
  inject("uiStore", "orderOwnStore"),
  observer
)(_OrderInner);

export default class Order extends React.Component {
  render() {
    return <Wrapper>{() => <OrderInner />}</Wrapper>;
  }
}
