import React, { Fragment } from "react";
import { inject, observer } from "mobx-react";
import { withRouter } from "react-router";
import { Button } from "bloomer";
import LinkButton from "../../LinkButton";
import { compose, loginLinkWithRedirectAfter } from "../../../utils";
import { LOADER_TAG_AUTH } from "../../../constants";

class AuthCorner extends React.Component {
  logout = () => {
    this.props.authStore.logout();
  };

  get authLoading() {
    return this.props.uiStore.loadingByTag(LOADER_TAG_AUTH);
  }

  render() {
    const currentPath = this.props.location.pathname;

    return (
      <Fragment>
        {!this.props.authStore.isAuthenticated && (
          <LinkButton
            to={loginLinkWithRedirectAfter(currentPath)}
            isColor="primary"
            isOutlined
          >
            Login
          </LinkButton>
        )}
        {this.props.authStore.isAuthenticated && (
          <Fragment>
            <LinkButton isSize="small" to="/cart">
              Cart
            </LinkButton>
            &nbsp;
            <LinkButton isSize="small" to="/account">
              Account
            </LinkButton>
            &nbsp;
            <Button
              isSize="small"
              onClick={this.logout}
              disabled={this.authLoading}
            >
              Logout
            </Button>
            <br />
            <div>
              {`Hello, ${this.props.authStore.user.name ||
                this.props.authStore.user.email}!`}
            </div>
          </Fragment>
        )}
      </Fragment>
    );
  }
}

export default compose(
  withRouter,
  inject("authStore", "uiStore"),
  observer
)(AuthCorner);
