import React, { Component } from "react";
import SelectFieldImmediate from "../SelectFieldImmediate";
import agent from "../../../agent";
import { LOADER_TAG_ADMIN_FIELD_SHIPPING_SERVICE } from "../../../constants";

export default class SelectShippingService extends Component {
  render() {
    return (
      <SelectFieldImmediate
        getFn={() =>
          agent.AdminShippingServices.all({
            loaderTag: LOADER_TAG_ADMIN_FIELD_SHIPPING_SERVICE
          })
        }
        loaderTag={LOADER_TAG_ADMIN_FIELD_SHIPPING_SERVICE}
        {...this.props}
      />
    );
  }
}
