/**
 * NotFoundError
 */
class NotFoundError {
  constructor(message) {
    this.name = "NotFoundError";
    this.alertConfigFinal = { group: "not-found" };
    this.message = message;
    this.stack = new Error().stack; // Optional
  }
}
NotFoundError.prototype = Object.create(Error.prototype);

export default NotFoundError;
