import React from "react";

import { formatMomentLocalLLL, parseMomentUTC } from "../../../datetime";

const LastUpdated = ({ report, isLive = false }) =>
  report ? (
    <div className="is-size-7 has-text-grey-light has-text-centered">
      Last updated {formatMomentLocalLLL(parseMomentUTC(report.updated_at))}
      {isLive ? " (generated on-the-fly)" : ""}
    </div>
  ) : null;

export default LastUpdated;
