import React from "react";
import { observer } from "mobx-react";
import _pick from "lodash.pick";

import AdminSite from "../../../components/AdminSite";

import agent from "../../../agent";
import { compose, idFromProps } from "../../../utils";
import { LOADER_TAG_ADMIN_RECEIVING_EVENTS } from "../../../constants";
import GenericEditor from "../../../components/GenericEditor";
import GenericResourceEditorStore from "../../../stores/special/GenericResourceEditorStore";
import { makeRecordValidator, makeFieldValidator } from "../../../validators";
import TextFieldWithErrors from "../../../components/fields/TextFieldWithErrors";
import DateFieldWithErrors from "../../../components/fields/DateFieldWithErrors";
import Breadcrumbs from "../../../components/Breadcrumbs";

const initialFormValuesFromReceivingEvent = receivingEvent => {
  return _pick(receivingEvent, ["name", "date"]);
};

const newStructure = {
  name: null,
  date: null
};

const receivingEventEditorStore = new GenericResourceEditorStore(
  "receiving event",
  LOADER_TAG_ADMIN_RECEIVING_EVENTS,
  "receivingevent-editor",
  "recdon",
  (params, config) =>
    agent.AdminRecdons.show({ type: "receiving", ...params }, config),
  (params, config) =>
    agent.AdminRecdons.create({ type: "receiving", ...params }, config),
  (params, config) =>
    agent.AdminRecdons.update({ type: "receiving", ...params }, config),
  (params, config) =>
    agent.AdminRecdons.remove({ type: "receiving", ...params }, config),
  newStructure,
  null,
  "Receiving event saved successfully!",
  "Receiving event deleted successfully!"
);

const validate = makeRecordValidator(
  makeFieldValidator("name", "required"),
  makeFieldValidator("date", "required", "date")
);

class ReceivingEventCrud extends React.Component {
  render() {
    const idParam = idFromProps(this.props);
    const isNew = idParam === "new";

    return (
      <AdminSite>
        <Breadcrumbs
          items={[
            { to: "/admin", label: "Admin" },
            { to: "/admin/recdons", label: "Receiving/Donating Events" },
            {
              to: `/admin/receiving-events/${isNew ? "new" : idParam}`,
              label: isNew
                ? "New Receiving Event"
                : `Receiving Event ${idParam}`
            }
          ]}
        />
        <GenericEditor
          name="receiving event"
          nameForTitle="Receiving Event"
          homeResourcePath="/admin/recdons"
          basePath="/admin/receiving-events"
          store={receivingEventEditorStore}
          loaderTag={LOADER_TAG_ADMIN_RECEIVING_EVENTS}
          recordValidator={validate}
          initialFormValuesFn={initialFormValuesFromReceivingEvent}
        >
          <TextFieldWithErrors name="name" label="Name" />
          <DateFieldWithErrors name="date" label="Date" hasTodayButton />
        </GenericEditor>
      </AdminSite>
    );
  }
}

export default compose(observer)(ReceivingEventCrud);
