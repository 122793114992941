import React, { Component } from "react";
import SelectFieldImmediate from "../SelectFieldImmediate";
import agent from "../../../agent";
import { LOADER_TAG_ADMIN_FIELD_ORGANIZATION } from "../../../constants";

export default class SelectOrganization extends Component {
  render() {
    return (
      <SelectFieldImmediate
        getFn={() =>
          agent.AdminFields.allOfType(
            { field: "organization" },
            { loaderTag: LOADER_TAG_ADMIN_FIELD_ORGANIZATION }
          )
        }
        loaderTag={LOADER_TAG_ADMIN_FIELD_ORGANIZATION}
        {...this.props}
      />
    );
  }
}
