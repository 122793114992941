/**
 * TooManyRequestsError
 */
class TooManyRequestsError {
  constructor(message) {
    this.name = "TooManyRequestsError";
    this.alertConfigFinal = { group: "too-many-requests" };
    this.message = message;
    this.stack = new Error().stack; // Optional
  }
}
TooManyRequestsError.prototype = Object.create(Error.prototype);

export default TooManyRequestsError;
