import {
  PLAN_LIFE,
  PLAN_DAY,
  PLAN_MONTH,
  PLAN_YEAR,
  PLAN_MONTH_RENEW,
  PLAN_YEAR_RENEW,
  VISIT_TYPE_MEMBER,
  VISIT_TYPE_VOLUNTEER,
  VISIT_TYPE_VISITOR,
  VISIT_TYPE_WTFNIGHT,
  VISIT_TYPE_CLASS,
  BIKE_STATUS_NEW,
  BIKE_STATUS_AVAILABLE,
  BIKE_STATUS_DONATED,
  BIKE_STATUS_SCRAPPED,
  BIKE_STATUS_SOLD,
  BIKE_STATUS_MISSING,
  BIKE_STATUS_RETURNED,
  BIKE_CONDITION_UNKNOWN,
  BIKE_CONDITION_POOR,
  BIKE_CONDITION_GOOD,
  BIKE_CONDITION_GREAT,
  BIKE_CONDITION_NEW,
  SHIPMENT_STATUS_UNMARKED,
  SHIPMENT_STATUS_SHIPPED,
  SHIPMENT_STATUS_WAIVED,
  FIELD_EDITOR_ACTION_CREATE,
  FIELD_EDITOR_ACTION_UPDATE,
  FIELD_EDITOR_ACTION_DELETE
} from "./constants";

/**
 * BSBC Front-end Config
 */

/**
 * NODE_ENV is injected by webpack during compilation.
 * This turns it a simple boolean production flag.
 */
export const IS_PRODUCTION = process.env.NODE_ENV === "production";

/**
 * Base URL for the endpoints in /agent.js
 */
export const API_ROOT = IS_PRODUCTION
  ? "https://api.bsbc.co/api"
  : "http://localhost/api";

/**
 * SEO: Site url (no trailing comma)
 */
export const SEO_SITE_URL = "https://bsbc.co";

/**
 * SEO: Site name (og:site_name)
 */
export const SEO_SITE_NAME = "Bradley Street Bicycle Co-op";

/**
 * SEO: Site description
 */
export const SEO_SITE_DESCRIPTION =
  "The Bradley Street Bicycle Co-op is a community bike shop working towards an equitable New Haven by getting people on bikes.";

/**
 * SEO: Article Publisher
 */
export const SEO_ARTICLE_PUBLISHER = "https://www.facebook.com/bsbc.co";

/**
 * SEO: Schema.org spec (in JSON-LD), for Google Knowledge Graph
 * See https://schema.org/BikeStore for fields relevant to bike shops!
 */
export const SEO_SCHEMA_ORG_JSON = {
  "@context": "https://schema.org",
  "@id": SEO_SITE_URL,
  "@type": "BikeStore",
  url: SEO_SITE_URL,
  sameAs: [
    "https://www.facebook.com/bsbc.co/",
    "https://www.instagram.com/bsbcco/"
  ],
  name: SEO_SITE_NAME,
  description: SEO_SITE_DESCRIPTION,
  logo: "https://bsbc.co/img/logo-huge.jpg",
  currenciesAccepted: "USD",
  openingHours: "Tu,We,Th 16:00-19:00",
  paymentAccepted: "Cash, Credit Card",
  priceRange: "$",
  address: {
    "@type": "PostalAddress",
    addressLocality: "New Haven",
    addressRegion: "CT",
    postalCode: "06511",
    streetAddress: "138 Bradley Street"
  },
  founder: {
    "@type": "Person",
    name: "John Martin"
  }
};

/**
 * Google Analytics ID
 */
export const GOOGLE_ANALYTICS_ID = IS_PRODUCTION
  ? "UA-91343762-1" // (same as old site)
  : null; // don't track in dev

/**
 * Don't track any paths starting with these prefixes
 */
export const GOOGLE_ANALYTICS_DONT_TRACK_PREFIXES = ["/admin", "/station"];

/**
 * Square application id (for SqPaymentForm)
 */
export const SQUARE_APPLICATION_ID = IS_PRODUCTION
  ? "sq0idp-95vgqYeWzrHYJhKH7x5oow" // production application id
  : "sandbox-sq0idp--xw_XYDeKCwa9JMX8kB69A"; // andrew's sandbox application id

/**
 * Square location id (for SqPaymentForm)
 */
export const SQUARE_LOCATION_ID = IS_PRODUCTION
  ? "6HHP3AVYZ5XSP" // production location id
  : "PY9TD64352RN1"; // andrew's sandbox location id

/**
 * Google ReCAPTCHA site key
 * (set in .env.development / .env.production)
 * (also accessed in index.html)
 */
export const RECAPTCHA_SITE_KEY =
  process.env.REACT_APP_RECAPTCHA_SITE_KEY || null;

/**
 * Google Calendar ID (URL)
 * Must be a public calendar
 * (set in .env.development / .env.production)
 */
export const GOOGLE_CALENDAR_ID =
  process.env.REACT_APP_GOOGLE_CALENDAR_ID || null;

/**
 * DonorBox Embed URL
 */
export const DONORBOX_EMBED_URL = "https://donorbox.org/embed/donate-to-coop";

/**
 * Timezone for parsing some dates coming from server as YYYY-MM-DD
 * (such as membership dates)
 */
export const DATE_TIMEZONE = "America/New_York";

/**
 * Document title templates and defaults (used by react-helmet in <BaseHelmet />)
 */
export const TITLE_DEFAULT_PUBLIC = "Bradley Street Bicycle Co-op";
export const TITLE_TEMPLATE_PUBLIC = "%s | Bradley Street Bicycle Co-op";
export const TITLE_DEFAULT_ADMIN = "BSBC Admin";
export const TITLE_TEMPLATE_ADMIN = "%s | BSBC Admin";
export const TITLE_DEFAULT_STATION = "BSBC Station";
export const TITLE_TEMPLATE_STATION = "%s | BSBC  Station";

/**
 * Path to redirect to after successful authentication (if redirectAfterLogin state isn't set)
 */
export const REDIRECT_AFTER_LOGIN_DEFAULT = "/";

/**
 * Path to redirect to after successful station authentication
 */
export const REDIRECT_AFTER_LOGIN_STATION = "/station";

/**
 * Path for login (redirected here if not authenticated)
 */
export const LOGIN_PATH = "/auth/login";

/**
 * Path for 404 not found
 */
export const NOTFOUND_PATH = "/not-found";

/**
 * Regular expression to test email addresses
 */
export const EMAIL_REGEX = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

/**
 * Regular expression to test phone numbers (pretty strict)
 */
export const PHONE_REGEX = /^\+?\d+$/;

/**
 * Translate alert types to notification colors (defined by bloomer/bulma)
 */
export const ALERT_COLORS = {
  default: "light",
  error: "danger",
  warning: "warning",
  success: "success",
  info: "info"
};

/**
 * Translate some back-end generic error messages (NOT for fields) for users
 */
export const TRANSLATE_ERROR_MESSAGES = {
  "422 Unprocessable Entity":
    "Please correct the errored fields below and try again.",
  "The given data was invalid.":
    "Please correct the errored fields below and try again."
};

export const TRANSLATE_SHIPMENT_STATUSES = {
  unmarked: "Awaiting shipment",
  shipped: "Shipped!",
  waived: "Order did not contain any shippable items"
};

/**
 * Map of react-router paths to remote page ids for retrieval
 * Also used to create react-router routes
 */
export const REMOTE_PAGES = {
  "/about": 1,
  "/about/shop-hours": 2,
  "/about/bicycle-recycling": 3,
  "/about/wtf-nights": 4,
  "/about/faq": 5,
  "/terms": 6,
  "/covid19": 7,
  "/jobs": 10
};

/**
 * Disregard these users from the leaderboards
 */
export const LEADERBOARD_DISREGARD_USERS = IS_PRODUCTION
  ? [
      // 1 // andrew suzuki
    ]
  : [];

/**
 * Volunteer jump codes (station)
 */
export const VOLUNTEER_JUMP_CODES = IS_PRODUCTION
  ? {
      // Production jump codes
      a: 1, // andrew suzuki
      s: 5, // stuart lawson
      t: 7, // taylor holdaway
      k: 8, // kai addae
      d: 9, // dante archangeli
      j: 10, // john martin
      l: 15 // lester mcholland
    }
  : {
      // Dev jump codes
      a: 1 // andrew suzuki
    };

/**
 * Header title in admin
 */
export const ADMIN_TITLE = "BSBC Admin";

/**
 * Plan display names
 */
export const PLAN_DISPLAY_NAMES = {
  [PLAN_LIFE]: "Life",
  [PLAN_DAY]: "Day",
  [PLAN_MONTH]: "Month",
  [PLAN_YEAR]: "Year",
  [PLAN_MONTH_RENEW]: "Month (renew)",
  [PLAN_YEAR_RENEW]: "Year (renew)"
};

/**
 * Shop Visit type display names
 */
export const VISIT_DISPLAY_NAMES = {
  [VISIT_TYPE_VOLUNTEER]: "Volunteer",
  [VISIT_TYPE_MEMBER]: "Member",
  [VISIT_TYPE_VISITOR]: "Visitor", // (not used yet)
  [VISIT_TYPE_WTFNIGHT]: "WTF Night",
  [VISIT_TYPE_CLASS]: "Class"
};

/**
 * Enum: Membership Plans
 */
export const ENUM_MEMBERSHIP_PLANS = [
  PLAN_LIFE,
  PLAN_DAY,
  PLAN_MONTH,
  PLAN_YEAR,
  PLAN_MONTH_RENEW,
  PLAN_YEAR_RENEW
];

/**
 * Enum: Grantable Membership Plans
 */
export const ENUM_GRANTABLE_PLANS = [
  PLAN_LIFE,
  PLAN_DAY,
  PLAN_MONTH,
  PLAN_YEAR
];

/**
 * Enum: Quantifiable Membership Plans
 */
export const ENUM_QUANTIFIABLE_PLANS = [PLAN_MONTH, PLAN_YEAR];

/**
 * Enum: Renewable Membership Plans
 */
export const ENUM_RENEWABLE_PLANS = [PLAN_MONTH_RENEW, PLAN_YEAR_RENEW];

/**
 * Enum: Shop Visit Types
 */
export const ENUM_VISIT_TYPES = [
  VISIT_TYPE_MEMBER,
  VISIT_TYPE_VOLUNTEER,
  VISIT_TYPE_VISITOR,
  VISIT_TYPE_WTFNIGHT,
  VISIT_TYPE_CLASS
];

/**
 * Enum: Bike Status
 */
export const ENUM_BIKE_STATUSES = [
  BIKE_STATUS_NEW,
  BIKE_STATUS_AVAILABLE,
  BIKE_STATUS_DONATED,
  BIKE_STATUS_SCRAPPED,
  BIKE_STATUS_SOLD,
  BIKE_STATUS_MISSING,
  BIKE_STATUS_RETURNED
];

/**
 * Enum: Bike Condition
 */
export const ENUM_BIKE_CONDITIONS = [
  BIKE_CONDITION_UNKNOWN,
  BIKE_CONDITION_POOR,
  BIKE_CONDITION_GOOD,
  BIKE_CONDITION_GREAT,
  BIKE_CONDITION_NEW
];

/**
 * Enum: Order Shipment Statuses
 */
export const ENUM_SHIPMENT_STATUSES = [
  SHIPMENT_STATUS_UNMARKED,
  SHIPMENT_STATUS_SHIPPED,
  SHIPMENT_STATUS_WAIVED
];

/**
 * BSize order map
 * used in sort comparator for admin bike table
 */
export const BSIZE_ORDER = {
  XXS: 0, // does this actually exist? doesn't matter
  XS: 1,
  S: 2,
  M: 3,
  L: 4,
  XL: 5,
  XXL: 6 // does this actually exist? doesn't matter
};

/**
 * The standard final-form FieldState subscriptions
 */
export const standardFinalFormSubscriptions = {
  submitting: true,
  hasValidationErrors: true,
  hasSubmitErrors: true,
  errors: true,
  touched: true
};

export const FIELD_EDITOR = {
  organization: {
    title: "Organization",
    can: [
      FIELD_EDITOR_ACTION_CREATE,
      FIELD_EDITOR_ACTION_UPDATE,
      FIELD_EDITOR_ACTION_DELETE
    ]
  },
  bcolor: {
    title: "Bike Color",
    can: [
      FIELD_EDITOR_ACTION_CREATE,
      FIELD_EDITOR_ACTION_UPDATE,
      FIELD_EDITOR_ACTION_DELETE
    ]
  },
  bmake: {
    title: "Bike Make",
    can: [
      FIELD_EDITOR_ACTION_CREATE,
      FIELD_EDITOR_ACTION_UPDATE,
      FIELD_EDITOR_ACTION_DELETE
    ]
  },
  bmodel: {
    title: "Bike Model",
    can: [
      FIELD_EDITOR_ACTION_CREATE,
      FIELD_EDITOR_ACTION_UPDATE,
      FIELD_EDITOR_ACTION_DELETE
    ]
  },
  bsize: {
    title: "Bike Size",
    can: [
      FIELD_EDITOR_ACTION_CREATE,
      FIELD_EDITOR_ACTION_UPDATE,
      FIELD_EDITOR_ACTION_DELETE
    ]
  },
  bstyle: {
    title: "Bike Style",
    can: [
      FIELD_EDITOR_ACTION_CREATE,
      FIELD_EDITOR_ACTION_UPDATE,
      FIELD_EDITOR_ACTION_DELETE
    ]
  },
  dagerange: {
    title: "D-Set Age Range",
    can: [
      FIELD_EDITOR_ACTION_CREATE,
      FIELD_EDITOR_ACTION_UPDATE,
      FIELD_EDITOR_ACTION_DELETE
    ]
  },
  dgender: {
    title: "D-Set Gender",
    can: [
      FIELD_EDITOR_ACTION_CREATE,
      FIELD_EDITOR_ACTION_UPDATE,
      FIELD_EDITOR_ACTION_DELETE
    ]
  },
  dhouseholdcount: {
    title: "D-Set Household Count",
    can: [
      FIELD_EDITOR_ACTION_CREATE,
      FIELD_EDITOR_ACTION_UPDATE,
      FIELD_EDITOR_ACTION_DELETE
    ]
  },
  dincomerange: {
    title: "D-Set Income Range",
    can: [
      FIELD_EDITOR_ACTION_CREATE,
      FIELD_EDITOR_ACTION_UPDATE,
      FIELD_EDITOR_ACTION_DELETE
    ]
  },
  dnationality: {
    title: "D-Set Nationality",
    can: [
      FIELD_EDITOR_ACTION_CREATE,
      FIELD_EDITOR_ACTION_UPDATE,
      FIELD_EDITOR_ACTION_DELETE
    ]
  },
  dneighborhood: {
    title: "D-Set Neighborhood",
    can: [
      FIELD_EDITOR_ACTION_CREATE,
      FIELD_EDITOR_ACTION_UPDATE,
      FIELD_EDITOR_ACTION_DELETE
    ]
  },
  drace: {
    title: "D-Set Race",
    can: [
      FIELD_EDITOR_ACTION_CREATE,
      FIELD_EDITOR_ACTION_UPDATE,
      FIELD_EDITOR_ACTION_DELETE
    ]
  },
  dresidence: {
    title: "D-Set Residence",
    can: [
      FIELD_EDITOR_ACTION_CREATE,
      FIELD_EDITOR_ACTION_UPDATE,
      FIELD_EDITOR_ACTION_DELETE
    ]
  },
  demploymentstatus: {
    title: "D-Set Employment Status",
    can: [
      FIELD_EDITOR_ACTION_CREATE,
      FIELD_EDITOR_ACTION_UPDATE,
      FIELD_EDITOR_ACTION_DELETE
    ]
  },
  dbikeuse: {
    title: "D-Set Bike Use",
    can: [
      FIELD_EDITOR_ACTION_CREATE,
      FIELD_EDITOR_ACTION_UPDATE,
      FIELD_EDITOR_ACTION_DELETE
    ]
  }
};

/**
 * Map of BColor names to css3-valid color codes (bg and fg)
 */
// common colors
const COLOR_WHITE = "white";
const COLOR_BLACK = "black";
export const COLORS = {
  "*SPLATTER*": {},
  "*FADE*": {},
  "*SPRAY PAINT*": {},
  "beige/tan": { backgroundColor: "#F5F5DC", color: COLOR_BLACK },
  black: { backgroundColor: COLOR_BLACK, color: COLOR_WHITE },
  blue: { backgroundColor: "#0000FF", color: COLOR_WHITE },
  "blue (light/baby)": { backgroundColor: "#add8e6", color: COLOR_BLACK },
  "blue (cyan/neon)": { backgroundColor: "#00FFFF", color: COLOR_WHITE },
  "blue (teal/bluegreen)": { backgroundColor: "#008080", color: COLOR_WHITE },
  "blue (dark/navy)": { backgroundColor: "#000080", color: COLOR_WHITE },
  brown: { backgroundColor: "#8b4513", color: COLOR_BLACK },
  gray: { backgroundColor: "#808080", color: COLOR_BLACK },
  green: { backgroundColor: "#008000", color: COLOR_WHITE },
  "green (acid/neon)": { backgroundColor: "#39ff14", color: COLOR_WHITE },
  "green (celeste/mint)": { backgroundColor: "#2AFFD4", color: COLOR_WHITE },
  "green (lime)": { backgroundColor: "#BFFF00", color: COLOR_BLACK },
  orange: { backgroundColor: "#ffa500", color: COLOR_BLACK },
  "orange (copper)": { backgroundColor: "#b87333", color: COLOR_BLACK },
  "orange (salmon)": { backgroundColor: "#ff8c69", color: COLOR_BLACK },
  "orange (dark/burnt)": { backgroundColor: "#CC5500", color: COLOR_BLACK },
  pink: { backgroundColor: "#ffc0cb", color: COLOR_BLACK },
  purple: { backgroundColor: "#800080", color: COLOR_WHITE },
  red: { backgroundColor: "#ff0000", color: COLOR_BLACK },
  "red (orangered)": { backgroundColor: "#FF4500", color: COLOR_BLACK },
  "red (maroon/burgundy/plum)": {
    backgroundColor: "#800000",
    color: COLOR_WHITE
  },
  silver: {
    backgroundColor: "#c9c6c6",
    color: COLOR_BLACK
  },
  "silver (chrome)": {
    backgroundColor: "#c9c6c6",
    backgroundImage: "linear-gradient(to top, #c9c6c6, #f1f2f6)",
    color: COLOR_BLACK
  },
  white: { backgroundColor: "#f6f6f6", color: COLOR_BLACK }, // (bg is not-quite-white!)
  "white (cream)": { backgroundColor: "#fffaf0", color: COLOR_BLACK },
  yellow: { backgroundColor: "#ffff00", color: COLOR_BLACK },
  "yellow (gold)": {
    backgroundColor: "#ffd700",
    backgroundImage: "linear-gradient(to top, #fbb034, #ffd700)",
    color: COLOR_BLACK
  }
};

/**
 * Shippable countries
 */
export const SHIPPABLE_COUNTRIES = ["United States"];

/**
 * Short US state names
 */
export const SHORT_STATES = [
  "AL",
  "AK",
  "AS",
  "AZ",
  "AR",
  "CA",
  "CO",
  "CT",
  "DE",
  "DC",
  "FM",
  "FL",
  "GA",
  "GU",
  "HI",
  "ID",
  "IL",
  "IN",
  "IA",
  "KS",
  "KY",
  "LA",
  "ME",
  "MH",
  "MD",
  "MA",
  "MI",
  "MN",
  "MS",
  "MO",
  "MT",
  "NE",
  "NV",
  "NH",
  "NJ",
  "NM",
  "NY",
  "NC",
  "ND",
  "MP",
  "OH",
  "OK",
  "OR",
  "PW",
  "PA",
  "PR",
  "RI",
  "SC",
  "SD",
  "TN",
  "TX",
  "UT",
  "VT",
  "VI",
  "VA",
  "WA",
  "WV",
  "WI",
  "WY",
  "AE",
  "AP",
  "AA"
];
