import React, { Component } from "react";
import { decorate, computed } from "mobx";
import { observer } from "mobx-react";

import { calculateCartTotals, formatMoney } from "../../utils";

import PlainInfoParagraph from "../../components/PlainInfoParagraph";
import LinkButton from "../../components/LinkButton";

import CartItem from "./CartItem";

class CartInner extends Component {
  render() {
    const { cart, onUpdate, onRemove, showCheckoutButton = true } = this.props;

    const totals = calculateCartTotals(this.props.cart);

    return (
      <div>
        {cart && cart.items && cart.items.length ? (
          <div>
            {cart.items.map(item => (
              <CartItem
                key={item.cart_item.id}
                item={item}
                onUpdate={onUpdate}
                onRemove={onRemove}
              />
            ))}
            <br />
            {!!totals.shipment_price && (
              <div>
                <strong>Subtotal:</strong> {formatMoney(totals.subtotal)}
                <br />
                <strong>Shipping:</strong> {formatMoney(totals.shipment_price)}
              </div>
            )}
            <strong>Total:</strong> {formatMoney(totals.total)}
            {showCheckoutButton && (
              <div>
                <br />
                <LinkButton
                  to={{
                    pathname: "/checkout",
                    state: { didGetHereFromCart: true }
                  }}
                  isColor="primary"
                  isSize="medium"
                >
                  Checkout
                </LinkButton>
              </div>
            )}
          </div>
        ) : (
          <PlainInfoParagraph>Your cart is empty.</PlainInfoParagraph>
        )}
      </div>
    );
  }
}

decorate(CartInner, {
  totals: computed
});

export default observer(CartInner);
