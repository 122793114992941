import React from "react";
import { Columns, Column, Box, Title } from "bloomer";
import { Link, withRouter } from "react-router-dom";
import Hotkeys from "react-hot-keys";
import styles from "./dashboard.module.scss";

class _BigLinkColumn extends React.PureComponent {
  onHotkey = (keyName, e) => {
    e.preventDefault();
    this.props.history.push(this.props.to);
  };

  render() {
    const { to, title, iconClass, hotkey } = this.props;
    return (
      <Column isSize={"1/2"}>
        {hotkey && <Hotkeys keyName={hotkey} onKeyDown={this.onHotkey} />}
        <Box isPaddingless className={styles.box}>
          <Link to={to} className={styles.biglink}>
            <Title isSize="3">{title}</Title>
            <span className={`fas ${iconClass} fa-10x`} />
            {hotkey && (
              <div className={styles.hotkeyHint}>
                <span className="has-soft-yellow-bg">{hotkey}</span>
              </div>
            )}
          </Link>
        </Box>
      </Column>
    );
  }
}

const BigLinkColumn = withRouter(_BigLinkColumn);

const BigLinks = () => (
  <Columns isMultiline>
    <BigLinkColumn
      to="/station/check-in"
      title="Check In"
      iconClass="fa-user-check"
      hotkey="i"
    />
    <BigLinkColumn
      to="/station/check-out"
      title="Check Out"
      // iconClass="fa-sign-out-alt"
      iconClass="fa-user-clock"
      hotkey="o"
    />
    <BigLinkColumn
      to="/station/sign-up"
      title="New Shop User"
      iconClass="fa-user-plus"
    />
    <BigLinkColumn
      to="/station/record-work"
      title="Record Work"
      iconClass="fa-bicycle"
      hotkey="r"
    />
    <BigLinkColumn
      to="/station/waiver"
      title="Sign Waiver"
      iconClass="fa-file-signature"
    />
    <BigLinkColumn
      to="/station/newsletter"
      title="Subscribe"
      iconClass="fa-mail-bulk"
    />
  </Columns>
);

export default BigLinks;
