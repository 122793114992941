import React from "react";
import { Field } from "react-final-form";
import {
  Field as FieldStyle,
  Label,
  Control,
  Input,
  Help,
  Button,
  Tag
} from "bloomer";
import styles from "./bike-parts-field.module.scss";
import { deriveFieldError } from "../../../utils";
import SelectBikePart from "../../selects/immediate/SelectBikePart";

class Inner extends React.PureComponent {
  get val() {
    return this.props.value || [];
  }

  handleUpdateQuantity = idx => e => {
    const newBikeParts = this.val.map((bikePart, sidx) => {
      if (idx !== sidx) {
        return bikePart;
      }

      const newQuantity = parseInt(e.target.value, 10) || 0;

      return {
        ...bikePart,
        pivot: {
          ...bikePart.pivot,
          quantity: newQuantity
        }
      };
    });

    this.props.onChange(newBikeParts);
  };

  handleSelect = ({ value: bikePart }) => {
    // Bubble up with new bike part and "fake" pivot data
    this.props.onChange([
      ...this.val,
      {
        ...bikePart,
        pivot: {
          quantity: 1,
          type: bikePart.filled_type // use filled type
        }
      }
    ]);
  };

  handleRemove = idx => () => {
    this.props.onChange(this.val.filter((s, sidx) => idx !== sidx));
  };

  render() {
    const { errors = {}, isStation } = this.props;

    const selectIgnore = this.val.reduce(
      (acc, bikePart) => ({
        ...acc,
        [bikePart.id]: [...(acc[bikePart.id] || []), bikePart.pivot.type]
      }),
      {}
    );

    return (
      <div>
        <p>
          <SelectBikePart
            value={null} // never fill this field
            controlShouldRenderValue={false}
            onChange={this.handleSelect}
            ignore={selectIgnore}
            isStation={isStation}
          />
        </p>
        {!this.val.length ? (
          <p>
            <em>None selected</em>
          </p>
        ) : (
          <ul>
            {this.val.map((bikePart, idx) => (
              <li key={idx} className={styles.existing}>
                <Tag
                  isColor={bikePart.pivot.type === "new" ? "success" : "link"}
                  className={styles.mrTag}
                >
                  {bikePart.pivot.type}
                </Tag>
                {(bikePart.is_deleted || bikePart.is_outdated) && (
                  <Tag isColor="warning" className={styles.mrTag}>
                    {bikePart.is_deleted ? "Deleted" : "Outdated"}
                  </Tag>
                )}
                <div className={styles.name}>{bikePart.title}</div>
                <FieldStyle hasAddons className={styles.field}>
                  <Control>
                    <Input
                      type="number"
                      min="1"
                      step="1"
                      placeholder="Quantity"
                      value={bikePart.pivot.quantity}
                      onChange={this.handleUpdateQuantity(idx)}
                      onBlur={this.props.onBlur}
                      onFocus={this.props.onFocus}
                      isColor={errors[idx] ? "danger" : null}
                      className={styles.quantity}
                      isSize="small"
                    />
                  </Control>
                  <Control>
                    <Button
                      onClick={this.handleRemove(idx)}
                      isColor="danger"
                      isSize="small"
                    >
                      Remove
                    </Button>
                  </Control>
                </FieldStyle>
                {errors[idx] && (
                  <Help isColor="danger">
                    {/* Error a map of subfield => message.
                        Just join the messages together for now. */}
                    {Object.values(errors[idx]).join(" ")}
                  </Help>
                )}
              </li>
            ))}
          </ul>
        )}
      </div>
    );
  }
}

class BikePartsField extends React.PureComponent {
  render() {
    const { name = "bikeparts", help = null, isStation = false } = this.props;

    return (
      <Field
        name={name}
        subscription={{
          value: true,
          error: true,
          submitError: true,
          touched: true,
          dirtySinceLastSubmit: true
        }}
      >
        {({ input, meta }) => (
          <FieldStyle>
            <Label>Bike Parts</Label>
            {help}
            <Control>
              {React.createElement(Inner, {
                errors: deriveFieldError(meta),
                isStation,
                ...input
              })}
            </Control>
          </FieldStyle>
        )}
      </Field>
    );
  }
}

export default BikePartsField;
