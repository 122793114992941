import React from "react";
import { observer, inject } from "mobx-react";
import { Columns, Column } from "bloomer";

import PublicSite from "../../../../components/PublicSite";
import { compose } from "../../../../utils";
import Menu from "./Menu";

/**
 * Account Wrapper
 *
 * Provides account side menu and basic column layout.
 *
 * Since most account routes depend on authStore having a user,
 * Wrapper accepts the `children` prop as a render function that will only
 * be called if there is a user in authStore.
 */
class Wrapper extends React.Component {
  render() {
    const { authStore, children } = this.props;
    const { isAuthenticated } = authStore;

    return (
      <PublicSite>
        <Columns>
          <Column isSize={4}>
            <Menu />
          </Column>
          <Column>
            {isAuthenticated &&
              (typeof children === "function" ? children() : children)}
          </Column>
        </Columns>
      </PublicSite>
    );
  }
}

export default compose(
  inject("authStore"),
  observer
)(Wrapper);
