import { observable, decorate, action, runInAction } from "mobx";
import agent from "../agent";
import { LOADER_TAG_ADMIN_REPORTS } from "../constants";

export class ReportAdminStore {
  reports = observable.map();

  clear() {
    this.reports.clear();
  }

  // action
  loadAll() {
    return agent.AdminReports.all({ loaderTag: LOADER_TAG_ADMIN_REPORTS }).then(
      body => {
        const reports = body.data;

        const reportsMap = reports.reduce((acc, report) => {
          acc[report.name] = report;
          return acc;
        }, {});

        runInAction(() => {
          this.reports.replace(reportsMap);
        });

        return reportsMap;
      }
    );
  }
}

decorate(ReportAdminStore, {
  reports: observable,
  clear: action.bound,
  loadAll: action.bound
});

export default new ReportAdminStore();
