import React, { PureComponent } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer
} from "recharts";

export default class BarPerYear extends PureComponent {
  render() {
    const { yearMap, dataKey } = this.props;

    if (!yearMap) {
      return "n/a";
    }

    const data = Object.entries(yearMap).reduce(
      (acc, [year, entries]) => [...acc, { year, [dataKey]: entries }],
      []
    );

    return (
      <ResponsiveContainer width="100%" height={300}>
        <BarChart data={data}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="year" />
          <YAxis />
          <Tooltip />
          <Bar dataKey={dataKey} fill="#8884d8" />
        </BarChart>
      </ResponsiveContainer>
    );
  }
}
