import { observable, decorate, runInAction, action } from "mobx";
import agent from "../agent";
import { LOADER_TAG_STATION_CIP2W } from "../constants";

// checked-in users from past two weeks station store

export class Cip2wStationStore {
  users = observable.array();

  loaded = false;

  clear() {
    this.users.clear();
    this.loaded = false;
  }

  loadOnce() {
    if (this.loaded) {
      return;
    }

    this.loaded = true;

    return agent.StationAccount.cip2w({
      loaderTag: LOADER_TAG_STATION_CIP2W
    }).then(body => {
      const users = body.data;
      runInAction(() => {
        this.users.replace(users);
      });
      return users;
    });
  }
}

decorate(Cip2wStationStore, {
  users: observable,
  loaded: observable,
  clear: action.bound,
  loadOnce: action.bound
});

export default new Cip2wStationStore();
