/**
 * ValidationError holds nested field-level errors
 * ({ fieldName: errorMessage, ... })
 */
class ValidationError {
  constructor(message, fields = {}) {
    this.name = "ValidationError";
    this.message = message;
    this.fields = fields;
    this.stack = new Error().stack; // Optional
  }
}
ValidationError.prototype = Object.create(Error.prototype);

export default ValidationError;
