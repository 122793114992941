import React from "react";

import BaseSortTable from "./BaseSortTable";
import {
  idColumn,
  createdAtColumn,
  updatedAtColumn,
  makeActionsColumn
} from "./columns";
import {
  cellBoolean,
  cellMoney,
  cellSimpleList,
  cellDemographicSet,
  cellUserName,
  wrapColumn,
  userColumn,
  plainLocalDateColumn,
  objectNameColumn,
  NotesTooltip,
  nullElement
} from "./helpers";
import BColorTags from "../BColorTags";
import { sortBSizeComparator } from "../../utils";

const getPropsGreyOutNoFixCell = (state, rowInfo) => {
  if (!rowInfo || !rowInfo.original.is_nofix) {
    return {};
  }

  // grey-out nofix backgrounds
  return {
    style: {
      background: "#cccccc"
    }
  };
};

const columns = [
  makeActionsColumn("/admin/bikes"),
  {
    Header: "General",
    headerStyle: {
      color: "white",
      background: "#363636"
    },
    columns: [
      idColumn,
      {
        Header: "Status",
        accessor: "status",
        width: 80
      },
      {
        Header: "Received At",
        accessor: "received_at",
        ...plainLocalDateColumn
      },
      {
        Header: "Admin Notes",
        accessor: "notes_admin",
        width: 150,
        Cell: ({ value }) => <NotesTooltip content={value} />
      }
    ]
  },
  {
    Header: "Reception",
    headerStyle: {
      color: "white",
      background: "#209cee"
    },
    columns: [
      {
        Header: "Condition",
        accessor: "condition",
        width: 80
      },
      {
        Header: "Acquisition",
        accessor: "acquisition",
        ...objectNameColumn,
        width: 120
      },
      {
        Header: "Receiving Event",
        accessor: "receivingevent",
        ...objectNameColumn,
        width: 150
      },
      {
        Header: "Donating Organization",
        accessor: "organization_donating",
        ...objectNameColumn,
        width: 150
      },
      {
        Header: "Donor Email",
        accessor: "donoremail",
        width: 150,
        Cell: ({ value, original }) => {
          if (!value) {
            return nullElement;
          }
          return `${original.is_donoremail_sent ? "[sent] " : ""}${value}`;
        }
      }
    ]
  },
  {
    Header: "Bike",
    headerStyle: {
      color: "white",
      background: "#ff3860"
    },
    columns: [
      {
        Header: "Make",
        accessor: "bmake",
        ...objectNameColumn,
        width: 150
      },
      {
        Header: "Model",
        accessor: "bmodel",
        ...objectNameColumn,
        width: 150
      },
      {
        Header: "Size",
        accessor: "bsize",
        ...objectNameColumn,
        // sort sizes by their actual order instead of alphabetical
        // override objectNameColumn sort
        sortMethod: sortBSizeComparator,
        width: 80
      },
      {
        Header: "Style",
        accessor: "bstyle",
        ...objectNameColumn,
        width: 100
      },
      {
        Header: "Color(s)",
        accessor: "bcolors",
        Cell: ({ value }) => {
          return <BColorTags bcolors={value} />;
        },
        width: 250,
        ...wrapColumn
      },
      {
        Header: "Bike Notes",
        accessor: "notes_bike",
        width: 150,
        Cell: ({ value }) => <NotesTooltip content={value} />
      }
    ]
  },
  {
    Header: "Repair",
    headerStyle: {
      color: "black",
      background: "#ffdd57"
    },
    columns: [
      {
        Header: "No Fix Needed?",
        accessor: "is_nofix",
        Cell: cellBoolean,
        width: 130,
        getProps: getPropsGreyOutNoFixCell
      },
      {
        Header: "Fixed At",
        accessor: "fixed_at",
        ...plainLocalDateColumn,
        getProps: getPropsGreyOutNoFixCell
      },
      {
        Header: "Add. Fix Cost",
        accessor: "fix_cost",
        Cell: cellMoney,
        width: 120,
        getProps: getPropsGreyOutNoFixCell
      },
      {
        Header: "Fix Time",
        accessor: "fix_time",
        width: 100,
        getProps: getPropsGreyOutNoFixCell
      },
      {
        Header: "Mechanics",
        accessor: "mechanics",
        Cell: cellSimpleList(cellUserName, true), // no duplicates!
        width: 300,
        getProps: getPropsGreyOutNoFixCell
      }
    ]
  },
  {
    Header: "Out The Door",
    headerStyle: {
      color: "white",
      background: "#23d160"
    },
    columns: [
      {
        Header: "Price (Sold/Donated)",
        accessor: "price_sold",
        Cell: cellMoney,
        width: 100
      },
      {
        Header: "Sale Notes",
        accessor: "notes_sale",
        width: 150,
        Cell: ({ value }) => <NotesTooltip content={value} />
      },
      {
        Header: "Sold/Donated At",
        accessor: "sold_at",
        ...plainLocalDateColumn
      },
      {
        Header: "Donating Event",
        accessor: "donatingevent",
        ...objectNameColumn,
        width: 150
      },
      {
        Header: "Receiving Organization",
        accessor: "organization_receiving",
        ...objectNameColumn,
        width: 150
      }
    ]
  },
  {
    Header: "Public",
    headerStyle: {
      color: "black",
      background: "rgb(173, 216, 230)"
    },
    columns: [
      {
        Header: "Price (Ask)",
        accessor: "price_asking",
        Cell: cellMoney,
        width: 100
      },
      {
        Header: "List Online?",
        accessor: "list_online",
        Cell: cellBoolean,
        width: 100
      },
      {
        Header: "Online Description",
        accessor: "notes_public",
        width: 150,
        Cell: ({ value }) => <NotesTooltip content={value} />
      }
    ]
  },
  {
    Header: "Recipient",
    headerStyle: {
      color: "black",
      background: "#ffc0cb"
    },
    columns: [
      {
        Header: "Recipient (User)",
        accessor: "recipient",
        ...userColumn
      },
      {
        Header: "Demographic Set",
        accessor: "demographicset",
        Cell: cellDemographicSet,
        width: 150
      }
    ]
  },
  {
    Header: "Tracker Timestamps",
    headerStyle: {},
    columns: [createdAtColumn, updatedAtColumn]
  }
];

class BikeTable extends React.Component {
  render() {
    return (
      <BaseSortTable isFullScreenWidth columns={columns} {...this.props} />
    );
  }
}

export default BikeTable;
