import React from "react";
import { observer, inject } from "mobx-react";
import { Columns, Column } from "bloomer";

import PublicSite from "../../components/PublicSite";
import PageHeading from "../../components/PageHeading";
import PlainInfoParagraph from "../../components/PlainInfoParagraph";
import FullWidthImage from "../../components/FullWidthImage";

import { compose } from "../../utils";
import { LOADER_TAG_PUBLIC_BIKES } from "../../constants";

import BikeCard from "./BikeCard";
import LoadingText from "../../components/LoadingText";

class BikesForSale extends React.Component {
  get store() {
    return this.props.bikePublicStore;
  }

  get loading() {
    return this.props.uiStore.loadingByTag(LOADER_TAG_PUBLIC_BIKES);
  }

  componentDidMount() {
    this.store.loadAll();
  }

  componentWillUnmount() {
    this.store.clear();
  }

  render() {
    const { bikes } = this.store;

    return (
      <PublicSite>
        <PageHeading hasTextAlign="centered">Bikes For Sale</PageHeading>
        <PlainInfoParagraph isCentered={false}>
          Owning a bike will change your life! You meet all sorts of people, get
          places faster and can fully explore the amazing city we live in. We
          have a collection of fully tuned-up bikes of all sorts and sizes.
        </PlainInfoParagraph>
        <PlainInfoParagraph isCentered={false}>
          Prices range between $250-$350 for most bikes. Come and visit us at
          138 Bradley Street during DIY Open Shop hours: Tuesday and
          Thursday, 4-8pm. All bikes sold support the Co-op’s mission of getting
          more affordable bikes out onto the streets of New Haven!
        </PlainInfoParagraph>

        {this.loading ? (
          <LoadingText />
        ) : bikes.length === 0 ? null : (
          // (
          //   <div>
          //     <PlainInfoParagraph>
          //       There aren't any bikes listed at the moment, but we have many for
          //       sale at the shop.
          //     </PlainInfoParagraph>
          //     <PlainInfoParagraph>
          //       Come and visit us at 138 Bradley Street, or{" "}
          //       <Link
          //         to={{
          //           pathname: "/contact",
          //           state: { contactReason: "purchase" }
          //         }}
          //       >
          //         send us an email
          //       </Link>
          //       .
          //     </PlainInfoParagraph>
          //   </div>
          // )
          <div>
            <PlainInfoParagraph isCentered={false}>
              Some of our bikes currently for sale are listed below. We have
              many more available at the shop.
            </PlainInfoParagraph>
            <Columns isMultiline>
              {bikes.map(bike => (
                <Column key={bike.id} isSize={{ tablet: 6, widescreen: 4 }}>
                  <BikeCard bike={bike} />
                </Column>
              ))}
            </Columns>
          </div>
        )}

        <br />

        <FullWidthImage
          src="/img/content/bikes-for-sale/showroom-2019-12-06.jpg"
          alt="BSBC Bike Showroom"
          noMargin
        />
      </PublicSite>
    );
  }
}

export default compose(
  inject("bikePublicStore", "uiStore"),
  observer
)(BikesForSale);
