import React from "react";
import { observer, inject } from "mobx-react";
import { Level, LevelLeft, LevelItem } from "bloomer";

import RentalTable from "../../../components/tables/RentalTable";
import AdminTableSection from "../../../components/AdminTableSection";
import AdminSite from "../../../components/AdminSite";
import ResourceCreateLinkButton from "../../../components/ResourceCreateLinkButton";

import { compose } from "../../../utils";
import { LOADER_TAG_ADMIN_RENTALBIKES } from "../../../constants";
import PageHeading from "../../../components/PageHeading";

class Rentals extends React.Component {
  render() {
    return (
      <AdminSite>
        <PageHeading>Rentals</PageHeading>
        <Level>
          <LevelLeft>
            <LevelItem>
              <ResourceCreateLinkButton
                to="/admin/rentals/periods/new"
                label="New Rental"
                isMarginless
              />
            </LevelItem>
            <LevelItem>
              <ResourceCreateLinkButton
                to="/admin/rentals/new"
                label="New Rental Bike"
                isColor="light"
                isMarginless
              />
            </LevelItem>
          </LevelLeft>
        </Level>
        <AdminTableSection
          store={this.props.rentalAdminStore}
          getter={store => store.rentals.slice()}
          loadAction={store => store.loadAll}
          loaderTag={LOADER_TAG_ADMIN_RENTALBIKES}
          clearAction={store => store.clear}
          tableComponent={RentalTable}
        />
      </AdminSite>
    );
  }
}

export default compose(
  inject("rentalAdminStore"),
  observer
)(Rentals);
