import React, { Component } from "react";
import { Box, Button } from "bloomer";
import { observer, inject } from "mobx-react";

import { compose } from "../../../utils";
import agent from "../../../agent";
import StationSite from "../../../components/StationSite";
import MediumCenterInner from "../../../components/MediumCenterInner";
import CustomFinalForm from "../../../components/CustomFinalForm";
import TextFieldWithErrors from "../../../components/fields/TextFieldWithErrors";
import { makeRecordValidator, makeFieldValidator } from "../../../validators";
import formValidationErrorLayer from "../../../errors/formValidationErrorLayer";
import PageHeading from "../../../components/PageHeading";
import SearchUsers from "../common/SearchUsers";
import { LOADER_TAG_STATION_WAIVER } from "../../../constants";
import { standardFinalFormSubscriptions } from "../../../config";

import WAIVER from "../../../WAIVER_01";
import FinalSubmit from "../../../components/fields/FinalSubmit";

const WAIVER_ALERT_GROUP = "waiver";

const validate = makeRecordValidator(
  makeFieldValidator("signature", "required")
);

class Waiver extends Component {
  state = { selectedUser: null };

  onSubmit = ({ signature }) => {
    this.props.uiStore.clearAlertsAfterRouteChange();

    return formValidationErrorLayer(
      agent.StationAccount.waiver(
        { id: this.state.selectedUser.id, signature },
        {
          loaderTag: LOADER_TAG_STATION_WAIVER,
          alertGroup: WAIVER_ALERT_GROUP,
          notFoundName: "user"
        }
      ).then(() => {
        this.props.uiStore.addAlert(
          "success",
          <span>
            Waiver was signed successfully! Lastly, please choose your visit
            type for today.
          </span>,
          { group: WAIVER_ALERT_GROUP }
        );

        // redirect
        this.props.history.push(
          `/station/check-in?forcedUserId=${this.state.selectedUser.id}`,
          { wasRedirect: true }
        );
      })
    ).catch(() => null);
  };

  onSelectUser = user => {
    if (user.did_sign_waiver) {
      // already signed; show error
      this.props.uiStore.addAlert("error", "You already signed the waiver.");
      return;
    }

    this.setState({ selectedUser: user });
  };

  get loading() {
    return this.props.uiStore.loadingByTag(LOADER_TAG_STATION_WAIVER);
  }

  render() {
    const { selectedUser } = this.state;
    return (
      <StationSite>
        <MediumCenterInner>
          <Box>
            <PageHeading>Sign Waiver</PageHeading>
            {selectedUser ? (
              <div>
                <p>
                  Signing waiver as{" "}
                  <b>{selectedUser.name || selectedUser.email}</b>
                </p>
                <WAIVER />
                <br />
                <CustomFinalForm
                  onSubmit={this.onSubmit}
                  validate={validate}
                  initialValues={{ email: null }}
                  subscription={standardFinalFormSubscriptions}
                  render={renderProps => {
                    const { handleSubmit } = renderProps;
                    return (
                      <form onSubmit={handleSubmit}>
                        <TextFieldWithErrors
                          type="text"
                          name="signature"
                          label="Signature"
                        />
                        <FinalSubmit {...renderProps} loading={this.loading} />
                      </form>
                    );
                  }}
                />
              </div>
            ) : (
              <div>
                <p>
                  Review and sign our waiver before working as a volunteer or
                  member at BSBC. First, search for your user account below. If
                  you don't have an account yet, please sign up first.
                </p>
                <SearchUsers
                  onForceSelect={this.onSelectUser}
                  renderActions={user => {
                    return !user.did_sign_waiver ? (
                      <div>
                        <Button
                          onClick={() => this.onSelectUser(user)}
                          isColor="primary"
                        >
                          Select
                        </Button>
                      </div>
                    ) : (
                      <b>Already Signed</b>
                    );
                  }}
                />
              </div>
            )}
          </Box>
        </MediumCenterInner>
      </StationSite>
    );
  }
}

export default compose(
  inject("uiStore"),
  observer
)(Waiver);
