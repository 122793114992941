import React, { Component } from "react";
import { Container } from "bloomer";
import { Route } from "react-router-dom";

import LinkButton from "../../components/LinkButton";
import Logo from "../../components/Logo";

import styles from "./station-site.module.scss";

const homePath = "/station";

export default class Header extends Component {
  render() {
    return (
      <header className={styles.header}>
        <Container>
          <div className={styles.headerInner}>
            <div>
              {/* Only display when not home (i.e. there's more after the '/station' part of the url) */}
              <Route
                path="/station/:else"
                render={() => (
                  <div className={styles.homeButtonContainer}>
                    <div className={styles.hotkeyHint}>
                      or press <span className="has-soft-yellow-bg">esc</span>
                    </div>
                    <LinkButton to={homePath} isSize="large" isColor="light">
                      Home
                    </LinkButton>
                  </div>
                )}
              />
            </div>
            <div className={styles.logoContainer}>
              <Logo to={homePath} />
            </div>
          </div>
        </Container>
      </header>
    );
  }
}
