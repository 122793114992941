import React, { Component } from "react";
import QuickResourceJump from "../../../components/QuickResourceJump";

export default class BikeQuickJump extends Component {
  render() {
    return (
      <QuickResourceJump
        pathPrefix="/admin/bikes"
        inputProps={{
          placeholder: "e.g. 10203",
          min: 10000
        }}
        isMarginless
      />
    );
  }
}
