import React from "react";
import _get from "lodash.get";
import BaseSortTable from "./BaseSortTable";
import { userColumn, NotesTooltip } from "./helpers";
import {
  idColumn,
  createdAtColumn,
  titleColumn,
  makeActionsColumn
} from "./columns";
import templateConfig from "../../pages/admin/PageCrud/templateConfig";

const columns = [
  makeActionsColumn("/admin/pages"),
  idColumn,
  titleColumn,
  {
    Header: "Template",
    accessor: "template",
    Cell: ({ value }) => _get(templateConfig, [value, "title"]) || value,
    width: 150
  },
  {
    Header: "SEO Description",
    accessor: "description",
    width: 150,
    Cell: ({ value }) => <NotesTooltip content={value} />
  },
  {
    Header: "Last Edited By",
    accessor: "user",
    ...userColumn
  },
  createdAtColumn
];

class PageTable extends React.Component {
  render() {
    return <BaseSortTable columns={columns} {...this.props} />;
  }
}

export default PageTable;
