import React from "react";
import BaseSortTable from "./BaseSortTable";
import { Button } from "bloomer";
import {
  cellBoolean,
  cellCastBoolean,
  cellOrder,
  userColumn,
  disabledElement,
  plainLocalDateColumn,
  cellUser,
  orderColumn
} from "./helpers";
import { idColumn, createdAtColumn } from "./columns";
import { ENUM_RENEWABLE_PLANS, ENUM_QUANTIFIABLE_PLANS } from "../../config";
import membershipPeriodAdmin from "../../stores/membershipPeriodAdmin";

const columns = [
  {
    Cell: ({ original }) => (
      <div>
        {original.deactivation_date && <b>Deactivated</b>}
        {!original.deactivation_date && (
          <Button
            isColor="danger"
            isSize="small"
            onClick={() => {
              if (
                !window.confirm ||
                window.confirm(
                  "Are you sure you want to deactivate this membership? This cannot be undone."
                )
              ) {
                membershipPeriodAdmin.deactivate(original.id);
              }
            }}
          >
            Deactivate
          </Button>
        )}
        &nbsp;
        {original.is_active &&
          ENUM_RENEWABLE_PLANS.includes(original.plan) && (
            <Button
              isColor="warning"
              isSize="small"
              disabled={original.is_marked_for_cancel}
              onClick={() => {
                if (
                  !window.confirm ||
                  window.confirm(
                    "Are you sure you want to cancel renewal of this membership?"
                  )
                ) {
                  membershipPeriodAdmin.cancel(original.id);
                }
              }}
            >
              Cancel
            </Button>
          )}
      </div>
    ),
    width: 154,
    sortable: false
  },

  idColumn,
  {
    Header: "Plan",
    accessor: "plan",
    width: 100
  },
  {
    Header: "Queued?",
    accessor: "is_queued",
    Cell: cellBoolean,
    width: 100
  },
  {
    Header: "Active?",
    accessor: "is_active",
    Cell: cellBoolean,
    width: 100
  },
  {
    Header: "To Cancel?",
    accessor: "is_marked_for_cancel",
    Cell: props => {
      if (ENUM_RENEWABLE_PLANS.includes(props.original.plan)) {
        return cellBoolean(props);
      }
      return disabledElement;
    },
    width: 100
  },
  {
    Header: "User",
    accessor: "user",
    ...userColumn
  },
  createdAtColumn,
  {
    Header: "One-time Amount",
    accessor: "onetime_amount",
    Cell: props => {
      if (ENUM_QUANTIFIABLE_PLANS.includes(props.original.plan)) {
        return props.value;
      }
      return disabledElement;
    },
    width: 150
  },
  {
    Header: "Start Date",
    accessor: "start_date",
    ...plainLocalDateColumn
  },
  {
    Header: "End Date",
    accessor: "end_date",
    ...plainLocalDateColumn
  },
  {
    Header: "Deactivation Date",
    accessor: "deactivation_date",
    ...plainLocalDateColumn
  },
  {
    Header: "From auto-renew?",
    accessor: "previous",
    Cell: props => {
      if (ENUM_RENEWABLE_PLANS.includes(props.original.plan)) {
        return cellCastBoolean(props);
      }
      return disabledElement;
    },
    width: 150
  },
  {
    Header: "Renew Index",
    accessor: "renew_index",
    Cell: props => {
      if (ENUM_RENEWABLE_PLANS.includes(props.original.plan)) {
        return props.value;
      }
      return disabledElement;
    },
    width: 150
  },
  {
    Header: "Granted By Admin",
    accessor: "granter",
    ...userColumn,
    // override userColumn Cell
    Cell: props => {
      if (!props.original.order && !props.original.gift) {
        return cellUser(props);
      }
      return disabledElement;
    }
  },
  {
    Header: "Order",
    accessor: "order",
    ...orderColumn,
    // override orderColumn Cell
    Cell: props => {
      if (!props.original.granter && !props.original.gift) {
        return cellOrder(props);
      }
      return disabledElement;
    },
    width: 180
  }
];

class MembershipPeriodTable extends React.Component {
  render() {
    return (
      <BaseSortTable isFullScreenWidth columns={columns} {...this.props} />
    );
  }
}

export default MembershipPeriodTable;
