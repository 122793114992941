import React, { PureComponent } from "react";
import { Message, MessageBody } from "bloomer";

export default class DonateBikesDumbHoursMessage extends PureComponent {
  render() {
    return (
      <Message isColor="success">
        <MessageBody style={{ border: "none", fontWeight: "bold" }}>
          <p>We accept donations of bicycles or components in any condition.</p>
          <p>Please bring it to BSBC during shop hours! We are open:</p>
          <ul className="bulleted">
            <li>Monday 4-8pm (for volunteering only)</li>
            <li>Tuesday 4-8pm</li>
            <li>Thursday 4-8pm</li>
          </ul>
        </MessageBody>
      </Message>
    );
  }
}
