import React, { Component } from "react";
import { Field, Label, Control, Select } from "bloomer";

const OPTION_ANY = "__ANY__";
const OPTION_YES = "__YES__";
const OPTION_NO = "__NO__";

const mapTextValueToActual = text => {
  if (text === OPTION_YES) {
    return true;
  } else if (text === OPTION_NO) {
    return false;
  }
  return null; // (likely OPTION_ANY)
};

const mapActualValuetoText = value => {
  if (value === true) {
    return OPTION_YES;
  } else if (value === false) {
    return OPTION_NO;
  }
  return OPTION_ANY;
};

export default class NullableBooleanFilterField extends Component {
  onChange = e => {
    const value = e.target.value;
    this.props.onChange(mapTextValueToActual(value)); // cast empty string to null
  };

  render() {
    const { label, value } = this.props;

    return (
      <Field>
        <Label>{label}</Label>
        <Control>
          <Select onChange={this.onChange} value={mapActualValuetoText(value)}>
            <option value={OPTION_ANY}>&lt;Any&gt;</option>
            <option value={OPTION_YES}>Yes</option>
            <option value={OPTION_NO}>No</option>
          </Select>
        </Control>
      </Field>
    );
  }
}
