import React, { Fragment } from "react";
import _get from "lodash.get";
import {
  Card,
  CardHeader,
  CardHeaderTitle,
  CardHeaderIcon,
  CardContent,
  Table,
  Columns,
  Column,
  Title
} from "bloomer";

import { parseMomentUTC, formatMomentLocalLLL } from "../../datetime";
import { formatMoney } from "../../utils";
import { SHIPMENT_STATUS_WAIVED } from "../../constants";
import { TRANSLATE_SHIPMENT_STATUSES } from "../../config";

import LinkButton from "../../components/LinkButton";

import styles from "./ordercard.module.scss";
import Address from "../Address";

const ItemLine = ({ item }) => (
  <tr>
    <td>
      {item.title}
      {item.cart_item.variation && <i> ({item.cart_item.variation})</i>}
    </td>
    <td>{item.cart_item.quantity}</td>
    <td>{formatMoney(item.price)}</td>
    <td>{formatMoney(item.price * item.cart_item.quantity)}</td>
  </tr>
);

const Items = ({ items }) =>
  !items ? null : (
    <div>
      <Table isBordered isFullWidth className="is-hoverable">
        <thead>
          <tr>
            <th>Item</th>
            <th>Quantity</th>
            <th>Price Each</th>
            <th>Subtotal</th>
          </tr>
        </thead>
        <tbody>
          {items.map(item => (
            <ItemLine key={item.id} item={item} />
          ))}
        </tbody>
      </Table>
    </div>
  );

const OrderCard = ({ order, showDetailsLink = true }) => (
  <Card className={styles.card}>
    <CardHeader>
      <CardHeaderTitle>
        <span>Order #{order.id}</span>
        {showDetailsLink && (
          <span>
            &nbsp;&nbsp;
            <LinkButton
              to={`/account/orders/${order.id}`}
              isSize="small"
              isColor="info"
            >
              Order Details
            </LinkButton>
          </span>
        )}
      </CardHeaderTitle>
      <CardHeaderIcon tag="span" style={{ cursor: "inherit" }}>
        <span className="has-text-grey is-uppercase is-size-7">
          <i>Placed</i>:{" "}
          {formatMomentLocalLLL(parseMomentUTC(order.created_at))}
        </span>
      </CardHeaderIcon>
    </CardHeader>
    <CardContent>
      <div>
        <strong>Total: </strong> {formatMoney(order.total)}
        <br />
        <br />
      </div>
      {order.shipment !== SHIPMENT_STATUS_WAIVED && (
        <div>
          <Columns>
            <Column>
              <Title isSize={5}>Shipment</Title>
              <strong>Status:</strong>{" "}
              {TRANSLATE_SHIPMENT_STATUSES[order.shipment]}
              <br />
              <strong>Price:</strong> {formatMoney(order.shipment_price)}
              <br />
              <strong>Service:</strong> {order.shippingservice.name}
              {order.shipment_tracking &&
                order.tracking_link && (
                  <Fragment>
                    <br />
                    <strong>Tracking:</strong>{" "}
                    <a
                      href={order.tracking_link}
                      title="Tracking link"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {order.shipment_tracking}
                    </a>
                  </Fragment>
                )}
            </Column>
            <Column>
              <Address
                title="Shipping Address"
                address={order.shipping_address}
              />
            </Column>
          </Columns>
        </div>
      )}
      <Title isSize={5}>Items</Title>
      <Items items={_get(order, "cart.items")} />
    </CardContent>
  </Card>
);

export default OrderCard;
