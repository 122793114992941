import React from "react";
import { observer } from "mobx-react";
import _pick from "lodash.pick";

import AdminSite from "../../../components/AdminSite";

import agent from "../../../agent";
import { compose, idFromProps } from "../../../utils";
import { LOADER_TAG_ADMIN_DONATING_EVENTS } from "../../../constants";
import GenericEditor from "../../../components/GenericEditor";
import GenericResourceEditorStore from "../../../stores/special/GenericResourceEditorStore";
import { makeRecordValidator, makeFieldValidator } from "../../../validators";
import TextFieldWithErrors from "../../../components/fields/TextFieldWithErrors";
import DateFieldWithErrors from "../../../components/fields/DateFieldWithErrors";
import Breadcrumbs from "../../../components/Breadcrumbs";

const initialFormValuesFromDonatingEvent = donatingEvent => {
  return _pick(donatingEvent, ["name", "date"]);
};

const newStructure = {
  name: null,
  date: null
};

const donatingEventEditorStore = new GenericResourceEditorStore(
  "donating event",
  LOADER_TAG_ADMIN_DONATING_EVENTS,
  "donatingevent-editor",
  "recdon",
  (params, config) =>
    agent.AdminRecdons.show({ type: "donating", ...params }, config),
  (params, config) =>
    agent.AdminRecdons.create({ type: "donating", ...params }, config),
  (params, config) =>
    agent.AdminRecdons.update({ type: "donating", ...params }, config),
  (params, config) =>
    agent.AdminRecdons.remove({ type: "donating", ...params }, config),
  newStructure,
  null,
  "Donating event saved successfully!",
  "Donating event deleted successfully!"
);

const validate = makeRecordValidator(
  makeFieldValidator("name", "required"),
  makeFieldValidator("date", "required", "date")
);

class DonatingEventCrud extends React.Component {
  render() {
    const idParam = idFromProps(this.props);
    const isNew = idParam === "new";

    return (
      <AdminSite>
        <Breadcrumbs
          items={[
            { to: "/admin", label: "Admin" },
            { to: "/admin/recdons", label: "Receiving/Donating Events" },
            {
              to: `/admin/donating-events/${isNew ? "new" : idParam}`,
              label: isNew ? "New Donating Event" : `Donating Event ${idParam}`
            }
          ]}
        />
        <GenericEditor
          name="donating event"
          nameForTitle="Donating Event"
          homeResourcePath="/admin/recdons"
          basePath="/admin/donating-events"
          store={donatingEventEditorStore}
          loaderTag={LOADER_TAG_ADMIN_DONATING_EVENTS}
          recordValidator={validate}
          initialFormValuesFn={initialFormValuesFromDonatingEvent}
        >
          <TextFieldWithErrors name="name" label="Name" />
          <DateFieldWithErrors name="date" label="Date" hasTodayButton />
        </GenericEditor>
      </AdminSite>
    );
  }
}

export default compose(observer)(DonatingEventCrud);
