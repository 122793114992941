import React from "react";
import { observer, inject } from "mobx-react";

import ItemTable from "../../../components/tables/ItemTable";
import AdminTableSection from "../../../components/AdminTableSection";
import AdminSite from "../../../components/AdminSite";
import ResourceCreateLinkButton from "../../../components/ResourceCreateLinkButton";

import { compose } from "../../../utils";
import { LOADER_TAG_ADMIN_ITEMS } from "../../../constants";
import PageHeading from "../../../components/PageHeading";

class Items extends React.Component {
  render() {
    return (
      <AdminSite>
        <PageHeading>Items</PageHeading>
        <ResourceCreateLinkButton to="/admin/items/new" label="New Item" />
        <AdminTableSection
          store={this.props.itemAdminStore}
          getter={store => store.items.slice()}
          loadAction={store => store.loadAll}
          loaderTag={LOADER_TAG_ADMIN_ITEMS}
          clearAction={store => store.clear}
          tableComponent={ItemTable}
        />
      </AdminSite>
    );
  }
}

export default compose(
  inject("itemAdminStore"),
  observer
)(Items);
