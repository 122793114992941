import React from "react";
import { Field as FieldStyle, Help } from "bloomer";

import { deriveDisableForValidationErrors } from "../utils";
import { IS_PRODUCTION } from "../config";

const FinalFormErrors = ({
  // from FieldState
  submitting,
  hasValidationErrors,
  hasSubmitErrors,
  errors,
  touched
}) => {
  if (!IS_PRODUCTION) {
    // If not production, double check that the
    // final-form FieldState was property subscribed-to
    if (typeof submitting === "undefined") {
      console.warn("FinalFormErrors requires a subscription to 'submitting'");
    }
    if (typeof hasValidationErrors === "undefined") {
      console.warn(
        "FinalFormErrors requires a subscription to 'hasValidationErrors'"
      );
    }
    if (typeof hasSubmitErrors === "undefined") {
      console.warn(
        "FinalFormErrors requires a subscription to 'hasSubmitErrors'"
      );
    }
    if (typeof errors === "undefined") {
      console.warn("FinalFormErrors requires a subscription to 'errors'");
    }
    if (typeof touched === "undefined") {
      console.warn("FinalFormErrors requires a subscription to 'touched'");
    }
  }

  const disableForValidationErrors = deriveDisableForValidationErrors(
    hasValidationErrors,
    errors,
    touched
  );

  return (
    <FieldStyle>
      {disableForValidationErrors && (
        <Help isColor="danger">
          Please correct the errored fields above before submitting.
        </Help>
      )}
      {hasSubmitErrors && (
        <Help isColor="danger">
          There were submission errors, please correct them before submitting
          again.
        </Help>
      )}
    </FieldStyle>
  );
};

export default FinalFormErrors;
