import React, { Component } from "react";
import { observer } from "mobx-react";
import { decorate, computed, toJS } from "mobx";
import { Columns, Column, Field as FieldStyle, Control, Button } from "bloomer";

import { compose } from "../../../utils";

import filterConfig from "./filterConfig";

const FilterColumn = props => (
  <Column
    isSize={{ tablet: "1/2", desktop: "1/3", widescreen: "1/4" }}
    {...props}
  />
);

const defaultFilterValueGetter = (filters, name) => filters[name];

/**
 * Wrapper for filters, providing field management and form buttons
 */
class FilterWrapper extends Component {
  state = {
    errors: {}
  };

  get filtersJS() {
    return toJS(this.props.filters);
  }

  get hasErrors() {
    return Object.keys(this.state.errors).length > 0;
  }

  fieldConfigToColumn = field => {
    const { name, type } = field;
    const result = filterConfig[type];
    if (!result) {
      throw new Error(`Filter field component '${type}' does not exist`); // unlikely
    }
    const {
      component,
      validator: defaultValidator,
      filterValueGetter = defaultFilterValueGetter
    } = result;
    const value = filterValueGetter(this.filtersJS, name);
    return (
      <FilterColumn key={name}>
        {React.createElement(component, {
          ...field,
          name,
          error: this.state.errors[name],
          value,
          onChange: (value, moreOptions) =>
            this.onFieldChange(field, value, defaultValidator, moreOptions)
        })}
      </FilterColumn>
    );
  };

  onFieldChange = (field, value, defaultValidator, moreOptions = {}) => {
    const { name, validator } = field;
    this.props.setFilter(moreOptions.filterNameOverride || name, value);
    // validate
    const error =
      defaultValidator(value, field, moreOptions, this.filtersJS) ||
      (validator
        ? validator(value, field, moreOptions, this.filtersJS)
        : undefined);
    if (error) {
      this.setState({
        errors: { ...this.state.errors, [name]: error }
      });
    } else {
      const { [name]: deletedError, ...restErrors } = this.state.errors;
      this.setState({
        errors: restErrors
      });
    }
  };

  onSubmit = e => {
    e.preventDefault();
    if (!this.hasErrors) {
      this.props.onSubmit();
    }
  };

  onReset = e => {
    e.preventDefault();
    this.props.resetFilters();
    this.setState({ errors: [] });
  };

  render() {
    const { fields, loading } = this.props;

    const buttonsDisabled = loading || this.hasErrors;

    return (
      <div style={{ marginBottom: "2rem" }}>
        <form onSubmit={this.onSubmit}>
          <Columns isMultiline>{fields.map(this.fieldConfigToColumn)}</Columns>
          <Columns>
            <Column>
              <FieldStyle isGrouped className="is-grouped-multiline">
                <Control>
                  <Button
                    type="submit"
                    isColor="primary"
                    disabled={buttonsDisabled}
                    isLoading={loading}
                  >
                    Apply Filters
                  </Button>
                </Control>
                <Control>
                  <Button
                    isColor="light"
                    onClick={this.onReset}
                    disabled={buttonsDisabled}
                  >
                    Reset
                  </Button>
                </Control>
              </FieldStyle>
            </Column>
          </Columns>
        </form>
      </div>
    );
  }
}

decorate(FilterWrapper, {
  filtersJS: computed
});

export default compose(observer)(FilterWrapper);
