import React, { PureComponent } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer
} from "recharts";
import { parseYMDMomentTimezoneConfig } from "../../../../datetime";

export default class CountAndRatioLinePerMonth extends PureComponent {
  render() {
    const { monthVisitMap } = this.props;

    if (!monthVisitMap) {
      return "n/a";
    }

    const data = Object.entries(monthVisitMap).reduce(
      (acc, [month, { count, ratio }]) => [
        ...acc,
        {
          month: parseYMDMomentTimezoneConfig(month).format("MMM YYYY"),
          count,
          ratio
        }
      ],
      []
    );

    return (
      <ResponsiveContainer width="100%" height={350}>
        <LineChart data={data}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="month"
            interval={0}
            angle={-45}
            textAnchor="end"
            height={50}
            tick={{ fontSize: 10 }}
          />
          <YAxis yAxisId="left" />
          <YAxis yAxisId="right" />
          <Tooltip />
          <Line
            yAxisId="left"
            dataKey="count"
            stroke="#8884d8"
            fill="#8884d8"
          />
          <Line
            yAxisId="right"
            dataKey="ratio"
            stroke="#00C49F"
            fill="#00C49F"
          />
        </LineChart>
      </ResponsiveContainer>
    );
  }
}
