import React from "react";
import { observer, inject } from "mobx-react";
import { Title, Notification } from "bloomer";

import { compose } from "../../../utils";
import Wrapper from "./common/Wrapper";

class Bikes extends React.Component {
  render() {
    // const { authStore } = this.props;
    // const { user } = authStore;

    return (
      <Wrapper>
        {() => (
          <div>
            <Title isSize={3}>Bikes I've Worked On</Title>
            <Notification>Coming soon</Notification>
          </div>
        )}
      </Wrapper>
    );
  }
}

export default compose(
  inject("authStore"),
  observer
)(Bikes);
