import React, { Component, Fragment } from "react";
import { Field } from "react-final-form";
import { OnChange } from "react-final-form-listeners";
import {
  BIKE_STATUS_AVAILABLE,
  BIKE_STATUS_DONATED,
  BIKE_STATUS_SOLD,
  BIKE_STATUS_MISSING
} from "../../../constants";

const SetAppropriateStatusOnChange = ({
  field,
  status,
  notIncludingStatuses = [
    BIKE_STATUS_SOLD,
    BIKE_STATUS_DONATED,
    BIKE_STATUS_MISSING
  ]
}) => (
  <Field name="status" subscription={{ value: true }}>
    {({ input: { onChange, value: currentStatus } }) => (
      <OnChange name={field}>
        {(value, previous) => {
          if (
            value &&
            !previous &&
            currentStatus !== status &&
            !notIncludingStatuses.includes(currentStatus)
          ) {
            if (window.confirm(`Set bike status to ${status}?`)) {
              onChange(status);
            }
          }
        }}
      </OnChange>
    )}
  </Field>
);

/**
 * Listen for Change on certain fields and make
 * the appropriate change to bike status
 * (if user confirms using dialog box)
 *
 * fixed_at => available
 * fix_cost => available
 * price_asking => available
 * organization_receiving => donated
 * donatingevent => donated
 */
export default class EditorListeners extends Component {
  render() {
    return (
      <Fragment>
        <SetAppropriateStatusOnChange
          field="fixed_at"
          status={BIKE_STATUS_AVAILABLE}
        />
        <SetAppropriateStatusOnChange
          field="fix_cost"
          status={BIKE_STATUS_AVAILABLE}
        />
        <SetAppropriateStatusOnChange
          field="price_asking"
          status={BIKE_STATUS_AVAILABLE}
        />
        <SetAppropriateStatusOnChange
          field="organization_receiving"
          status={BIKE_STATUS_DONATED}
          notIncludingStatuses={[BIKE_STATUS_MISSING]}
        />
        <SetAppropriateStatusOnChange
          field="donatingevent"
          status={BIKE_STATUS_DONATED}
          notIncludingStatuses={[BIKE_STATUS_MISSING]}
        />
      </Fragment>
    );
  }
}
