import React, { PureComponent } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend
} from "recharts";
import moment from "moment";
import { parseYMDMomentTimezoneConfig } from "../../../../datetime";
import { getYearColor } from "./utils";

export default class BarBikePerMonthGroupedYear extends PureComponent {
  render() {
    const { monthBikeMap, field } = this.props;

    if (!monthBikeMap) {
      return "n/a";
    }

    const yearsRaw = [];

    const byMonth = Object.entries(monthBikeMap).reduce(
      (acc, [date, fields]) => {
        const m = parseYMDMomentTimezoneConfig(date);

        // note: m.month() is zero-indexed (january is 0), as is acc
        if (acc[m.month()]) {
          acc[m.month()].push({ year: m.year(), value: fields[field] });
        }

        // not functional, but whatever
        if (yearsRaw.indexOf(m.year()) < 0) {
          yearsRaw.push(m.year());
        }

        return acc;
      },
      // initial object: { [month number]: [], ... }
      Array.from(Array(12).keys()).reduce((acc, m) => ({ ...acc, [m]: [] }), {})
    );

    const years = yearsRaw.sort();

    const data = Object.entries(byMonth).reduce((acc, [month, yearObjects]) => {
      return [
        ...acc,
        {
          month: moment()
            .month(month)
            .format("MMM"),
          ...yearObjects.reduce(
            (yac, { year, value }) => ({ ...yac, [year]: value }),
            {}
          )
        }
      ];
    }, []);

    return (
      <ResponsiveContainer width="100%" height={350}>
        <BarChart
          data={data}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="month" interval={0} height={40} />
          <YAxis />
          <Tooltip />
          <Legend />
          {years.map((year, i) => (
            <Bar key={`bar-${year}`} dataKey={year} fill={getYearColor(year)} />
          ))}
        </BarChart>
      </ResponsiveContainer>
    );
  }
}
