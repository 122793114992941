import React from "react";
import BaseSortTable from "./BaseSortTable";
import { cellMoney } from "./helpers";
import {
  idColumn,
  createdAtColumn,
  titleColumn,
  makeActionsColumn,
  updatedAtColumn
} from "./columns";

const columns = [
  makeActionsColumn("/admin/bikeparts"),
  idColumn,
  titleColumn,
  {
    Header: "Cost (New)",
    accessor: "cost_new",
    Cell: cellMoney,
    width: 100
  },
  {
    Header: "Cost (Used)",
    accessor: "cost_used",
    Cell: cellMoney,
    width: 100
  },
  createdAtColumn,
  updatedAtColumn
];

class BikePartTable extends React.Component {
  render() {
    return <BaseSortTable columns={columns} {...this.props} />;
  }
}

export default BikePartTable;
