import React, { Component } from "react";
import SelectFieldImmediate from "../SelectFieldImmediate";
import agent from "../../../agent";
import { LOADER_TAG_ADMIN_FIELD_RENTALBIKE } from "../../../constants";

export default class SelectAvailableRentalBike extends Component {
  render() {
    return (
      <SelectFieldImmediate
        getFn={() =>
          agent.AdminRentalBikes.allAvailable({
            loaderTag: LOADER_TAG_ADMIN_FIELD_RENTALBIKE
          })
        }
        loaderTag={LOADER_TAG_ADMIN_FIELD_RENTALBIKE}
        {...this.props}
      />
    );
  }
}
