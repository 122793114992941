import React from "react";
import { observer, inject } from "mobx-react";

import RecdonTable from "../../../components/tables/RecdonTable";
import AdminTableSection from "../../../components/AdminTableSection";
import AdminSite from "../../../components/AdminSite";

import { compose } from "../../../utils";
import {
  LOADER_TAG_ADMIN_RECEIVING_EVENTS,
  LOADER_TAG_ADMIN_DONATING_EVENTS
} from "../../../constants";
import ResourceCreateLinkButton from "../../../components/ResourceCreateLinkButton";
import PageHeading from "../../../components/PageHeading";

class Recdons extends React.Component {
  render() {
    return (
      <AdminSite>
        <PageHeading>Receiving Events</PageHeading>
        <ResourceCreateLinkButton
          to="/admin/receiving-events/new"
          label="New Receiving Event"
        />
        <AdminTableSection
          store={this.props.receivingEventAdminStore}
          getter={store => store.receivingevents.slice()}
          loadAction={store => store.loadAll}
          loaderTag={LOADER_TAG_ADMIN_RECEIVING_EVENTS}
          clearAction={store => store.clear}
          tableComponent={RecdonTable}
          tableComponentProps={{ recdonType: "receiving" }}
        />
        <br />
        <PageHeading>Donating Events</PageHeading>
        <ResourceCreateLinkButton
          to="/admin/donating-events/new"
          label="New Donating Event"
        />
        <AdminTableSection
          store={this.props.donatingEventAdminStore}
          getter={store => store.donatingevents.slice()}
          loadAction={store => store.loadAll}
          loaderTag={LOADER_TAG_ADMIN_DONATING_EVENTS}
          clearAction={store => store.clear}
          tableComponent={RecdonTable}
          tableComponentProps={{ recdonType: "donating" }}
        />
      </AdminSite>
    );
  }
}

export default compose(
  inject("receivingEventAdminStore", "donatingEventAdminStore"),
  observer
)(Recdons);
