import React from "react";
import { observer, inject } from "mobx-react";
import { Field as FieldStyle, Control } from "bloomer";
import _get from "lodash.get";
import { Link } from "react-router-dom";

import {
  REDIRECT_AFTER_LOGIN_DEFAULT,
  standardFinalFormSubscriptions,
  REDIRECT_AFTER_LOGIN_STATION
} from "../../../config";
import { makeRecordValidator, makeFieldValidator } from "../../../validators";
import formValidationErrorLayer from "../../../errors/formValidationErrorLayer";
import PublicSite from "../../../components/PublicSite";
import SimpleFormPage from "../../../components/SimpleFormPage";
import TextFieldWithErrors from "../../../components/fields/TextFieldWithErrors";
import CustomFinalForm from "../../../components/CustomFinalForm";
import LinkButton from "../../../components/LinkButton";
import CustomRedirect from "../../../components/CustomRedirect";
import { compose } from "../../../utils";
import FinalSubmit from "../../../components/fields/FinalSubmit";
import { ROLE_STATION } from "../../../constants";
import roleCheck from "../../../roleCheck";

const validate = makeRecordValidator(
  makeFieldValidator("email", "required"),
  makeFieldValidator("password", "required")
);

class Login extends React.Component {
  onSubmit = ({ email = null, password = null }) => {
    return formValidationErrorLayer(
      this.props.authStore.login(email, password)
    );
  };

  render() {
    if (this.props.authStore.isAuthenticated) {
      // If redirectAfterLogin is set in route location state
      // (often as a result of trying to access protected route),
      // redirect there after successful auth.
      // Otherwise, redirect to our default (probably home)
      const redirectAfterLogin = _get(this.props.location, [
        "state",
        "redirectAfterLogin"
      ]);

      // force redirect to station home if user is station
      const to = roleCheck(this.props.authStore.user, ROLE_STATION)
        ? REDIRECT_AFTER_LOGIN_STATION
        : redirectAfterLogin || REDIRECT_AFTER_LOGIN_DEFAULT;

      return <CustomRedirect to={to} />;
    }

    return (
      <PublicSite>
        <SimpleFormPage title="Login">
          <CustomFinalForm
            onSubmit={this.onSubmit}
            validate={validate}
            subscription={standardFinalFormSubscriptions}
            initialValues={{ email: null, password: null }}
            render={renderProps => {
              const { handleSubmit } = renderProps;
              return (
                <form onSubmit={handleSubmit}>
                  <TextFieldWithErrors
                    type="text"
                    name="email"
                    label="Email"
                    placeholder="e.g. alexsmith@gmail.com"
                  />
                  <TextFieldWithErrors
                    type="password"
                    name="password"
                    label="Password"
                    placeholder="Password"
                  />
                  <FinalSubmit {...renderProps} label="Login" isSize="medium" />
                  <br />
                  <FieldStyle isGrouped className="is-grouped-multiline">
                    <Control>
                      <LinkButton to="/account/register" isColor="light">
                        Create Account
                      </LinkButton>
                    </Control>
                    <Control>
                      <LinkButton to="/account/forgot" isColor="light">
                        Forgot Password?
                      </LinkButton>
                    </Control>
                  </FieldStyle>
                  <p>
                    If you created an account at the shop station, it likely
                    doesn't have a set password. To log in, you'll have to set a
                    password via email <Link to="/account/forgot">here</Link>.
                  </p>
                </form>
              );
            }}
          />
        </SimpleFormPage>
      </PublicSite>
    );
  }
}

export default compose(
  inject("authStore"),
  observer
)(Login);
