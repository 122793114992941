import { observable, decorate, action, runInAction } from "mobx";
import agent from "../agent";
import { LOADER_TAG_ADMIN_RENTALBIKES } from "../constants";
import { parseMomentUTC } from "../datetime";

const deriveAndSetLatestRentalPeriod = rentalBike => {
  const rps = rentalBike.rentalperiods;
  if (!rps.length) {
    rentalBike.latestRentalPeriod = null;
  } else {
    rentalBike.latestRentalPeriod = rps.sort(
      (a, b) =>
        parseMomentUTC(a.due_date).isBefore(parseMomentUTC(b.due_date)) ? 1 : -1
    )[0];
  }
};

export class RentalAdminStore {
  rentals = observable.array();

  clear() {
    this.rentals.clear();
  }

  // action
  loadAll() {
    return agent.AdminRentalBikes.all({
      loaderTag: LOADER_TAG_ADMIN_RENTALBIKES
    }).then(body => {
      const rentals = body.data;
      if (Array.isArray(rentals)) {
        rentals.forEach(deriveAndSetLatestRentalPeriod);
      }
      runInAction(() => {
        this.rentals.replace(rentals);
      });
      return rentals;
    });
  }
}

decorate(RentalAdminStore, {
  rentals: observable,
  clear: action.bound,
  loadAll: action.bound
});

export default new RentalAdminStore();
