import React from "react";
import _get from "lodash.get";
import BaseSortTable from "./BaseSortTable";
import {
  cellBoolean,
  objectNameColumn,
  plainLocalDateColumn,
  cellBooleanYesNo,
  userColumn
} from "./helpers";
import { idColumn, makeActionsColumn } from "./columns";
import { sortBSizeComparator } from "../../utils";
import { parseMomentUTC, momentNowLocal } from "../../datetime";

const columns = [
  makeActionsColumn("/admin/rentals", "View", 58),
  {
    Header: "Bike",
    headerStyle: {
      color: "white",
      background: "#363636"
    },
    columns: [
      idColumn,
      {
        Header: "Missing?",
        accessor: "is_missing",
        Cell: cellBoolean,
        width: 100
      },
      {
        Header: "Serial",
        accessor: "serial",
        width: 150
      },
      {
        Header: "Size",
        accessor: "bsize",
        ...objectNameColumn,
        // sort sizes by their actual order instead of alphabetical
        // override objectNameColumn sort
        sortMethod: sortBSizeComparator,
        width: 80
      }
    ]
  },
  {
    Header: "Latest Rental",
    headerStyle: {
      color: "white",
      background: "#209cee"
    },
    columns: [
      makeActionsColumn(
        "/admin/rentals/periods",
        "View",
        58,
        "latestRentalPeriod.id"
      ),
      {
        Header: "Returned?",
        accessor: "latestRentalPeriod.is_returned",
        Cell: cellBooleanYesNo,
        width: 100
      },
      {
        Header: "Start",
        accessor: "latestRentalPeriod.start_date",
        ...plainLocalDateColumn
      },
      {
        Header: "Due",
        accessor: "latestRentalPeriod.due_date",
        ...plainLocalDateColumn
      },
      {
        Header: "Renter",
        accessor: "latestRentalPeriod.user",
        ...userColumn
      }
    ]
  }
];

class RentalTable extends React.Component {
  render() {
    return (
      <BaseSortTable
        columns={columns}
        getTrProps={(state, rowInfo, column) => {
          let background = null;
          let text = null;

          const rb = _get(rowInfo, "original");
          if (rb && rb.is_missing) {
            // gray it out
            background = "#707070";
            text = "#A0A0A0";
          } else {
            const rpMaybe = _get(rb, "latestRentalPeriod");
            if (!rpMaybe) {
              return {};
            }

            if (rpMaybe.is_returned) {
              // if returned, no bg needed...
              background = null;
            } else {
              const parsedDueDate = parseMomentUTC(rpMaybe.due_date);
              const today = momentNowLocal()
                .utc()
                .startOf("day");
              background = parsedDueDate.isBefore(today)
                ? "#ffc0cb"
                : "#c1fcc1";
            }
          }

          return {
            style: { background, color: text }
          };
        }}
        {...this.props}
      />
    );
  }
}

export default RentalTable;
