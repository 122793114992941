import { observable, decorate, action, runInAction, toJS } from "mobx";
import agent from "../agent";
import { LOADER_TAG_ADMIN_ORDERS } from "../constants";
import { ENUM_SHIPMENT_STATUSES } from "../config";
import { mapSomeFieldsToId } from "../utils";

export class OrderAdminStore {
  orders = observable.array();

  filters = observable.map();

  order = null;

  constructor() {
    this.resetFilters();
  }

  // action
  loadFiltered() {
    const finalFilters = mapSomeFieldsToId(toJS(this.filters), [
      "user",
      "shippingservice"
    ]);
    return agent.AdminOrders.filter(
      { filters: finalFilters },
      { loaderTag: LOADER_TAG_ADMIN_ORDERS }
    )
      .then(body => {
        const orders = body.data;
        runInAction(() => {
          this.orders.replace(orders);
        });
        return orders;
      })
      .catch(error => {
        this.clear();
        return Promise.reject(error); // re-throw
      });
  }

  // action
  clear() {
    this.orders.clear();
    this.resetFilters();
    this.order = null;
  }

  // action
  show(id) {
    this.order = null;

    return agent.AdminOrders.show(
      { id },
      {
        notFoundName: "order"
      }
    ).then(body => {
      const order = body.data;
      runInAction(() => {
        this.order = order;
      });
      return order;
    });
  }

  // action
  ship(id, cost, tracking, shippingservice_id) {
    return agent.AdminOrders.ship(
      { id, cost, tracking, shippingservice_id },
      {
        notFoundName: "order or shipping service"
      }
    );
  }

  // action
  resetFilters() {
    this.filters.replace({
      sort_field: "created_at",
      sort_direction: "desc",

      square_order_id: null,
      square_transaction_id: null,
      shipments: ENUM_SHIPMENT_STATUSES,
      shipment_tracking: null,
      user: [],
      shippingservice: []
    });
  }

  // action
  setFilter(field, value) {
    this.filters.set(field, value);
  }
}

decorate(OrderAdminStore, {
  orders: observable,
  filters: observable,
  order: observable,
  loadFiltered: action.bound,
  clear: action.bound,
  show: action.bound,
  ship: action.bound,
  resetFilters: action.bound,
  setFilter: action.bound
});

export default new OrderAdminStore();
