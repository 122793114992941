import {
  observable,
  decorate,
  computed,
  action,
  runInAction,
  reaction
} from "mobx";

import agent from "../agent";
import uiStore from "./ui";
import AuthenticationError from "../errors/AuthenticationError";
import {
  setBearerAuthorizationHeader,
  jsonParseSafe,
  logIfNotProduction
} from "../utils";
import { LOADER_TAG_AUTH } from "../constants";

const LOCALSTORAGE_JWT_KEY = "jwt";
const LOCALSTORAGE_USER_KEY = "user";

export class AuthStore {
  token = window.localStorage.getItem(LOCALSTORAGE_JWT_KEY);
  user = jsonParseSafe(window.localStorage.getItem(LOCALSTORAGE_USER_KEY));

  constructor() {
    // side-effects: sync jwt and user localstorage
    reaction(
      () => this.token,
      token => {
        // update localstorage
        if (token) {
          logIfNotProduction("set jwt");
          window.localStorage.setItem(LOCALSTORAGE_JWT_KEY, token);
        } else {
          logIfNotProduction("removed jwt");
          window.localStorage.removeItem(LOCALSTORAGE_JWT_KEY);
        }
      }
    );
    reaction(
      () => this.user,
      user => {
        // update localstorage
        if (user) {
          logIfNotProduction("set user");
          window.localStorage.setItem(
            LOCALSTORAGE_USER_KEY,
            JSON.stringify(user)
          );
        } else {
          logIfNotProduction("removed user");
          window.localStorage.removeItem(LOCALSTORAGE_USER_KEY);
        }
      }
    );
  }

  // computed
  get isAuthenticated() {
    return Boolean(this.user);
  }

  clearAll() {
    this.token = null;
    this.user = null;
  }

  /**
   * Get user using current this.token and set as this.user
   * Freeze by default (i.e. show loading screen)
   */
  pullUser({ freeze = true } = {}) {
    return agent.Auth.me({ freeze, loaderTag: LOADER_TAG_AUTH })
      .then(body => {
        const user = body.data;
        runInAction(() => {
          this.user = user;
        });
        return user; // for convenience if needed
      })
      .catch(error => {
        this.clearAll();
        return Promise.reject(error); // re-throw
      });
  }

  // action
  login(email, password) {
    // NOTE: automatic displayError is OFF
    return agent.Auth.login(
      { email, password },
      { displayError: false, loaderTag: LOADER_TAG_AUTH }
    )
      .then(body => {
        this.setTokenFromResponseBody(body);
      })
      .then(() => {
        return this.pullUser();
      })
      .then(() => {
        // Success
        uiStore.addAlert("success", "You are now logged in.", {
          group: "auth"
        });
      })
      .catch(error => {
        this.clearAll();

        // Display error ourselves
        if (error instanceof AuthenticationError) {
          uiStore.addAlert(
            "error",
            "The email and/or password you entered did not match our records. If you forgot your password, you can reset it.",
            { group: "auth" }
          );
        } else {
          uiStore.addAlert("error", error);
        }

        // re-throw
        return Promise.reject(error);
      });
  }

  // action
  logout() {
    return agent.Auth.logout({ loaderTag: LOADER_TAG_AUTH })
      .catch(error => {
        if (error.response && error.response.status === 401) {
          // This is a special case where the backend has
          // auth middleware for this route but not jwt refresh middleware.
          // Therefore it's possible logout will be called after the
          // jwt time-to-live, yet won't be refreshed, yielding an
          // expired token error (under the 401 status code).
          // We're going to silence those cases by returning a resolving promise.
          return Promise.resolve();
        }
        return Promise.reject(error);
      })
      .then(() => {
        // Effectively a route change even though it's a redirect,
        // so clear alerts
        uiStore.clearAlertsAfterRouteChange();

        // Logout success message
        uiStore.addAlert("info", "You have been logged out successfully.", {
          group: "auth"
        });
      })
      .finally(this.clearAll);
  }

  // action
  register(name, email, password, password_confirmation, newsletter) {
    return agent.Account.register({
      name,
      email,
      password,
      password_confirmation,
      newsletter
    })
      .then(body => {
        this.setTokenFromResponseBody(body);
      })
      .then(() => {
        return this.pullUser();
      })
      .then(() => {
        // Success
        uiStore.addAlert("success", "Your account was created successfully!", {
          group: "auth"
        });
      })
      .catch(error => {
        this.clearAll();
        return Promise.reject(error); // re-throw
      });
  }

  // action
  update(user) {
    return agent.Account.update({ user })
      .then(body => {
        runInAction(() => {
          this.user = body.data;
        });
      })
      .then(() => {
        // Success
        uiStore.addAlert("success", "Your account was updated successfully!", {
          group: "account-update"
        });
      });
  }

  // action
  updatePassword(password, password_confirmation) {
    return agent.Account.updatePassword({
      password,
      password_confirmation
    })
      .then(body => {
        runInAction(() => {
          this.user = body.data;
        });
      })
      .then(() => {
        // Success
        uiStore.addAlert("success", "Your password was updated successfully!", {
          group: "account-update"
        });
      });
  }

  // action
  forgotPassword(email) {
    // (no this action doesn't really need to be in this store)
    return agent.Account.forgotPassword({ email }).then(() => {
      // Success
      uiStore.addAlert(
        "success",
        "We just sent a password reset email to the address you specified.",
        {
          group: "auth"
        }
      );
    });
  }

  // action
  resetPassword(email, token, password, password_confirmation) {
    return agent.Account.resetPassword({
      email,
      token,
      password,
      password_confirmation
    })
      .then(body => {
        this.setTokenFromResponseBody(body);
      })
      .then(() => {
        return this.pullUser();
      })
      .then(() => {
        // Success
        uiStore.addAlert(
          "success",
          "Your password was reset successfully! You are now logged in.",
          {
            group: "auth"
          }
        );
      })
      .catch(error => {
        this.clearAll();
        return Promise.reject(error); // re-throw
      });
  }

  // action
  refreshMaybe(loaderTag = null) {
    if (this.token) {
      const config = { loaderTag };
      setBearerAuthorizationHeader(this.token, config);
      // start custom loader
      return agent.Auth.refresh(config)
        .then(body => {
          logIfNotProduction("refreshed token");
          this.setTokenFromResponseBody(body);
        })
        .catch(error => {
          this.clearAll();
          return Promise.reject(error); // re-throw
        });
    } else {
      this.clearAll();
      return Promise.reject(new Error("cant refresh without [old] token"));
    }
  }

  // action
  setTokenFromResponseBody(body) {
    const { access_token } = body;
    this.token = access_token;
  }
}

decorate(AuthStore, {
  token: observable,
  user: observable,
  isAuthenticated: computed,
  clearAll: action.bound,
  pullUser: action.bound,
  login: action.bound,
  logout: action.bound,
  register: action.bound,
  update: action.bound,
  updatePassword: action.bound,
  forgotPassword: action.bound,
  resetPassword: action.bound,
  refreshMaybe: action.bound,
  setTokenFromResponseBody: action.bound
});

export default new AuthStore();
