import React from "react";
import { observer, inject } from "mobx-react";

import AdminSite from "../../../components/AdminSite";

import { compose } from "../../../utils";
import { LOADER_TAG_ADMIN_MEMBERSHIPS } from "../../../constants";
import PageHeading from "../../../components/PageHeading";
import SelectGrantablePlan from "../../../components/selects/static/SelectGrantablePlan";
import SelectUser from "../../../components/selects/throttled/SelectUser";
import CustomFinalForm from "../../../components/CustomFinalForm";
import Breadcrumbs from "../../../components/Breadcrumbs";
import {
  makeRecordValidator,
  makeFieldValidator,
  required
} from "../../../validators";
import FancySelectFieldWithErrors from "../../../components/fields/FancySelectFieldWithErrors";
import TextFieldWithErrors from "../../../components/fields/TextFieldWithErrors";
import formValidationErrorLayer from "../../../errors/formValidationErrorLayer";
import { Field } from "react-final-form";
import {
  ENUM_QUANTIFIABLE_PLANS,
  standardFinalFormSubscriptions
} from "../../../config";
import FinalSubmit from "../../../components/fields/FinalSubmit";

const planIsQuantifiable = plan => ENUM_QUANTIFIABLE_PLANS.includes(plan);

const validate = makeRecordValidator(
  makeFieldValidator("plan", "required"),
  makeFieldValidator("user", "required"),
  makeFieldValidator("amount", "int", "minZero", "maxMysqlInt", [
    (value, plan) =>
      plan && planIsQuantifiable(plan.id) ? required(value) : undefined,
    "plan"
  ])
);

class MembershipGrant extends React.Component {
  get loading() {
    return this.props.uiStore.loadingByTag(LOADER_TAG_ADMIN_MEMBERSHIPS);
  }

  onSubmit = fields => {
    const userId = fields.user.id;
    const plan = fields.plan.id;
    const amount = fields.amount;

    return formValidationErrorLayer(
      this.props.membershipPeriodAdminStore
        .grant(userId, plan, planIsQuantifiable(plan) ? amount : null)
        .then(() => {
          this.props.uiStore.addAlert(
            "success",
            "Membership was granted successfully!"
          );
          this.props.history.push("/admin/memberships", { wasRedirect: true });
        })
    );
  };

  render() {
    return (
      <AdminSite>
        <Breadcrumbs
          items={[
            { to: "/admin", label: "Admin" },
            { to: "/admin/memberships", label: "Memberships" },
            {
              to: `/admin/memberships/grant`,
              label: "Grant Membership"
            }
          ]}
        />
        <PageHeading>Grant Membership</PageHeading>
        <CustomFinalForm
          onSubmit={this.onSubmit}
          validate={validate}
          initialValues={{ plan: null, user: null, amount: null }}
          subscription={standardFinalFormSubscriptions}
          render={renderProps => {
            const { handleSubmit } = renderProps;
            return (
              <form onSubmit={handleSubmit}>
                <FancySelectFieldWithErrors
                  name="plan"
                  label="Plan"
                  component={SelectGrantablePlan}
                />
                <Field name="plan">
                  {({ input: planInput }) => {
                    if (
                      planInput.value &&
                      planIsQuantifiable(planInput.value.id)
                    ) {
                      return (
                        <TextFieldWithErrors name="amount" label="Amount" />
                      );
                    }
                    return null;
                  }}
                </Field>
                <FancySelectFieldWithErrors
                  name="user"
                  label="User"
                  component={SelectUser}
                />
                <FinalSubmit
                  {...renderProps}
                  label="Grant"
                  loading={this.loading}
                />
              </form>
            );
          }}
        />
      </AdminSite>
    );
  }
}

export default compose(
  inject("membershipPeriodAdminStore", "uiStore"),
  observer
)(MembershipGrant);
