import React from "react";
import classNames from "classnames";

import MediumCenterInner from "../MediumCenterInner";

import styles from "./mission.module.scss";

const Mission = ({ children }) => {
  return (
    <MediumCenterInner>
      <p className={classNames(styles.mission)}>{children}</p>
    </MediumCenterInner>
  );
};

export default Mission;
