import React from "react";
import { Section, Container } from "bloomer";

import Header from "./Header";
import ErrorBag from "../ErrorBag";
import ScrollToTopOnMount from "../ScrollToTopOnMount";

class AdminSite extends React.Component {
  render() {
    return (
      <div>
        <ScrollToTopOnMount />
        <Header />
        <ErrorBag />
        <Section>
          <Container>{this.props.children}</Container>
        </Section>
      </div>
    );
  }
}

export default AdminSite;
