import { observer } from "mobx-react";
import React from "react";
import { NavLink } from "react-router-dom";
import {
  Navbar,
  NavbarBrand,
  NavbarItem,
  NavbarBurger,
  NavbarMenu,
  NavbarStart,
  NavbarEnd
} from "bloomer";

import { compose } from "../../../utils";
import { ADMIN_TITLE } from "../../../config";

import items from "./items";
import AdminAuthInfo from "./AdminAuthInfo";

const AwareNavbarLink = ({ label, ...props }) => (
  <NavLink className="navbar-item" activeClassName="is-active" {...props}>
    {label}
  </NavLink>
);

class Header extends React.Component {
  state = { expanded: false };

  onClickNav = () => {
    this.setState({ expanded: !this.state.expanded });
  };

  render() {
    const { expanded } = this.state;

    return (
      <header>
        <Navbar>
          <NavbarBrand>
            <NavbarItem>{ADMIN_TITLE}</NavbarItem>
            <NavbarBurger isActive={expanded} onClick={this.onClickNav} />
          </NavbarBrand>
          <NavbarMenu isActive={expanded} onClick={this.onClickNav}>
            <NavbarStart>
              {items.map(item => (
                <AwareNavbarLink key={item.to} {...item} />
              ))}
            </NavbarStart>
            <NavbarEnd>
              <NavbarItem>
                <AdminAuthInfo />
              </NavbarItem>
            </NavbarEnd>
          </NavbarMenu>
        </Navbar>
      </header>
    );
  }
}

export default compose(observer)(Header);
