import { observable, decorate, computed, action, reaction } from "mobx";
import { createTransformer } from "mobx-utils";
import NProgress from "nprogress";
import { scrollTop } from "../utils";

let loaderIndex = 0;
let alertIndex = 0;

const defaultAlertConfig = {
  group: null,
  clearableByUser: true,
  clearOnRouteChange: true,
  clearOnSubmit: true,
  scrollTop: true
};

export class UiStore {
  constructor() {
    // side-effect: start and stop nprogress
    reaction(
      () => this.loadingButNotFrozen,
      loading => {
        if (loading) {
          NProgress.start();
        } else {
          NProgress.done();
        }
      }
    );
  }

  // Loading state

  loaders = observable.map();

  get loading() {
    return this.loaders.size > 0;
  }

  get loadingButNotFrozen() {
    return this.loading && !this.frozen;
  }

  get loadingByTag() {
    return createTransformer(byTag =>
      Array.from(this.loaders.values()).some(({ tag }) => byTag === tag)
    );
  }

  get frozen() {
    return (
      this.loading &&
      Array.from(this.loaders.values()).some(({ frozen }) => frozen)
    );
  }

  startLoader({ tag = null, freeze = false } = {}) {
    const loaderId = loaderIndex++;
    this.loaders.set(loaderId, { tag: tag || null, frozen: freeze || false });
    return loaderId;
  }

  stopLoader(id) {
    this.loaders.delete(id);
  }

  stopAllLoaders() {
    this.loaders.clear();
  }

  // User alert message state

  alerts = observable.map(null, { deep: false }); // shallow observable map, since entries don't need to be observed

  get hasAlerts() {
    return this.alerts.size > 0;
  }

  /**
   * Add an alert
   *
   * @return integer id of alert (for future clearing)
   */
  addAlert(type, messageOrError, config = {}) {
    const id = alertIndex++;

    // Build config
    const finalConfig = {};
    Object.assign(finalConfig, defaultAlertConfig);
    if (messageOrError instanceof Error && messageOrError.alertConfigDefault) {
      // errors can specify their own default config
      Object.assign(finalConfig, messageOrError.alertConfigDefault);
    }
    Object.assign(finalConfig, config);
    if (messageOrError instanceof Error && messageOrError.alertConfigFinal) {
      // errors can specify their own final config
      Object.assign(finalConfig, messageOrError.alertConfigFinal);
    }

    // Clear existing in group if specified
    if (finalConfig.group) {
      this.clearAlertsInGroup(finalConfig.group);
    }

    // Build value
    const value = { id, type, config: finalConfig };
    if (messageOrError instanceof Error) {
      value.message = messageOrError.message;
    } else {
      value.message = messageOrError;
    }

    // Set
    this.alerts.set(id, value);

    // Scroll to top
    if (finalConfig.scrollTop) {
      scrollTop();
    }

    return id;
  }

  clearAlertById(id) {
    this.alerts.delete(id);
  }

  /**
   * Clear alerts that have the clearOnRouteChange config option set as true
   * (Invoked by CustomBrowserRouter component via browser history subscription)
   */
  clearAlertsAfterRouteChange() {
    this.alerts.forEach(({ config }, id) => {
      if (config.clearOnRouteChange) {
        this.clearAlertById(id);
      }
    });
  }

  clearAlertsAfterSubmit() {
    this.alerts.forEach(({ config }, id) => {
      if (config.clearOnSubmit) {
        this.clearAlertById(id);
      }
    });
  }

  clearAlertsInGroup(group = null) {
    this.alerts.forEach(({ config }, id) => {
      if (config.group === group) {
        this.clearAlertById(id);
      }
    });
  }

  clearAllAlerts() {
    this.alerts.clear();
  }
}

decorate(UiStore, {
  loaders: observable,
  loading: computed,
  loadingButNotFrozen: computed,
  loadingByTag: computed,
  frozen: computed,
  startLoader: action,
  stopLoader: action,
  stopAllLoaders: action,

  alerts: observable,
  hasAlerts: computed,
  addAlert: action,
  clearAlertById: action.bound,
  clearAlertsAfterRouteChange: action,
  clearAlertsAfterSubmit: action,
  clearAlertsInGroup: action,
  clearAllAlerts: action
});

export default new UiStore();
