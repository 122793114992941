import authStore from "../../stores/auth";
import { setBearerAuthorizationHeader } from "../../utils";

export default function attachTokenInterceptor(ax) {
  // Add header authentication token if authenticated
  ax.interceptors.request.use(config => {
    if (authStore.token) {
      setBearerAuthorizationHeader(authStore.token, config);
    }
    return config;
  });

  return ax;
}
