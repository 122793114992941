import React from "react";

import Ul from "./components/Ul";

const WAIVER = () => (
  <div>
    <b>Waiver And Release</b>
    <p>
      In consideration of the Bradley Street Bicycle Co-op (hereinafter referred
      to as BSBC) renting/selling/borrowing/giving me a bicycle, or
      fixing/teaching/or engaging in any bike repair, I specifically release and
      forever discharge BSBC and its affiliates, officers, agents, and employees
      from any and all liability or claims for injury, illness, death or loss of
      or damage to property which I may suffer while riding said bicycle and
      participating in bicycling and bicycle repair. This discharge specifically
      includes, but is not limited to, liability or claims for injury, illness,
      death or damage caused by the negligence of BSBC or its affiliates,
      officers, agents, or employees.
    </p>
    <p>
      By signing this document, I realize that liability may arise from
      negligence or carelessness on the part of the persons or entities being
      released, and I hereby assume all of the risks of participating and/or
      volunteering at BSBC, including but not limited to injury or damage to
      personal property from dangerous or defective equipment, property, tools,
      or otherwise in BSBC and the 138 and 140 Bradley St. building.
    </p>
    <Ul>
      <li>
        I realize the importance of wearing a helmet and using visibility
        enhancement equipment such as blinking lights. Lights and a helmet has
        been recommended to me by BSBC staff. If I do not use visibility
        enhancement equipment or wear a helmet I am doing so at my own risk.
      </li>
      <li>
        I understand that these activities may result in severe injury,
        including but not limited to spinal or head injury, and may also may
        result in hazards posed by other bicycles and traffic or road
        conditions.
      </li>
      <li>
        I understand that BSBC will not be liable for any damages or injury
        arising from the work of its staff and/or members and/or volunteers.
      </li>
      <li>
        I understand that BSBC will not be liable for any injury to myself or
        injury caused to others through my working at BSBC, arising from
        activities done on BSBC premises or at the 138 and 140 Bradley Street
        properties, or through use of BSBC bicycles or tools.
      </li>
      <li>
        I understand that BSBC will not be liable for any theft or damage to my
        personal property which may hereafter accrue to me including my
        travelling to and from the space, using the shop’s bicycles, equipment,
        or other facilities, arising from activities done on BSBC premises or in
        association with BSBC.
      </li>
      <li>
        I have read and agree to the BSBC User Responsibilities (previous page).
      </li>
      <li>
        I authorize BSBC to copyright, use, and publish these photos in print
        and/or electronically without compensation. I agree that BSBC may use
        such photographs of me with or without my name and for any lawful
        purpose, including for example such purposes as publicity, illustration,
        advertising, and web content.
      </li>
    </Ul>
    <p>
      I HAVE CAREFULLY READ THIS AGREEMENT IN ITS ENTIRETY AND UNDERSTAND ITS
      CONTENT. I AM AWARE THIS IS AN ASSUMPTION OF RISK, WAIVER AND RELEASE OF
      LIABILITY AND I SIGN IT VOLUNTARILY. I ALSO UNDERSTAND THAT I SHOULD NOT
      AND MAY NOT PARTICIPATE IN THIS ACTIVITY IF I AM UNDER THE INFLUENCE OF
      ALCOHOL OR DRUGS.
    </p>
    <p>------------------</p>
    <p>
      <b>User Rules and Responsibilities</b>
    </p>
    <p>Respect the Community</p>
    <Ul>
      <li>Respect others and self.</li>
      <li>
        Racist, ableist, ageist, homophobic, sexist, and classist behavior and
        language will not be tolerated.
      </li>
      <li>
        Do not insult other people’s bikes, customers, volunteers, or otherwise.
      </li>
      <li>We all ride what we ride, and that is beautiful.</li>
      <li>
        Staff, volunteers, and members will not abuse their power or their
        position in any way.
      </li>
      <li>
        BSBC seeks to build a healthy lifestyle community, and behavior seen as
        hindering this objective will not be tolerated.
      </li>
      <li>Respect the Space.</li>
      <li>Ensure you always leave time to clean up after yourself.</li>
      <li>Put tools back where you found them.</li>
      <li>If you don’t know what it is or where it goes, ask someone!</li>
      <li>
        If you have to leave in the middle of a project but want to keep working
        on it, put pieces into a small bag and attach it to the bike.
      </li>
      <li>
        Label your bike with your name, phone number, and the last date you
        worked on it.
      </li>
      <li>
        Do not borrow tools/inventory/parts within the space for personal use.
      </li>
      <li>Always remember to do a pocket check before leaving.</li>
      <li>
        If you accidentally walk off with a tool, don’t worry! Just bring it
        back as soon as you can.
      </li>
      <li>
        Do not force tools. If you do not know how to use a tool, please ask
        someone before using it.
      </li>
      <li>
        Personal projects may be not stored at BSBC. If it is left without
        approval from staff, then you risk it being stripped, donated, or sold.
      </li>
      <li>BSBC takes no responsibility over anyone’s personal property.</li>
    </Ul>
    <p>
      <b>Rules</b>
    </p>
    <Ul>
      <li>No alcohol at BSBC without consent from the lead volunteer.</li>
    </Ul>
  </div>
);

export default WAIVER;
