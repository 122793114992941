import { Component } from "react";
import { observer, inject } from "mobx-react";

import { compose } from "../../../utils";

class RfidListen extends Component {
  rfidBuffer = [];

  lastTimeout = null;

  rfidListen = e => {
    const key = e.key || String.fromCharCode(e.which);

    if (this.lastTimeout) {
      clearTimeout(this.lastTimeout);
      this.lastTimeout = null;
    }

    if (this.rfidBuffer.length === 0 && key !== "0") {
      // do nothing
      return;
    }

    // if buffer is 10 long and this is the enter key
    if (this.rfidBuffer.length === 10 && key === "Enter") {
      // don't submit any forms or reload accidentally with this enter key
      e.preventDefault();

      // complete
      this.tryRfid(this.rfidBuffer.join(""));

      // clear buffer
      this.rfidBuffer = [];
    }

    this.rfidBuffer.push(key);

    this.lastTimeout = setTimeout(() => {
      // clear buffer
      this.rfidBuffer.length = 0;
    }, 20);
  };

  tryRfid = rfid => {
    const { metaLoaded, rfids } = this.props.stationMetaStore;

    // meta must be loaded
    if (!metaLoaded) {
      console.warn("meta/rfids not loaded");
      return;
    }

    const userId = rfids[rfid];
    if (userId) {
      this.props.onSuccess(userId);
    } else {
      window.alert("rfid did not match user");
    }
  };

  componentWillMount() {
    window.addEventListener("keydown", this.rfidListen);
  }

  componentWillUnmount() {
    window.removeEventListener("keydown", this.rfidListen);
  }

  render() {
    // de-ref rfids for use in tryRfid
    const { metaLoaded, rfids } = this.props.stationMetaStore; // eslint-disable-line no-unused-vars

    return null;
  }
}

export default compose(
  inject("stationMetaStore"),
  observer
)(RfidListen);
