import React from "react";
import Helmet from "react-helmet";
import {
  SEO_SITE_NAME,
  SEO_ARTICLE_PUBLISHER,
  SEO_SCHEMA_ORG_JSON
} from "../../config";

const schemaOrgJsonString = JSON.stringify(SEO_SCHEMA_ORG_JSON);

export default function BaseSEO() {
  return (
    <Helmet>
      <meta property="og:locale" content="en_US" />
      <meta property="og:type" content="website" />
      <meta name="twitter:card" content="summary" />
      <meta property="og:site_name" content={SEO_SITE_NAME} />
      <meta property="article:publisher" content={SEO_ARTICLE_PUBLISHER} />
      <script type="application/ld+json">{schemaOrgJsonString}</script>
    </Helmet>
  );
}
