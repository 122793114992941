import React from "react";
import Helmet from "react-helmet";
import { SEO_SITE_URL } from "../../config";

export default function UrlSEO({ url }) {
  const urlFull = url && url.startsWith("/") ? `${SEO_SITE_URL}${url}` : url;

  return (
    <Helmet>
      {urlFull && <link rel="canonical" href={urlFull} />}
      {urlFull && <meta property="og:url" content={urlFull} />}
    </Helmet>
  );
}
