import { observable, decorate, action, runInAction } from "mobx";
import agent from "../agent";
import { LOADER_TAG_ADMIN_BIKE_PARTS } from "../constants";

export class BikePartAdminStore {
  parts = observable.array();

  clear() {
    this.parts.clear();
  }

  // action
  loadAll() {
    return agent.AdminBikeParts.all({
      loaderTag: LOADER_TAG_ADMIN_BIKE_PARTS
    }).then(body => {
      const parts = body.data;
      runInAction(() => {
        this.parts.replace(parts);
      });
      return parts;
    });
  }
}

decorate(BikePartAdminStore, {
  parts: observable,
  clear: action.bound,
  loadAll: action.bound
});

export default new BikePartAdminStore();
