import React, { Component } from "react";
import SelectFieldImmediate from "../SelectFieldImmediate";
import agent from "../../../agent";
import { LOADER_TAG_ADMIN_FIELD_BSTYLE } from "../../../constants";

export default class SelectBStyle extends Component {
  render() {
    return (
      <SelectFieldImmediate
        getFn={() =>
          agent.AdminFields.allOfType(
            { field: "bstyle" },
            { loaderTag: LOADER_TAG_ADMIN_FIELD_BSTYLE }
          )
        }
        loaderTag={LOADER_TAG_ADMIN_FIELD_BSTYLE}
        {...this.props}
      />
    );
  }
}
