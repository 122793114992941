import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { Title } from "bloomer";

import { compose } from "../../../utils";
import { LOADER_TAG_ADMIN_ORDERS } from "../../../constants";
import agent from "../../../agent";
import OrderTable from "../../../components/tables/OrderTable";
import Wrapper from "./Wrapper";

class OrdersInner_ extends Component {
  _hasUnmounted = false;

  state = { orders: [] };

  get loading() {
    return this.props.uiStore.loadingByTag(LOADER_TAG_ADMIN_ORDERS);
  }

  componentDidMount() {
    this.pullOrders();
  }

  componentWillUnmount() {
    this._hasUnmounted = true;
  }

  pullOrders() {
    const userId = this.props.user.id;
    return agent.AdminOrders.filter(
      {
        filters: {
          sort_field: "created_at",
          sort_direction: "desc",
          user: [userId]
        }
      },
      { loaderTag: LOADER_TAG_ADMIN_ORDERS }
    ).then(body => {
      const orders = body.data;
      if (!this._hasUnmounted) {
        this.setState({ orders });
      }
    });
  }

  render() {
    return <OrderTable loading={this.loading} data={this.state.orders} />;
  }
}

const OrdersInner = compose(
  inject("uiStore"),
  observer
)(OrdersInner_);

class Orders extends Component {
  render() {
    return (
      <Wrapper renderWithUser={user => <OrdersInner user={user} />}>
        <Title isSize={3}>Orders</Title>
      </Wrapper>
    );
  }
}

export default compose(observer)(Orders);
