import React from "react";
import BaseSortTable from "./BaseSortTable";
import { plainLocalDateColumn } from "./helpers";
import { idColumn, makeActionsColumn } from "./columns";

const columns = [
  // (actions column is added below dynamically according to recdon type)
  idColumn,
  {
    Header: "Name",
    accessor: "name",
    width: 250
  },
  {
    Header: "Date",
    accessor: "date",
    ...plainLocalDateColumn
  }
];

class RecdonTable extends React.Component {
  get columns() {
    return [
      makeActionsColumn(`/admin/${this.props.recdonType}-events`),
      ...columns
    ];
  }

  render() {
    return <BaseSortTable columns={this.columns} {...this.props} />;
  }
}

export default RecdonTable;
