import React, { Fragment } from "react";
import { Route } from "react-router-dom";

import TitleHelmet from "../seo/TitleHelmet";
import UrlSEO from "../seo/UrlSEO";
import DescriptionSEO from "../seo/DescriptionSEO";

const BaseRoute = ({
  title,
  canonical = null,
  description = null, // can set to bool false to disable warning
  ...restProps
}) => {
  const { path } = restProps;

  // use canonical url if supplied, otherwise use the `path` prop, as long as
  // it doesn't include any :parameters
  const url =
    canonical || (typeof path === "string" && path.includes(":") ? null : path);

  return (
    <Fragment>
      <TitleHelmet title={title} />
      {url && <UrlSEO url={url} />}
      {description && <DescriptionSEO description={description} />}
      <Route {...restProps} />
    </Fragment>
  );
};

export default BaseRoute;
