import React from "react";
import { Field } from "react-final-form";
import { Field as FieldStyle, Label, Control, Input, Help } from "bloomer";

import { deriveFieldError, emptyNonZero } from "../../utils";

const toMH = totalMinutes => {
  if (emptyNonZero(totalMinutes)) {
    return { minutes: "", hours: "" };
  }
  const minutes = totalMinutes % 60;
  const hours = (totalMinutes - minutes) / 60;
  return { minutes, hours };
};

class InnerInputs extends React.Component {
  onHoursChange = e => {
    const oldTotalMinutes = this.props.value;
    const newHoursRaw = e.target.value;
    const newHours = emptyNonZero(newHoursRaw) ? 0 : parseInt(newHoursRaw, 10);
    const { minutes: oldMinutesMaybe } = toMH(oldTotalMinutes);

    return this.props.onChange(newHours * 60 + (oldMinutesMaybe || 0));
  };

  onMinutesChange = e => {
    const oldTotalMinutes = this.props.value;
    const newMinutesRaw = e.target.value;
    const newMinutes = emptyNonZero(newMinutesRaw)
      ? 0
      : parseInt(newMinutesRaw, 10);
    const { hours: oldHoursMaybe } = toMH(oldTotalMinutes);

    return this.props.onChange((oldHoursMaybe || 0) * 60 + newMinutes);
  };

  render() {
    const { value, ...restProps } = this.props;

    const { minutes, hours } = toMH(value);

    return (
      <FieldStyle hasAddons>
        <Control>
          <Input
            {...restProps}
            value={hours || ""}
            onChange={this.onHoursChange}
            placeholder="Hrs"
            type="number"
            min="0"
            max="23"
            step="1"
          />
        </Control>
        <Control>
          <Input
            {...restProps}
            value={minutes || ""}
            onChange={this.onMinutesChange}
            placeholder="Mins"
            type="number"
            min="0"
            max="59"
            step="1"
          />
        </Control>
      </FieldStyle>
    );
  }
}

export default class DurationFieldWithErrors extends React.PureComponent {
  render() {
    const { autoFocus, disabled, name, label, placeholder } = this.props;
    return (
      <Field
        name={name}
        subscription={{
          value: true,
          error: true,
          submitError: true,
          touched: true,
          dirtySinceLastSubmit: true
        }}
      >
        {({ input, meta }) => {
          const derivedError = deriveFieldError(meta);
          return (
            <FieldStyle>
              <Label>{label}</Label>
              {React.createElement(InnerInputs, {
                autoFocus,
                disabled,
                ...input,
                placeholder,
                isColor: derivedError ? "danger" : undefined
              })}
              {derivedError && <Help isColor="danger">{derivedError}</Help>}
            </FieldStyle>
          );
        }}
      </Field>
    );
  }
}
