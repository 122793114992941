import React from "react";
import { observer, inject } from "mobx-react";

import MembershipPeriodTable from "../../../components/tables/MembershipPeriodTable";
import MembershipFilters from "../../../components/filters/MembershipFilters";
import AdminTableSection from "../../../components/AdminTableSection";
import AdminSite from "../../../components/AdminSite";

import { compose } from "../../../utils";
import { LOADER_TAG_ADMIN_MEMBERSHIPS } from "../../../constants";
import PageHeading from "../../../components/PageHeading";
import ResourceCreateLinkButton from "../../../components/ResourceCreateLinkButton";

class Memberships extends React.Component {
  render() {
    return (
      <AdminSite>
        <PageHeading>Memberships</PageHeading>
        <ResourceCreateLinkButton
          to="/admin/memberships/grant"
          label="Grant Membership"
        />
        <AdminTableSection
          store={this.props.membershipPeriodAdminStore}
          getter={store => store.periods.slice()}
          loadAction={store => store.loadFiltered}
          loaderTag={LOADER_TAG_ADMIN_MEMBERSHIPS}
          clearAction={store => store.clear}
          filterComponent={MembershipFilters}
          tableComponent={MembershipPeriodTable}
        />
      </AdminSite>
    );
  }
}

export default compose(
  inject("membershipPeriodAdminStore"),
  observer
)(Memberships);
