import _get from "lodash.get";

import { EMAIL_REGEX, PHONE_REGEX } from "./config";
import formValidationErrorLayer from "./errors/formValidationErrorLayer";
import ValidationError from "./errors/ValidationError";
import { isInteger, isNumber } from "./utils";
import { parseYMDMomentTimezoneConfig } from "./datetime";

/**
 * Each validator takes the full values object and returns either:
 * - false-y (pass)
 * - object of fieldName => string error
 * - Promise with either of the above
 *    NOTE: due to a bug in react-final-form, this function catches all rejected Promises
 *          so that the browser doesn't crash.
 *          see https://github.com/final-form/final-form/issues/166
 *
 * The above results are then reduced into a single object,
 * or Promise wrapping an object, for react-final-form.
 *
 * @return Promise<Object>|Promise<undefined>
 */
export const makeRecordValidator = (...fieldValidators) => values => {
  return Promise.all(
    fieldValidators.map(validator => {
      // Result can be false-y, Object, Promise<false-y>, or Promise<Object>
      const result = validator(values);

      if (typeof result.then === "function") {
        // result is a promise.
        // Wrap promise in formValidationErrorLayer to handle ValidationErrors.
        // Make sure that rejected promises (that aren't ValidationErrors)
        // don't go any further due to the react-final-form bug mentioned above.
        return formValidationErrorLayer(result).catch(() => ({
          FORM_ERROR: "An unknown error occurred."
        }));
      }

      // Assume this is a plain object with errors, or false-y (pass). Wrap in a promise.
      return Promise.resolve(result);
    })
  ).then(results => {
    // merge error objects
    const errors = results.reduce(
      (acc, result) => (result ? { ...acc, ...result } : acc),
      {}
    );
    return Object.keys(errors).length ? errors : undefined;
  });
};

// Validators with arguments

export const minValue = min => value =>
  value >= min ? undefined : `Must be at least ${min}`;

export const maxValue = max => value =>
  value <= max ? undefined : `Must be at most ${max}`;

export const oneOf = arr => primary =>
  arr.includes(primary) ? undefined : "Invalid option";

export const minLength = n => value =>
  !value || value.length >= n ? undefined : `Must be at least ${n} characters`;

export const maxLength = n => value =>
  !value || value.length <= n ? undefined : `Must be at most ${n} characters`;

// Simple validators

export const required = value => (value ? undefined : "Required");

export const email = value =>
  !value || EMAIL_REGEX.test(value) ? undefined : "Invalid email";

export const phone = value =>
  !value || PHONE_REGEX.test(value)
    ? undefined
    : "The phone number must consist of only digits and +.";

export const matches = (primary, confirmation) =>
  // NOTE: only triggers if a confirmation has actually been attempted
  !confirmation || primary === confirmation
    ? undefined
    : "Does not match below field";

export const date = value => {
  if (!value) {
    return undefined;
  }
  const m = parseYMDMomentTimezoneConfig(value);
  if (m.isValid()) {
    return undefined;
  }
  return "Invalid date";
};

export const isDateAfter = (value, ...others) => {
  if (!value) {
    return undefined; // ok
  }
  const m = parseYMDMomentTimezoneConfig(value);
  if (!m.isValid()) {
    return undefined; // ok
  }
  const badOthers = others
    .map(other => {
      if (!other) {
        return undefined; // ok
      }
      const mo = parseYMDMomentTimezoneConfig(other);
      if (!mo.isValid()) {
        return undefined; // ok
      }
      return m.isSameOrAfter(mo) ? undefined : other;
    })
    .filter(x => x);
  if (badOthers.length === 1) {
    return `Date must be same or after another date field ${badOthers[0]}`;
  } else if (badOthers.length > 1) {
    return `Date must be same or after other date fields: ${badOthers.join(
      ", "
    )}`;
  }
  return undefined; // ok
};

export const int = value =>
  !value || isInteger(value) ? undefined : "Must be valid integer";

export const number = value =>
  value === "" ||
  value === null ||
  typeof value === "undefined" ||
  isNumber(value)
    ? undefined
    : "Must be a plain number";

export const minZero = value => (!value ? undefined : minValue(0)(value));

export const maxMysqlInt = value =>
  !value ? undefined : maxValue(4294967295)(value) ? "Too big" : undefined;

const validatorNames = {
  required,
  email,
  phone,
  matches,
  date,
  isDateAfter,
  int,
  number,
  minZero,
  maxMysqlInt
};
/**
 * Compose validators and form error object under field name.
 *
 * Each validator must return either:
 * - undefined (passed)
 * - promise resolving to undefined (passed)
 * - string error message (failed)
 * - promise resolving to string error message (failed)
 * OR throw a ValidationError with fields object set
 *
 * @param string fieldName (accepts dot notation)
 * @param  {...function|string} validators
 */
export const makeFieldValidator = (
  fieldName,
  ...validators
) => async values => {
  for (let index = 0; index < validators.length; index++) {
    const element = validators[index];

    const valueRaw = _get(values, fieldName);
    // If it's a string, trim it
    const value = typeof valueRaw === "string" ? valueRaw.trim() : valueRaw;

    let validatorOrValidatorName;
    let validatorArgs;
    if (Array.isArray(element)) {
      // this is a special validator that requests multiple fields,
      // or a field that doesn't match fieldName.
      validatorOrValidatorName = element[0];
      validatorArgs = [value, ...element.slice(1).map(name => values[name])];
    } else {
      validatorOrValidatorName = element;
      validatorArgs = [value];
    }

    // makeFieldValidator accepts either a function or
    // the name of a pre-defined function (see validationNames object)
    const validator =
      typeof validatorOrValidatorName === "function"
        ? validatorOrValidatorName
        : validatorNames[validatorOrValidatorName];

    let result;
    try {
      result = await validator(...validatorArgs);
    } catch (error) {
      if (error instanceof ValidationError) {
        result = error;
      } else {
        console.error(error);
        result = "Couldn't validate";
      }
    }

    if (result) {
      if (result instanceof ValidationError) {
        throw result;
      } else {
        throw new ValidationError(null, { [fieldName]: result });
      }
    }
  }
  return undefined;
};
