import React, { Component } from "react";
import { Field as FieldStyle, Label, Control, Button } from "bloomer";
import Script from "react-load-script";
import LoadingText from "../../components/LoadingText";

/**
 * SquarePaymentForm
 *
 * Loads remote SqPaymentForm script and integrates it as a react component
 *
 * Heavily borrowed from:
 * https://www.npmjs.com/package/react-square-hosted-fields
 */

const SQPAYMENTFORM_SCRIPT_URL = "https://js.squareup.com/v2/paymentform";

class SquarePaymentForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // state props go in here
      appId: "",
      locationId: "",
      paymentForm: null,
      paymentFormLoaded: false,
      scriptCreated: false,
      scriptErrored: false,
      scriptLoaded: false
    };

    if (!this.props.appId) {
      console.warn(
        "No appId or appIdResource sent for props of SquarePaymentForm"
      );
    }

    this.scriptCreated = this.scriptCreated.bind(this);
    this.scriptErrored = this.scriptErrored.bind(this);
    this.scriptLoaded = this.scriptLoaded.bind(this);
    this.renderChildren = this.renderChildren.bind(this);
    this.generateNonce = this.generateNonce.bind(this);
  }

  static defaultProps = {
    // stuff that's sent to square's api
    appId: "",
    locationId: "",
    inputClass: "sq-input",
    inputStyles: [
      {
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSize: "16px",
        lineHeight: 1.5,
        backgroundColor: "white",
        color: "#363636",
        placeholderColor: "rgba(54, 54, 54, 0.3)",
        boxShadow: "none",
        padding: "0.375em 0.625em"
      }
    ],
    cardNumber: {
      elementId: "sq-card-number",
      placeholder: "e.g. xxxx-xxxx-xxxx-xxxx"
    },
    cvv: {
      elementId: "sq-cvv",
      placeholder: "e.g. xxx"
    },
    expirationDate: {
      elementId: "sq-expiration-date",
      placeholder: "mm/yy"
    },
    postalCode: {
      elementId: "sq-postal-code",
      placeholder: "e.g. xxxxx"
    },

    // props to plug in that aren't square-based
    submitText: "Place Order",

    onGetAppIdError: error => {
      console.warn(
        "No onAppIdResourceError listener sent for props of SquarePaymentForm"
      );
    },
    onScriptCreated: () => {},
    onScriptError: () => {
      console.warn(
        "No onScriptError listener sent for props of SquarePaymentForm"
      );
    },
    onScriptLoaded: () => {},

    onPaymentFormLoaded: () => {},
    onInputEventReceived: inputEvent => {},
    onUnsupportedBrowserDetected: () => {
      console.warn(
        "No onPaymentFormLoaded listener sent for props of SquarePaymentForm"
      );
    },
    onNonceRequested: () => {},
    onNonceGenerated: (nonce, cardData) => {
      console.warn(
        "No onUnsupportedBrowserDetected listener sent for props of SquarePaymentForm"
      );
    },
    onNonceError: errors => {
      console.warn(
        "No onNonceError listener sent for props of SquarePaymentForm"
      );
    }
  };

  componentWillUnmount() {
    if (this.state.paymentForm) {
      this.state.paymentForm.destroy();
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      (!this.state.scriptLoaded && nextState.scriptLoaded) ||
      this.state.paymentFormLoaded !== nextState.paymentFormLoaded
    );
  }

  componentWillUpdate(nextProps, nextState) {
    if (!(!this.state.scriptLoaded && nextState.scriptLoaded)) {
      return;
    }

    let prom = new Promise((resolve, reject) => {
      resolve(nextProps.appId);
    });

    prom.then(appId => {
      // got the app id, load the form now
      const paymentForm = new window.SqPaymentForm({
        applicationId: appId,
        locationId: this.props.locationId,
        inputClass: this.props.inputClass,
        inputStyles: this.props.inputStyles,
        cardNumber: this.props.cardNumber,
        cvv: this.props.cvv,
        expirationDate: this.props.expirationDate,
        postalCode: this.props.postalCode,
        callbacks: {
          cardNonceResponseReceived: (errors, nonce, cardData) => {
            if (errors && errors.length) {
              this.props.onNonceError(errors);
            } else {
              this.props.onNonceGenerated(nonce, cardData);
            }
          },
          paymentFormLoaded: () => {
            this.setState({
              paymentFormLoaded: true
            });
            // handle here
            this.props.onPaymentFormLoaded();
          },
          inputEventReceived: inputEvent => {
            // handle here
            this.props.onInputEventReceived(inputEvent);
          },
          unsupportedBrowserDetected: () => {
            this.props.onUnsupportedBrowserDetected();
          }
        }
      });

      paymentForm.build();

      this.setState({
        appId: appId,
        paymentForm: paymentForm
      });
    });

    prom.catch(error => {
      this.props.onGetAppIdError(error);
    });
  }

  scriptCreated() {
    this.setState({
      scriptCreated: true
    });

    this.props.onScriptCreated();
  }

  scriptErrored() {
    this.setState({
      scriptError: true
    });

    this.props.onScriptError();
  }

  scriptLoaded() {
    this.setState({
      scriptLoaded: true
    });

    this.props.onScriptLoaded();
  }

  generateNonce(evt) {
    if (this.state.scriptLoaded && this.state.paymentForm) {
      this.props.onNonceRequested();
      this.state.paymentForm.requestCardNonce();
    } else {
      console.warn("Premature generateNonce call in SquarePaymentForm");
    }
  }

  renderChildren() {
    return (
      <div>
        <FieldStyle>
          <Label>Card Number</Label>
          <Control>
            <div id={this.props.cardNumber.elementId} />
          </Control>
        </FieldStyle>
        <FieldStyle>
          <Label>CVV</Label>
          <Control>
            <div id={this.props.cvv.elementId} />
          </Control>
        </FieldStyle>
        <FieldStyle>
          <Label>Expiration</Label>
          <Control>
            <div id={this.props.expirationDate.elementId} />
          </Control>
        </FieldStyle>
        <FieldStyle>
          <Label>Postal Code</Label>
          <Control>
            <div id={this.props.postalCode.elementId} />
          </Control>
        </FieldStyle>
      </div>
    );
  }

  render() {
    return (
      <div>
        {/* Load remote SqPaymentForm script */}
        <Script
          url={SQPAYMENTFORM_SCRIPT_URL}
          onCreate={this.scriptCreated}
          onError={this.scriptErrored}
          onLoad={this.scriptLoaded}
        />
        {/* Render */}
        <LoadingText cond={!this.state.paymentFormLoaded} />
        <div
          style={{
            visibility: this.state.paymentFormLoaded ? "visible" : "hidden"
          }}
        >
          {this.renderChildren()}
          <div style={{ marginTop: "1rem" }}>
            <Button
              isSize="large"
              isColor="primary"
              onClick={this.generateNonce}
            >
              {this.props.submitText}
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

export default SquarePaymentForm;
