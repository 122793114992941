import React, { Component } from "react";
import Select from "react-select";
import CreatableSelect from "react-select/lib/Creatable";
import { inject, observer } from "mobx-react";

import { compose, getCurrentReactSelectValue } from "../../utils";
import { TEMP_FAKE } from "../../constants";

/**
 * SelectFieldImmediate
 *
 * Props:
 * - value
 * - getFn (with embedded loader tag and field name)
 * - loaderTag
 * - onCreateFn
 * - optionFilter (pre-map)
 * - prepareOptions
 * - [Select props]
 */
class SelectFieldImmediate extends Component {
  _hasUnmounted = false;

  state = {
    loadedOrLoading: false,
    options: [] // as they come in, later mapped to actual react-select options by prepareOptions
  };

  get loading() {
    return this.props.loaderTag
      ? this.props.uiStore.loadingByTag(this.props.loaderTag)
      : false;
  }

  loadOptions = () => {
    if (this.state.loadedOrLoading) {
      return this.state.options;
    }

    this.setState({ loadedOrLoading: true });
    return this.props
      .getFn()
      .then(body => body.data)
      .then(options => {
        if (!this._hasUnmounted) {
          this.setState({ options });
        }
        return options;
      })
      .catch(e => {
        this.setState({ loadedOrLoading: false });
        throw e;
      });
  };

  componentWillUnmount() {
    this._hasUnmounted = true;
  }

  onCreateOption = value => {
    const { onChange, onCreateOption, value: originalValue } = this.props;
    if (onCreateOption) {
      // fake the select value until it exists
      onChange({ value: TEMP_FAKE, label: value });
      // create new, then reload options
      onCreateOption(value)
        .then(body => {
          // add to options
          this.setState({
            options: [body.data, ...this.state.options]
          });

          // call onChange
          if (onChange) {
            const result = body.data;
            // set the real value with new id
            onChange({ value: result.id, label: result.name });
          }
        })
        .catch(e => {
          // failed, so set the value back to the original
          onChange(getCurrentReactSelectValue(originalValue));

          // re-throw
          throw e;
        });
    }
  };

  render() {
    const {
      value,
      onCreateOption: isCreateable,
      optionFilter = x => x,
      prepareOptions = options =>
        options.map(({ id, name }) => ({ value: id, label: name }))
    } = this.props;
    const { options } = this.state;

    const component = isCreateable ? CreatableSelect : Select;

    return React.createElement(component, {
      ...this.props,
      onMenuOpen: this.loadOptions,
      value: getCurrentReactSelectValue(value),
      options: prepareOptions(options.filter(optionFilter)),
      isLoading: this.loading,
      ...(isCreateable && {
        onCreateOption: this.onCreateOption,
        isValidNewOption: (inputValue, selectValue, selectOptions) => {
          // Check for empty -- a workaround for a react-select bug, see:
          // https://github.com/JedWatson/react-select/issues/2944
          if (!inputValue) {
            return false;
          }

          // make sure it isn't a duplicate (case insensitive)
          const isNotDuplicated = !selectOptions
            .map(({ label }) => label && label.toLowerCase())
            .includes(inputValue && inputValue.toLowerCase().trim());

          return isNotDuplicated;
        }
      })
    });
  }
}

export default compose(inject("uiStore"), observer)(SelectFieldImmediate);
