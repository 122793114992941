import React from "react";

import FilterWrapper from "./support/FilterWrapper";
import SelectPlan from "../selects/static/SelectPlan";

const fields = [
  {
    name: "plans",
    label: "Plans",
    type: "fancy-select",
    component: SelectPlan,
    isMulti: true // for SelectPlan
  },
  {
    name: "is_active",
    label: "Active?",
    type: "nullable-boolean"
  },
  {
    name: "is_queued",
    label: "Queued?",
    type: "nullable-boolean"
  },
  {
    name: "is_deactivated",
    label: "Deactivated?",
    type: "nullable-boolean"
  },
  {
    name: "is_marked_for_cancel",
    label: "Marked for cancel?",
    type: "nullable-boolean"
  },
  {
    name: "is_purchased",
    label: "Purchased?",
    type: "nullable-boolean"
  },
  {
    name: "is_granted",
    label: "Granted?",
    type: "nullable-boolean"
  },
  {
    name: "is_redeemed",
    label: "Redeemed?",
    type: "nullable-boolean"
  }
  // order[] is also available, but we'll pass for now
];

export default class MembershipFilters extends React.Component {
  render() {
    return <FilterWrapper fields={fields} {...this.props} />;
  }
}
