import _get from "lodash.get";

import uiStore from "../../stores/ui";

export default function attachLoaderInterceptors(ax) {
  // loader request interceptor
  ax.interceptors.request.use(config => {
    if (config.showLoader !== false) {
      // store loader id in config
      config.loaderId = uiStore.startLoader({
        tag: config.loaderTag,
        freeze: config.freeze
      });
    }
    return config;
  });

  // loader response interceptor
  ax.interceptors.response.use(
    response => {
      if (_get(response.config.showLoader) !== false) {
        uiStore.stopLoader(response.config.loaderId);
      }
      return response;
    },
    error => {
      if (_get(error.config.showLoader) !== false) {
        uiStore.stopLoader(error.config.loaderId);
      }

      return Promise.reject(error);
    }
  );

  return ax;
}
