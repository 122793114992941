import React, { Component } from "react";
import { Tag } from "bloomer";

import styles from "./editor.module.scss";

export default class EditorSectionHeading extends Component {
  render() {
    return (
      <div className={styles.sectionHeading}>
        <Tag isSize="large" {...this.props} />
      </div>
    );
  }
}
