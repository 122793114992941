import React, { Component } from "react";
import { Title, Notification } from "bloomer";
import Wrapper from "./Wrapper";

export default class Bikes extends Component {
  render() {
    return (
      <Wrapper>
        <Title isSize={3}>Bikes Worked On</Title>
        <Notification>This view is a work in progress.</Notification>
      </Wrapper>
    );
  }
}
