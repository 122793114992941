import React from "react";
import ReactTable from "react-table";

import "react-table/react-table.css";

import styles from "./base-sort-table.module.scss";

/**
 * BaseSortTable is a light wrapper around react-table
 * providing common defaults/behavior
 */
class BaseSortTable extends React.Component {
  render() {
    const { isFullScreenWidth = false } = this.props;

    return (
      <ReactTable
        className={isFullScreenWidth ? styles.fullScreenWidth : null}
        pageSizeOptions={[25, 50, 100, 200]}
        defaultPageSize={100}
        minRows={10} // controls the minimum number of rows to display
        noDataText={"No results"}
        defaultSorted={[
          {
            id: "id",
            desc: true
          }
        ]}
        {...this.props}
      />
    );
  }
}

export default BaseSortTable;
