import React, { Component } from "react";
import { Box } from "bloomer";
import { observer, inject } from "mobx-react";

import { compose } from "../../../utils";
import agent from "../../../agent";

import StationSite from "../../../components/StationSite";
import NarrowCenterInner from "../../../components/NarrowCenterInner";
import PageHeading from "../../../components/PageHeading";
import CustomFinalForm from "../../../components/CustomFinalForm";
import TextFieldWithErrors from "../../../components/fields/TextFieldWithErrors";
import {
  makeRecordValidator,
  makeFieldValidator,
  minLength
} from "../../../validators";
import { LOADER_TAG_STATION_REGISTER } from "../../../constants";
import formValidationErrorLayer from "../../../errors/formValidationErrorLayer";
import SingleCheckboxFieldWithErrors from "../../../components/fields/SingleCheckboxFieldWithErrors";
import { standardFinalFormSubscriptions } from "../../../config";
import FinalSubmit from "../../../components/fields/FinalSubmit";

const STATION_REGISTER_ALERT_GROUP = "station-register";

const validate = makeRecordValidator(
  makeFieldValidator("name", "required", minLength(3)),
  makeFieldValidator("email", "required", "email"),
  makeFieldValidator("newsletter")
);

class SignUp extends Component {
  resetForm = null;

  onSubmit = fields => {
    return formValidationErrorLayer(
      agent.StationAccount.register(fields, {
        loaderTag: LOADER_TAG_STATION_REGISTER,
        alertGroup: STATION_REGISTER_ALERT_GROUP
      }).then(body => {
        // reset form
        this.resetForm();

        // alert
        this.props.uiStore.addAlert(
          "success",
          "Your BSBC account was created successfully! Please sign the waiver below if you'd like to check in today.",
          { group: STATION_REGISTER_ALERT_GROUP }
        );

        // redirect
        this.props.history.push(
          `/station/waiver?forcedUserId=${body.data.id}`,
          { wasRedirect: true }
        );
      })
    ).catch(() => null);
  };

  get loading() {
    return this.props.uiStore.loadingByTag(LOADER_TAG_STATION_REGISTER);
  }

  render() {
    return (
      <StationSite>
        <NarrowCenterInner>
          <Box>
            <PageHeading>Create Account</PageHeading>
            <CustomFinalForm
              onSubmit={this.onSubmit}
              validate={validate}
              initialValues={{
                name: null,
                email: null,
                newsletter: true
              }}
              subscription={standardFinalFormSubscriptions}
              render={renderProps => {
                const { handleSubmit, form } = renderProps;
                this.resetForm = form.reset;
                return (
                  <form onSubmit={handleSubmit}>
                    <TextFieldWithErrors
                      type="text"
                      name="name"
                      label="Full Name"
                      placeholder="e.g. Alex Smith"
                      autoFocus
                    />
                    <TextFieldWithErrors
                      type="text"
                      name="email"
                      label="Email"
                      placeholder="e.g. alexsmith@gmail.com"
                    />
                    <SingleCheckboxFieldWithErrors
                      name="newsletter"
                      label="Subscribe to monthly newsletter"
                    />
                    <FinalSubmit
                      {...renderProps}
                      label="Next"
                      loading={this.loading}
                    />
                  </form>
                );
              }}
            />
          </Box>
        </NarrowCenterInner>
      </StationSite>
    );
  }
}

export default compose(
  inject("uiStore"),
  observer
)(SignUp);
