import React, { Fragment } from "react";
import { observer } from "mobx-react";
import { runInAction } from "mobx";
import _pick from "lodash.pick";
import { Title } from "bloomer";

import AdminSite from "../../../components/AdminSite";

import agent from "../../../agent";
import { compose, idFromProps } from "../../../utils";
import { LOADER_TAG_ADMIN_ITEMS } from "../../../constants";
import GenericEditor from "../../../components/GenericEditor";
import GenericResourceEditorStore from "../../../stores/special/GenericResourceEditorStore";
import {
  makeRecordValidator,
  makeFieldValidator,
  required
} from "../../../validators";
import TextFieldWithErrors from "../../../components/fields/TextFieldWithErrors";
import SingleCheckboxFieldWithErrors from "../../../components/fields/SingleCheckboxFieldWithErrors";
import MoneyFieldWithErrors from "../../../components/fields/MoneyFieldWithErrors";
import MultipleDynamicTextFieldWithErrors from "../../../components/fields/MultipleDynamicTextFieldWithErrors";
import ImageUpload from "../../../components/fields/ImageUpload";
import Breadcrumbs from "../../../components/Breadcrumbs";

const initialFormValuesFromItem = item => {
  return _pick(item, [
    "is_available",
    "is_shippable",
    "is_quantifiable",
    "title",
    "description",
    "price",
    "variations"
  ]);
};

const newStructure = {
  is_available: true,
  is_shippable: false,
  is_quantifiable: true,
  title: null,
  description: null,
  price: null,
  variations: []
};

const itemEditorStore = new GenericResourceEditorStore(
  "item",
  LOADER_TAG_ADMIN_ITEMS,
  "item-editor",
  "item",
  agent.AdminItems.show,
  agent.AdminItems.create,
  agent.AdminItems.update,
  agent.AdminItems.remove,
  newStructure,
  null,
  "Item saved successfully!",
  "Item deleted successfully!"
);

const validate = makeRecordValidator(
  makeFieldValidator("title", "required"),
  makeFieldValidator("description", "required"),
  makeFieldValidator("price", "required", "number", "minZero"),
  makeFieldValidator("variations", variations => {
    if (!variations || variations.length === 0) {
      return undefined;
    }
    return variations.reduce((acc, variation, key) => {
      const req = required(variation);
      if (req) {
        return { ...acc, [key]: req };
      }
      return acc;
    }, {});
  })
);

class ItemCrud extends React.Component {
  render() {
    const idParam = idFromProps(this.props);
    const isNew = idParam === "new";

    return (
      <AdminSite>
        <Breadcrumbs
          items={[
            { to: "/admin", label: "Admin" },
            { to: "/admin/items", label: "Items" },
            {
              to: `/admin/items/${isNew ? "new" : idParam}`,
              label: isNew ? "New Item" : `Item ${idParam}`
            }
          ]}
        />
        <GenericEditor
          name="item"
          nameForTitle="Item"
          basePath="/admin/items"
          store={itemEditorStore}
          loaderTag={LOADER_TAG_ADMIN_ITEMS}
          recordValidator={validate}
          initialFormValuesFn={initialFormValuesFromItem}
        >
          <SingleCheckboxFieldWithErrors
            name="is_available"
            label="Available?"
          />
          <SingleCheckboxFieldWithErrors
            name="is_shippable"
            label="Shippable?"
          />
          <SingleCheckboxFieldWithErrors
            name="is_quantifiable"
            label="Quantifiable?"
          />

          <TextFieldWithErrors name="title" label="Title" />
          <TextFieldWithErrors
            name="description"
            label="Description"
            isTextarea
          />
          <MoneyFieldWithErrors name="price" label="Price" />

          <MultipleDynamicTextFieldWithErrors
            name="variations[]"
            label="Variations"
          />

          {itemEditorStore.resource &&
            itemEditorStore.resource.id && (
              <Fragment>
                <Title isSize={4}>Image</Title>
                <ImageUpload
                  current={itemEditorStore.resource.image}
                  onPick={image =>
                    agent.AdminItems.uploadImage({
                      id: itemEditorStore.resource.id,
                      file: image
                    })
                  }
                  onRemove={() => {
                    const item = itemEditorStore.resource;
                    // set item image to null
                    runInAction(() => {
                      item.image = null;
                    });
                    return agent.AdminItems.removeImage({
                      id: item.id
                    });
                  }}
                />
              </Fragment>
            )}
        </GenericEditor>
      </AdminSite>
    );
  }
}

export default compose(observer)(ItemCrud);
