import React, { Component } from "react";
import { Tag } from "bloomer";
import { COLORS } from "../config";

export default class BColorTags extends Component {
  render() {
    const { bcolors = [] } = this.props;

    return (
      <div className="tags">
        {bcolors.map(({ id, name }) => {
          const customStyle = COLORS[name.toLowerCase()] || null;
          return (
            <Tag key={id} style={customStyle}>
              {name}
            </Tag>
          );
        })}
      </div>
    );
  }
}
