/**
 * Constants
 */

// Editor Modes
export const MODE_NEW = "new";
export const MODE_EDIT = "edit";

// Role checks (for AuthRoute)
export const ROLE_BASIC = "basic";
export const ROLE_ADMIN = "admin";
export const ROLE_STATION = "station";
export const ROLE_NOT_STATION = "NOT_STATION"; // (not a real role)
export const ROLE_ANY = "ANY"; // (not a real role)

// Volunteer levels
export const VOLUNTEER_LEVEL_BASIC = "basic";
export const VOLUNTEER_LEVEL_SUPER = "super";
export const VOLUNTEER_LEVEL_COORDINATOR = "coordinator";

// Plans
export const PLAN_DAY = "day";
export const PLAN_MONTH = "month";
export const PLAN_YEAR = "year";
export const PLAN_MONTH_RENEW = "month-renew";
export const PLAN_YEAR_RENEW = "year-renew";
export const PLAN_LIFE = "life";

// Shop visit types
export const VISIT_TYPE_MEMBER = "member";
export const VISIT_TYPE_VOLUNTEER = "volunteer";
export const VISIT_TYPE_VISITOR = "visitor";
export const VISIT_TYPE_WTFNIGHT = "wtfnight";
export const VISIT_TYPE_CLASS = "class";

// Bike statuses
export const BIKE_STATUS_NEW = "new";
export const BIKE_STATUS_AVAILABLE = "available";
export const BIKE_STATUS_DONATED = "donated";
export const BIKE_STATUS_SCRAPPED = "scrapped";
export const BIKE_STATUS_SOLD = "sold";
export const BIKE_STATUS_MISSING = "missing";
export const BIKE_STATUS_RETURNED = "returned";

// Bike conditions
export const BIKE_CONDITION_UNKNOWN = "unknown";
export const BIKE_CONDITION_POOR = "poor";
export const BIKE_CONDITION_GOOD = "good";
export const BIKE_CONDITION_GREAT = "great";
export const BIKE_CONDITION_NEW = "new";

// Shipment statuses
export const SHIPMENT_STATUS_UNMARKED = "unmarked";
export const SHIPMENT_STATUS_SHIPPED = "shipped";
export const SHIPMENT_STATUS_WAIVED = "waived";

// Loader tags
export const LOADER_TAG_AUTH = "auth";
export const LOADER_TAG_CART = "cart";
export const LOADER_TAG_CONTACT = "contact";
export const LOADER_TAG_CHECKOUT = "checkout";
export const LOADER_TAG_PUBLIC_BIKES = "public-bikes";
export const LOADER_TAG_PUBLIC_ITEMS = "public-items";
export const LOADER_TAG_ACCOUNT_ORDERS = "account-orders";
export const LOADER_TAG_ACCOUNT_VISITS = "account-visits";
export const LOADER_TAG_ACCOUNT_MEMBERSHIP = "account-membership";
export const LOADER_TAG_ACCOUNT_NEWSLETTER = "account-newsletter";
export const LOADER_TAG_ADMIN_BIKES = "admin-bikes";
export const LOADER_TAG_ADMIN_BIKE_WORK = "admin-bike-work";
export const LOADER_TAG_ADMIN_BIKE_PARTS = "admin-bike-parts";
export const LOADER_TAG_ADMIN_ITEMS = "admin-items";
export const LOADER_TAG_ADMIN_MEMBERSHIPS = "admin-memberships";
export const LOADER_TAG_ADMIN_ORDERS = "admin-orders";
export const LOADER_TAG_ADMIN_ORDER_SHIP = "admin-order-ship";
export const LOADER_TAG_ADMIN_PAGES = "admin-pages";
export const LOADER_TAG_ADMIN_RECEIVING_EVENTS = "admin-receiving-events";
export const LOADER_TAG_ADMIN_DONATING_EVENTS = "admin-donating-events";
export const LOADER_TAG_ADMIN_SHOPVISITS = "admin-shopvisits";
export const LOADER_TAG_ADMIN_RENTALBIKES = "admin-rentalbikes";
export const LOADER_TAG_ADMIN_RENTALPERIODS = "admin-rentalperiods";
export const LOADER_TAG_ADMIN_REPORTS = "admin-reports";
export const LOADER_TAG_ADMIN_REPORTS_REGENERATE = "admin-reports-regenerate";
export const LOADER_TAG_ADMIN_REPORTS_EMAIL_TOP_VOLUNTEERS =
  "admin-reports-email-top-volunteers";
export const LOADER_TAG_ADMIN_USERS = "admin-users";
export const LOADER_TAG_ADMIN_EXPORT_USERS = "admin-export-users";
export const LOADER_TAG_ADMIN_FIELDS = "admin-field-saving";
export const LOADER_TAG_ADMIN_FIELD_ACQUISITION = "admin-field-acquisition";
export const LOADER_TAG_ADMIN_FIELD_ROLE = "admin-field-role";
export const LOADER_TAG_ADMIN_FIELD_VOLUNTEER_LEVEL =
  "admin-field-volunteer-level";
export const LOADER_TAG_ADMIN_FIELD_ORGANIZATION = "admin-field-organization";
export const LOADER_TAG_ADMIN_FIELD_SHIPPING_SERVICE =
  "admin-field-shipping-service";
export const LOADER_TAG_ADMIN_FIELD_BMAKE = "admin-field-bmake";
export const LOADER_TAG_ADMIN_FIELD_BMODEL = "admin-field-bmodel";
export const LOADER_TAG_ADMIN_FIELD_BSIZE = "admin-field-bsize";
export const LOADER_TAG_ADMIN_FIELD_BSTYLE = "admin-field-bstyle";
export const LOADER_TAG_ADMIN_FIELD_BCOLOR = "admin-field-bcolor";
export const LOADER_TAG_ADMIN_FIELD_RENTALBIKE = "admin-field-rentalbike";
export const LOADER_TAG_ADMIN_FIELD_BIKE_PART = "admin-field-bike-part";
export const LOADER_TAG_STATION_META = "station-meta";
export const LOADER_TAG_STATION_USERS = "station-users";
export const LOADER_TAG_STATION_NEWSLETTER = "station-newsletter";
export const LOADER_TAG_STATION_WAIVER = "station-waiver";
export const LOADER_TAG_STATION_REGISTER = "station-register";
export const LOADER_TAG_STATION_RECORD_WORK = "station-record-work";
export const LOADER_TAG_STATION_FIELD_BIKE_PART = "station-field-bike-part";
export const LOADER_TAG_STATION_CIP2W = "station-cip2w";
export const LOADER_TAG_STATION_STREAKS = "station-streaks";

// Field editor
export const FIELD_EDITOR_ACTION_CREATE = "create";
export const FIELD_EDITOR_ACTION_UPDATE = "update";
export const FIELD_EDITOR_ACTION_DELETE = "delete";

// Bike editor
export const BIKE_EDITOR_RECIPIENT_TYPE_USER = "user";
export const BIKE_EDITOR_RECIPIENT_TYPE_HEADLESS = "headless";

// Common Alert Groups (usually defined in modules)
export const ADMIN_ORDER_ALERT_GROUP = "admin-order";

// Misc
export const NO_CHANGE = "***NO_CHANGE***";
export const TEMP_FAKE = "***FAKE***";
