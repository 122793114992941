import { observable, decorate, runInAction, action } from "mobx";
import agent from "../agent";
import { LOADER_TAG_STATION_STREAKS } from "../constants";

export class StreaksStationStore {
  streaks = observable.map();

  loaded = false;

  clear() {
    this.streaks.clear();
    this.loaded = false;
  }

  loadOnce() {
    if (this.loaded) {
      return;
    }

    this.loaded = true;

    return agent.StationAccount.streaks({
      loaderTag: LOADER_TAG_STATION_STREAKS
    }).then(body => {
      runInAction(() => {
        this.streaks.replace(body);
      });
      return body;
    });
  }
}

decorate(StreaksStationStore, {
  streaks: observable,
  loaded: observable,
  clear: action.bound,
  loadOnce: action.bound
});

export default new StreaksStationStore();
