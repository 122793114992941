import React from "react";
import { observer, inject } from "mobx-react";
import { Link } from "react-router-dom";
import _get from "lodash.get";
// import { Message, MessageBody } from "bloomer";

import agent from "../../agent";
import { compose, generateCaptchaToken } from "../../utils";
import { makeRecordValidator, makeFieldValidator } from "../../validators";
import formValidationErrorLayer from "../../errors/formValidationErrorLayer";

import PublicSite from "../../components/PublicSite";
import PageHeading from "../../components/PageHeading";
import NarrowCenterInner from "../../components/NarrowCenterInner";
import CustomFinalForm from "../../components/CustomFinalForm";
import { LOADER_TAG_CONTACT } from "../../constants";
import { standardFinalFormSubscriptions } from "../../config";
import TextFieldWithErrors from "../../components/fields/TextFieldWithErrors";
import FinalSubmit from "../../components/fields/FinalSubmit";
import SelectFieldWithErrors from "../../components/fields/SelectFieldWithErrors";

const CONTACT_ALERT_GROUP = "contact";

const validate = makeRecordValidator(
  makeFieldValidator("reason", "required"),
  makeFieldValidator("email", "required", "email"),
  makeFieldValidator("name", "required"),
  makeFieldValidator("phone"),
  makeFieldValidator("message", "required")
);

class Contact extends React.Component {
  resetForm = null;

  state = {
    initialReason: null
  };

  componentDidMount() {
    // Look for pre-filled reason in history state
    const reason = _get(this.props.location, ["state", "contactReason"]);

    if (reason) {
      this.setState({
        initialReason: reason
      });
    }
  }

  onSubmit = ({ reason, name, email, phone, message }) => {
    return formValidationErrorLayer(
      generateCaptchaToken("contact")
        .then(token =>
          agent.Contact.contact(
            { reason, name, email, phone, message, token },
            {
              loaderTag: LOADER_TAG_CONTACT,
              alertGroup: CONTACT_ALERT_GROUP
            }
          )
        )
        .then(() => {
          // reset form
          this.resetForm();

          // reset initial reason
          this.setState({
            initialReason: null
          });

          // alert user
          this.props.uiStore.addAlert(
            "success",
            "Your message was sent successfully. Thank you!",
            { group: CONTACT_ALERT_GROUP }
          );
        })
    );
  };

  render() {
    return (
      <PublicSite>
        <NarrowCenterInner>
          <PageHeading>Contact</PageHeading>

          {/* <Message isColor="danger">
            <MessageBody style={{ border: "none" }}>
              <p>
                COVID-19 UPDATE: We are open for{" "}
                <strong>emergency repairs</strong>,{" "}
                <strong>used bike sales</strong>,{" "}
                <strong>bike fixing by appointment</strong>, and{" "}
                <strong>volunteering by appointment</strong>.{" "}
                <Link to="/covid19" title="Covid-19 Resources">
                  Click here
                </Link>{" "}
                to make an appointment with us Tuesday - Thursday, 4 - 7pm. You
                do not need to contact us if you book an appointment.
              </p>
            </MessageBody>
          </Message> */}

          <p>
            <strong>Address</strong>
            <br />
            Bradley Street Bicycle Co-op
            <br />
            138 Bradley Street
            <br />
            New Haven, CT 06511
            <br />
            <a
              href="https://goo.gl/maps/3oRutkR9uKaLjf1w8"
              target="_blank"
              rel="noopener noreferrer"
            >
              Google Maps
            </a>
          </p>

          <p>
            <strong>Hours</strong>
            <ul className="bulleted">
              <li>Monday 4-8pm (For volunteering only)</li>
              <li>Tuesday 4-8pm</li>
              <li>Thursday 4-8pm</li>
            </ul>
          </p>

          <p>
            Please review the <Link to="/about/faq">FAQ</Link> before sending
            any general questions.
          </p>

          <p>
            Send us a message using the form below and we'll get back to you via
            email as soon as possible.
          </p>

          <CustomFinalForm
            onSubmit={this.onSubmit}
            validate={validate}
            initialValues={{
              reason: this.state.initialReason,
              email: null,
              name: null,
              phone: null,
              message: null
            }}
            subscription={standardFinalFormSubscriptions}
            render={renderProps => {
              const { handleSubmit, form } = renderProps;
              this.resetForm = form.reset;
              return (
                <form onSubmit={handleSubmit}>
                  <SelectFieldWithErrors name="reason" label="Reason">
                    <option />
                    <option value="purchase">Bike Purchase</option>
                    <option value="donation">Bike Donation</option>
                    <option value="general">General</option>
                  </SelectFieldWithErrors>
                  <TextFieldWithErrors
                    type="text"
                    name="name"
                    label="Full Name"
                  />
                  <TextFieldWithErrors
                    type="text"
                    name="email"
                    label="Email"
                    placeholder="e.g. alexsmith@gmail.com"
                  />
                  <TextFieldWithErrors
                    type="text"
                    name="phone"
                    label="Phone"
                    extraHelp="Optional"
                  />
                  <TextFieldWithErrors
                    type="text"
                    name="message"
                    label="Message"
                    isTextarea
                  />
                  <FinalSubmit {...renderProps} isSize="normal" />
                </form>
              );
            }}
          />

          <br />
          <small className="is-size-7">
            This site is protected by reCAPTCHA. The Google{" "}
            <a href="https://policies.google.com/privacy">Privacy Policy</a> and{" "}
            <a href="https://policies.google.com/terms">Terms of Service</a>{" "}
            apply.
          </small>
        </NarrowCenterInner>
      </PublicSite>
    );
  }
}

export default compose(inject("uiStore"), observer)(Contact);
