import { decorate, observable, action, runInAction, computed } from "mobx";

import agent from "../agent";
import { LOADER_TAG_STATION_META } from "../constants";

export class StationMetaStore {
  meta = observable.map({ rfids: {} }, { deep: true });

  metaLoaded = false;

  clearAll() {
    this.metaLoaded = false;
    this.meta.clear();
  }

  get rfids() {
    return this.meta.get("rfids");
  }

  // action
  // hard reload
  loadMeta() {
    return agent.StationMeta.all({
      loaderTag: LOADER_TAG_STATION_META
    })
      .then(body => {
        runInAction(() => {
          this.metaLoaded = true;
          this.meta.replace(observable.map(body, { deep: true }));
        });
        return this.meta;
      })
      .catch(error => {
        this.clearAll();
        return Promise.reject(error); // re-throw
      });
  }

  // action
  // doesn't reload if already marked as loaded
  loadMetaOnce() {
    if (!this.metaLoaded) {
      return this.loadMeta();
    } else {
      return Promise.resolve(this.meta);
    }
  }
}

decorate(StationMetaStore, {
  meta: observable,
  metaLoaded: observable,
  rfids: computed,
  clearAll: action.bound,
  loadMeta: action.bound,
  loadMetaOnce: action.bound
});

export default new StationMetaStore();
