import React, { Component } from "react";
import { Button, Box } from "bloomer";
import { Columns } from "bloomer";
import { observer, inject } from "mobx-react";

import { compose } from "../../../utils";
import agent from "../../../agent";
import StationSite from "../../../components/StationSite";
import MediumCenterInner from "../../../components/MediumCenterInner";
import PageHeading from "../../../components/PageHeading";
import SearchUsers from "../common/SearchUsers";
import {
  LOADER_TAG_STATION_USERS,
  VISIT_TYPE_VOLUNTEER,
  VISIT_TYPE_MEMBER,
  VISIT_TYPE_WTFNIGHT,
  VISIT_TYPE_CLASS,
  VISIT_TYPE_VISITOR
} from "../../../constants";
import { VISIT_DISPLAY_NAMES } from "../../../config";
import VisitTypeButtonColumn from "./VisitTypeButtonColumn";

const CHECK_IN_ALERT_GROUP = "check-in";

class CheckIn extends Component {
  state = { selectedUser: null };

  get loading() {
    return this.props.uiStore.loadingByTag(LOADER_TAG_STATION_USERS);
  }

  onCheckIn = type => {
    this.props.uiStore.clearAlertsAfterRouteChange();

    agent.StationPunch.checkIn(
      { id: this.state.selectedUser.id, type },
      {
        loaderTag: LOADER_TAG_STATION_USERS,
        alertGroup: CHECK_IN_ALERT_GROUP,
        notFoundName: "user"
      }
    )
      .then(() => {
        this.props.uiStore.addAlert(
          "success",
          "You were checked in successfully. Welcome to BSBC!",
          { group: CHECK_IN_ALERT_GROUP }
        );
      })
      .catch(() => null)
      .finally(() => {
        this.setState({ selectedUser: null });

        // force reload currently checked in
        this.props.userStationStore.loadCurrentlyCheckedIn();

        // redirect
        this.props.history.push("/station", { wasRedirect: true });
      });
  };

  onSelectUser = user => {
    this.setState({ selectedUser: user });
  };

  render() {
    const { selectedUser } = this.state;
    return (
      <StationSite>
        <MediumCenterInner>
          <Box>
            <PageHeading>Check In</PageHeading>
            {selectedUser ? (
              <div>
                <p>
                  {selectedUser.name
                    ? `Welcome, ${selectedUser.name}!`
                    : "Welcome!"}
                </p>
                <p>Please select your visit type for today.</p>
                <Columns>
                  <VisitTypeButtonColumn
                    hotkey="1"
                    visitType={VISIT_TYPE_VOLUNTEER}
                    buttonColor="primary"
                    onCheckIn={this.onCheckIn}
                    loading={this.loading}
                  >
                    Help us/others fix bikes at the shop
                  </VisitTypeButtonColumn>
                  <VisitTypeButtonColumn
                    hotkey="2"
                    visitType={VISIT_TYPE_MEMBER}
                    buttonColor="info"
                    onCheckIn={this.onCheckIn}
                    loading={this.loading}
                  >
                    Work on your own bike
                  </VisitTypeButtonColumn>
                </Columns>
                <Columns>
                  <VisitTypeButtonColumn
                    hotkey="3"
                    visitType={VISIT_TYPE_VISITOR}
                    buttonColor="link"
                    buttonSize="medium"
                    onCheckIn={this.onCheckIn}
                    loading={this.loading}
                  >
                    Just saying hi!
                  </VisitTypeButtonColumn>
                  <VisitTypeButtonColumn
                    hotkey="4"
                    visitType={VISIT_TYPE_CLASS}
                    buttonColor="link"
                    buttonSize="medium"
                    onCheckIn={this.onCheckIn}
                    loading={this.loading}
                  >
                    e.g. BSBC Build-A-Bike
                  </VisitTypeButtonColumn>
                  <VisitTypeButtonColumn
                    hotkey="5"
                    visitType={VISIT_TYPE_WTFNIGHT}
                    buttonColor="link"
                    buttonSize="medium"
                    onCheckIn={this.onCheckIn}
                    loading={this.loading}
                  >
                    Tuesday nights for women/trans/femme
                  </VisitTypeButtonColumn>
                </Columns>
              </div>
            ) : (
              <div>
                <p>First, search for and select your account.</p>
                <SearchUsers
                  onForceSelect={this.onSelectUser}
                  renderActions={user => {
                    const isCheckedIn = user.current_visit;

                    return !isCheckedIn ? (
                      <div>
                        <Button
                          onClick={() => this.onSelectUser(user)}
                          isColor="primary"
                        >
                          Select
                        </Button>
                      </div>
                    ) : (
                      <b>
                        Already Checked In (
                        {VISIT_DISPLAY_NAMES[user.current_visit.type]})
                      </b>
                    );
                  }}
                />
              </div>
            )}
          </Box>
        </MediumCenterInner>
      </StationSite>
    );
  }
}

export default compose(
  inject("userStationStore", "uiStore"),
  observer
)(CheckIn);
