import React from "react";
import { observer, inject } from "mobx-react";
import { Title } from "bloomer";

import {
  makeRecordValidator,
  makeFieldValidator,
  minLength
} from "../../../validators";
import formValidationErrorLayer from "../../../errors/formValidationErrorLayer";
import TextFieldWithErrors from "../../../components/fields/TextFieldWithErrors";
import CustomFinalForm from "../../../components/CustomFinalForm";
import { standardFinalFormSubscriptions } from "../../../config";
import { compose } from "../../../utils";
import FinalSubmit from "../../../components/fields/FinalSubmit";
import Wrapper from "./common/Wrapper";

const validate = makeRecordValidator(
  makeFieldValidator("password", "required", minLength(6), [
    "matches",
    "password_confirmation"
  ]),
  makeFieldValidator("password_confirmation", "required")
);

class UpdatePassword extends React.Component {
  resetForm = null;

  onSubmit = ({ password, password_confirmation }) => {
    return formValidationErrorLayer(
      this.props.authStore
        .updatePassword(password, password_confirmation)
        .then(() => {
          // reset form
          this.resetForm();
        })
    );
  };

  render() {
    return (
      <Wrapper>
        {() => (
          <div>
            <Title isSize={3}>Update Password</Title>
            <CustomFinalForm
              onSubmit={this.onSubmit}
              validate={validate}
              initialValues={{}}
              subscription={standardFinalFormSubscriptions}
              render={renderProps => {
                const { handleSubmit, form } = renderProps;
                this.resetForm = form.reset;
                return (
                  <form onSubmit={handleSubmit}>
                    <TextFieldWithErrors
                      type="password"
                      name="password"
                      label="Password"
                      placeholder="Password"
                      extraHelp="Must be at least 6 characters"
                    />
                    <TextFieldWithErrors
                      type="password"
                      name="password_confirmation"
                      label="Password (Again)"
                    />
                    <FinalSubmit
                      {...renderProps}
                      label="Update Password"
                      isSize="normal"
                    />
                  </form>
                );
              }}
            />
          </div>
        )}
      </Wrapper>
    );
  }
}

export default compose(
  inject("authStore"),
  observer
)(UpdatePassword);
