import React, { Component } from "react";
import { observer, inject } from "mobx-react";

import { compose } from "../../../utils";
import { LOADER_TAG_STATION_CIP2W } from "../../../constants";

class Cip2w extends Component {
  get loading() {
    return this.props.uiStore.loadingByTag(LOADER_TAG_STATION_CIP2W);
  }

  componentDidMount() {
    this.props.cip2wStationStore.loadOnce();
  }

  render() {
    const { cip2wStationStore, component, componentProps } = this.props;
    const { users } = cip2wStationStore;
    return React.createElement(component, {
      loading: this.loading,
      users,
      ...componentProps
    });
  }
}

export default compose(
  inject("cip2wStationStore", "uiStore"),
  observer
)(Cip2w);
