import { observable, decorate, runInAction, action, toJS } from "mobx";
import agent from "../agent";
import { LOADER_TAG_ADMIN_SHOPVISITS } from "../constants";
import { ENUM_VISIT_TYPES } from "../config";
import { mapSomeFieldsToId } from "../utils";
import { formatMomentLocalYMD, momentNowLocal } from "../datetime";

export class ShopVisitAdminStore {
  visits = observable.array();

  filters = observable.map();

  constructor() {
    this.resetFilters();
  }

  clear() {
    this.visits.clear();
    this.resetFilters();
  }

  loadFiltered() {
    const finalFilters = mapSomeFieldsToId(toJS(this.filters), []);
    return agent.AdminVisits.filter(
      { filters: finalFilters },
      { loaderTag: LOADER_TAG_ADMIN_SHOPVISITS }
    )
      .then(body => {
        const visits = body.data;
        runInAction(() => {
          this.visits.replace(visits);
        });
      })
      .catch(error => {
        this.clear();
        return Promise.reject(error); // re-throw
      });
  }

  resetFilters() {
    this.filters.replace({
      types: ENUM_VISIT_TYPES,
      date_from: formatMomentLocalYMD(momentNowLocal().subtract(7, "days")),
      date_to: null
    });
  }

  setFilter(field, value) {
    this.filters.set(field, value);
  }
}

decorate(ShopVisitAdminStore, {
  visits: observable,
  filters: observable,
  clear: action.bound,
  loadFiltered: action.bound,
  resetFilters: action.bound,
  setFilter: action.bound
});

export default new ShopVisitAdminStore();
