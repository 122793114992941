import React from "react";
import { observer } from "mobx-react";
import _pick from "lodash.pick";

import AdminSite from "../../../components/AdminSite";

import agent from "../../../agent";
import { compose, idFromProps, mapSomeFieldsToId } from "../../../utils";
import { LOADER_TAG_ADMIN_RENTALPERIODS } from "../../../constants";
import GenericEditor from "../../../components/GenericEditor";
import GenericResourceEditorStore from "../../../stores/special/GenericResourceEditorStore";
import { makeRecordValidator, makeFieldValidator } from "../../../validators";
import TextFieldWithErrors from "../../../components/fields/TextFieldWithErrors";
import SingleCheckboxFieldWithErrors from "../../../components/fields/SingleCheckboxFieldWithErrors";
import Breadcrumbs from "../../../components/Breadcrumbs";
import FancySelectFieldWithErrors from "../../../components/fields/FancySelectFieldWithErrors";
import PageHeading from "../../../components/PageHeading";
import TitleHelmet from "../../../components/seo/TitleHelmet";
import SelectUser from "../../../components/selects/throttled/SelectUser";
import DateFieldWithErrors from "../../../components/fields/DateFieldWithErrors";
import SelectAvailableRentalBike from "../../../components/selects/immediate/SelectAvailableRentalBike";
import {
  formatMomentLocalYMD,
  parseMomentTimezoneConfig
} from "../../../datetime";

const initialFormValuesFromRentalPeriod = rentalperiod => {
  const picked = _pick(rentalperiod, [
    "is_returned",
    "square_order_id",
    "renter_phone",
    "start_date",
    "due_date",
    "notes_during",
    "notes_return",
    "notes_repair",
    "rentalbike",
    "user"
  ]);

  // Parse dates
  if (picked.start_date) {
    picked.start_date = formatMomentLocalYMD(
      parseMomentTimezoneConfig(picked.start_date)
    );
  }
  if (picked.due_date) {
    picked.due_date = formatMomentLocalYMD(
      parseMomentTimezoneConfig(picked.due_date)
    );
  }

  return picked;
};

const newStructure = {
  is_returned: false,
  square_order_id: null,
  renter_phone: null,
  start_date: null,
  due_date: null,
  notes_during: null,
  notes_return: null,
  notes_repair: null,
  rentalbike: null,
  user: null
};

const validate = makeRecordValidator(
  makeFieldValidator("square_order_id", "required"),
  makeFieldValidator("renter_phone", "required", "phone"),
  makeFieldValidator("start_date", "required", "date"),
  makeFieldValidator("due_date", "required", "date", [
    "isDateAfter",
    "start_date"
  ]),
  makeFieldValidator("rentalbike", "required"),
  makeFieldValidator("user", "required")
);

const rentalPeriodEditorStore = new GenericResourceEditorStore(
  "rentalperiod",
  LOADER_TAG_ADMIN_RENTALPERIODS,
  "rentalperiod-editor",
  "rentalperiod",
  agent.AdminRentalPeriods.show,
  agent.AdminRentalPeriods.create,
  agent.AdminRentalPeriods.update,
  agent.AdminRentalPeriods.remove,
  newStructure,
  fields => mapSomeFieldsToId(fields, ["rentalbike", "user"]),
  "Rental period saved successfully!",
  "Rental period deleted successfully!"
);

class RentalPeriodCrud extends React.Component {
  render() {
    const idParam = idFromProps(this.props);
    const isNew = idParam === "new";

    const title = isNew ? `New Rental Period` : `Manage Rental Period`;

    return (
      <AdminSite>
        <Breadcrumbs
          items={[
            { to: "/admin", label: "Admin" },
            { to: "/admin/rentals", label: "Rentals" },
            {
              to: `/admin/rentals/periods/${idParam}/${
                isNew ? "new" : idParam
              }`,
              label: title
            }
          ]}
        />
        <GenericEditor
          name="rental period"
          homeResourcePath="/admin/rentals"
          basePath="/admin/rentals/periods"
          store={rentalPeriodEditorStore}
          loaderTag={LOADER_TAG_ADMIN_RENTALPERIODS}
          recordValidator={validate}
          initialFormValuesFn={initialFormValuesFromRentalPeriod}
        >
          {<PageHeading>{title}</PageHeading>}
          {<TitleHelmet title={title} />}

          <FancySelectFieldWithErrors
            name="rentalbike"
            label="Rental Bike"
            component={SelectAvailableRentalBike}
            componentProps={{
              isDisabled: !isNew
            }}
          />
          <FancySelectFieldWithErrors
            name="user"
            label="User"
            component={SelectUser}
            componentProps={{
              isDisabled: !isNew
            }}
          />

          <SingleCheckboxFieldWithErrors name="is_returned" label="Returned?" />

          <TextFieldWithErrors name="square_order_id" label="Square Order Id" />
          <TextFieldWithErrors name="renter_phone" label="Renter Phone" />
          <DateFieldWithErrors name="start_date" label="Start Date" />
          <DateFieldWithErrors name="due_date" label="Due Date" />
          <TextFieldWithErrors
            name="notes_during"
            label="Notes (During rental)"
            isTextarea
          />
          <TextFieldWithErrors
            name="notes_return"
            label="Notes (After return)"
            isTextarea
          />
          <TextFieldWithErrors
            name="notes_repair"
            label="Notes (Repair)"
            isTextarea
          />
        </GenericEditor>
      </AdminSite>
    );
  }
}

export default compose(observer)(RentalPeriodCrud);
