import { observable, decorate, action, runInAction } from "mobx";
import { MODE_NEW, MODE_EDIT } from "../../constants";
import uiStore from "../ui";

class GenericResourceEditorStore {
  // observables
  mode = null;
  resource = null;

  // plain properties
  displayName = null;
  loaderTag = null;
  alertGroup = null;
  resourceFnKey = null;
  showFn = null;
  createFn = null;
  updateFn = null;
  removeFn = null;
  newStructure = null;
  preSave = null;
  saveSuccessMessage = null;
  removeSuccessMessage = null;

  constructor(
    displayName,
    loaderTag,
    alertGroup,
    resourceFnKey,
    showFn,
    createFn,
    updateFn,
    removeFn,
    newStructure,
    preSave,
    saveSuccessMessage,
    removeSuccessMessage
  ) {
    this.displayName = displayName;
    this.loaderTag = loaderTag;
    this.alertGroup = alertGroup;
    this.resourceFnKey = resourceFnKey;
    this.showFn = showFn;
    this.createFn = createFn;
    this.updateFn = updateFn;
    this.removeFn = removeFn;
    this.newStructure = newStructure;
    this.preSave = preSave;
    this.saveSuccessMessage = saveSuccessMessage;
    this.removeSuccessMessage = removeSuccessMessage;
  }

  // action
  clear() {
    this.mode = null;
    this.resource = null;
  }

  // action
  initializeNew() {
    this.clear();
    this.mode = MODE_NEW;
    this.resource = this.newStructure;
    return Promise.resolve(this.resource);
  }

  // action
  initializeEdit(id) {
    this.clear();
    this.mode = MODE_EDIT;
    return this.showFn(
      { id },
      { loaderTag: this.loaderTag, notFoundName: this.displayName }
    ).then(body => {
      const resource = body.data;
      runInAction(() => {
        this.resource = resource;
      });
      return resource;
    });
  }

  // action
  save(resource) {
    const finalResource = this.preSave ? this.preSave(resource) : resource;

    if (this.mode === MODE_NEW) {
      return this.createFn(
        { [this.resourceFnKey]: finalResource },
        { loaderTag: this.loaderTag }
      )
        .then(body => {
          const resource = body.data;
          runInAction(() => {
            this.resource = resource;
            this.mode = MODE_EDIT;
          });
          return resource;
        })
        .then(() => {
          // Success
          uiStore.addAlert("success", this.saveSuccessMessage, {
            group: this.alertGroup
          });
        });
    } else if (this.mode === MODE_EDIT) {
      return this.updateFn(
        {
          id: this.resource.id,
          [this.resourceFnKey]: finalResource
        },
        { loaderTag: this.loaderTag, notFoundName: this.displayName }
      )
        .then(body => {
          const resource = body.data;
          runInAction(() => {
            this.resource = resource;
          });
          return resource;
        })
        .then(() => {
          // Success
          uiStore.addAlert("success", this.saveSuccessMessage, {
            group: this.alertGroup
          });
        });
    }
  }

  // action
  remove() {
    if (this.mode === MODE_EDIT) {
      return this.removeFn(
        { id: this.resource.id },
        { loaderTag: this.loaderTag, notFoundName: this.displayName }
      ).then(() => {
        // Success
        uiStore.addAlert("success", this.removeSuccessMessage, {
          group: this.alertGroup
        });
      });
    }
  }
}

decorate(GenericResourceEditorStore, {
  mode: observable,
  resource: observable,
  clear: action.bound,
  initializeNew: action.bound,
  initializeEdit: action.bound,
  save: action.bound,
  remove: action.bound
});

export default GenericResourceEditorStore;
