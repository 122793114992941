import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { Title } from "bloomer";

import { compose } from "../../../utils";
import { LOADER_TAG_ADMIN_SHOPVISITS } from "../../../constants";
import agent from "../../../agent";
import ShopVisitTable from "../../../components/tables/ShopVisitTable";
import Wrapper from "./Wrapper";

class VisitsInner_ extends Component {
  _hasUnmounted = false;

  state = { visits: [] };

  get loading() {
    return this.props.uiStore.loadingByTag(LOADER_TAG_ADMIN_SHOPVISITS);
  }

  componentDidMount() {
    this.pullVisits();
  }

  componentWillUnmount() {
    this._hasUnmounted = true;
  }

  pullVisits() {
    const userId = this.props.user.id;
    return agent.AdminVisits.filter(
      {
        filters: {
          user: [userId]
        }
      },
      { loaderTag: LOADER_TAG_ADMIN_SHOPVISITS }
    ).then(body => {
      const visits = body.data;
      if (!this._hasUnmounted) {
        this.setState({ visits });
      }
    });
  }

  render() {
    return <ShopVisitTable loading={this.loading} data={this.state.visits} />;
  }
}

const VisitsInner = compose(
  inject("uiStore"),
  observer
)(VisitsInner_);

class Visits extends Component {
  render() {
    return (
      <Wrapper renderWithUser={user => <VisitsInner user={user} />}>
        <Title isSize={3}>Visits</Title>
      </Wrapper>
    );
  }
}

export default compose(observer)(Visits);
