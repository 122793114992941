import React from "react";

const RENTALWAIVER = () => (
  <div>
    <b>Waiver And Release</b>
    TODO: CONTENT DO NOT SIGN
  </div>
);

export default RENTALWAIVER;
