import React, { Component } from "react";
import SelectFieldThrottled from "../SelectFieldThrottled";
import { recdonOneLiner } from "../../../utils";

export default class SelectReceivingEvent extends Component {
  render() {
    return (
      <SelectFieldThrottled
        field="receiving-event"
        {...this.props}
        labelMaker={recdonOneLiner}
      />
    );
  }
}
