import React, { PureComponent } from "react";

export default class PlainInfoParagraph extends PureComponent {
  render() {
    const { isCentered = true, children, ...restProps } = this.props;

    if (!children) {
      return null;
    }

    return (
      <p
        className={`${isCentered &&
          "has-text-centered"} has-margin-bottom is-size-5`}
        {...restProps}
      >
        {children}
      </p>
    );
  }
}
