import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import classNames from "classnames";

import styles from "./burger-menu.module.scss";
import AuthCorner from "./AuthCorner";

const AwareNavbarLink = ({ label, children, ...props }) => (
  <li>
    <NavLink
      className={styles.navLink}
      activeClassName={styles.navLinkActive}
      {...props}
    >
      {label}
    </NavLink>
    {children && <ul>{children}</ul>}
  </li>
);

export default function BurgerMenu() {
  const [open, setOpen] = useState(false);

  const toggle = () => setOpen(!open);
  const close = () => setOpen(false);

  return (
    <div className={styles.wrapper}>
      <div className={styles.hamburger}>
        <span>
          <span style={{ top: "0%" }} />
          <span style={{ top: "42%" }} />
          <span style={{ top: "84%" }} />
        </span>
        <button onClick={toggle}>Open Menu</button>
      </div>
      <div
        className={classNames(styles.overlay, { [styles.overlayIsOpen]: open })}
        onClick={toggle}
      />
      <div className={classNames(styles.menu, { [styles.isOpen]: open })}>
        <div className={styles.cross}>
          <span>
            <span />
            <span />
          </span>
          <button onClick={close}>Close Menu</button>
        </div>
        <div className={styles.nav}>
          <ul>
            <AwareNavbarLink onClick={close} to="/" exact label="Home" />
            <AwareNavbarLink onClick={close} to="/donate" label="Donate" />
            <AwareNavbarLink
              onClick={close}
              to="/about"
              label="About / Mission"
            >
              <AwareNavbarLink
                onClick={close}
                to="/about/shop-hours"
                label="DIY Open Shop Hours"
              />
              <AwareNavbarLink
                onClick={close}
                to="/about/bicycle-recycling"
                label="Bicycle Recycling Program"
              />
              <AwareNavbarLink
                onClick={close}
                to="/about/wtf-nights"
                label="WTF Nights"
              />
              <AwareNavbarLink onClick={close} to="/about/faq" label="FAQ" />
            </AwareNavbarLink>
            <AwareNavbarLink onClick={close} to="/store" label="Store">
              <AwareNavbarLink
                onClick={close}
                to="/store/bikes"
                label="Bikes For Sale"
              />
            </AwareNavbarLink>
            <AwareNavbarLink onClick={close} to="/covid19" label="Covid-19" />
            <AwareNavbarLink onClick={close} to="/jobs" label="Jobs" />
            <AwareNavbarLink onClick={close} to="/contact" label="Contact" />
          </ul>
        </div>
        <hr />
        <div className={styles.authWrapper}>
          <AuthCorner />
        </div>
      </div>
    </div>
  );
}
