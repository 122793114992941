import React from "react";
import { Field } from "react-final-form";
import { Field as FieldStyle, Control, Checkbox, Help } from "bloomer";
import { deriveFieldError } from "../../utils";

class SingleCheckboxFieldWithErrors extends React.PureComponent {
  render() {
    const { name, label } = this.props;
    return (
      <Field
        name={name}
        type="checkbox"
        subscription={{
          value: true,
          error: true,
          submitError: true,
          touched: true,
          dirtySinceLastSubmit: true
        }}
      >
        {({ input, meta }) => {
          const derivedError = deriveFieldError(meta);
          return (
            <FieldStyle>
              <Control>
                <Checkbox {...input}> {label}</Checkbox>
              </Control>
              {derivedError && <Help isColor="danger">{derivedError}</Help>}
            </FieldStyle>
          );
        }}
      </Field>
    );
  }
}

export default SingleCheckboxFieldWithErrors;
