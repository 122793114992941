import React, { PureComponent } from "react";
import { PieChart, Pie, ResponsiveContainer, Cell } from "recharts";
import { ratioToPrettyPercent } from "../../../../utils";

const COLORS = {
  new: "#FFBB28",
  available: "#FF8042",
  sold: "#00C49F",
  donated: "#0088FE",
  scrapped: "#D0E3CC",
  missing: "#44355B",
  returned: "#E7BBE3"
};

const ORDER = [
  "new",
  "available",
  "sold",
  "donated",
  "scrapped",
  "missing",
  "returned"
];

export default class PieBikesStatus extends PureComponent {
  render() {
    const { statuses } = this.props;

    if (!statuses) {
      return "n/a";
    }

    const totalBikes = Object.values(statuses).reduce(
      (acc, bikes) => acc + bikes,
      0
    );

    const data = ORDER.map(status => {
      const bikes = statuses[status];
      if (!bikes) {
        return null;
      }
      return {
        status,
        bikes,
        percent: ratioToPrettyPercent(totalBikes > 0 ? bikes / totalBikes : 1)
      };
    }).filter(x => x);

    return (
      <ResponsiveContainer width="100%" height={300}>
        <PieChart>
          <Pie
            dataKey="bikes"
            isAnimationActive={true}
            data={data}
            outerRadius={100}
            label={({
              cx,
              cy,
              midAngle,
              innerRadius,
              outerRadius,
              value,
              index
            }) => {
              const RADIAN = Math.PI / 180;
              // eslint-disable-next-line
              const radius = 25 + innerRadius + (outerRadius - innerRadius);
              // eslint-disable-next-line
              const x = cx + radius * Math.cos(-midAngle * RADIAN);
              // eslint-disable-next-line
              const y = cy + radius * Math.sin(-midAngle * RADIAN);

              const thisStatus = data[index];

              return (
                <text
                  x={x}
                  y={y}
                  // fill="#8884d8"
                  textAnchor={x > cx ? "start" : "end"}
                  dominantBaseline="central"
                >
                  {thisStatus.status} &ndash; {value} ({thisStatus.percent})
                </text>
              );
            }}
          >
            {data.map(({ status }) => (
              <Cell key={`cell-${status}`} fill={COLORS[status] || "black"} />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    );
  }
}
