/**
 * Google Analytics component
 *
 * Use as a child component to Router (react-router)
 */

import React from "react";
import ReactGA from "react-ga";
import { withRouter } from "react-router-dom";

import {
  GOOGLE_ANALYTICS_ID,
  GOOGLE_ANALYTICS_DONT_TRACK_PREFIXES
} from "../config";

if (GOOGLE_ANALYTICS_ID) {
  ReactGA.initialize(GOOGLE_ANALYTICS_ID);
}

/**
 * Filter out certain locations from being sent to analytics
 * @param {object} location
 */
function allowLocation(location) {
  return !GOOGLE_ANALYTICS_DONT_TRACK_PREFIXES.some(prefix =>
    location.pathname.startsWith(prefix)
  );
}

class _Analytics extends React.Component {
  componentDidMount() {
    if (GOOGLE_ANALYTICS_ID) {
      const { history } = this.props;
      this.sendPageView(history.location);
      history.listen(this.sendPageView);
    }
  }

  sendPageView(location) {
    if (allowLocation(location) !== false) {
      ReactGA.set({ page: location.pathname });
      ReactGA.pageview(location.pathname);
    }
  }

  render() {
    return this.props.children;
  }
}

const Analytics = withRouter(_Analytics);
export default Analytics;
