import React, { PureComponent } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Cell
} from "recharts";
import { formatMoney } from "../../../../utils";

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload[0]) {
    return (
      <div
        style={{
          margin: 0,
          padding: 10,
          backgroundColor: "rgb(255, 255, 255)",
          border: "1px solid rgb(204, 204, 204)",
          whiteSpace: "nowrap"
        }}
      >
        {label}
        <br />
        {formatMoney(payload[0].value)}
        <p className="is-size-7">({payload[0].payload.count} bikes)</p>
      </div>
    );
  }

  return null;
};

export default class BarBikesPerOrganization extends PureComponent {
  render() {
    const {
      organizationDataKey,
      organizationBikeMap,
      countMinForColor = 0,
      removeZeroes
    } = this.props;

    if (!organizationBikeMap) {
      return "n/a";
    }

    const data = Object.entries(organizationBikeMap)
      .reduce(
        (acc, [organization, organizationData]) => [
          ...acc,
          {
            organization,
            value: organizationData[organizationDataKey],
            count: organizationData["count"] // for bar color calculation, see below
          }
        ],
        []
      )
      .filter(a => (removeZeroes ? a.value > 0 : true))
      .sort((a, b) => (a.value > b.value ? 1 : -1));

    return (
      <ResponsiveContainer width="100%" height={350}>
        <BarChart data={data}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="organization"
            interval={0}
            angle={-45}
            textAnchor="end"
            height={100}
            tick={{ fontSize: 10 }}
          />
          <YAxis stroke="#8884d8" />
          {countMinForColor ? (
            <Tooltip content={<CustomTooltip />} />
          ) : (
            <Tooltip />
          )}
          <Bar dataKey="value" fill="#8884d8">
            {data.map((entry, index) => {
              const color =
                countMinForColor && entry.count <= countMinForColor
                  ? "#cccccccc"
                  : "#8884d8";
              return <Cell key={`cell-${index}`} fill={color} />;
            })}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    );
  }
}
