import { isInteger } from "../../../utils";
import { parseYMDMomentTimezoneConfig } from "../../../datetime";

import TextFilterField from "./TextFilterField";
import DateRangeFilterField from "./DateRangeFilterField";
import NullableBooleanFilterField from "./NullableBooleanFilterField";
import CheckboxMultiFilterField from "./CheckboxMultiFilterField";
import FancySelectFilterField from "./FancySelectFilterField";
import IntegerRangeFilterField from "./IntegerRangeFilterField";
import MoneyRangeFilterField from "./MoneyRangeFilterField";
import FancySelectWithBooltypeFilterField from "./FancySelectWithBooltypeFilterField";

const integerRangeValidator = (value, field, { fromOrTo }, filters) => {
  // validate both
  const fromRaw = filters[`${field.name}_from`];
  const toRaw = filters[`${field.name}_to`];

  const fromString = fromRaw && fromRaw.trim();
  const toString = toRaw && toRaw.trim();

  if (!fromString && field.fromRequired) {
    return "Required (from)";
  } else if (!toString && field.toRequired) {
    return "Required (to)";
  }

  if (fromString && !isInteger(fromString)) {
    return "Invalid integer (from)";
  } else if (toString && !isInteger(toString)) {
    return "Invalid integer (to)";
  }

  if (!fromString || !toString) {
    return undefined;
  }

  if (parseInt(fromString, 10) > parseInt(toString, 10)) {
    return "'from' must be less than or equal to 'to'";
  }

  return undefined;
};

const filterConfig = {
  text: {
    component: TextFilterField,
    validator: value => {
      return undefined;
    }
  },
  "date-range": {
    component: DateRangeFilterField,
    filterValueGetter: (filters, name) => {
      return {
        from: filters[`${name}_from`],
        to: filters[`${name}_to`]
      };
    },
    validator: (value, field, { fromOrTo }, filters) => {
      if (
        ((field.fromRequired && fromOrTo === "from") ||
          (field.toRequired && fromOrTo === "to")) &&
        (!value || !value.trim())
      ) {
        return `Required (${fromOrTo})`;
      } else if (!value || !value.trim()) {
        return undefined;
      }

      const m = parseYMDMomentTimezoneConfig(value);
      if (!m.isValid()) {
        return `Invalid '${fromOrTo}' date`;
      }
      // validate that to is after from
      if (fromOrTo === "from") {
        const toValue = filters[`${field.name}_to`];
        if (field.toRequired || (toValue && toValue.trim())) {
          // parse to
          const mTo = parseYMDMomentTimezoneConfig(toValue);
          if (!m.isSameOrBefore(mTo)) {
            return "'to' must be same or after 'from'";
          }
        }
      } else if (fromOrTo === "to") {
        const fromValue = filters[`${field.name}_from`];
        if (field.fromRequired || (fromValue && fromValue.trim())) {
          // parse from
          const mFrom = parseYMDMomentTimezoneConfig(fromValue);
          if (!m.isSameOrAfter(mFrom)) {
            return "'to' must be same or after 'from'";
          }
        }
      }
      return undefined;
    }
  },
  "integer-range": {
    component: IntegerRangeFilterField,
    filterValueGetter: (filters, name) => {
      return {
        from: filters[`${name}_from`],
        to: filters[`${name}_to`]
      };
    },
    validator: integerRangeValidator
  },
  "money-range": {
    component: MoneyRangeFilterField,
    filterValueGetter: (filters, name) => {
      return {
        from: filters[`${name}_from`],
        to: filters[`${name}_to`]
      };
    },
    validator: integerRangeValidator
  },
  "nullable-boolean": {
    component: NullableBooleanFilterField,
    validator: value => {
      return undefined;
    }
  },
  "checkbox-multi": {
    component: CheckboxMultiFilterField,
    validator: value => {
      return undefined;
    }
  },
  "fancy-select": {
    component: FancySelectFilterField,
    validator: value => {
      return undefined;
    }
  },
  "fancy-select-with-booltype": {
    component: FancySelectWithBooltypeFilterField,
    filterValueGetter: (filters, name) => {
      return {
        select: filters[name],
        booltype: filters[`${name}_booltype`]
      };
    },
    validator: value => {
      return undefined;
    }
  }
};

export default filterConfig;
