import { observable, decorate, action, runInAction, toJS } from "mobx";
import agent from "../agent";
import { LOADER_TAG_ADMIN_BIKES } from "../constants";
import { ENUM_BIKE_STATUSES, ENUM_BIKE_CONDITIONS } from "../config";
import { mapSomeFieldsToId } from "../utils";

export class BikeAdminStore {
  bikes = observable.array();

  filters = observable.map();

  constructor() {
    this.resetFilters();
  }

  // action
  loadFiltered() {
    const finalFilters = mapSomeFieldsToId(toJS(this.filters), [
      "acquisition",
      "recipient",
      "receivingevent",
      "donatingevent",
      "organization_donating",
      "organization_receiving",
      "bmake",
      "bmodel",
      "bstyle",
      "bsize",
      "mechanics",
      "bcolors"
    ]);
    return agent.AdminBikes.filter(
      { filters: finalFilters },
      { loaderTag: LOADER_TAG_ADMIN_BIKES }
    )
      .then(body => {
        const bikes = body.data;
        runInAction(() => {
          this.bikes.replace(bikes);
        });
        return bikes;
      })
      .catch(error => {
        this.clear();
        return Promise.reject(error); // re-throw
      });
  }

  // action
  clear() {
    this.bikes.clear();
    this.resetFilters();
  }

  // action
  resetFilters() {
    this.filters.replace({
      sort_field: "received_at",
      sort_direction: "desc",

      received_at_from: null,
      received_at_to: null,
      fixed_at_from: null,
      fixed_at_to: null,
      sold_at_from: null,
      sold_at_to: null,
      updated_at_from: null,
      updated_at_to: null,
      fix_cost_from: null,
      fix_cost_to: null,
      fix_time_from: null,
      fix_time_to: null,
      price_asking_from: null,
      price_asking_to: null,
      price_sold_from: null,
      price_sold_to: null,
      statuses: ENUM_BIKE_STATUSES,
      conditions: ENUM_BIKE_CONDITIONS,
      list_online: null,
      has_admin_notes: null,
      has_bike_notes: null,
      has_sale_notes: null,
      has_public_notes: null,
      acquisition: [],
      recipient: [],
      receivingevent: [],
      donatingevent: [],
      organization_donating: [],
      organization_receiving: [],
      bmake: [],
      bmodel: [],
      bstyle: [],
      bsize: [],
      mechanics: [],
      mechanics_booltype: "or",
      bcolors: [],
      bcolors_booltype: "or"
    });
  }

  // action
  setFilter(field, value) {
    this.filters.set(field, value);
  }
}

decorate(BikeAdminStore, {
  bikes: observable,
  filters: observable,
  loadFiltered: action.bound,
  clear: action.bound,
  resetFilters: action,
  setFilter: action
});

export default new BikeAdminStore();
