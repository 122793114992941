import { observable, decorate, action, runInAction, toJS } from "mobx";
import agent from "../agent";
import { LOADER_TAG_ADMIN_MEMBERSHIPS } from "../constants";
import { ENUM_MEMBERSHIP_PLANS, PLAN_DISPLAY_NAMES } from "../config";
import { mapSomeFieldsToId } from "../utils";

export class MembershipPeriodAdminStore {
  periods = observable.array();

  filters = observable.map();

  constructor() {
    this.resetFilters();
  }

  // action
  loadFiltered() {
    const finalFilters = mapSomeFieldsToId(toJS(this.filters), [
      "order",
      "plans"
    ]);
    return agent.AdminMemberships.filter(
      { filters: finalFilters },
      { loaderTag: LOADER_TAG_ADMIN_MEMBERSHIPS }
    )
      .then(body => {
        const periods = body.data;
        runInAction(() => {
          this.periods.replace(periods);
        });
        return periods;
      })
      .catch(error => {
        this.clear();
        return Promise.reject(error); // re-throw
      });
  }

  // action
  clear() {
    this.periods.clear();
    this.resetFilters();
  }

  // action
  grant(user_id, plan, amount) {
    return agent.AdminMemberships.grant(
      { user_id, plan, amount },
      {
        loaderTag: LOADER_TAG_ADMIN_MEMBERSHIPS,
        notFoundName: "user"
      }
    );
  }

  // action
  deactivate(id) {
    return agent.AdminMemberships.deactivate(
      { id },
      {
        loaderTag: LOADER_TAG_ADMIN_MEMBERSHIPS,
        notFoundName: "membership"
      }
    ).then(() => {
      this.loadFiltered();
    });
  }

  // action
  cancel(id) {
    return agent.AdminMemberships.cancel(
      { id },
      {
        loaderTag: LOADER_TAG_ADMIN_MEMBERSHIPS,
        notFoundName: "membership"
      }
    ).then(() => {
      this.loadFiltered();
    });
  }

  // action
  resetFilters() {
    this.filters.replace({
      sort_field: "created_at",
      sort_direction: "desc",

      plans: ENUM_MEMBERSHIP_PLANS.map(plan => ({
        id: plan,
        name: PLAN_DISPLAY_NAMES[plan]
      })),
      is_active: null,
      is_queued: null,
      is_deactivated: null,
      is_marked_for_cancel: null,
      is_purchased: null,
      is_granted: null,
      is_redeemed: null,
      order: []
    });
  }

  // action
  setFilter(field, value) {
    this.filters.set(field, value);
  }
}

decorate(MembershipPeriodAdminStore, {
  periods: observable,
  filters: observable,
  loadFiltered: action.bound,
  clear: action.bound,
  grant: action,
  deactivate: action,
  cancel: action,
  resetFilters: action,
  setFilter: action
});

export default new MembershipPeriodAdminStore();
