import React, { Component } from "react";
import { observer } from "mobx-react";
import { Title, Level, LevelLeft, LevelItem, Button } from "bloomer";

import { compose } from "../../../utils";

import AdminSite from "../../../components/AdminSite";
import PageHeading from "../../../components/PageHeading";
import { momentNowLocal, formatMomentLocalYMD } from "../../../datetime";
import CompactBikeEditorRow from "./CompactBikeEditorRow";
import BikePrototype from "./BikePrototype";
import { newStructure as newBikeStructure } from "../../../stores/bikeEditor";

const bikePrototypeOriginal = {
  received_at: formatMomentLocalYMD(momentNowLocal()),
  notes_admin: null,
  acquisition: null,
  receivingevent: null,
  organization_donating: null
};

class BikeBatch extends Component {
  state = {
    bproto: Object.assign({}, bikePrototypeOriginal),
    bikes: []
  };

  addBike = () => {
    const newBikeId = Math.round(Math.random() * 10e9); // temporary id
    this.setState({
      bikes: [
        ...this.state.bikes,
        {
          id: newBikeId,
          handleSubmit: null // set by forwardHandleSubmitById
        }
      ]
    });
  };

  saveAll = () => {
    const { bikes } = this.state;

    // save bikes one after another
    const p = bikes.reduce(
      (acc, bike) => (bike.handleSubmit ? acc.finally(bike.handleSubmit) : acc),
      Promise.resolve()
    );

    return p;
  };

  removeBikeById = id => {
    this.setState({
      bikes: this.state.bikes.filter(bike => bike.id !== id)
    });
  };

  renderActionButtons() {
    return (
      <Level>
        <LevelLeft>
          <LevelItem>
            <Button onClick={this.addBike}>Add Bike</Button>
          </LevelItem>
          <LevelItem>
            <Button onClick={this.saveAll}>Save All</Button>
          </LevelItem>
        </LevelLeft>
      </Level>
    );
  }

  render() {
    const { bproto, bikes } = this.state;

    return (
      <AdminSite>
        <PageHeading>Batch Bike Creator</PageHeading>
        <BikePrototype
          value={bproto}
          onChange={bproto => this.setState({ bproto })}
        />
        <Title isSize={4}>Bikes</Title>
        {this.renderActionButtons()}
        {bikes.length > 0 && (
          <p>
            Editing <strong>{bikes.length}</strong> new bike
            {bikes.length > 1 ? "s" : ""}
          </p>
        )}
        <div>
          {bikes.map(bike => (
            <CompactBikeEditorRow
              key={bike.id}
              id={bike.id}
              onRemove={this.removeBikeById}
              forwardHandleSubmit={handleSubmit =>
                (bike.handleSubmit = handleSubmit)
              }
              bproto={{ ...newBikeStructure, ...bproto }}
            />
          ))}
        </div>
        {bikes.length > 1 && this.renderActionButtons()}
      </AdminSite>
    );
  }
}

export default compose(observer)(BikeBatch);
