import agent from "../agent";
import { mapSomeFieldsToId } from "../utils";
import GenericResourceEditorStore from "../stores/special/GenericResourceEditorStore";
import { LOADER_TAG_ADMIN_USERS } from "../constants";

const newStructure = {
  name: null,
  email: null,
  newsletter: false,
  show_volunteer_profile: false,
  profile_title: null,
  profile_description: null,
  admin_notes: null,
  demographicset: null,
  volunteerlevel: null
};

const userEditorStore = new GenericResourceEditorStore(
  "user",
  LOADER_TAG_ADMIN_USERS,
  "user-editor",
  "user",
  agent.AdminUsers.show,
  () => Promise.reject(), // no create
  agent.AdminUsers.update,
  agent.AdminUsers.remove,
  newStructure,
  fields => {
    const { demographicset, ...restFields } = fields;

    const fieldsWithPickedDemographicSet = {
      ...restFields,
      demographicset: mapSomeFieldsToId(demographicset || {}, [
        "dneighborhood",
        "dincomerange",
        "dagerange",
        "dgender",
        "drace",
        "dnationality",
        "dresidence",
        "dhouseholdcount",
        "demploymentstatus",
        "dbikeuses"
      ])
    };

    return mapSomeFieldsToId(fieldsWithPickedDemographicSet, [
      "volunteerlevel"
    ]);
  },
  "User saved successfully!",
  "User deleted successfully!"
);

export default userEditorStore;
