import React from "react";

import PublicSite from "../../components/PublicSite";
import PageHeading from "../../components/PageHeading";
import Calendar from "./Calendar";

class Events extends React.Component {
  render() {
    return (
      <PublicSite>
        <PageHeading>Events</PageHeading>
        <Calendar />
      </PublicSite>
    );
  }
}

export default Events;
