import React from "react";
import { observer, inject } from "mobx-react";

import BikePartTable from "../../../components/tables/BikePartTable";
import AdminTableSection from "../../../components/AdminTableSection";
import AdminSite from "../../../components/AdminSite";
import PageHeading from "../../../components/PageHeading";
import ResourceCreateLinkButton from "../../../components/ResourceCreateLinkButton";

import { compose } from "../../../utils";
import { LOADER_TAG_ADMIN_BIKE_PARTS } from "../../../constants";

class BikeParts extends React.Component {
  render() {
    return (
      <AdminSite>
        <PageHeading>Bike Parts</PageHeading>
        <ResourceCreateLinkButton
          to="/admin/bikeparts/new"
          label="New Bike Part"
        />
        <AdminTableSection
          store={this.props.bikePartAdminStore}
          getter={store => store.parts.slice()}
          loadAction={store => store.loadAll}
          loaderTag={LOADER_TAG_ADMIN_BIKE_PARTS}
          clearAction={store => store.clear}
          tableComponent={BikePartTable}
        />
      </AdminSite>
    );
  }
}

export default compose(inject("bikePartAdminStore"), observer)(BikeParts);
