import { observable, decorate, runInAction, action } from "mobx";
import agent from "../agent";

export class PagePublicStore {
  pageCache = observable.map();

  getPage(id, { displayError = false } = {}) {
    if (this.pageCache.has(id)) {
      return Promise.resolve(this.pageCache.get(id));
    }
    return agent.Pages.show({ id }, { displayError }).then(body => {
      const page = body.data;
      runInAction(() => {
        this.pageCache.set(id, page);
      });
      return page;
    });
  }
}

decorate(PagePublicStore, {
  pages: observable,
  getPage: action.bound
});

export default new PagePublicStore();
