import React, { PureComponent } from "react";
import { PieChart, Pie, ResponsiveContainer } from "recharts";

export default class PieBikesFromTopOrganizations extends PureComponent {
  render() {
    const { organizationBikeMap, topN = 6 } = this.props;

    if (!organizationBikeMap) {
      return "n/a";
    }

    const data = Object.entries(organizationBikeMap)
      .reduce(
        (acc, [organization, { count }]) => [
          ...acc,
          { organization, bikes: count }
        ],
        []
      )
      .sort((a, b) => (a.bikes >= b.bikes ? -1 : 1));

    const final = data.slice(0, topN);

    const other = data.slice(topN).reduce((acc, { bikes }) => acc + bikes, 0);

    if (other > 0) {
      final.push({
        organization: "Other",
        bikes: other
      });
    }

    return (
      <ResponsiveContainer width="100%" height={300}>
        <PieChart>
          <Pie
            dataKey="bikes"
            isAnimationActive={true}
            data={final}
            outerRadius={100}
            label={({
              cx,
              cy,
              midAngle,
              innerRadius,
              outerRadius,
              value,
              index
            }) => {
              const RADIAN = Math.PI / 180;
              // eslint-disable-next-line
              const radius = 25 + innerRadius + (outerRadius - innerRadius);
              // eslint-disable-next-line
              const x = cx + radius * Math.cos(-midAngle * RADIAN);
              // eslint-disable-next-line
              const y = cy + radius * Math.sin(-midAngle * RADIAN);

              return (
                <text
                  x={x}
                  y={y}
                  // fill="#8884d8"
                  textAnchor={x > cx ? "start" : "end"}
                  dominantBaseline="central"
                >
                  {final[index].organization} ({value})
                </text>
              );
            }}
          />
        </PieChart>
      </ResponsiveContainer>
    );
  }
}
