import React, { Component } from "react";
import { Button } from "bloomer";
import { Field } from "react-final-form";

import FancySelectFieldWithErrors from "../../../components/fields/FancySelectFieldWithErrors";
import SelectUser from "../../../components/selects/throttled/SelectUser";
import {
  BIKE_EDITOR_RECIPIENT_TYPE_USER,
  BIKE_EDITOR_RECIPIENT_TYPE_HEADLESS
} from "../../../constants";
import DemographicSetFields from "../../../components/fields/DemographicSetFields";

export default class RecipientOrDemograpicSet extends Component {
  render() {
    return (
      <div>
        <p>
          A bike can refer to its recipient as a user, or a headless demographic
          set. Switching between the two will void the current recipient. If
          you'd like to add demographics to a user, please edit the user
          directly.
        </p>
        <Field
          name="recipientType"
          subscription={{
            // NOTE: it's ok that this parent field doesn't subscribe to errors,
            // child field subscriptions will still work.
            value: true
          }}
        >
          {({
            input: { onChange: changeRecipientType, value: recipientType }
          }) => {
            const otherValue =
              recipientType === BIKE_EDITOR_RECIPIENT_TYPE_USER
                ? BIKE_EDITOR_RECIPIENT_TYPE_HEADLESS
                : BIKE_EDITOR_RECIPIENT_TYPE_USER;
            return (
              <div>
                <p>
                  <Button
                    isSize="small"
                    onClick={() => {
                      changeRecipientType(otherValue);
                    }}
                  >
                    Switch to {otherValue}
                  </Button>
                </p>
                {recipientType === BIKE_EDITOR_RECIPIENT_TYPE_USER && (
                  <FancySelectFieldWithErrors
                    name="recipient"
                    label="User"
                    component={SelectUser}
                    componentProps={{ isClearable: true }}
                  />
                )}
                {recipientType === BIKE_EDITOR_RECIPIENT_TYPE_HEADLESS && (
                  <DemographicSetFields />
                )}
              </div>
            );
          }}
        </Field>
      </div>
    );
  }
}
