import React from "react";
import { inject, observer } from "mobx-react";
import { Button } from "bloomer";
import { compose } from "../../../utils";
import { LOADER_TAG_AUTH } from "../../../constants";

class AdminAuthInfo extends React.Component {
  onLogout = e => {
    e.target.blur(); // loading styles are messed up when focused
    this.props.authStore.logout();
  };

  get loading() {
    return this.props.uiStore.loadingByTag(LOADER_TAG_AUTH);
  }

  render() {
    return (
      <span>
        {this.props.authStore.user &&
          `Hello, ${this.props.authStore.user.name}!`}
        &nbsp;
        <Button
          onClick={this.onLogout}
          isSize="small"
          isColor="info"
          isOutlined
          isLoading={this.loading}
        >
          Logout
        </Button>
      </span>
    );
  }
}

export default compose(
  inject("authStore", "uiStore"),
  observer
)(AdminAuthInfo);
