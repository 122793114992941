import NarrowCenterTemplateEditor from "./templateEditors/NarrowCenterTemplateEditor";

const templateConfig = {
  narrow_center: {
    title: "Narrow Center",
    baseContent: { body: "" },
    templateEditor: NarrowCenterTemplateEditor
  }
};

export default templateConfig;
