import React from "react";
import { observer, inject } from "mobx-react";
import { Title } from "bloomer";

import {
  makeRecordValidator,
  makeFieldValidator,
  minLength
} from "../../../validators";
import formValidationErrorLayer from "../../../errors/formValidationErrorLayer";
import TextFieldWithErrors from "../../../components/fields/TextFieldWithErrors";
import CustomFinalForm from "../../../components/CustomFinalForm";
import SingleCheckboxFieldWithErrors from "../../../components/fields/SingleCheckboxFieldWithErrors";
import { compose } from "../../../utils";
import { standardFinalFormSubscriptions } from "../../../config";
import FinalSubmit from "../../../components/fields/FinalSubmit";
import Wrapper from "./common/Wrapper";

const validate = makeRecordValidator(
  makeFieldValidator("name", "required", minLength(3)),
  makeFieldValidator("email", "required", "email"),
  makeFieldValidator("newsletter")
);

const initialFormValuesFromUser = user => ({
  name: user.name,
  email: user.email,
  newsletter: user.newsletter
});

class Settings extends React.Component {
  resetForm = null;

  onSubmit = user => {
    return formValidationErrorLayer(
      this.props.authStore.update(user).then(() => {
        // reset form with new user fields (most likely identical to submitted fields)
        this.resetForm(initialFormValuesFromUser(this.props.authStore.user));
      })
    );
  };

  render() {
    const { authStore } = this.props;
    const { user } = authStore;

    return (
      <Wrapper>
        {() => (
          <div>
            <Title isSize={3}>Settings</Title>
            <CustomFinalForm
              onSubmit={this.onSubmit}
              validate={validate}
              initialValues={initialFormValuesFromUser(user)}
              subscription={standardFinalFormSubscriptions}
              render={renderProps => {
                const { handleSubmit, form } = renderProps;
                this.resetForm = form.reset;
                return (
                  <form onSubmit={handleSubmit}>
                    <TextFieldWithErrors
                      type="text"
                      name="name"
                      label="Name"
                      placeholder="e.g. Alex Smith"
                    />
                    <TextFieldWithErrors
                      type="text"
                      name="email"
                      label="Email"
                      placeholder="e.g. alexsmith@gmail.com"
                    />
                    <SingleCheckboxFieldWithErrors
                      name="newsletter"
                      label="Subscribe to monthly newsletter"
                    />
                    <FinalSubmit
                      {...renderProps}
                      label="Update"
                      isSize="normal"
                    />
                  </form>
                );
              }}
            />
          </div>
        )}
      </Wrapper>
    );
  }
}

export default compose(
  inject("authStore"),
  observer
)(Settings);
