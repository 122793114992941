/**
 * AuthenticationError
 */
class AuthenticationError {
  constructor(message) {
    this.name = "AuthenticationError";
    this.alertConfigFinal = { group: "auth" };
    this.message = message;
    this.stack = new Error().stack; // Optional
  }
}
AuthenticationError.prototype = Object.create(Error.prototype);

export default AuthenticationError;
