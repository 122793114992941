import React, { PureComponent } from "react";
import { Columns, Column } from "bloomer";

import TextFieldWithErrors from "./TextFieldWithErrors";
import FancySelectFieldWithErrors from "./FancySelectFieldWithErrors";
import SelectDemographicSetField from "../../components/selects/immediate/SelectDemographicSetField";
import DateFieldWithErrors from "./DateFieldWithErrors";
import SelectFieldWithErrors from "./SelectFieldWithErrors";

export default class DemographicSetFields extends PureComponent {
  render() {
    const { isForUser = false } = this.props;

    return (
      <div>
        <Columns>
          <Column isSize={"1/2"}>
            {!isForUser && (
              <Columns className="is-marginless-bottom">
                <Column>
                  <TextFieldWithErrors
                    name="demographicset.name"
                    label="Name"
                  />
                </Column>
                <Column>
                  <DateFieldWithErrors
                    name="demographicset.birth_date"
                    label="Date of Birth"
                  />
                </Column>
              </Columns>
            )}
            <FancySelectFieldWithErrors
              component={SelectDemographicSetField}
              name="demographicset.dneighborhood"
              label="Neighborhood"
              componentProps={{ field: "dneighborhood" }}
            />
            <FancySelectFieldWithErrors
              component={SelectDemographicSetField}
              name="demographicset.dincomerange"
              label="Income Range"
              componentProps={{ field: "dincomerange" }}
            />
            <FancySelectFieldWithErrors
              component={SelectDemographicSetField}
              name="demographicset.dagerange"
              label="Age Range"
              componentProps={{ field: "dagerange" }}
            />
            <FancySelectFieldWithErrors
              component={SelectDemographicSetField}
              name="demographicset.dgender"
              label="Gender"
              componentProps={{ field: "dgender" }}
            />
            <Columns>
              <Column>
                <SelectFieldWithErrors
                  name="demographicset.health_mental"
                  label="Mental Health"
                >
                  <option />
                  <option>1</option>
                  <option>2</option>
                  <option>3</option>
                  <option>4</option>
                  <option>5</option>
                </SelectFieldWithErrors>
              </Column>
              <Column>
                <SelectFieldWithErrors
                  name="demographicset.health_physical"
                  label="Physical Health"
                >
                  <option />
                  <option>1</option>
                  <option>2</option>
                  <option>3</option>
                  <option>4</option>
                  <option>5</option>
                </SelectFieldWithErrors>
              </Column>
              <Column>
                <SelectFieldWithErrors
                  name="demographicset.health_economic"
                  label="Economic Health"
                >
                  <option />
                  <option>1</option>
                  <option>2</option>
                  <option>3</option>
                  <option>4</option>
                  <option>5</option>
                </SelectFieldWithErrors>
              </Column>
            </Columns>
          </Column>
          <Column isSize={"1/2"}>
            {!isForUser && (
              <Columns className="is-marginless-bottom">
                <Column>
                  <TextFieldWithErrors
                    name="demographicset.email"
                    label="Email"
                  />
                </Column>
                <Column>
                  <TextFieldWithErrors
                    name="demographicset.context"
                    label="Context"
                  />
                </Column>
              </Columns>
            )}
            <FancySelectFieldWithErrors
              component={SelectDemographicSetField}
              name="demographicset.drace"
              label="Race"
              componentProps={{ field: "drace" }}
            />
            <FancySelectFieldWithErrors
              component={SelectDemographicSetField}
              name="demographicset.dnationality"
              label="Nationality"
              componentProps={{ field: "dnationality" }}
            />
            <FancySelectFieldWithErrors
              component={SelectDemographicSetField}
              name="demographicset.dresidence"
              label="Residence"
              componentProps={{ field: "dresidence" }}
            />
            <FancySelectFieldWithErrors
              component={SelectDemographicSetField}
              name="demographicset.dhouseholdcount"
              label="Household Count"
              componentProps={{ field: "dhouseholdcount" }}
            />
            <FancySelectFieldWithErrors
              component={SelectDemographicSetField}
              name="demographicset.demploymentstatus"
              label="Employment Status"
              componentProps={{ field: "demploymentstatus" }}
            />
            <FancySelectFieldWithErrors
              component={SelectDemographicSetField}
              name="demographicset.dbikeuses"
              label="Bike Use"
              componentProps={{ field: "dbikeuse", isMulti: true }}
            />
          </Column>
        </Columns>
      </div>
    );
  }
}
