/**
 * BSBC Backend Agent
 * i.e. transport layer
 */

import { API_ROOT } from "../config";
import createAxiosInstance from "./axios/createInstance";
import attachLoaderInterceptors from "./axios/attachLoaderInterceptors";
import attachTokenInterceptor from "./axios/attachTokenInterceptor";
import attachErrorInterceptor from "./axios/attachErrorInterceptor";
import attachRefreshInterceptor from "./axios/attachRefreshInterceptor";

/**
 * Setup
 */

// Build axios with request and response interceptors
// Axios interceptors documentation: https://github.com/axios/axios/blob/v0.18.0/README.md#interceptors
const axiosInstance = createAxiosInstance();
attachLoaderInterceptors(axiosInstance); // request + response
attachTokenInterceptor(axiosInstance); // request
attachRefreshInterceptor(axiosInstance); // response
attachErrorInterceptor(axiosInstance); // response

// Request helpers
const responseBody = res => res.data; // get parsed data (json) from response
const requests = {
  del: (url, config) =>
    axiosInstance.delete(`${API_ROOT}${url}`, config).then(responseBody),
  get: (url, config) =>
    axiosInstance.get(`${API_ROOT}${url}`, config).then(responseBody),
  put: (url, body, config) =>
    axiosInstance.put(`${API_ROOT}${url}`, body, config).then(responseBody),
  post: (url, body, config) =>
    axiosInstance.post(`${API_ROOT}${url}`, body, config).then(responseBody)
};

/**
 * Endpoints
 */

const Auth = {
  me: config => requests.get("/auth/me", config),
  login: ({ email, password }, config) =>
    requests.post("/auth/login", { email, password }, config),
  logout: config => requests.post("/auth/logout", null, config),
  refresh: config => {
    // Make custom axios for refresh with only loader interceptors
    // NOTE: does not accept most of our custom config options
    // (except for showLoader, loaderTag, freeze)
    const refreshAxios = createAxiosInstance();
    attachLoaderInterceptors(refreshAxios);
    return refreshAxios
      .post(`${API_ROOT}/auth/refresh`, null, config)
      .then(responseBody);
  }
};

const Account = {
  register: (
    { name, email, password, password_confirmation, newsletter },
    config
  ) =>
    requests.post(
      "/account",
      { name, email, password, password_confirmation, newsletter },
      config
    ),
  update: ({ user }, config) => requests.put("/account", user, config), // doesn't need id!
  updatePassword: ({ password, password_confirmation }, config) =>
    requests.put(
      "/account/password",
      { password, password_confirmation },
      config
    ),
  forgotPassword: ({ email }, config) =>
    requests.post("/account/password/forgot", { email }, config),
  resetPassword: ({ email, token, password, password_confirmation }, config) =>
    requests.post(
      "/account/password/reset",
      { email, token, password, password_confirmation },
      config
    ),
  newsletter: ({ email }, config) =>
    requests.post("/account/newsletter", { email }, config)
};

const Membership = {
  all: config => requests.get("/membership", config),
  redeem: ({ code }, config) =>
    requests.post("/membership/redeem", { code }, config),
  cancel: ({ id }, config) =>
    requests.post(`/membership/cancel/${id}`, null, config)
};

const Visits = {
  all: config => requests.get("/visits", config)
};

const Cart = {
  show: config => requests.get("/cart", config),
  add: ({ item_id, quantity, variation }, config) =>
    requests.post("/cart", { item_id, quantity, variation }, config),
  update: ({ item_id, quantity, variation }, config) =>
    requests.put("/cart", { item_id, quantity, variation }, config),
  remove: ({ item_id, variation }, config) =>
    requests.post("/cart/remove", { item_id, variation }, config)
};

const Contact = {
  contact: ({ reason, name, email, phone, message, token }, config) =>
    requests.post(
      "/contact",
      { reason, name, email, phone, message, token },
      config
    )
};

const Checkout = {
  checkout: ({ square_card_nonce, address }, config) =>
    requests.post("/checkout", { square_card_nonce, address }, config)
};

const Orders = {
  all: config => requests.get("/orders", config),
  show: ({ id }, config) => requests.get(`/orders/${id}`, config)
};

const Pages = {
  show: ({ id }, config) => requests.get(`/pages/${id}`, config)
};

const Items = {
  all: config => requests.get("/items", config),
  show: ({ id }, config) => requests.get(`/items/${id}`, config)
};

const Bikes = {
  all: config => requests.get("/bikes", config),
  show: ({ id }, config) => requests.get(`/bikes/${id}`, config)
};

const StationMeta = {
  all: config => requests.get("/station/meta/all", config)
};

const StationPunch = {
  currentlyCheckedIn: config =>
    requests.get("/station/punch/currently_checked_in", config),
  checkIn: ({ id, type }, config) =>
    requests.post(`/station/punch/check/in/${id}`, { type }, config),
  checkOut: ({ id }, config) =>
    requests.post(`/station/punch/check/out/${id}`, null, config)
};

const StationBike = {
  show: ({ id }, config) => requests.get(`/station/bike/${id}`, config),
  allBikeParts: config =>
    requests.get("/station/bike/worked/all_bike_parts", config),
  worked: ({ id, user_id, minutes, note, bikeparts }, config) =>
    requests.post(
      `/station/bike/worked/${id}`,
      { user_id, minutes, note, bikeparts },
      config
    )
};

const StationAccount = {
  show: ({ id }, config) => requests.get(`/station/account/${id}`, config),
  cip2w: config =>
    requests.get("/station/account/checked_in_past_two_weeks", config),
  streaks: config => requests.get("/station/account/streaks", config),
  search: ({ name, email }, config) =>
    requests.get("/station/account/search", {
      ...config,
      params: { name, email }
    }),
  waiver: ({ id, signature }, config) =>
    requests.post(`/station/account/waiver/${id}`, { signature }, config),
  rentalWaiver: ({ id, signature }, config) =>
    requests.post(`/station/account/rentalwaiver/${id}`, { signature }, config),
  register: (fields, config) =>
    requests.post("/station/account", fields, config),
  newsletter: ({ email }, config) =>
    requests.post("/station/account/newsletter", { email }, config)
};

const StationInfo = {
  stats: config => requests.get("/station/info/stats", config),
  rankings: config => requests.get("/station/info/rankings", config)
};

const AdminUsers = {
  filter: ({ filters }, config) =>
    requests.get("/admin/users", { ...config, params: filters }),
  select: ({ search }, config) =>
    requests.get("/admin/users/select", { ...config, params: { search } }),
  exportAll: config => requests.get("/admin/users/export_all", config),
  show: ({ id }, config) => requests.get(`/admin/users/${id}`, config),
  update: ({ id, user }, config) =>
    requests.put(`/admin/users/${id}`, user, config),
  remove: ({ id }, config) => requests.del(`/admin/users/${id}`, config),
  uploadImage: ({ id, file }, config) => {
    const data = new FormData();
    data.append("image", file, file.name);
    return requests.post(`/admin/users/${id}/image`, data, config);
  },
  removeImage: ({ id }, config) =>
    requests.del(`/admin/users/${id}/image`, config)
};

const AdminMemberships = {
  filter: ({ filters }, config) =>
    requests.get("/admin/memberships", { ...config, params: filters }),
  grant: ({ user_id, plan, amount }, config) =>
    requests.post(
      `/admin/memberships/${user_id}/grant`,
      { plan, amount },
      config
    ),
  deactivate: ({ id }, config) =>
    requests.post(`/admin/memberships/${id}/deactivate`, null, config),
  cancel: ({ id }, config) =>
    requests.post(`/admin/memberships/${id}/cancel`, null, config)
};

const AdminVisits = {
  filter: ({ filters }, config) =>
    requests.get("/admin/visits", { ...config, params: filters }),
  getForUser: ({ id }, config) => requests.get(`/admin/visits/${id}`, config)
};

const AdminFields = {
  allOfType: ({ field }, config) =>
    requests.get("/admin/fields", { ...config, params: { field } }),
  select: ({ field, search }, config) =>
    requests.get(`/admin/fields/select`, {
      ...config,
      params: { field, search }
    }),
  create: ({ field, value }, config) =>
    requests.post("/admin/fields", { field, value }, config),
  update: ({ field, id, value }, config) =>
    requests.put("/admin/fields", { field, id, value }, config),
  remove: ({ field, id }, config) =>
    requests.post("/admin/fields/remove", { field, id }, config)
};

const AdminItems = {
  all: config => requests.get("/admin/items", config),
  show: ({ id }, config) => requests.get(`/admin/items/${id}`, config),
  create: ({ item }, config) => requests.post("/admin/items", item, config),
  update: ({ id, item }, config) =>
    requests.put(`/admin/items/${id}`, item, config),
  remove: ({ id }, config) => requests.del(`/admin/items/${id}`, config),
  uploadImage: ({ id, file }, config) => {
    const data = new FormData();
    data.append("image", file, file.name);
    return requests.post(`/admin/items/${id}/image`, data, config);
  },
  removeImage: ({ id }, config) =>
    requests.del(`/admin/items/${id}/image`, config)
};

const AdminOrders = {
  filter: ({ filters }, config) =>
    requests.get("/admin/orders", { ...config, params: filters }),
  show: ({ id }, config) => requests.get(`/admin/orders/${id}`, config),
  ship: ({ id, cost, tracking, shippingservice }, config) =>
    requests.post(
      `/admin/orders/${id}/ship`,
      { cost, tracking, shippingservice },
      config
    )
};

const AdminPages = {
  all: config => requests.get("/admin/pages", config),
  show: ({ id }, config) => requests.get(`/admin/pages/${id}`, config),
  create: ({ page }, config) => requests.post("/admin/pages", page, config),
  update: ({ id, page }, config) =>
    requests.put(`/admin/pages/${id}`, page, config),
  remove: ({ id }, config) => requests.del(`/admin/pages/${id}`, config)
};

const AdminDemographicsets = {
  show: ({ id }, config) =>
    requests.get(`/admin/demographicsets/${id}`, config),
  create: ({ demographicset }, config) =>
    requests.post("/admin/demographicsets", demographicset, config),
  update: ({ id, demographicset }, config) =>
    requests.put(`/admin/demographicsets/${id}`, demographicset, config),
  remove: ({ id }, config) =>
    requests.del(`/admin/demographicsets/${id}`, config)
};

const AdminBikes = {
  filter: ({ filters }, config) =>
    requests.get("/admin/bikes", { ...config, params: filters }),
  create: ({ bike }, config) => requests.post("/admin/bikes", bike, config),
  show: ({ id }, config) => requests.get(`/admin/bikes/${id}`, config),
  update: ({ id, bike }, config) =>
    requests.put(`/admin/bikes/${id}`, bike, config),
  remove: ({ id }, config) => requests.del(`/admin/bikes/${id}`, config),
  worked: ({ id, user_id, minutes, note }, config) =>
    requests.post(
      `/admin/bikes/${id}/worked`,
      { user_id, minutes, note },
      config
    ),
  uploadImage: ({ id, file }, config) => {
    const data = new FormData();
    data.append("image", file, file.name);
    return requests.post(`/admin/bikes/${id}/image`, data, config);
  },
  removeImage: ({ id }, config) =>
    requests.del(`/admin/bikes/${id}/image`, config)
};

const AdminBikeParts = {
  all: config => requests.get("/admin/bikeparts", config),
  create: ({ bikePart }, config) =>
    requests.post("/admin/bikeparts", bikePart, config),
  show: ({ id }, config) => requests.get(`/admin/bikeparts/${id}`, config),
  update: ({ id, bikePart }, config) =>
    requests.put(`/admin/bikeparts/${id}`, bikePart, config),
  remove: ({ id }, config) => requests.del(`/admin/bikeparts/${id}`, config)
};

const AdminRecdons = {
  allReceiving: config => requests.get("/admin/recdons/receiving", config),
  allDonating: config => requests.get("/admin/recdons/donating", config),
  select: ({ type, search }, config) =>
    requests.get(`/admin/recdons/select/${type}`, {
      ...config,
      params: { search }
    }),
  create: ({ type, recdon }, config) =>
    requests.post(`/admin/recdons/${type}`, recdon, config),
  show: ({ type, id }, config) =>
    requests.get(`/admin/recdons/${type}/${id}`, config),
  update: ({ type, id, recdon }, config) =>
    requests.put(`/admin/recdons/${type}/${id}`, recdon, config),
  remove: ({ type, id }, config) =>
    requests.del(`/admin/recdons/${type}/${id}`, config)
};

const AdminRentalBikes = {
  all: config => requests.get("/admin/rentalbikes", config),
  allAvailable: config => requests.get("/admin/rentalbikes/available", config),
  show: ({ id }, config) => requests.get(`/admin/rentalbikes/${id}`, config),
  create: ({ rentalbike }, config) =>
    requests.post("/admin/rentalbikes", rentalbike, config),
  update: ({ id, rentalbike }, config) =>
    requests.put(`/admin/rentalbikes/${id}`, rentalbike, config),
  remove: ({ id }, config) => requests.del(`/admin/rentalbikes/${id}`, config)
};

const AdminRentalPeriods = {
  show: ({ id }, config) => requests.get(`/admin/rentalperiods/${id}`, config),
  create: ({ rentalperiod }, config) =>
    requests.post("/admin/rentalperiods", rentalperiod, config),
  update: ({ id, rentalperiod }, config) =>
    requests.put(`/admin/rentalperiods/${id}`, rentalperiod, config),
  remove: ({ id }, config) => requests.del(`/admin/rentalperiods/${id}`, config)
};

const AdminReports = {
  all: config => requests.get("/admin/reports", config),
  generateAll: (fields, config) =>
    requests.post("/admin/reports/generate_all", null, config),
  emailTopVolunteers: (fields, config) =>
    requests.post("/admin/reports/email_top_volunteers", null, config)
};

const AdminRoles = {
  all: config => requests.get("/admin/roles", config)
};

const AdminVolunteerLevels = {
  all: config => requests.get("/admin/volunteerlevels", config)
};

const AdminShippingServices = {
  all: config => requests.get("/admin/shippingservices", config)
};

const AdminAcquisitions = {
  all: config => requests.get("/admin/acquisitions", config)
};

export default {
  Auth,
  Account,
  Membership,
  Visits,
  Cart,
  Contact,
  Checkout,
  Orders,
  Pages,
  Items,
  Bikes,
  StationMeta,
  StationPunch,
  StationBike,
  StationAccount,
  StationInfo,
  AdminUsers,
  AdminMemberships,
  AdminVisits,
  AdminFields,
  AdminItems,
  AdminOrders,
  AdminPages,
  AdminDemographicsets,
  AdminBikes,
  AdminBikeParts,
  AdminRecdons,
  AdminRentalBikes,
  AdminRentalPeriods,
  AdminReports,
  AdminRoles,
  AdminVolunteerLevels,
  AdminShippingServices,
  AdminAcquisitions
};
