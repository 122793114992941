import React from "react";
import { observer, inject } from "mobx-react";

import PublicSite from "../../components/PublicSite";
import { compose } from "../../utils";
import PageHeading from "../../components/PageHeading";
import NarrowCenterInner from "../../components/NarrowCenterInner";
import { LOADER_TAG_CART } from "../../constants";
import CartInner from "./CartInner";
import LoadingText from "../../components/LoadingText";

class Cart extends React.Component {
  get store() {
    return this.props.cartStore;
  }

  get loading() {
    return this.props.uiStore.loadingByTag(LOADER_TAG_CART);
  }

  componentDidMount() {
    this.store.loadCart();
  }

  render() {
    return (
      <PublicSite>
        <NarrowCenterInner>
          <PageHeading>Cart</PageHeading>
          {this.loading ? (
            <LoadingText />
          ) : (
            <CartInner
              cart={this.store.cart}
              onUpdate={this.store.update}
              onRemove={this.store.remove}
            />
          )}
        </NarrowCenterInner>
      </PublicSite>
    );
  }
}

export default compose(
  inject("uiStore", "cartStore"),
  observer
)(Cart);
