import React from "react";

import { Title } from "bloomer";

const PageHeading = ({ children, ...restProps }) => (
  <Title isSize={2} {...restProps}>
    {children}
  </Title>
);

export default PageHeading;
