import React, { Component } from "react";
import { observer } from "mobx-react";
import { Button, Title } from "bloomer";

import { compose } from "../../../../utils";

import LoadingText from "../../../../components/LoadingText";

import styles from "./search-users.module.scss";

class ChooseUserFromFrequent extends Component {
  render() {
    const { loading, users, onChoose } = this.props;

    const usersFinal = users
      // sort by visit count
      .sort((a, b) => {
        const alen = a.shopvisits.length;
        const blen = b.shopvisits.length;
        if (alen === blen) {
          return 0;
        }
        return alen < blen ? 1 : -1;
      })
      // take top 10
      .slice(0, 10);

    return (
      <div style={{ marginTop: "1.5em" }}>
        <Title isSize={5}>Quick select</Title>
        {loading ? (
          <LoadingText isLeft />
        ) : (
          <div className={styles.quickSelectUserButtons}>
            {usersFinal.map(user => (
              <span key={user.id}>
                <Button onClick={() => onChoose(user)} isColor="light">
                  {user.name}
                </Button>
                &nbsp;&nbsp;
              </span>
            ))}
          </div>
        )}
      </div>
    );
  }
}

export default compose(observer)(ChooseUserFromFrequent);
