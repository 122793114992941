import React from "react";
import { Field } from "react-final-form";
import {
  Field as FieldStyle,
  Label,
  Control,
  Input,
  Help,
  Button
} from "bloomer";
import { deriveFieldError } from "../../utils";

/**
 * Helper for react-final-form
 *
 * extraHelp can either be:
 * - string/element (as child of Help)
 * - props object that includes `children` (of Help (bloomer) props)
 */
class MoneyFieldWithErrors extends React.PureComponent {
  render() {
    const {
      name,
      label,
      type = "text",
      placeholder,
      hasZeroButton = false,
      extraHelp = null
    } = this.props;
    return (
      <Field
        name={name}
        subscription={{
          value: true,
          error: true,
          submitError: true,
          touched: true,
          dirtySinceLastSubmit: true
        }}
      >
        {({ input, meta }) => {
          const derivedError = deriveFieldError(meta);
          return (
            <FieldStyle>
              <Label>{label}</Label>
              <FieldStyle hasAddons>
                <Control>
                  <Button isStatic>$</Button>
                </Control>
                <Control>
                  {React.createElement(Input, {
                    ...input,
                    type,
                    placeholder,
                    isColor: derivedError ? "danger" : null
                  })}
                </Control>
                {hasZeroButton && (
                  <Control>
                    <Button
                      title="Set to zero"
                      onClick={() => {
                        input.onChange("0");
                      }}
                    >
                      0
                    </Button>
                  </Control>
                )}
              </FieldStyle>
              {extraHelp &&
                (React.isValidElement(extraHelp) ||
                typeof extraHelp === "string" ? (
                  <Help children={extraHelp} />
                ) : (
                  <Help {...extraHelp} />
                ))}
              {derivedError && <Help isColor="danger">{derivedError}</Help>}
            </FieldStyle>
          );
        }}
      </Field>
    );
  }
}

export default MoneyFieldWithErrors;
