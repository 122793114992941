import React, { Component } from "react";
import { Box } from "bloomer";
import { observer, inject } from "mobx-react";

import { compose } from "../../../utils";
import agent from "../../../agent";

import StationSite from "../../../components/StationSite";
import NarrowCenterInner from "../../../components/NarrowCenterInner";
import PageHeading from "../../../components/PageHeading";
import CustomFinalForm from "../../../components/CustomFinalForm";
import TextFieldWithErrors from "../../../components/fields/TextFieldWithErrors";
import { makeRecordValidator, makeFieldValidator } from "../../../validators";
import { LOADER_TAG_STATION_NEWSLETTER } from "../../../constants";
import formValidationErrorLayer from "../../../errors/formValidationErrorLayer";
import { standardFinalFormSubscriptions } from "../../../config";
import FinalSubmit from "../../../components/fields/FinalSubmit";
import ValidationError from "../../../errors/ValidationError";

const NEWSLETTER_ALERT_GROUP = "newsletter";

const validate = makeRecordValidator(
  makeFieldValidator("email", "required", "email")
);

class SubscribeToNewsletter extends Component {
  resetForm = null;

  onSubmit = ({ email }) => {
    return formValidationErrorLayer(
      agent.StationAccount.newsletter(
        { email },
        {
          loaderTag: LOADER_TAG_STATION_NEWSLETTER,
          alertGroup: NEWSLETTER_ALERT_GROUP
        }
      )
        .then(() => {
          this.props.uiStore.addAlert(
            "success",
            "You were subscribed to the newsletter successfully!",
            { group: NEWSLETTER_ALERT_GROUP }
          );

          // redirect
          this.props.history.push("/station", { wasRedirect: true });
        })
        .catch(e => {
          // redirect if not validation error
          if (!(e instanceof ValidationError)) {
            this.props.history.push("/station", { wasRedirect: true });
          }

          // re-throw
          throw e;
        })
    )
      .catch(() => null)
      .finally(() => {
        // reset form
        this.resetForm();
      });
  };

  get loading() {
    return this.props.uiStore.loadingByTag(LOADER_TAG_STATION_NEWSLETTER);
  }

  render() {
    return (
      <StationSite>
        <NarrowCenterInner>
          <Box>
            <PageHeading>Subscribe to Newsletter</PageHeading>
            <CustomFinalForm
              onSubmit={this.onSubmit}
              validate={validate}
              initialValues={{ email: null }}
              subscription={standardFinalFormSubscriptions}
              render={renderProps => {
                const { handleSubmit, form } = renderProps;
                this.resetForm = form.reset;
                return (
                  <form onSubmit={handleSubmit}>
                    <TextFieldWithErrors
                      type="text"
                      name="email"
                      label="Email"
                      placeholder="e.g. alexsmith@gmail.com"
                      autoFocus
                    />
                    <FinalSubmit
                      {...renderProps}
                      label="Subscribe"
                      loading={this.loading}
                    />
                  </form>
                );
              }}
            />
          </Box>
        </NarrowCenterInner>
      </StationSite>
    );
  }
}

export default compose(inject("uiStore"), observer)(SubscribeToNewsletter);
