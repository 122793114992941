import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { Box, Title } from "bloomer";

import { compose } from "../../../../utils";

import styles from "./currently-checked-in.module.scss";
import { VISIT_TYPE_VOLUNTEER, VISIT_TYPE_MEMBER } from "../../../../constants";
import { VISIT_DISPLAY_NAMES } from "../../../../config";
import UserBadge from "../../common/UserBadge";

const UserListOfType = ({ type, usersSorted }) => {
  const users = usersSorted[type];

  if (users.length === 0) {
    return null;
  }

  return (
    <div className={styles.byType}>
      <Title isSize={5} style={{ marginBottom: 3 }}>
        {VISIT_DISPLAY_NAMES[type] || "Other"}
      </Title>
      {users.map(user => (
        <UserBadge user={user} key={user.id} />
      ))}
    </div>
  );
};

/**
 * Currently checked-in users
 */
class CurrentlyCheckedIn extends Component {
  componentDidMount() {
    this.props.userStationStore.loadCurrentlyCheckedInOnce();
  }

  render() {
    const { userStationStore } = this.props;
    const { checkedInUsers } = userStationStore;

    if (checkedInUsers.length === 0) {
      return null;
    }

    const usersSorted = checkedInUsers.reduce(
      (carry, user) => {
        const type = user.current_visit.type;
        if (type === VISIT_TYPE_VOLUNTEER) {
          carry[VISIT_TYPE_VOLUNTEER].push(user);
        } else if (type === VISIT_TYPE_MEMBER) {
          carry[VISIT_TYPE_MEMBER].push(user);
        } else {
          carry.other.push(user);
        }
        return carry;
      },
      {
        [VISIT_TYPE_VOLUNTEER]: [],
        [VISIT_TYPE_MEMBER]: [],
        other: []
      }
    );

    return (
      <Box>
        <Title isSize={4}>Currently Checked In</Title>
        <div>
          <UserListOfType
            type={VISIT_TYPE_VOLUNTEER}
            usersSorted={usersSorted}
          />
          <UserListOfType type={VISIT_TYPE_MEMBER} usersSorted={usersSorted} />
          <UserListOfType type={"other"} usersSorted={usersSorted} />
        </div>
      </Box>
    );
  }
}

export default compose(
  inject("userStationStore"),
  observer
)(CurrentlyCheckedIn);
