import React from "react";
import { observer, inject } from "mobx-react";
import { toJS } from "mobx";
import { Button, Level, LevelLeft, LevelItem } from "bloomer";
import FileSaver from "file-saver";

import { compose } from "../../../utils";
import { LOADER_TAG_ADMIN_BIKES } from "../../../constants";

import BikeTable from "../../../components/tables/BikeTable";
import BikeFilters from "../../../components/filters/BikeFilters";
import AdminTableSection from "../../../components/AdminTableSection";
import AdminSite from "../../../components/AdminSite";
import ResourceCreateLinkButton from "../../../components/ResourceCreateLinkButton";
import PageHeading from "../../../components/PageHeading";

import LinkButton from "../../../components/LinkButton";
import BikeQuickJump from "./BikeQuickJump";

class Bikes extends React.Component {
  downloadBikesJson = () => {
    const { bikes } = this.props.bikeAdminStore;

    const filename = `bsbc-bike-export-${new Date().toISOString()}.json`;

    const bikesBlob = new Blob(
      [JSON.stringify(toJS(bikes), null, 2)], // "pretty print" json
      {
        type: "application/json",
        name: filename
      }
    );

    // Download it
    FileSaver.saveAs(bikesBlob, filename);
  };

  render() {
    return (
      <AdminSite>
        <PageHeading>Bikes</PageHeading>
        <Level>
          <LevelLeft>
            <LevelItem>
              <ResourceCreateLinkButton
                to="/admin/bikes/new"
                label="New Bike"
                isMarginless
              />
            </LevelItem>
            <LevelItem>
              <BikeQuickJump />
            </LevelItem>
            <LevelItem>
              <LinkButton to="/admin/bikes/batch" isColor="link">
                Batch Creator
              </LinkButton>
            </LevelItem>
          </LevelLeft>
        </Level>
        <AdminTableSection
          store={this.props.bikeAdminStore}
          getter={store => store.bikes.slice()}
          loadAction={store => store.loadFiltered}
          loaderTag={LOADER_TAG_ADMIN_BIKES}
          clearAction={store => store.clear}
          filterComponent={BikeFilters}
          tableComponent={BikeTable}
        />
        <br />
        <p>
          <Button onClick={this.downloadBikesJson} isColor="link">
            Download Filtered Bikes as JSON
          </Button>
        </p>
      </AdminSite>
    );
  }
}

export default compose(inject("bikeAdminStore"), observer)(Bikes);
