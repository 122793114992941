/**
 * Index
 */

// Note: this file is ignored by Prettier (.prettierignore)
// because we need to shim before other imports

// Polyfills
import "react-app-polyfill/ie9";
import "core-js/features/array/is-array"
import "core-js/features/array/includes"
import "core-js/features/array/find"
import "core-js/features/string/includes"
import promiseFinally from "promise.prototype.finally";
promiseFinally.shim();

/* eslint-disable import/first */

import React from "react";
import ReactDOM from "react-dom";
import { configure } from "mobx";
import App from "./components/App";
import * as serviceWorker from "./serviceWorker";

import "./styles/base.scss";
import "./styles/global.scss";

/* eslint-enable import/first */

// mobx ensure state isn't modified outside actions
configure({ enforceActions: "always" });

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
