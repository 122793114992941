import { inject, observer } from "mobx-react";
import React from "react";
import { NavLink } from "react-router-dom";
import { Container } from "bloomer";
import classNames from "classnames";

import AuthCorner from "./AuthCorner";
import { compose } from "../../../utils";
import Logo from "../../Logo";
import LinkButton from "../../LinkButton";

import styles from "./header.module.scss";
import BurgerMenu from "./BurgerMenu";

const PublicHeaderNavLink = ({ after, ...restProps }) => (
  <li>
    <NavLink activeClassName={styles.activeItem} {...restProps} />
    {after}
  </li>
);

const Item = ({ sub, ...props }) => (
  <PublicHeaderNavLink
    {...props}
    after={
      sub && (
        <ul>
          {sub.map(subProps => (
            <PublicHeaderNavLink key={subProps.to} {...subProps} />
          ))}
        </ul>
      )
    }
  />
);

class Header extends React.Component {
  render() {
    return (
      <header className={styles.header}>
        <div className={classNames(styles.touchBanner)}>
          <LinkButton to="/donate" isColor="light">
            <span role="img" aria-label="heart">
              💗
            </span>
            &nbsp;Donate to BSBC
          </LinkButton>
        </div>
        <BurgerMenu />
        <div className={classNames(styles.remote, styles.remoteLeft)}>
          <p style={{ marginBottom: "0.4em" }}>
            <LinkButton to="/store/bikes" isColor="light">
              <span role="img" aria-label="bicycle">
                🚲
              </span>
              &nbsp;&nbsp;Bikes For Sale
            </LinkButton>
          </p>
          <LinkButton to="/donate" isColor="light">
            <span role="img" aria-label="heart">
              💗
            </span>
            &nbsp;&nbsp;Donate to BSBC
          </LinkButton>
        </div>
        <div className={classNames(styles.remote, styles.remoteRight)}>
          <AuthCorner />
        </div>
        <Container>
          <div className={styles.inner}>
            <div className={classNames(styles.left, styles.nav)}>
              <ul>
                <Item to="/" exact>
                  Home
                </Item>
                <Item
                  to="/about"
                  sub={[
                    { children: "Mission", to: "/about", exact: true },
                    { children: "DIY Open Shop Hours", to: "/about/shop-hours" },
                    {
                      children: "Bicycle Recycling Program",
                      to: "/about/bicycle-recycling"
                    },
                    { children: "RAR", to: "/about/wtf-nights" },
                    { children: "FAQ", to: "/about/faq" },
                    { children: "Events", to: "/events" },
                    { children: "Covid-19", to: "/covid19" },
                    { children: "Jobs", to: "/jobs" }
                  ]}
                >
                  About
                </Item>
              </ul>
            </div>
            <Logo />
            <div className={classNames(styles.right, styles.nav)}>
              <ul>
                <Item
                  to="/store"
                  sub={[
                    { children: "Online Store", to: "/store", exact: true },
                    { children: "Bikes For Sale", to: "/store/bikes" }
                  ]}
                >
                  Store
                </Item>
                <Item to="/contact">Contact</Item>
              </ul>
            </div>
          </div>
        </Container>
      </header>
    );
  }
}

export default compose(inject(), observer)(Header);
