import React from "react";
import { Icon, Button } from "bloomer";
import { Link } from "react-router-dom";
import moment from "moment";

import styles from "./footer.module.scss";

const IconButton = ({ href, iconClass }) => (
  <Button tag="a" href={href} isColor="white">
    <Icon isSize="small" className={iconClass} />
  </Button>
);

const SmallGrayLink = ({ ...restProps }) => (
  <Link {...restProps} className={styles.smallGrayLink} />
);

const year = moment().format("Y");

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.social}>
        <IconButton
          href="https://www.instagram.com/bsbcco"
          iconClass="fab fa-instagram"
        />
        <IconButton
          href="https://www.facebook.com/bsbc.co"
          iconClass="fab fa-facebook"
        />
        <IconButton
          href="https://www.strava.com/clubs/bsbcco"
          iconClass="fab fa-strava"
        />
      </div>
      <div className={styles.smallGrayLinks}>
        <SmallGrayLink to="/about/faq">FAQ</SmallGrayLink>
        <SmallGrayLink to="/terms">Terms of Service</SmallGrayLink>
        <SmallGrayLink to="/contact">Contact Us</SmallGrayLink>
      </div>
      <div className={styles.copy}>
        &copy;
        {year} Bradley Street Bicycle Co-op
        <br />
        138 Bradley St, New Haven, CT, 06511, USA
        <br />
        DIY Open Shop hours Tues/Thurs 4-8pm
        <br />
        Volunteer hours Monday 4-8pm
        <br />
        Website made in New Haven by{" "}
        <a href="http://andrewsuzuki.com">Andrew Suzuki</a>
      </div>
    </footer>
  );
};

export default Footer;
