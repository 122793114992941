import React from "react";
import { observer, inject } from "mobx-react";
import { Section, Container } from "bloomer";
import Hotkeys from "react-hot-keys";
import { withRouter } from "react-router-dom";

import Header from "./Header";
import Footer from "./Footer";
import AutoRefresh from "./AutoRefresh";

import ErrorBag from "../ErrorBag";
import ScrollToTopOnMount from "../ScrollToTopOnMount";

import { compose } from "../../utils";

import styles from "./station-site.module.scss";

class StationSite extends React.Component {
  onHotkeyHome = (key, e) => {
    e.preventDefault();
    this.props.history.push("/station");
  };

  componentDidMount() {
    // get meta
    this.props.stationMetaStore.loadMetaOnce();
  }

  render() {
    return (
      <div className={styles.station}>
        <Hotkeys keyName="esc" onKeyDown={this.onHotkeyHome} />
        <ScrollToTopOnMount />
        <Header />
        <AutoRefresh />
        <ErrorBag />
        <Section className={styles.main}>
          <Container>{this.props.children}</Container>
        </Section>
        <Footer />
      </div>
    );
  }
}

export default compose(
  withRouter,
  inject("stationMetaStore"),
  observer
)(StationSite);
