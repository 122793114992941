import React, { Component } from "react";
import { Title, Columns, Column, Table } from "bloomer";
import BColorTags from "../../../components/BColorTags";
import {
  parseMomentUTC,
  formatMomentLocalLL,
  minutesToHumanDuration,
  minutesToHumanDurationShorter
} from "../../../datetime";

const FieldDisplay = ({ label, pickName = false, children }) => (
  <Columns>
    <Column isSize={{ tablet: 4 }} className="has-text-right-tablet">
      <strong>{label}</strong>
    </Column>
    <Column isSize={{ tablet: 8 }}>
      {pickName ? children && children.name : children || <i>&mdash;</i>}
    </Column>
  </Columns>
);

const MechanicHistory = ({ history }) => (
  <div>
    <p>
      <strong>Work History</strong>
    </p>
    {history && history.length ? (
      <div>
        <Table isBordered isStriped>
          <thead>
            <tr>
              <th>Mechanic</th>
              <th style={{ minWidth: 122 }}>Date</th>
              <th style={{ minWidth: 85 }}>Time</th>
              <th>Notes</th>
            </tr>
          </thead>
          <tbody>
            {history.map(({ name, email, work }) => (
              <tr key={work.id}>
                <td>{name || email}</td>
                <td>{formatMomentLocalLL(parseMomentUTC(work.recorded_at))}</td>
                <td>{minutesToHumanDurationShorter(work.minutes_worked)}</td>
                <td>{work.note}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    ) : (
      "None yet"
    )}
  </div>
);

export default class StationBikeDisplay extends Component {
  render() {
    const { bike } = this.props;
    const {
      id,
      condition,
      received_at,
      notes_bike,
      fix_time,

      bmake,
      bmodel,
      bsize,
      bstyle,
      bcolors,

      acquisition,
      receivingevent,
      organization_donating,
      mechanics
    } = bike;

    return (
      <div>
        <Title isSize={4}>Bike #{id}</Title>
        <FieldDisplay label="Make" pickName>
          {bmake}
        </FieldDisplay>
        <FieldDisplay label="Model" pickName>
          {bmodel}
        </FieldDisplay>
        <FieldDisplay label="Size" pickName>
          {bsize}
        </FieldDisplay>
        <FieldDisplay label="Style" pickName>
          {bstyle}
        </FieldDisplay>
        <FieldDisplay label="Color(s)">
          <BColorTags bcolors={bcolors} />
        </FieldDisplay>
        <FieldDisplay label="Acquisition">
          <div>{acquisition.name}</div>
          {organization_donating && (
            <div>Donating Organization: {organization_donating.name}</div>
          )}
          {receivingevent && (
            <div>
              Receiving Event: {receivingevent.name} (
              {formatMomentLocalLL(parseMomentUTC(receivingevent.date))})
            </div>
          )}
        </FieldDisplay>
        <FieldDisplay label="Received At">
          {formatMomentLocalLL(parseMomentUTC(received_at))}
        </FieldDisplay>
        <FieldDisplay label="Incoming Condition">{condition}</FieldDisplay>
        <FieldDisplay label="Fix Time So Far">
          {minutesToHumanDuration(fix_time)}
        </FieldDisplay>
        <FieldDisplay label="Notes From Us">{notes_bike}</FieldDisplay>
        <MechanicHistory history={mechanics} />
      </div>
    );
  }
}
