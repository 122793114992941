import React, { Component } from "react";
import SelectFieldThrottled from "../SelectFieldThrottled";
import { userOneLiner } from "../../../utils";

export default class SelectUser extends Component {
  render() {
    return (
      <SelectFieldThrottled
        field="user"
        labelMaker={userOneLiner}
        {...this.props}
      />
    );
  }
}
