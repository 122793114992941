import React, { PureComponent } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from "recharts";
import { runReportByYearMonthly, getYearColor } from "./utils";

export default class YTDBikesMonthly extends PureComponent {
  render() {
    const {
      bikes,
      dateField = "received_at",
      sorter = null,
      bikeFilter = null
    } = this.props;

    if (!bikes) {
      return "n/a";
    }

    const { data, years } = runReportByYearMonthly(
      bikeFilter ? bikes.filter(bikeFilter) : bikes,
      dateField, // if replacing the sorter, will only be used to get min month
      recordsThisMonth => (recordsThisMonth ? recordsThisMonth.length : 0),
      sorter
    );

    return (
      <ResponsiveContainer width="100%" height={350}>
        <LineChart data={data}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="month" interval={0} height={40} />
          <YAxis />
          <Tooltip />
          <Legend />
          {years.map(year => (
            <Line
              key={`line-${year}`}
              dataKey={year}
              stroke={getYearColor(year)}
            />
          ))}
        </LineChart>
      </ResponsiveContainer>
    );
  }
}
