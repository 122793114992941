import React, { Component } from "react";
import SelectFieldImmediate from "../SelectFieldImmediate";
import agent from "../../../agent";

export default class SelectDemographicSetField extends Component {
  render() {
    const { field } = this.props;
    const loaderTag = `admin-field-demographicset-${field}`;

    return (
      <SelectFieldImmediate
        getFn={() => agent.AdminFields.allOfType({ field }, { loaderTag })}
        loaderTag={loaderTag}
        isClearable={true}
        {...this.props}
      />
    );
  }
}
