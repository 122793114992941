import React, { Component } from "react";
import SelectFieldImmediate from "../SelectFieldImmediate";
import agent from "../../../agent";
import { LOADER_TAG_ADMIN_FIELD_ROLE } from "../../../constants";

export default class SelectRole extends Component {
  render() {
    return (
      <SelectFieldImmediate
        getFn={() =>
          agent.AdminRoles.all({
            loaderTag: LOADER_TAG_ADMIN_FIELD_ROLE
          })
        }
        loaderTag={LOADER_TAG_ADMIN_FIELD_ROLE}
        {...this.props}
      />
    );
  }
}
