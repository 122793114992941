import React, { Component, Fragment } from "react";
import { PieChart, Pie, ResponsiveContainer, Cell } from "recharts";
import { Field, Control } from "bloomer";
import { ratioToPrettyPercent } from "../../../../utils";
import { ENUM_BIKE_CONDITIONS } from "../../../../config";
import {
  BIKE_CONDITION_GOOD,
  BIKE_CONDITION_NEW,
  BIKE_CONDITION_GREAT,
  BIKE_CONDITION_UNKNOWN,
  BIKE_CONDITION_POOR
} from "../../../../constants";

const COLORS = {
  [BIKE_CONDITION_UNKNOWN]: "#FFBB28",
  [BIKE_CONDITION_POOR]: "#FF8042",
  [BIKE_CONDITION_GOOD]: "#00C49F",
  [BIKE_CONDITION_GREAT]: "#0088FE",
  [BIKE_CONDITION_NEW]: "#D0E3CC"
};

const ORDER = ENUM_BIKE_CONDITIONS;

export default class PieBikesCondition extends Component {
  constructor(props) {
    super(props);

    this.state = {
      includeUnknown: false
    };
  }

  onChangeIncludeUnknown = e => {
    const includeUnknown = e.target.checked;
    this.setState({ includeUnknown });
  };

  render() {
    const { conditions } = this.props;

    if (!conditions) {
      return "n/a";
    }

    const totalBikes = Object.values(conditions).reduce(
      (acc, bikes) => acc + bikes,
      0
    );

    const data = ORDER.map(condition => {
      const bikes = conditions[condition];
      if (!bikes) {
        return null;
      }
      if (!this.state.includeUnknown && condition === "unknown") {
        return null;
      }
      return {
        condition,
        bikes,
        percent: ratioToPrettyPercent(totalBikes > 0 ? bikes / totalBikes : 1)
      };
    }).filter(x => x);

    return (
      <Fragment>
        <Field>
          <Control>
            <span>
              <label style={{ display: "inline-block" }}>
                <input
                  type="checkbox"
                  onChange={this.onChangeIncludeUnknown}
                  value="yes"
                  checked={this.state.includeUnknown}
                  style={{ verticalAlign: "middle" }}
                />
                &nbsp; Include Unknown
              </label>
            </span>
          </Control>
        </Field>

        {!data.length ? (
          <p>
            <em>n/a</em>
          </p>
        ) : (
          <ResponsiveContainer width="100%" height={300}>
            <PieChart>
              <Pie
                dataKey="bikes"
                isAnimationActive={true}
                data={data}
                outerRadius={100}
                label={({
                  cx,
                  cy,
                  midAngle,
                  innerRadius,
                  outerRadius,
                  value,
                  index
                }) => {
                  const RADIAN = Math.PI / 180;
                  // eslint-disable-next-line
                  const radius = 25 + innerRadius + (outerRadius - innerRadius);
                  // eslint-disable-next-line
                  const x = cx + radius * Math.cos(-midAngle * RADIAN);
                  // eslint-disable-next-line
                  const y = cy + radius * Math.sin(-midAngle * RADIAN);

                  const thisCondition = data[index];

                  return (
                    <text
                      x={x}
                      y={y}
                      // fill="#8884d8"
                      textAnchor={x > cx ? "start" : "end"}
                      dominantBaseline="central"
                    >
                      {thisCondition.condition} &ndash; {value} (
                      {thisCondition.percent})
                    </text>
                  );
                }}
              >
                {data.map(({ condition }) => (
                  <Cell
                    key={`cell-${condition}`}
                    fill={COLORS[condition] || "black"}
                  />
                ))}
              </Pie>
            </PieChart>
          </ResponsiveContainer>
        )}
      </Fragment>
    );
  }
}
