import React from "react";
import BaseSortTable from "./BaseSortTable";
import {
  idColumn,
  createdAtColumn,
  updatedAtColumn,
  makeActionsColumn
} from "./columns";
import {
  cellBoolean,
  cellCastBoolean,
  cellDemographicSet,
  cellNullIfFalsey,
  objectNameColumn
} from "./helpers";

const columns = [
  makeActionsColumn("/admin/users"),
  idColumn,
  {
    Header: "Role",
    accessor: "role",
    ...objectNameColumn,
    width: 80
  },
  {
    Header: "V Level",
    accessor: "volunteerlevel",
    ...objectNameColumn,
    width: 80
  },
  {
    Header: "Name",
    accessor: "name",
    width: 250
  },
  {
    Header: "Email",
    accessor: "email",
    width: 250
  },
  {
    Header: "Square Customer Id",
    accessor: "square_customer_id",
    Cell: cellNullIfFalsey,
    width: 250
  },
  {
    Header: "Newsletter?",
    accessor: "newsletter",
    Cell: cellBoolean,
    width: 150
  },
  {
    Header: "Active Membership?",
    accessor: "has_active_membership",
    Cell: cellBoolean,
    width: 150
  },
  {
    Header: "Show Volunteer Profile?",
    accessor: "show_volunteer_profile",
    Cell: cellBoolean,
    width: 150
  },
  {
    Header: "Signed Waiver?",
    accessor: "waiver_signature",
    Cell: cellCastBoolean,
    width: 150
  },
  {
    Header: "Signed Rental Waiver?",
    accessor: "rental_waiver_signature",
    Cell: cellCastBoolean,
    width: 150
  },
  {
    Header: "Demographic Set",
    accessor: "demographicset",
    Cell: cellDemographicSet,
    width: 150
  },
  createdAtColumn,
  updatedAtColumn
];

class UserTable extends React.Component {
  render() {
    return (
      <BaseSortTable isFullScreenWidth columns={columns} {...this.props} />
    );
  }
}

export default UserTable;
