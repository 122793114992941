import React, { PureComponent } from "react";
import DayPickerInput from "react-day-picker/DayPickerInput";

import "react-day-picker/lib/style.css";
import {
  parseYMDMomentTimezoneConfig,
  formatMomentLocalYMD
} from "../../datetime";

export default class Datepicker extends PureComponent {
  onDayChange = (date, modifiers) => {
    // TODO: WAITING Note that react-day-picker has a bug with invalid dates:
    // https://github.com/gpbl/react-day-picker/issues/815
    // https://github.com/gpbl/react-day-picker/pull/816
    // Update package, as well as this wrapper, when it is fixed.

    const m = parseYMDMomentTimezoneConfig(date);
    if (!m.isValid()) {
      this.props.onChange(date); // forward with 'raw' invalid date
    } else {
      this.props.onChange(formatMomentLocalYMD(m));
    }
  };

  render() {
    const { onChange, isColor, inputProps, ...restProps } = this.props;
    return (
      <DayPickerInput
        {...restProps}
        inputProps={{
          className: `input ${isColor && "is-" + isColor}`,
          placeholder: "YYYY-MM-DD",
          ...inputProps
        }}
        onDayChange={this.onDayChange}
      />
    );
  }
}
