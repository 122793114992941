import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import {
  Title,
  Box,
  Field as FieldStyle,
  Control,
  Input,
  Button
} from "bloomer";
import { Field } from "react-final-form";
import classNames from "classnames";

import { compose } from "../../utils";
import agent from "../../agent";

import CustomFinalForm from "../../components/CustomFinalForm";
import { makeRecordValidator, makeFieldValidator } from "../../validators";
import formValidationErrorLayer from "../../errors/formValidationErrorLayer";
import { standardFinalFormSubscriptions } from "../../config";
import { LOADER_TAG_ACCOUNT_NEWSLETTER } from "../../constants";
import MediumCenterInner from "../MediumCenterInner";

import styles from "./mailing-list-signup.module.scss";

const NEWSLETTER_ALERT_GROUP = "newsletter@public";

const validate = makeRecordValidator(
  makeFieldValidator("email", "required", "email")
);

class MailingListSignUp extends Component {
  resetForm = null;

  onSubmit = ({ email }) => {
    return formValidationErrorLayer(
      agent.Account.newsletter(
        { email },
        {
          loaderTag: LOADER_TAG_ACCOUNT_NEWSLETTER,
          alertGroup: NEWSLETTER_ALERT_GROUP
        }
      )
        .then(() => {
          this.props.uiStore.addAlert(
            "success",
            "You were subscribed to the newsletter successfully!",
            { group: NEWSLETTER_ALERT_GROUP }
          );
        })
        .catch(e => {
          // re-throw
          throw e;
        })
    )
      .catch(() => null)
      .finally(() => {
        // reset form
        this.resetForm();
      });
  };

  get loading() {
    return this.props.uiStore.loadingByTag(LOADER_TAG_ACCOUNT_NEWSLETTER);
  }

  render() {
    return (
      <div className={styles.wrapper} id="newsletter">
        <MediumCenterInner>
          <Box style={{ textAlign: "center" }}>
            <Title isSize={4}>Susbcribe to our newsletter!</Title>
            <p>
              Sign up for our newsletter to find out about cool events and
              what’s been happening at the Co-op.
            </p>
            <CustomFinalForm
              onSubmit={this.onSubmit}
              validate={validate}
              initialValues={{ email: null }}
              subscription={standardFinalFormSubscriptions}
              render={renderProps => {
                const { handleSubmit, form, submitting } = renderProps;
                this.resetForm = form.reset;
                return (
                  <form onSubmit={handleSubmit}>
                    <div
                      className={classNames(styles.formContainer, styles.big)}
                    >
                      <FieldStyle hasAddons>
                        <Control>
                          <Field name="email">
                            {({ input }) => (
                              <Input
                                type="email"
                                placeholder="e.g. alexsmith@gmail.com"
                                className={styles.email}
                                isSize="medium"
                                {...input}
                              />
                            )}
                          </Field>
                        </Control>
                        <Control>
                          <Button
                            type="submit"
                            disabled={submitting || this.loading}
                            isLoading={submitting}
                            isColor="info"
                            isSize="medium"
                          >
                            Subscribe
                          </Button>
                        </Control>
                      </FieldStyle>
                    </div>
                    <div
                      className={classNames(styles.formContainer, styles.small)}
                    >
                      <FieldStyle hasAddons>
                        <Control>
                          <Field name="email">
                            {({ input }) => (
                              <Input
                                type="email"
                                placeholder="e.g. alexsmith@gmail.com"
                                className={styles.email}
                                isSize="small"
                                {...input}
                              />
                            )}
                          </Field>
                        </Control>
                        <Control>
                          <Button
                            type="submit"
                            isLoading={this.loading}
                            isColor="info"
                            isSize="small"
                          >
                            Subscribe
                          </Button>
                        </Control>
                      </FieldStyle>
                    </div>
                  </form>
                );
              }}
            />
          </Box>
        </MediumCenterInner>
      </div>
    );
  }
}

export default compose(inject("uiStore"), observer)(MailingListSignUp);
