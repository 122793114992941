import { observable, decorate, action, runInAction } from "mobx";
import agent from "../agent";
import { LOADER_TAG_ADMIN_ITEMS } from "../constants";

export class ItemAdminStore {
  items = observable.array();

  clear() {
    this.items.clear();
  }

  // action
  loadAll() {
    return agent.AdminItems.all({ loaderTag: LOADER_TAG_ADMIN_ITEMS }).then(
      body => {
        const items = body.data;
        runInAction(() => {
          this.items.replace(items);
        });
        return items;
      }
    );
  }
}

decorate(ItemAdminStore, {
  items: observable,
  clear: action.bound,
  loadAll: action.bound
});

export default new ItemAdminStore();
