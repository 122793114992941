import React from "react";
import { isObservableArray } from "mobx";
import { Link } from "react-router-dom";
import Popup from "reactjs-popup";

import {
  userOneLiner,
  formatMoney,
  makeNestedSortComparator
} from "../../utils";
import {
  parseMomentUTC,
  formatMomentLocalLLL,
  minutesToHumanDurationShorter,
  formatMomentLocalYMD,
  parseMomentTimezoneConfig
} from "../../datetime";

// Components

// null
export const nullElement = <i>n/a</i>;

export const disabledElement = (
  <div
    style={{
      backgroundImage:
        "url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAIAAAACCAYAAABytg0kAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAABZJREFUeNpi2r9//38gYGAEESAAEGAAasgJOgzOKCoAAAAASUVORK5CYII=)",
      backgroundRepeat: "repeat",
      width: "100%",
      height: "100%"
    }}
  />
);

export const NotesTooltip = ({ content }) => {
  return (
    <Popup
      trigger={
        <span
          style={{
            display: "block",
            maxWidth: "100%",
            overflow: "hidden",
            textOverflow: "ellipsis"
          }}
        >
          {content}
        </span>
      }
      on="hover"
      contentStyle={{ whiteSpace: "normal", width: "400px", maxWidth: "100%" }}
      keepTooltipInside
    >
      <div>{content}</div>
    </Popup>
  );
};

// Cells

export const cellIdentity = ({ value }) => value;

export const cellNullIfFalsey = ({ value }) => value || nullElement;

export const cellBoolean = ({ value }) =>
  typeof value === "boolean" ? (value ? "Yes" : "") : nullElement;

export const cellBooleanYesNo = ({ value }) =>
  typeof value === "boolean" ? (value ? "Yes" : "No") : nullElement;

// for example, null => "", "bob" => "Yes"
export const cellCastBoolean = ({ value }) => (value ? "Yes" : "");

export const cellMoney = ({ value }) => formatMoney(value);

export const cellDatetime = ({ value }) => {
  return value ? formatMomentLocalLLL(parseMomentUTC(value)) : nullElement;
};

export const cellPlainLocalDate = ({ value }) => {
  return value
    ? formatMomentLocalYMD(parseMomentTimezoneConfig(value))
    : nullElement;
};

export const cellMinutesDuration = ({ value }) => {
  return value ? minutesToHumanDurationShorter(value) : nullElement;
};

export const cellStringArray = ({ value }) => {
  return value && value.join(", ");
};

const getUserCellKeyProp = user =>
  `${user.id}${user.work ? "/WORK-" + user.work.id : ""}`; // prevent duplicate key errors due to pivot stuff
export const cellUser = ({ value }) => {
  return (
    (value && (
      <Link key={getUserCellKeyProp(value)} to={`/admin/users/${value.id}`}>
        {userOneLiner(value)}
      </Link>
    )) ||
    nullElement
  );
};
export const cellUserName = ({ value }) =>
  (value && (
    <Link key={getUserCellKeyProp(value)} to={`/admin/users/${value.id}`}>
      {value.name || "<no name>"}
    </Link>
  )) ||
  nullElement;

export const cellOrder = ({ value }) => {
  return (
    (value && (
      <Link key={value.id} to={`/admin/orders/${value.id}`}>
        {value.id}
      </Link>
    )) ||
    nullElement
  );
};

export const cellObjectWithName = ({ value }) => {
  return (value && value.name) || nullElement;
};

export const sortObjectWithName = makeNestedSortComparator("name");
export const sortObjectWithId = makeNestedSortComparator("id");
export const sortObjectWithDate = makeNestedSortComparator("date");

// demographicset one-liner cell for table
const demographicSetPickOut = {
  name: { title: "Name", cell: cellIdentity }, // not on user->demographicset hopefully
  dagerange: { title: "Age Range", cell: cellObjectWithName },
  dgender: { title: "Gender", cell: cellObjectWithName },
  drace: { title: "Race", cell: cellObjectWithName },
  dnationality: { title: "Nationality", cell: cellObjectWithName }
  // Also available:
  // dneighborhood
  // dincomerange
  // dresidence
  // dhouseholdcount
  // date of birth
  // demploymentstatus
  // dbikeuses
  // [healths]
};
export const cellDemographicSet = ({ value }) => {
  if (!value) {
    return nullElement;
  }
  return (
    Object.keys(demographicSetPickOut)
      .map(name => {
        const innerValue = value[name];
        if (!innerValue) {
          return undefined;
        }
        const picked = demographicSetPickOut[name];
        return `${picked.title}: ${picked.cell({ value: innerValue })}`;
      })
      .filter(a => a)
      .join("; ") || "..."
  );
};

// Special cells

export const cellSimpleList = (innerCellFn, noDuplicateIds = false) => ({
  value
}) => {
  const ids = [];

  return (
    value &&
    (Array.isArray(value) || isObservableArray(value)) &&
    value
      .map(inner => {
        if (
          noDuplicateIds &&
          typeof inner === "object" &&
          inner.hasOwnProperty("id")
        ) {
          const { id } = inner;

          if (ids.indexOf(id) >= 0) {
            return null;
          }

          ids.push(inner.id);
        }

        return innerCellFn({ value: inner });
      })
      .filter(a => a) // filter nulls from above
      .reduce((acc, el, idx, src) => {
        acc.push(el);
        if (idx < src.length - 1) {
          // unless it's the last element, separate with comma
          acc.push(<span key={`SPAN-${idx}`}>, </span>);
        }
        return acc;
      }, [])
  );
};

// Column entries

export const wrapColumn = { style: { wordWrap: "unset" } };

export const dateTimeColumn = {
  width: 180,
  Cell: cellDatetime,
  sortMethod: sortObjectWithDate
};

export const plainLocalDateColumn = {
  width: 150,
  Cell: cellPlainLocalDate,
  sortMethod: sortObjectWithDate
};

export const minutesDurationColumn = { width: 85, Cell: cellMinutesDuration };

export const userColumn = {
  Cell: cellUser,
  sortMethod: sortObjectWithName,
  width: 250
};

export const objectNameColumn = {
  Cell: cellObjectWithName,
  sortMethod: sortObjectWithName
};

export const orderColumn = {
  Cell: cellOrder,
  sortMethod: sortObjectWithId
};
