import _get from "lodash.get";

import NarrowCenterTemplate from "./NarrowCenterTemplate";

const nameMap = {
  narrow_center: {
    template: NarrowCenterTemplate
  }
};

export const nameToTemplate = name => _get(nameMap, [name, "template"]);
