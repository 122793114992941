import uiStore from "./ui";
import authStore from "./auth";
import cartStore from "./cart";
import stationMetaStore from "./stationMeta";
import bikeAdminStore from "./bikeAdmin";
import bikeEditorStore from "./bikeEditor";
import bikePublicStore from "./bikePublic";
import bikePartAdminStore from "./bikePartAdmin";
import donatingEventAdminStore from "./donatingEventAdmin";
import fieldEditorAdminStore from "./fieldEditorAdmin";
import itemAdminStore from "./itemAdmin";
import itemPublicStore from "./itemPublic";
import membershipPeriodAdminStore from "./membershipPeriodAdmin";
import membershipPeriodOwnStore from "./membershipPeriodOwn";
import orderAdminStore from "./orderAdmin";
import orderOwnStore from "./orderOwn";
import pageAdminStore from "./pageAdmin";
import pagePublicStore from "./pagePublic";
import receivingEventAdminStore from "./receivingEventAdmin";
import rentalAdminStore from "./rentalAdmin";
import reportAdminStore from "./reportAdmin";
import shopVisitOwnStore from "./shopVisitOwn";
import shopVisitAdminStore from "./shopVisitAdmin";
import userAdminStore from "./userAdmin";
import userEditorStore from "./userEditor";
import userPublicStore from "./userPublic";
import userStationStore from "./userStation";
import cip2wStationStore from "./cip2wStation";
import streaksStationStore from "./streaksStation";

const stores = {
  uiStore,
  authStore,
  cartStore,

  stationMetaStore,

  bikeAdminStore,
  bikeEditorStore,
  bikePublicStore,
  bikePartAdminStore,
  donatingEventAdminStore,
  fieldEditorAdminStore,
  itemAdminStore,
  itemPublicStore,
  membershipPeriodAdminStore,
  membershipPeriodOwnStore,
  orderAdminStore,
  orderOwnStore,
  pageAdminStore,
  pagePublicStore,
  receivingEventAdminStore,
  rentalAdminStore,
  reportAdminStore,
  shopVisitOwnStore,
  shopVisitAdminStore,
  userAdminStore,
  userEditorStore,
  userPublicStore,
  userStationStore,
  cip2wStationStore,
  streaksStationStore
};

export default stores;
