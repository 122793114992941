import React, { Component } from "react";
import { withRouter } from "react-router";
import { decorate, computed, toJS } from "mobx";
import { observer, inject } from "mobx-react";

import { compose, idFromProps, currentIdIfChanged } from "../../utils";
import { MODE_EDIT, NO_CHANGE } from "../../constants";
import NotFoundError from "../../errors/NotFoundError";
import formValidationErrorLayer from "../../errors/formValidationErrorLayer";

import TitleHelmet from "../seo/TitleHelmet";
import DeleteConfirm from "../../components/DeleteConfirm";
import CustomFinalForm from "../../components/CustomFinalForm";
import PageHeading from "../../components/PageHeading";
import { standardFinalFormSubscriptions } from "../../config";
import FinalSubmit from "../fields/FinalSubmit";
import LoadingText from "../LoadingText";

/**
 * Props:
 * - name
 * - nameForTitle (optional)
 * - homeResourcePath (basePath by default)
 * - basePath
 * - children (the fields)
 * - loaderTag
 * - recordValidator
 * - initialFormValuesFn
 * - canCreateNew (set to false to disable creating new resources)
 * - showIdInTitle (bool)
 * - shouldClearStoreOnUnmount (optional bool)
 */
class GenericEditor extends Component {
  resetForm = null;

  get store() {
    return this.props.store;
  }

  onSubmit = fields => {
    return formValidationErrorLayer(
      this.store.save(fields).then(() => {
        // Reset form with new resource fields
        this.resetFormFromCurrent();
      })
    );
  };

  componentWillMount() {
    // clear data from previous
    this.store.clear();
  }

  componentDidMount() {
    this.initializeEditor(idFromProps(this.props));
  }

  componentDidUpdate(prevProps) {
    const currentIdMaybe = currentIdIfChanged(this.props, prevProps);
    if (currentIdMaybe !== NO_CHANGE) {
      this.initializeEditor(currentIdMaybe);
    }
  }

  componentWillUnmount() {
    const { shouldClearStoreOnUnmount = true } = this.props;
    if (shouldClearStoreOnUnmount) {
      this.store.clear();
    }
  }

  initializeEditor(idOrNewParam) {
    if (idOrNewParam === "new") {
      if (this.props.canCreateNew === false) {
        this.props.uiStore.addAlert(
          "warning",
          `Cannot create a new ${this.props.name}.`
        );
        this.props.history.push(
          this.props.homeResourcePath || this.props.basePath,
          { wasRedirect: true }
        );
      } else {
        return this.store.initializeNew().then(() => {
          this.resetFormFromCurrent();
        });
      }
    } else {
      return this.store
        .initializeEdit(idOrNewParam)
        .then(() => {
          this.resetFormFromCurrent();
        })
        .catch(e => {
          if (e instanceof NotFoundError) {
            // redirect back to base path
            this.props.history.push(
              this.props.homeResourcePath || this.props.basePath,
              { wasRedirect: true }
            );
          }
        });
    }
  }

  resetFormFromCurrent() {
    const resource = toJS(this.store.resource);

    // Update URL (maybe)
    if (resource && resource.id) {
      const idParam = idFromProps(this.props);
      const idParamInt = parseInt(idParam, 10);

      if (idParam === "new") {
        // Was recently new, but now it's saved. replace url with new resource id
        this.props.history.replace(`${this.props.basePath}/${resource.id}`, {
          wasRedirect: true
        });
      } else if (idParamInt > 0 && idParamInt !== resource.id) {
        // Id changed (likely through a backend "immutable" update)
        this.props.history.replace(`${this.props.basePath}/${resource.id}`, {
          wasRedirect: true
        });
      }
    }

    // Reset form with new values (maybe)
    if (this.resetForm) {
      this.resetForm(this.props.initialFormValuesFn(resource));
    }

    if (this.props.afterReset) {
      this.props.afterReset();
    }
  }

  remove = () => {
    this.store.remove().then(() => {
      this.props.history.push(
        this.props.homeResourcePath || this.props.basePath,
        {
          wasRedirect: true
        }
      );
    });
  };

  get loading() {
    return this.props.uiStore.loadingByTag(this.props.loaderTag);
  }

  render() {
    const { nameForTitle, showIdInTitle = false } = this.props;
    const { resource, mode } = this.store;

    const isEdit = mode === MODE_EDIT;
    const title =
      nameForTitle &&
      (showIdInTitle && isEdit && resource
        ? `Edit ${nameForTitle} #${resource.id}`
        : `${isEdit ? "Edit" : "New"} ${nameForTitle}`);

    return (
      <div>
        {title && <PageHeading>{title}</PageHeading>}
        {title && <TitleHelmet title={title} />}
        <LoadingText isLeft cond={!resource && this.loading} />
        {resource && (
          <CustomFinalForm
            onSubmit={this.onSubmit}
            validate={this.props.recordValidator}
            initialValues={this.props.initialFormValuesFn(resource)}
            subscription={standardFinalFormSubscriptions}
            render={renderProps => {
              const { handleSubmit, form } = renderProps;
              this.resetForm = form.reset;

              return (
                <form onSubmit={handleSubmit} autoComplete="off">
                  {this.props.children}
                  <FinalSubmit
                    {...renderProps}
                    label={isEdit ? "Update" : "Create"}
                    loading={this.loading}
                  />
                </form>
              );
            }}
          />
        )}
        {resource && isEdit && (
          <div>
            <br />
            <DeleteConfirm
              descriptor={this.props.name}
              onClick={this.remove}
              disabled={this.loading}
            />
          </div>
        )}
      </div>
    );
  }
}

decorate(GenericEditor, {
  loading: computed
});

export default compose(withRouter, inject("uiStore"), observer)(GenericEditor);
