import { Component } from "react";
import _throttle from "lodash.throttle";

const eventTypes = [
  "load",
  "mousemove",
  "mousedown",
  "touchstart",
  "click",
  "keypress",
  "scroll"
];

/**
 * Watch for user activity. Re-fetch station data if
 * mouse or keyboard haven't been touched in one hour.
 */
export default class AutoRefresh extends Component {
  timeout;
  wasActivity;

  constructor(props) {
    super(props);
    this.wasActivity = _throttle(this._wasActivity, 1000);
  }

  _wasActivity = () => {
    // reload if not cleared within one hour
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      document.location.reload();
    }, 3600000); // one hour
  };

  componentDidMount() {
    this.wasActivity();

    eventTypes.forEach(eventType => {
      window.addEventListener(eventType, this.wasActivity);
    });
  }

  componentWillUnmount() {
    eventTypes.forEach(eventType => {
      window.removeEventListener(eventType, this.wasActivity);
    });
  }

  render() {
    return null;
  }
}
