import React from "react";
import { Field } from "react-final-form";
import { Field as FieldStyle, Label, Control, Select, Help } from "bloomer";
import { deriveFieldError } from "../../utils";

/**
 * Helper for react-final-form
 */
class SelectFieldWithErrors extends React.PureComponent {
  render() {
    const { name, label, children } = this.props;
    return (
      <Field
        name={name}
        subscription={{
          value: true,
          error: true,
          submitError: true,
          touched: true,
          dirtySinceLastSubmit: true
        }}
      >
        {({ input, meta }) => {
          const derivedError = deriveFieldError(meta);
          return (
            <FieldStyle>
              <Label>{label}</Label>
              <Control>
                {React.createElement(
                  Select,
                  {
                    ...input,
                    isColor: derivedError ? "danger" : null
                  },
                  children
                )}
              </Control>
              {derivedError && <Help isColor="danger">{derivedError}</Help>}
            </FieldStyle>
          );
        }}
      </Field>
    );
  }
}

export default SelectFieldWithErrors;
