import React from "react";
import _get from "lodash.get";
import BaseSortTable from "./BaseSortTable";
import {
  idColumn,
  createdAtColumn,
  updatedAtColumn,
  makeActionsColumn
} from "./columns";
import {
  cellMoney,
  cellObjectWithName,
  userColumn,
  disabledElement
} from "./helpers";

const columns = [
  makeActionsColumn("/admin/orders", "View"),
  idColumn,
  {
    Header: "Customer",
    accessor: "user",
    ...userColumn
  },
  {
    Header: "Total",
    accessor: "total",
    Cell: cellMoney,
    width: 100
  },
  {
    Header: "Square Order Id",
    accessor: "square_order_id",
    width: 150
  },
  {
    Header: "Square Transaction Id",
    accessor: "square_transaction_id",
    width: 150
  },
  {
    Header: "Shipment",
    accessor: "shipment",
    width: 100
  },
  {
    Header: "Shipping Service",
    accessor: "shippingservice",
    Cell: props => {
      if (props.original.shipment === "shipped") {
        return cellObjectWithName(props);
      }
      return disabledElement;
    },
    width: 150
  },
  {
    Header: "Shipment Price",
    accessor: "shipment_price",
    Cell: props => {
      if (props.original.shipment === "shipped") {
        return cellMoney(props);
      }
      return disabledElement;
    },
    width: 150
  },
  {
    Header: "Shipment Cost",
    accessor: "shipment_cost",
    Cell: props => {
      if (props.original.shipment === "shipped") {
        return cellMoney(props);
      }
      return disabledElement;
    },
    width: 150
  },
  {
    Header: "Tracking",
    accessor: "shipment_tracking",
    Cell: props => {
      if (props.original.shipment === "shipped") {
        if (props.original.tracking_link) {
          return (
            <a
              href={props.original.tracking_link}
              title="Tracking link"
              target="_blank"
              rel="noopener noreferrer"
            >
              {props.value}
            </a>
          );
        }
        // tracking but no tracking link, unlikely.
        return props.value;
      }
      // no tracking [yet]
      return disabledElement;
    },
    width: 250
  },
  createdAtColumn,
  updatedAtColumn
];

class OrderTable extends React.Component {
  render() {
    return (
      <BaseSortTable
        isFullScreenWidth
        columns={columns}
        getTrProps={(state, rowInfo, column) => {
          let background = null;

          const order = _get(rowInfo, "original");

          if (order && order.shipment === "unmarked") {
            // order needs a shipment, so make it yellow
            background = "#F8DE7E";
          }

          return {
            style: { background }
          };
        }}
        {...this.props}
      />
    );
  }
}

export default OrderTable;
