import React, { Component } from "react";
import { Delete } from "bloomer";
import Dropzone from "react-dropzone";
import classNames from "classnames";

import styles from "./image-upload.module.scss";
import ImageFromMedialibrary from "../../ImageFromMedialibrary";

// wrapper around react-dropzone

export default class ImageUpload extends Component {
  state = { image: null, loading: false };

  onDrop = acceptedFiles => {
    if (acceptedFiles.length > 0) {
      const image = acceptedFiles[0];
      image.preview = URL.createObjectURL(image);
      this.setState({ image, loading: true });
      this.props.onPick(image).finally(() => {
        this.setState({ loading: false });
      });
    }
  };

  onRemove = e => {
    e.stopPropagation();
    this.setState({ image: null, loading: true });
    this.props.onRemove().finally(() => {
      this.setState({ loading: false });
    });
  };

  componentWillUnmount() {
    // Revoke image preview data uri to avoid memory leaks
    const { image } = this.state;
    if (image) {
      URL.revokeObjectURL(image.preview);
    }
  }

  render() {
    const { current, isFullWidth = false } = this.props;
    const { image, loading } = this.state;

    return (
      <div>
        <Dropzone
          onDrop={this.onDrop}
          accept={["image/png", "image/jpeg"]}
          multiple={false}
          style={{}} // don't use default styles
          className={classNames(styles.dropzone, {
            [styles.isFullWidth]: isFullWidth
          })}
          acceptClassName={styles.accept}
          rejectClassName={styles.reject}
          disabledClassName={styles.disabled}
          disabled={loading}
        >
          {(image || current) && (
            <Delete
              disabled={loading}
              onClick={this.onRemove}
              className={styles.delete}
            />
          )}
          {image ? (
            <img src={image.preview} alt="bike preview" />
          ) : current ? (
            <ImageFromMedialibrary image={this.props.current} component="img" />
          ) : (
            !loading && <p>Drop png/jpg here or click to upload</p>
          )}
        </Dropzone>
      </div>
    );
  }
}
