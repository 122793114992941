import { observable, decorate, runInAction, action } from "mobx";
import agent from "../agent";
import { LOADER_TAG_ACCOUNT_MEMBERSHIP } from "../constants";

export class MembershipPeriodOwnStore {
  periods = observable.array();

  clearAll() {
    this.periods.clear();
  }

  loadOwnPeriods() {
    return agent.Membership.all({ loaderTag: LOADER_TAG_ACCOUNT_MEMBERSHIP })
      .then(body => {
        const periods = body.data;
        runInAction(() => {
          this.periods.replace(periods);
        });
      })
      .catch(error => {
        this.clearAll();
        return Promise.reject(error); // re-throw
      });
  }

  redeem(code) {
    return agent.Membership.redeem(
      { code },
      { loaderTag: LOADER_TAG_ACCOUNT_MEMBERSHIP }
    ).then(() => {
      // reload everything
      return this.loadOwnPeriods();
    });
  }

  cancel(id) {
    return agent.Membership.cancel(
      { id },
      {
        loaderTag: LOADER_TAG_ACCOUNT_MEMBERSHIP,
        notFoundName: "membership"
      }
    ).then(() => {
      // reload everything
      return this.loadOwnPeriods();
    });
  }
}

decorate(MembershipPeriodOwnStore, {
  periods: observable,
  clearAll: action.bound,
  loadOwnPeriods: action.bound,
  redeem: action.bound,
  cancel: action.bound
});

export default new MembershipPeriodOwnStore();
