import ValidationError from "./ValidationError";

function parseDotNotation(str, val, obj) {
  var currentObj = obj,
    keys = str.split("."),
    i,
    l = Math.max(1, keys.length - 1),
    key;

  for (i = 0; i < l; ++i) {
    key = keys[i];
    currentObj[key] = currentObj[key] || {};
    currentObj = currentObj[key];
  }

  currentObj[keys[i]] = val;
  delete obj[str];
}

function expandDotNotation(obj) {
  for (var key in obj) {
    if (key.includes(".")) {
      parseDotNotation(key, obj[key], obj);
    }
  }
  return obj;
}

/**
 * Layer between our ValidationError and the field error data structure used by final-form
 * It requests a promise that RESOLVES to undefined an error object on failure (kinda dumb), or undefined on success.
 */
const formValidationErrorLayer = promise =>
  promise
    .then(() => {
      // Success
      return undefined;
    })
    .catch(error => {
      if (error instanceof ValidationError) {
        const errorObject = Object.keys(error.fields).reduce(
          (acc, fieldName) => {
            const value = error.fields[fieldName];
            // If there are multiple errors for this field, return the first one
            acc[fieldName] = Array.isArray(value) ? value[0] : value;
            return acc;
          },
          {}
        );
        // Laravel returns errors in a single-dimension array with dot-notated keys
        // expand those key/values into deep objects/arrays (mutates errorObject)
        expandDotNotation(errorObject);

        // Resolve with errors (as final-form needs)
        return Promise.resolve(errorObject);
      }

      // Not a ValidationError, so re-throw
      return Promise.reject(error);
    });

export default formValidationErrorLayer;
