import React from "react";
import { observer, inject } from "mobx-react";
import { Button } from "bloomer";

import { compose } from "../utils";

class AdminTableSection extends React.Component {
  state = { showFilters: false };

  get store() {
    return this.props.store;
  }

  get loading() {
    return this.props.uiStore.loadingByTag(this.props.loaderTag);
  }

  get data() {
    return this.props.getter(this.store);
  }

  load = () => {
    this.props.loadAction(this.store)();
  };

  clear = () => {
    this.props.clearAction(this.store)();
  };

  componentDidMount() {
    this.load();
  }

  componentWillUnmount() {
    this.clear();
  }

  toggleShowFilters = () => {
    this.setState({ showFilters: !this.state.showFilters });
  };

  render() {
    const { data, loading, store } = this;
    const { filterComponent, tableComponent, tableComponentProps } = this.props;
    const { showFilters } = this.state;

    return (
      <div>
        {filterComponent && (
          <p>
            <Button onClick={this.toggleShowFilters}>
              {showFilters ? "Hide" : "Show"} Filters
            </Button>
          </p>
        )}
        {showFilters &&
          filterComponent &&
          React.createElement(filterComponent, {
            filters: store.filters,
            setFilter: store.setFilter,
            resetFilters: store.resetFilters,
            onSubmit: store.loadFiltered,
            loading: loading
          })}
        <p>{loading ? "Loading..." : `${data.length} results`}</p>
        {React.createElement(tableComponent, {
          data,
          loading,
          ...tableComponentProps
        })}
      </div>
    );
  }
}

export default compose(
  inject("uiStore"),
  observer
)(AdminTableSection);
