import { observable, decorate, action, runInAction } from "mobx";
import agent from "../agent";
import { LOADER_TAG_ADMIN_PAGES } from "../constants";

export class PageAdminStore {
  pages = observable.array();

  clear() {
    this.pages.clear();
  }

  // action
  loadAll() {
    return agent.AdminPages.all({ loaderTag: LOADER_TAG_ADMIN_PAGES }).then(
      body => {
        const pages = body.data;
        runInAction(() => {
          this.pages.replace(pages);
        });
        return pages;
      }
    );
  }
}

decorate(PageAdminStore, {
  pages: observable,
  clear: action.bound,
  loadAll: action.bound
});

export default new PageAdminStore();
