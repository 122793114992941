import React, { Component } from "react";
import { Image } from "bloomer";

export default class ImageFromMedialibrary extends Component {
  render() {
    const { image, component = Image } = this.props;
    const { srcset, url } = image;
    return React.createElement(component, { srcSet: srcset, src: url });
  }
}
