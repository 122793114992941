import React from "react";
import { observer, inject } from "mobx-react";
import FileSaver from "file-saver";
import { Button } from "bloomer";

import UserTable from "../../../components/tables/UserTable";
import UserFilters from "../../../components/filters/UserFilters";
import AdminTableSection from "../../../components/AdminTableSection";
import AdminSite from "../../../components/AdminSite";

import agent from "../../../agent";
import { compose } from "../../../utils";
import {
  LOADER_TAG_ADMIN_USERS,
  LOADER_TAG_ADMIN_EXPORT_USERS
} from "../../../constants";
import PageHeading from "../../../components/PageHeading";

class Users extends React.Component {
  downloadUsersJson = () => {
    agent.AdminUsers.exportAll({
      loaderTag: LOADER_TAG_ADMIN_EXPORT_USERS
    })
      .then(body => {
        const users = body.data;

        const filename = `bsbc-user-export-${new Date().toISOString()}.json`;

        const usersBlob = new Blob(
          [JSON.stringify(users, null, 2)], // "pretty print" json
          {
            type: "application/json",
            name: filename
          }
        );

        // Download it
        FileSaver.saveAs(usersBlob, filename);
      })
      .catch(error => {
        if (error instanceof Error) {
          // crudely alert user
          window.alert(`Could not export users: ${error.message}`);
        }
      });
  };

  render() {
    return (
      <AdminSite>
        <PageHeading>Users</PageHeading>
        <AdminTableSection
          store={this.props.userAdminStore}
          getter={store => store.users.slice()}
          loadAction={store => store.loadFiltered}
          loaderTag={LOADER_TAG_ADMIN_USERS}
          clearAction={store => store.clear}
          filterComponent={UserFilters}
          tableComponent={UserTable}
        />
        <br />
        <p>
          <Button
            onClick={this.downloadUsersJson}
            isColor="link"
            isLoading={this.props.uiStore.loadingByTag(
              LOADER_TAG_ADMIN_EXPORT_USERS
            )}
          >
            Download All Users as JSON
          </Button>
        </p>
      </AdminSite>
    );
  }
}

export default compose(inject("userAdminStore", "uiStore"), observer)(Users);
