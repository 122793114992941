import _get from "lodash.get";
import _set from "lodash.set";
import isPlainObject from "is-plain-object";
import { isObservableArray } from "mobx";
import queryString from "query-string";

import {
  LOGIN_PATH,
  REMOTE_PAGES,
  IS_PRODUCTION,
  BSIZE_ORDER,
  RECAPTCHA_SITE_KEY
} from "./config";
import { NO_CHANGE } from "./constants";
import { parseMomentTimezoneConfig, formatMomentLocalYMD } from "./datetime";

/**
 * Utils
 */

/**
 * Functional compose (right-to-left)
 * http://busypeoples.github.io/post/functional-composing-javascript/
 * @param  {...fns} fns
 */
export const compose = (...fns) =>
  fns.reduceRight(
    (prevFn, nextFn) => (...args) => nextFn(prevFn(...args)),
    value => value
  );

/**
 * Safe JSON.parse. If parse fails, return null
 * @param {*} value
 */
export const jsonParseSafe = value => {
  if (!value) {
    return null;
  }

  try {
    return JSON.parse(value);
  } catch {
    return null;
  }
};

/**
 * Make link to login with redirect after login
 * @param {string|object} backTo react-router <Redirect> 'to' prop (string or object)
 */
export const loginLinkWithRedirectAfter = backTo => ({
  pathname: LOGIN_PATH,
  state: backTo ? { redirectAfterLogin: backTo } : {}
});

/**
 * Take a location object (from react-router) and get the requested remote page id
 * @param {object} location
 * @return {string|null}
 */
export const locationToPageId = ({ pathname }) =>
  REMOTE_PAGES[pathname] || null;

/**
 * Get a short string identifying a user, containing their name? and email?.
 * (something like "Andrew Suzuki <andrew.b.suzuki@gmail.com>")
 * @param {object} user
 * @return {string|null}
 */
export const userOneLiner = user => {
  if (!user) {
    return null;
  }
  return (
    (user.name ? `${user.name}` : "") +
    (user.email ? `${user.name && " "}<${user.email}>` : "")
  );
};

/**
 * Get a short string identifying a receiving/donating event
 * @param {object} recdon
 * @return {string|null}
 */
export const recdonOneLiner = recdon => {
  if (!recdon) {
    return null;
  }
  return `${recdon.name} (${formatMomentLocalYMD(
    parseMomentTimezoneConfig(recdon.date)
  )})`;
};

/**
 * Helper for react-select. It expects a value prop of the shape { value, label },
 * or an array of those objects. This converts from more common shape of { id, name }.
 * Accepts arrays values.
 */
export const getCurrentReactSelectValue = value => {
  if (!value) {
    return undefined;
  }

  if (Array.isArray(value) || isObservableArray(value)) {
    // multi-select
    return value.map(({ id, name }) => ({ value: id, label: name }));
  } else {
    return { value: value.id, label: value.name };
  }
};

/**
 * Helper for react-select. It sends its onChange value as an
 * object of shape { value, label }. This converts it to { id, name }
 * (for transformation before sending to store, api, etc)
 */
export const mapReactSelectValueToField = option => {
  if (!option) {
    return undefined;
  }
  return { id: option.value, name: option.label };
};

/**
 * Helper to prepare for put/post requests.
 * Expects obj to be a map from string keys to any value.
 * Will map values (specified by which[string] key array) that are
 * objects of the shape { id, ... } to their plain id.
 * Example:
 * mapSomeFieldsToId({ name: "my bike", manufacturer: { id: 3, name: "Specialized" }, ["manufacturer"])
 * => { name: "my bike", manufacturer: 3 }
 */
export const mapSomeFieldsToId = (obj, which = []) => {
  return Object.keys(obj).reduce((acc, key) => {
    const v = obj[key];
    const shouldMap = which.includes(key);
    if (shouldMap && (Array.isArray(v) || isObservableArray(v))) {
      acc[key] = v.map(({ id }) => id);
    } else if (shouldMap && v && v.id) {
      acc[key] = v.id;
    } else {
      acc[key] = v;
    }
    return acc;
  }, {});
};

/**
 * Check if a string or number is a good-looking integer that is less than Number.MAX_SAFE_INTEGER
 */
export const isInteger = value => {
  if (!isFinite(value)) {
    return false;
  }
  // don't allow leading zeroes (or anything else that passes this check)
  if (
    typeof value === "string" &&
    value.length > 1 &&
    value !== "0" &&
    value.startsWith("0")
  ) {
    // note doesn't handle negative leading-zero integers
    return false;
  }
  // don't allow integer strings that contain a point
  if (typeof value === "string" && value.includes(".")) {
    return false;
  }
  // final
  if (typeof value === "string") {
    const parsed = parseInt(value, 10);
    return parsed <= Number.MAX_SAFE_INTEGER && parsed + "" === value;
  } else if (typeof value === "number") {
    return value % 1 === 0;
  }
  return false;
};

/**
 * Check if a string is a good-looking finite number
 */
export const isNumber = value => {
  const isString = typeof value === "string";
  const trimmed = isString ? value.trim() : value;
  if (isString && (trimmed === "" || trimmed.slice(-1) === ".")) {
    return false;
  }
  return isFinite(Number(value));
};

export const formatFixed2 = value =>
  typeof value === "number" && value.toFixed(2);

export const formatMoney = value =>
  typeof value === "number" && "$" + formatFixed2(value);

/**
 * Set Authorization header as token (bearer) in axios config object
 */
export const setBearerAuthorizationHeader = (token, config = {}) => {
  _set(config, ["headers", "Authorization"], `Bearer ${token}`);
  return config;
};

/**
 * Values can be passing (undefined, empty object) or
 * failing (string, object with values)
 */
const isRealFinalFormError = error =>
  // plain
  typeof error === "string" ||
  // object
  (isPlainObject(error) &&
    Object.keys(error).some(sub => isRealFinalFormError(error[sub])));

/**
 * Derive field error from final-form meta object
 * @param {Object} meta
 * @returns string|object|undefined (string or object depending on incoming error/submitError shape; undefined if no error)
 */
export const deriveFieldError = ({
  touched,
  dirtySinceLastSubmit,
  error,
  submitError
}) => {
  return (
    (touched && isRealFinalFormError(error) && error) ||
    (!dirtySinceLastSubmit && submitError) ||
    undefined
  );
};

/**
 * Compute whether we should disable the submit button / show an error
 * based on if there are validation errors and there is an intersection
 * between those validation error fields with set errors and the
 * fieldstate touched object
 * @param {boolean} hasValidationErrors
 * @param {Object} errors
 * @param {Object} touched
 * @returns boolean
 */
export const deriveDisableForValidationErrors = (
  hasValidationErrors,
  errors,
  touched
) =>
  hasValidationErrors &&
  Object.keys(errors).some(
    fieldName => touched[fieldName] && isRealFinalFormError(errors[fieldName])
  );

/**
 * Calculate cart totals
 * $cart => ({ total, subtotal, shipment_price })
 */
export const calculateCartTotals = cart => {
  if (!cart || !cart.items || cart.items.length === 0) {
    return null;
  }
  const shipment_price = cart.shipment_price || 0;
  const subtotal = cart.items.reduce(
    (carry, item) => carry + item.price * item.cart_item.quantity,
    0
  );
  const total = subtotal + shipment_price;
  return { total, subtotal, shipment_price };
};

/**
 * Prepare bike parts request data (for admin bike editor or station bike work)
 */
export const makeBikePartsRequestData = bikeParts =>
  bikeParts.map(bp => ({
    bikepart: bp.id,
    quantity: bp.pivot.quantity,
    type: bp.pivot.type
  }));

/**
 * Get react-router id from props (assuming route was configured with :id in path)
 */
export const idFromProps = (props, idKey = "id") =>
  _get(props, ["match", "params", idKey]);

/**
 * Helper for some componentDidUpdate lifecycle methods.
 * Takes current props and previous props. Returns current id
 * (or null if current is false-y) if it changed,
 * or NO_CHANGE constant if it didn't.
 */
export const currentIdIfChanged = (props, prevProps) => {
  if (!IS_PRODUCTION) {
    // make sure both are not null during development
    if (!props || !prevProps) {
      throw new Error("currentIdIfChanged was called with null argument");
    }
  }
  const currentId = idFromProps(props);
  const previousId = idFromProps(prevProps);
  return currentId !== previousId ? currentId || null : NO_CHANGE;
};

export const logIfNotProduction = (...args) => {
  if (!IS_PRODUCTION) {
    return console.log(...args);
  }
  return undefined;
};

export const scrollTop = () => {
  window.scrollTo(0, 0);
};

export const queryParamsFromWithRouterProps = props => {
  return queryString.parse(props.location.search);
};

export const makeNestedSortComparator = path => (aSource, bSource, desc) => {
  let a = _get(aSource, path);
  let b = _get(bSource, path);
  // force null and undefined to the bottom
  a = a === null || a === undefined ? -Infinity : a;
  b = b === null || b === undefined ? -Infinity : b;
  // force any string values to lowercase
  a = typeof a === "string" ? a.toLowerCase() : a;
  b = typeof b === "string" ? b.toLowerCase() : b;
  // Return either 1 or -1 to indicate a sort priority
  if (a > b || (a !== -Infinity && b === -Infinity)) {
    return 1;
  }
  if (a < b || (a === -Infinity && b !== -Infinity)) {
    return -1;
  }
  // returning 0 or undefined will use any subsequent column sorting methods or the row index as a tiebreaker
  return 0;
};

export const emptyNonZero = x => {
  if (typeof x === "string") {
    const trimmed = x.trim();
    return !trimmed && trimmed !== "0";
  }
  return !x && x !== 0;
};

// sort BSizes by their actual order instead of alphabetical
export const sortBSizeComparator = (aSource, bSource) => {
  let a = BSIZE_ORDER[_get(aSource, "name")];
  let b = BSIZE_ORDER[_get(bSource, "name")];
  // force null and undefined to the bottom
  a = a === null || a === undefined ? -Infinity : a;
  b = b === null || b === undefined ? -Infinity : b;
  // Return either 1 or -1 to indicate a sort priority
  if (a > b) {
    return 1;
  }
  if (a < b) {
    return -1;
  }
  // returning 0 or undefined will use any subsequent column sorting methods or the row index as a tiebreaker
  return 0;
};

export const ratioToPrettyPercent = x => {
  if (typeof x === "number") {
    return `${Math.round(x * 100)}%`;
  }
  // do nothing
  return x;
};

export const generateCaptchaToken = action => {
  if (!window.grecaptcha || !RECAPTCHA_SITE_KEY) {
    return Promise.reject(new Error("Captcha not available"));
  }

  return new Promise((resolve, reject) => {
    window.grecaptcha.ready(resolve);
  }).then(() => {
    return window.grecaptcha.execute(RECAPTCHA_SITE_KEY, { action });
  });
};
