import React, { Fragment } from "react";
import { observer, inject } from "mobx-react";
import { Columns, Column, Button } from "bloomer";
import { Field, FormSpy } from "react-final-form";

import { compose, emptyNonZero } from "../../../utils";
import {
  LOADER_TAG_ADMIN_BIKES,
  BIKE_STATUS_AVAILABLE,
  BIKE_STATUS_SOLD,
  BIKE_STATUS_DONATED
} from "../../../constants";
import { ENUM_BIKE_STATUSES, ENUM_BIKE_CONDITIONS } from "../../../config";
import { minutesToHumanDuration } from "../../../datetime";

import GenericEditor from "../../../components/GenericEditor";
import TextFieldWithErrors from "../../../components/fields/TextFieldWithErrors";
import DateFieldWithErrors from "../../../components/fields/DateFieldWithErrors";
import SingleCheckboxFieldWithErrors from "../../../components/fields/SingleCheckboxFieldWithErrors";
import MoneyFieldWithErrors from "../../../components/fields/MoneyFieldWithErrors";
import SelectFieldWithErrors from "../../../components/fields/SelectFieldWithErrors";
import FancySelectFieldWithErrors from "../../../components/fields/FancySelectFieldWithErrors";
import SelectAcquisition from "../../../components/selects/immediate/SelectAcquisition";
import SelectOrganization from "../../../components/selects/immediate/SelectOrganization";
import SelectBColor from "../../../components/selects/immediate/SelectBColor";
import SelectBMake from "../../../components/selects/immediate/SelectBMake";
import SelectBSize from "../../../components/selects/immediate/SelectBSize";
import SelectBStyle from "../../../components/selects/immediate/SelectBStyle";
import SelectBModel from "../../../components/selects/throttled/SelectBModel";
import SelectDonatingEvent from "../../../components/selects/throttled/SelectDonatingEvent";
import SelectReceivingEvent from "../../../components/selects/throttled/SelectReceivingEvent";

import Wrapper from "./Wrapper";
import RecipientOrDemograpicSet from "./RecipientOrDemograpicSet";
import bikeValidator from "./bikeValidator";
import initialFormValuesFromBike from "./initialFormValuesFromBike";
import ImageUpload from "../../../components/fields/ImageUpload";
import agent from "../../../agent";
import { runInAction } from "mobx";
import EditorListeners from "./EditorListeners";
import EditorSectionHeading from "./EditorSectionHeading";
import BikePartsField from "../../../components/fields/BikePartsField";

/**
 * Admin Bike Editor
 *
 * Field dependency notes:
 * - fixed_at disabled+null if not available/donated/sold/missing
 * - sold_at disabled+null if not sold/donated
 * - list_online disabled+null if not available
 * - fix_cost disabled+null not available/donated/sold/donated
 * - price_sold disabled+null if not sold/donated
 * - donatingevent disabled+null if not donated
 * - organization_receiving disabled+null if not donated
 *
 * - recipient xor [demographicset]
 * - organization_donating  xor [acquisition=individual]
 */
class Editor extends React.Component {
  render() {
    const { bikeEditorStore } = this.props;

    return (
      <Wrapper>
        <GenericEditor
          name="bike"
          basePath="/admin/bikes"
          store={bikeEditorStore}
          loaderTag={LOADER_TAG_ADMIN_BIKES}
          recordValidator={bikeValidator}
          initialFormValuesFn={initialFormValuesFromBike}
          shouldClearStoreOnUnmount={false}
        >
          <EditorListeners />
          <Columns>
            <Column isSize={"1/2"}>
              <EditorSectionHeading isColor="dark">
                General
              </EditorSectionHeading>
              <SelectFieldWithErrors name="status" label="Status">
                {ENUM_BIKE_STATUSES.map(status => (
                  <option key={status} name={status}>
                    {status}
                  </option>
                ))}
              </SelectFieldWithErrors>
              <TextFieldWithErrors
                isTextarea
                name="notes_admin"
                label="Notes (Admin)"
                rows="3"
                extraHelp={
                  <span>
                    Misc notes <i>not</i> about the bike, sale/donation, or
                    recipient
                  </span>
                }
              />
              <EditorSectionHeading isColor="info">
                Reception
              </EditorSectionHeading>
              <Columns>
                <Column>
                  <DateFieldWithErrors
                    name="received_at"
                    label="Received At"
                    hasTodayButton
                  />
                </Column>
                <Column>
                  <SelectFieldWithErrors
                    name="condition"
                    label="Incoming Condition"
                  >
                    <option />
                    {ENUM_BIKE_CONDITIONS.map(condition => (
                      <option key={condition} name={condition}>
                        {condition}
                      </option>
                    ))}
                  </SelectFieldWithErrors>
                </Column>
              </Columns>
              <Columns>
                <Column>
                  <FancySelectFieldWithErrors
                    name="acquisition"
                    label="Acquisition"
                    component={SelectAcquisition}
                  />
                </Column>
                <Column>
                  <FancySelectFieldWithErrors
                    name="organization_donating"
                    label="Donating Organization"
                    component={SelectOrganization}
                    componentProps={{ isClearable: true }}
                  />
                </Column>
              </Columns>
              <Columns>
                <Column>
                  <FancySelectFieldWithErrors
                    name="receivingevent"
                    label="Receiving Event"
                    component={SelectReceivingEvent}
                    componentProps={{ isClearable: true }}
                  />
                </Column>
                <Column>
                  <TextFieldWithErrors
                    name="donoremail"
                    label="Donor Email"
                    extraHelp="Will be emailed when bike is sold or donated"
                  />
                </Column>
              </Columns>
            </Column>
            <Column isSize={"1/2"}>
              <EditorSectionHeading isColor="danger">Bike</EditorSectionHeading>
              <FancySelectFieldWithErrors
                name="bmake"
                label="Make"
                component={SelectBMake}
                componentProps={{ isCreateable: true }}
              />
              <FancySelectFieldWithErrors
                name="bmodel"
                label="Model"
                component={SelectBModel}
                componentProps={{ isCreateable: true }}
              />
              <FancySelectFieldWithErrors
                name="bstyle"
                label="Style"
                component={SelectBStyle}
              />
              <FancySelectFieldWithErrors
                name="bsize"
                label="Size"
                component={SelectBSize}
              />
              <FancySelectFieldWithErrors
                name="bcolors"
                label="Color(s)"
                component={SelectBColor}
                componentProps={{ isMulti: true }}
              />
              <TextFieldWithErrors
                isTextarea
                name="notes_bike"
                label="Notes (Bike)"
                rows="2"
              />
            </Column>
          </Columns>
          <Columns>
            <Column isSize={"1/3"}>
              <EditorSectionHeading isColor="warning">
                Repair
              </EditorSectionHeading>
              <SingleCheckboxFieldWithErrors
                name="is_nofix"
                label="No fix needed?"
              />
              <DateFieldWithErrors
                name="fixed_at"
                label="Fixed At"
                hasTodayButton
              />
              <Field name="fix_time">
                {({ input }) =>
                  input.value && (
                    <div className="has-tiny-mb">
                      <p className="has-tiny-mb">
                        <b>Total Fix Time</b>
                      </p>
                      <p>{minutesToHumanDuration(input.value)}</p>
                    </div>
                  )
                }
              </Field>
              <MoneyFieldWithErrors
                name="fix_cost"
                label="Additional Fix Cost"
                hasZeroButton
                extraHelp="Beyond the entered bike parts"
              />
              <BikePartsField isStation={false} />
            </Column>
            <Column isSize={"2/3"}>
              <EditorSectionHeading
                style={{ color: "black", background: "#add8e6" }}
              >
                Public
              </EditorSectionHeading>
              <MoneyFieldWithErrors
                name="price_asking"
                label="Price (Asking)"
              />
              <SingleCheckboxFieldWithErrors
                name="list_online"
                label="List online?"
              />
              <Columns>
                <Column>
                  <TextFieldWithErrors
                    isTextarea
                    name="notes_public"
                    label="Online description"
                  />
                </Column>
                <Column>
                  {bikeEditorStore.resource && bikeEditorStore.resource.id && (
                    <Fragment>
                      <p className="has-tiny-mb">
                        <b>Image</b>
                      </p>
                      <ImageUpload
                        isFullWidth
                        current={bikeEditorStore.resource.image}
                        onPick={image =>
                          agent.AdminBikes.uploadImage({
                            id: bikeEditorStore.resource.id,
                            file: image
                          })
                        }
                        onRemove={() => {
                          const bike = bikeEditorStore.resource;
                          // set bike image to null
                          runInAction(() => {
                            bike.image = null;
                          });
                          return agent.AdminBikes.removeImage({
                            id: bike.id
                          });
                        }}
                      />
                    </Fragment>
                  )}
                </Column>
              </Columns>
            </Column>
          </Columns>

          <Columns>
            <Column>
              <EditorSectionHeading isColor="success">
                Out The Door
              </EditorSectionHeading>
              <Columns>
                <Column>
                  <Columns>
                    <Column>
                      <DateFieldWithErrors
                        name="sold_at"
                        label="Sold/Donated At"
                        hasTodayButton
                      />
                    </Column>
                    <Column>
                      <MoneyFieldWithErrors
                        name="price_sold"
                        label="Price (Final)"
                        hasZeroButton
                      />
                      <FormSpy subscription={{ values: true }}>
                        {({ form }) => {
                          // Watch status and two price values and maybe
                          // show a button that sets sold price to the
                          // asking price. Also, if sold price doesn't
                          // match asking price, show a small note saying so
                          // (not a validation error, just a note)

                          const status = form.getFieldState("status").value;
                          const priceAskingRaw = form.getFieldState(
                            "price_asking"
                          ).value;

                          // Don't display anything if status
                          // is not available, donated, or sold,
                          // or if the asking price is not set
                          const statusIsAvailableDonatedSold = [
                            BIKE_STATUS_AVAILABLE,
                            BIKE_STATUS_SOLD,
                            BIKE_STATUS_DONATED
                          ].includes(status);
                          if (
                            !statusIsAvailableDonatedSold ||
                            emptyNonZero(priceAskingRaw)
                          ) {
                            return null;
                          }

                          const priceAsking = parseFloat(priceAskingRaw);

                          // get initial status
                          const initialValues = form.getState().initialValues;
                          const initialStatus = initialValues.status;

                          // determine if status changed to donated/sold
                          // and wasn't on initial load
                          const statusBecameDonatedOrSold =
                            [BIKE_STATUS_DONATED, BIKE_STATUS_SOLD].includes(
                              status
                            ) &&
                            ![BIKE_STATUS_DONATED, BIKE_STATUS_SOLD].includes(
                              initialStatus
                            );

                          const priceSold = parseFloat(
                            form.getFieldState("price_sold").value
                          );
                          const changePriceSold = v =>
                            form.change("price_sold", v);

                          return (
                            <Fragment>
                              {priceAsking === priceSold ? null : (
                                <p className="is-size-7 has-tiny-mb">
                                  <span className="has-soft-yellow-bg">
                                    Does not match asking price (ok)
                                  </span>
                                </p>
                              )}
                              {!statusBecameDonatedOrSold ||
                              !emptyNonZero(priceSold) ? null : (
                                <Button
                                  isSize="small"
                                  isColor="light"
                                  onClick={() => changePriceSold(priceAsking)}
                                >
                                  Copy from asking price ($
                                  {priceAsking})
                                </Button>
                              )}
                            </Fragment>
                          );
                        }}
                      </FormSpy>
                    </Column>
                  </Columns>
                  <TextFieldWithErrors
                    isTextarea
                    name="notes_sale"
                    label="Notes (Sale/Donation)"
                    rows="2"
                  />
                </Column>
                <Column>
                  <EditorSectionHeading isColor="light" isSize="medium">
                    Donation-specific
                  </EditorSectionHeading>
                  <FancySelectFieldWithErrors
                    name="organization_receiving"
                    label="Receiving Organization"
                    component={SelectOrganization}
                    componentProps={{ isClearable: true }}
                  />
                  <FancySelectFieldWithErrors
                    name="donatingevent"
                    label="Donating Event"
                    component={SelectDonatingEvent}
                    componentProps={{ isClearable: true }}
                  />
                </Column>
              </Columns>
            </Column>
          </Columns>

          <Columns>
            <Column>
              <EditorSectionHeading
                style={{ color: "black", background: "#ffc0cb" }}
              >
                Recipient
              </EditorSectionHeading>
              <RecipientOrDemograpicSet />
            </Column>
          </Columns>
        </GenericEditor>
      </Wrapper>
    );
  }
}

export default compose(inject("bikeEditorStore"), observer)(Editor);
