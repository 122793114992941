import React from "react";
import { observer, inject } from "mobx-react";
import { Title, Notification } from "bloomer";

import { compose } from "../../../utils";
import Wrapper from "./common/Wrapper";

class Dashboard extends React.Component {
  render() {
    const { authStore } = this.props;
    const { user } = authStore;

    return (
      <Wrapper>
        {() => (
          <div>
            <Title isSize={3}>Dashboard</Title>
            <Notification>Coming soon</Notification>
            <p>
              Your volunteer level is:{" "}
              <strong>{user.volunteerlevel.name}</strong>
            </p>
          </div>
        )}
      </Wrapper>
    );
  }
}

export default compose(
  inject("authStore"),
  observer
)(Dashboard);
