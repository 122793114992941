import React from "react";
import { observer, inject } from "mobx-react";
import { Title } from "bloomer";

import { compose } from "../../../utils";
import agent from "../../../agent";
import { LOADER_TAG_ADMIN_MEMBERSHIPS } from "../../../constants";

import MembershipCard from "../../../components/MembershipCard";

import Wrapper from "./Wrapper";
import LoadingText from "../../../components/LoadingText";

/**
 * Membership Period Listing
 *
 * NOTE: Not all of a user's membership periods are returned.
 */
const MembershipPeriods = ({ periods }) => (
  <div>
    {periods.map(period => (
      <MembershipCard key={period.id} period={period} />
    ))}
  </div>
);

class _MembershipInner extends React.Component {
  _hasUnmounted = false;

  state = { periods: [] };

  get loading() {
    return this.props.uiStore.loadingByTag(LOADER_TAG_ADMIN_MEMBERSHIPS);
  }

  componentDidMount() {
    this.pullMemberships();
  }

  componentWillUnmount() {
    this._hasUnmounted = true;
  }

  pullMemberships() {
    const userId = this.props.user.id;
    return agent.AdminMemberships.filter(
      {
        filters: {
          sort_field: "is_active",
          sort_direction: "desc",
          user: [userId]
        }
      },
      { loaderTag: LOADER_TAG_ADMIN_MEMBERSHIPS }
    ).then(body => {
      const periods = body.data;
      if (!this._hasUnmounted) {
        this.setState({ periods });
      }
    });
  }

  render() {
    const { periods } = this.state;
    const { user } = this.props;

    return (
      <div>
        <p>
          <span className="is-size-4">
            Status:{" "}
            {user.has_active_membership ? (
              <span className="has-text-success">Active</span>
            ) : (
              <span className="has-text-warning">Not Active</span>
            )}
          </span>
        </p>
        {this.loading ? (
          <LoadingText />
        ) : periods.length ? (
          <MembershipPeriods periods={periods} />
        ) : null}
      </div>
    );
  }
}

const MembershipInner = compose(
  inject("uiStore"),
  observer
)(_MembershipInner);

export default class Membership extends React.Component {
  render() {
    return (
      <Wrapper renderWithUser={user => <MembershipInner user={user} />}>
        <Title isSize={3}>Membership</Title>
      </Wrapper>
    );
  }
}
