import React from "react";
import { withRouter } from "react-router";
import { Helmet } from "react-helmet";
import {
  TITLE_DEFAULT_PUBLIC,
  TITLE_TEMPLATE_PUBLIC,
  TITLE_DEFAULT_ADMIN,
  TITLE_TEMPLATE_ADMIN,
  TITLE_DEFAULT_STATION,
  TITLE_TEMPLATE_STATION
} from "../../config";
import _startsWith from "lodash.startswith";

const BaseHelmet = ({ location }) => {
  const { pathname } = location;
  if (_startsWith(pathname, "/admin")) {
    return (
      <Helmet
        titleDefault={TITLE_DEFAULT_ADMIN}
        titleTemplate={TITLE_TEMPLATE_ADMIN}
      />
    );
  } else if (_startsWith(pathname, "/station")) {
    return (
      <Helmet
        titleDefault={TITLE_DEFAULT_STATION}
        titleTemplate={TITLE_TEMPLATE_STATION}
      />
    );
  }

  return (
    <Helmet
      titleDefault={TITLE_DEFAULT_PUBLIC}
      titleTemplate={TITLE_TEMPLATE_PUBLIC}
    />
  );
};

export default withRouter(BaseHelmet);
