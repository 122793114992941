import React from "react";

const Ul = ({ children, style, ...restProps }) => (
  <ul
    style={{ listStyleType: "disc", listStylePosition: "inside", ...style }}
    {...restProps}
  >
    {children}
  </ul>
);

export default Ul;
