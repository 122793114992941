import React from "react";
import { observer, inject } from "mobx-react";

import {
  makeRecordValidator,
  makeFieldValidator,
  minLength
} from "../../../validators";
import formValidationErrorLayer from "../../../errors/formValidationErrorLayer";
import PublicSite from "../../../components/PublicSite";
import SimpleFormPage from "../../../components/SimpleFormPage";
import TextFieldWithErrors from "../../../components/fields/TextFieldWithErrors";
import CustomFinalForm from "../../../components/CustomFinalForm";
import { compose, queryParamsFromWithRouterProps } from "../../../utils";
import CustomRedirect from "../../../components/CustomRedirect";
import {
  REDIRECT_AFTER_LOGIN_DEFAULT,
  standardFinalFormSubscriptions
} from "../../../config";
import FinalSubmit from "../../../components/fields/FinalSubmit";

const validate = makeRecordValidator(
  makeFieldValidator("email", "required"),
  makeFieldValidator("password", "required", minLength(6), [
    "matches",
    "password_confirmation"
  ]),
  makeFieldValidator("password_confirmation", "required")
);

class Reset extends React.Component {
  resetForm = null;

  state = {
    done: false
  };

  onSubmit = ({ email, password, password_confirmation }) => {
    // Parse token from query string.
    const query = queryParamsFromWithRouterProps(this.props);
    // If it doesn't exist then default to a string to
    // prevent "required field" errors from backend.
    const token = query.token || "NULL";

    return formValidationErrorLayer(
      this.props.authStore
        .resetPassword(email, token, password, password_confirmation)
        .then(() => {
          this.setState({ done: true });
        })
        .finally(() => {
          // reset form (on success and fail)
          this.resetForm();
        })
    );
  };

  render() {
    if (this.state.done) {
      return <CustomRedirect to={REDIRECT_AFTER_LOGIN_DEFAULT} />;
    }

    return (
      <PublicSite>
        <SimpleFormPage title="Reset Password">
          <CustomFinalForm
            onSubmit={this.onSubmit}
            validate={validate}
            initialValues={{}}
            subscription={standardFinalFormSubscriptions}
            render={renderProps => {
              const { handleSubmit, form } = renderProps;
              this.resetForm = form.reset;
              return (
                <form onSubmit={handleSubmit}>
                  <TextFieldWithErrors
                    type="text"
                    name="email"
                    label="Email"
                    placeholder="e.g. alexsmith@gmail.com"
                  />
                  <TextFieldWithErrors
                    type="password"
                    name="password"
                    label="Password"
                    placeholder="Password"
                    extraHelp="Must be at least 6 characters"
                  />
                  <TextFieldWithErrors
                    type="password"
                    name="password_confirmation"
                    label="Password (Again)"
                  />
                  <FinalSubmit {...renderProps} isSize="normal" />
                </form>
              );
            }}
          />
        </SimpleFormPage>
      </PublicSite>
    );
  }
}

export default compose(
  inject("authStore"),
  observer
)(Reset);
