import React from "react";
import { observer } from "mobx-react";
import _pick from "lodash.pick";

import AdminSite from "../../../components/AdminSite";

import agent from "../../../agent";
import { compose, idFromProps } from "../../../utils";
import { LOADER_TAG_ADMIN_BIKE_PARTS } from "../../../constants";
import GenericEditor from "../../../components/GenericEditor";
import GenericResourceEditorStore from "../../../stores/special/GenericResourceEditorStore";
import { makeRecordValidator, makeFieldValidator } from "../../../validators";
import TextFieldWithErrors from "../../../components/fields/TextFieldWithErrors";
import MoneyFieldWithErrors from "../../../components/fields/MoneyFieldWithErrors";
import Breadcrumbs from "../../../components/Breadcrumbs";

const initialFormValuesFromBikePart = part => {
  return _pick(part, ["title", "cost_new", "cost_used"]);
};

const newStructure = {
  title: null,
  cost_new: null,
  cost_used: null
};

const bikePartEditorStore = new GenericResourceEditorStore(
  "bike part",
  LOADER_TAG_ADMIN_BIKE_PARTS,
  "bike-part-editor",
  "bikePart",
  agent.AdminBikeParts.show,
  agent.AdminBikeParts.create,
  agent.AdminBikeParts.update,
  agent.AdminBikeParts.remove,
  newStructure,
  null,
  "Part saved successfully!",
  "Part deleted successfully!"
);

const validate = makeRecordValidator(
  makeFieldValidator("title", "required"),
  makeFieldValidator("cost_new", "number", "minZero"), // further validated by back-end
  makeFieldValidator("cost_used", "number", "minZero") // further validated by back-end
);

class BikePartCrud extends React.Component {
  render() {
    const idParam = idFromProps(this.props);
    const isNew = idParam === "new";

    return (
      <AdminSite>
        <Breadcrumbs
          items={[
            { to: "/admin", label: "Admin" },
            { to: "/admin/bikeparts", label: "Bike Parts" },
            {
              to: `/admin/bikeparts/${isNew ? "new" : idParam}`,
              label: isNew ? "New Bike Part" : `Bike Part ${idParam}`
            }
          ]}
        />
        <GenericEditor
          name="bike part"
          nameForTitle="Bike Part"
          basePath="/admin/bikeparts"
          store={bikePartEditorStore}
          loaderTag={LOADER_TAG_ADMIN_BIKE_PARTS}
          recordValidator={validate}
          initialFormValuesFn={initialFormValuesFromBikePart}
        >
          <TextFieldWithErrors name="title" label="Title" />
          <MoneyFieldWithErrors name="cost_new" label="Cost (New)" />
          <MoneyFieldWithErrors name="cost_used" label="Cost (Used)" />
        </GenericEditor>
      </AdminSite>
    );
  }
}

export default compose(observer)(BikePartCrud);
