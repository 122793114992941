import React from "react";

import FilterWrapper from "./support/FilterWrapper";
import { ENUM_BIKE_STATUSES, ENUM_BIKE_CONDITIONS } from "../../config";

import SelectAcquisition from "../../components/selects/immediate/SelectAcquisition";
import SelectOrganization from "../../components/selects/immediate/SelectOrganization";
import SelectBMake from "../../components/selects/immediate/SelectBMake";
import SelectBSize from "../../components/selects/immediate/SelectBSize";
import SelectBStyle from "../../components/selects/immediate/SelectBStyle";
import SelectBColor from "../../components/selects/immediate/SelectBColor";
import SelectUser from "../../components/selects/throttled/SelectUser";
import SelectReceivingEvent from "../../components/selects/throttled/SelectReceivingEvent";
import SelectDonatingEvent from "../../components/selects/throttled/SelectDonatingEvent";
import SelectBModel from "../../components/selects/throttled/SelectBModel";

const fields = [
  {
    name: "received_at",
    label: "Received At",
    type: "date-range"
  },
  {
    name: "fixed_at",
    label: "Fixed At",
    type: "date-range"
  },
  {
    name: "sold_at",
    label: "Sold/Donated At",
    type: "date-range"
  },
  {
    name: "updated_at",
    label: "Updated At",
    type: "date-range"
  },
  {
    name: "fix_cost",
    label: "Additional Fix Cost",
    type: "money-range"
  },
  {
    name: "fix_time",
    label: "Fix Time",
    type: "integer-range"
  },
  {
    name: "price_asking",
    label: "Price Asking",
    type: "money-range"
  },
  {
    name: "price_sold",
    label: "Price Sold",
    type: "money-range"
  },
  {
    name: "statuses",
    label: "Statuses",
    type: "checkbox-multi",
    options: ENUM_BIKE_STATUSES
  },
  {
    name: "conditions",
    label: "Conditions",
    type: "checkbox-multi",
    options: ENUM_BIKE_CONDITIONS
  },
  {
    name: "list_online",
    label: "Listed Online?",
    type: "nullable-boolean"
  },
  {
    name: "has_admin_notes",
    label: "Has admin notes?",
    type: "nullable-boolean"
  },
  {
    name: "has_bike_notes",
    label: "Has bike notes?",
    type: "nullable-boolean"
  },
  {
    name: "has_sale_notes",
    label: "Has sale notes?",
    type: "nullable-boolean"
  },
  {
    name: "has_public_notes",
    label: "Has online description?",
    type: "nullable-boolean"
  },
  {
    name: "acquisition",
    label: "Acquisition",
    type: "fancy-select",
    component: SelectAcquisition,
    isMulti: true // for SelectAcquisition
  },
  {
    name: "recipient",
    label: "Recipient",
    type: "fancy-select",
    component: SelectUser,
    isMulti: true
  },
  {
    name: "receivingevent",
    label: "Receiving Event",
    type: "fancy-select",
    component: SelectReceivingEvent,
    isMulti: true
  },
  {
    name: "donatingevent",
    label: "Donating Event",
    type: "fancy-select",
    component: SelectDonatingEvent,
    isMulti: true
  },
  {
    name: "organization_donating",
    label: "Donating Organization",
    type: "fancy-select",
    component: SelectOrganization,
    isMulti: true
  },
  {
    name: "organization_receiving",
    label: "Receiving Organization",
    type: "fancy-select",
    component: SelectOrganization,
    isMulti: true
  },
  {
    name: "bmake",
    label: "Make",
    type: "fancy-select",
    component: SelectBMake,
    isMulti: true
  },
  {
    name: "bmodel",
    label: "Model",
    type: "fancy-select",
    component: SelectBModel,
    isMulti: true
  },
  {
    name: "bstyle",
    label: "Style",
    type: "fancy-select",
    component: SelectBStyle,
    isMulti: true
  },
  {
    name: "bsize",
    label: "Size",
    type: "fancy-select",
    component: SelectBSize,
    isMulti: true
  },
  {
    name: "bcolors",
    label: "Colors",
    type: "fancy-select-with-booltype",
    component: SelectBColor,
    isMulti: true
  },
  {
    name: "mechanics",
    label: "Mechanics",
    type: "fancy-select-with-booltype",
    component: SelectUser,
    isMulti: true
  }
];

export default class BikeFilters extends React.Component {
  render() {
    return <FilterWrapper fields={fields} {...this.props} />;
  }
}
