import React from "react";
import { observer, inject } from "mobx-react";

import PageTable from "../../../components/tables/PageTable";
import AdminTableSection from "../../../components/AdminTableSection";
import AdminSite from "../../../components/AdminSite";
import PageHeading from "../../../components/PageHeading";
import ResourceCreateLinkButton from "../../../components/ResourceCreateLinkButton";

import { compose } from "../../../utils";
import { LOADER_TAG_ADMIN_PAGES } from "../../../constants";
class Pages extends React.Component {
  render() {
    return (
      <AdminSite>
        <PageHeading>Pages</PageHeading>
        <ResourceCreateLinkButton to="/admin/pages/new" label="New Page" />
        <AdminTableSection
          store={this.props.pageAdminStore}
          getter={store => store.pages.slice()}
          loadAction={store => store.loadAll}
          loaderTag={LOADER_TAG_ADMIN_PAGES}
          clearAction={store => store.clear}
          tableComponent={PageTable}
        />
      </AdminSite>
    );
  }
}

export default compose(
  inject("pageAdminStore"),
  observer
)(Pages);
