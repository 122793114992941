import React, { Component } from "react";
import { Field, Label, Control } from "bloomer";

export default class CheckboxMultiFilterField extends Component {
  onCheckboxChange = event => {
    const { checked, value } = event.target;
    const without = this.props.value.filter(v => v !== value);
    this.props.onChange(checked ? [...without, value] : without);
  };

  render() {
    const { label, options, value: currentlyChecked } = this.props;

    return (
      <Field>
        <Label>{label}</Label>
        <Control>
          {options.map(option => (
            <span key={option}>
              <label style={{ display: "inline-block" }}>
                <input
                  type="checkbox"
                  onChange={this.onCheckboxChange}
                  value={option}
                  checked={currentlyChecked.some(value => value === option)}
                />
                &nbsp;
                {option}
              </label>
              &nbsp;&nbsp;
            </span>
          ))}
        </Control>
      </Field>
    );
  }
}
