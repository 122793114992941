import {
  BIKE_STATUS_AVAILABLE,
  BIKE_STATUS_DONATED,
  BIKE_STATUS_SOLD,
  BIKE_STATUS_MISSING,
  BIKE_STATUS_RETURNED
} from "../../../constants";
import {
  makeRecordValidator,
  makeFieldValidator,
  oneOf
} from "../../../validators";
import { ENUM_BIKE_STATUSES, ENUM_BIKE_CONDITIONS } from "../../../config";
import { emptyNonZero } from "../../../utils";

const onlyWithStatus = (
  statusesRequired = [],
  statusesOnlyAllowedWithButNotRequired = [],
  shouldLog
) => [
  (value, status) => {
    const isRequiredStatus = statusesRequired.includes(status);
    const isOnlyAllowedStatus = statusesOnlyAllowedWithButNotRequired.includes(
      status
    );

    const valueIsEmpty = Array.isArray(value)
      ? value.length === 0
      : emptyNonZero(value);

    if (isRequiredStatus && valueIsEmpty) {
      // NOT OK
      return `Required when status is ${status}`;
    } else if (!isRequiredStatus && !valueIsEmpty && !isOnlyAllowedStatus) {
      // NOT OK
      const allAllowedStatuses = [
        ...statusesRequired,
        ...statusesOnlyAllowedWithButNotRequired
      ];
      return allAllowedStatuses.length > 1
        ? `Status must be ${allAllowedStatuses.join(" or ")}`
        : `Status must be ${allAllowedStatuses[0]}`;
    }

    // ok
    return undefined;
  },
  "status"
];

const requiredWhenListingOnline = [
  (value, list_online) => {
    if (!list_online || (value && list_online)) {
      return undefined; // pass
    } else {
      return "Required when listing online";
    }
  },
  "list_online"
];

const fixedDataConditional = [
  (value, is_nofix, status) => {
    if (!is_nofix) {
      // nofix validator passed, now validate using onlyWithStatus
      return onlyWithStatus(
        [BIKE_STATUS_AVAILABLE, BIKE_STATUS_DONATED, BIKE_STATUS_SOLD],
        [BIKE_STATUS_MISSING, BIKE_STATUS_RETURNED]
      )[0](value, status);
    } else if (is_nofix && emptyNonZero(value)) {
      // pass (nofix is checked, and this field is blank as it should be)
      return undefined;
    } else {
      // NOT OK (nofix is checked, but a value was also supplied for this field)
      return "Not allowed when no fix needed is checked";
    }
  },
  "is_nofix",
  "status"
];

const donatingOrganizationAcquisition = [
  (value, acquisition) => {
    if (!acquisition) {
      // acquisition hasn't been chosen yet
      return undefined;
    }

    const acquisitionNameLowercase = (acquisition.name || "").toLowerCase();

    if (acquisitionNameLowercase === "organization") {
      // require organization
      return !value && "Required when acquisition is organization";
    } else if (acquisitionNameLowercase === "rack cut") {
      // allow organization
      return undefined;
    } else {
      // disallow organization (if individual)
      return value && "Not allowed when acquisition is individual";
    }
  },
  "acquisition"
];

const bikeValidator = makeRecordValidator(
  makeFieldValidator("status", "required", oneOf(ENUM_BIKE_STATUSES)),
  makeFieldValidator("condition", "required", oneOf(ENUM_BIKE_CONDITIONS)),
  makeFieldValidator(
    "list_online",
    onlyWithStatus(undefined, [BIKE_STATUS_AVAILABLE])
  ),

  makeFieldValidator("received_at", "required", "date"),
  makeFieldValidator("fixed_at", fixedDataConditional, "date", [
    "isDateAfter",
    "received_at"
  ]),
  makeFieldValidator(
    "sold_at",
    onlyWithStatus([BIKE_STATUS_SOLD, BIKE_STATUS_DONATED]),
    "date",
    ["isDateAfter", "received_at", "fixed_at"]
  ),

  makeFieldValidator(
    "fix_cost",
    fixedDataConditional,
    "int",
    "minZero",
    "maxMysqlInt"
  ),
  makeFieldValidator(
    "price_asking",
    "int",
    "minZero",
    "maxMysqlInt",
    requiredWhenListingOnline
  ),
  makeFieldValidator(
    "price_sold",
    onlyWithStatus([BIKE_STATUS_SOLD, BIKE_STATUS_DONATED]),
    "int",
    "minZero",
    "maxMysqlInt"
  ),

  makeFieldValidator("notes_admin"), // none
  makeFieldValidator("notes_bike"), // none
  makeFieldValidator("notes_sale"), // none
  makeFieldValidator("notes_public"), // none

  makeFieldValidator("acquisition", "required"),
  makeFieldValidator("donoremail", "email"), // none
  makeFieldValidator("recipient"), // none
  makeFieldValidator("demographicset"), // none (on object)
  makeFieldValidator("demographicset.email", "email"),
  makeFieldValidator("demographicset.birth_date", "date"),
  makeFieldValidator("receivingevent"), // none
  makeFieldValidator(
    "donatingevent",
    onlyWithStatus(undefined, [BIKE_STATUS_DONATED])
  ),
  makeFieldValidator("organization_donating", donatingOrganizationAcquisition),
  makeFieldValidator(
    "organization_receiving",
    onlyWithStatus(undefined, [BIKE_STATUS_DONATED])
  ),

  makeFieldValidator("bmake", "required"),
  makeFieldValidator("bmodel", "required"),
  makeFieldValidator("bstyle", "required"),
  makeFieldValidator("bsize", "required"),
  makeFieldValidator("bcolors", "required")
  // bikeparts -- rely on backend for now
);

export default bikeValidator;
