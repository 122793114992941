import React from "react";
import Helmet from "react-helmet";

export default function DescriptionSEO({ description }) {
  return (
    <Helmet>
      {description && <meta property="og:description" content={description} />}
      {description && <meta name="description" content={description} />}
      {description && <meta name="twitter:description" content={description} />}
    </Helmet>
  );
}
