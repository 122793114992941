import React from "react";
import { Columns, Column } from "bloomer";

import StationSite from "../../../components/StationSite";

import Cip2w from "../common/Cip2w";
import RfidListen from "../common/RfidListen";

import Leaderboard from "./Leaderboard";
import CurrentlyCheckedIn from "./CurrentlyCheckedIn";
import BigLinks from "./BigLinks";

class Dashboard extends React.Component {
  render() {
    return (
      <StationSite>
        <RfidListen
          onSuccess={userId => {
            this.props.history.push(
              `/station/check-in?forcedUserId=${userId}`,
              { wasRedirect: true }
            );
          }}
        />
        <Columns>
          <Column isSize="2/3">
            <BigLinks />
          </Column>
          <Column isSize="1/3">
            <CurrentlyCheckedIn />
            <Cip2w component={Leaderboard} />
          </Column>
        </Columns>
      </StationSite>
    );
  }
}

export default Dashboard;
