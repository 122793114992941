import React from "react";

export default class YearSelector extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      year:
        this.props.years && this.props.years.length
          ? Math.max(...this.props.years)
          : null
    };
  }

  onSelectYear = e => {
    const year = e.target.value;
    this.setState({ year });
  };

  componentDidUpdate(prevProps) {
    if (
      JSON.stringify(prevProps.years) !== JSON.stringify(this.props.years) &&
      (!this.props.years ||
        !this.props.years.length ||
        !this.props.years.includes(this.state.year))
    ) {
      this.setState({
        year:
          !this.props.years || !this.props.years.length
            ? null
            : Math.max(...this.props.years)
      });
    }
  }

  render() {
    const { years, renderChart } = this.props;

    const selector = (
      <div
        className="select is-small is-rounded"
        style={{ verticalAlign: "middle" }}
      >
        <select onChange={this.onSelectYear} value={this.state.year}>
          {years.sort().map(year => (
            <option key={year} value={year}>
              {year}
            </option>
          ))}
        </select>
      </div>
    );

    return renderChart(this.state.year, selector);
  }
}
