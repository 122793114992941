import React, { Component } from "react";

import { standardFinalFormSubscriptions } from "../../config";
import { makeRecordValidator, makeFieldValidator } from "../../validators";
import TextFieldWithErrors from "../../components/fields/TextFieldWithErrors";
import CustomFinalForm from "../../components/CustomFinalForm";
import FinalSubmit from "../../components/fields/FinalSubmit";
import SelectFieldWithErrors from "../../components/fields/SelectFieldWithErrors";
import { SHORT_STATES, SHIPPABLE_COUNTRIES } from "../../config";

const validate = makeRecordValidator(
  makeFieldValidator("name", "required"),
  makeFieldValidator("line_1", "required"),
  makeFieldValidator("line_2"),
  makeFieldValidator("city", "required"),
  makeFieldValidator("state", "required"),
  makeFieldValidator("postal", "required"),
  makeFieldValidator("country", "required"),
  makeFieldValidator("phone", "required")
);

export default class AddressForm extends Component {
  onSubmit = fields => {
    this.props.onSubmit(fields);
  };

  render() {
    return (
      <div>
        <CustomFinalForm
          onSubmit={this.onSubmit}
          validate={validate}
          subscription={standardFinalFormSubscriptions}
          initialValues={{
            name: null,
            line_1: null,
            line_2: null,
            city: null,
            state: null,
            postal: null,
            country: null,
            phone: null
          }}
          render={renderProps => {
            const { handleSubmit } = renderProps;
            return (
              <form onSubmit={handleSubmit}>
                <TextFieldWithErrors type="text" name="name" label="Name" />
                <TextFieldWithErrors
                  type="text"
                  name="line_1"
                  label="Address Line 1"
                />
                <TextFieldWithErrors
                  type="text"
                  name="line_2"
                  label="Address Line 2"
                />
                <TextFieldWithErrors type="text" name="city" label="City" />
                <SelectFieldWithErrors name="state" label="State">
                  <option value={""}>&nbsp;</option>
                  {SHORT_STATES.map(state => (
                    <option key={state} value={state}>
                      {state}
                    </option>
                  ))}
                </SelectFieldWithErrors>
                <TextFieldWithErrors
                  type="text"
                  name="postal"
                  label="Postal Code"
                />
                <SelectFieldWithErrors name="country" label="Country">
                  <option value={""}>&nbsp;</option>
                  {SHIPPABLE_COUNTRIES.map(country => (
                    <option key={country} value={country}>
                      {country}
                    </option>
                  ))}
                </SelectFieldWithErrors>
                <TextFieldWithErrors
                  type="text"
                  name="phone"
                  label="Phone Number"
                />
                <FinalSubmit {...renderProps} label="Next" isSize="large" />
              </form>
            );
          }}
        />
      </div>
    );
  }
}
