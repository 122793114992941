import React, { Component } from "react";

import PlainInfoParagraph from "./PlainInfoParagraph";
import SimpleInlineSpinner from "./SimpleInlineSpinner";

export default class LoadingText extends Component {
  render() {
    const {
      cond = true,
      isCentered = null,
      isLeft = false,
      noMargin = false
    } = this.props;

    if (!cond) {
      return null;
    }

    return (
      <PlainInfoParagraph
        isCentered={isCentered !== null ? isCentered : !isLeft}
        style={{ marginBottom: noMargin && 0 }}
      >
        <SimpleInlineSpinner />
        <i>Loading...</i>
      </PlainInfoParagraph>
    );
  }
}
