import React, { Fragment } from "react";
import { observer, inject } from "mobx-react";

import { compose, currentIdIfChanged, idFromProps } from "../../../utils";
import {
  LOADER_TAG_ADMIN_ORDERS,
  NO_CHANGE,
  ADMIN_ORDER_ALERT_GROUP
} from "../../../constants";
import { parseMomentUTC, formatMomentLocalLLL } from "../../../datetime";

import AdminSite from "../../../components/AdminSite";
import LoadingText from "../../../components/LoadingText";
import PageHeading from "../../../components/PageHeading";
import Breadcrumbs from "../../../components/Breadcrumbs";
import OrderCard from "../../../components/OrderCard";
import { cellUser } from "../../../components/tables/helpers";
import Shipper from "./Shipper";

/**
 * Order detail fields:
 * - id
 *
 * - user
 *
 * - square_order_id
 * - square_transaction_id
 *
 * - shipment
 * - shipment_price
 * - shipment_cost
 * - shipment_tracking
 * - shippingservice
 * - tracking_link (computed backend)
 *
 * - created_at
 * - updated_at
 *
 * - total
 * - cart w/ items
 */
class OrderViewer extends React.Component {
  componentDidMount() {
    this.loadOrder();
  }

  componentDidUpdate(prevProps) {
    if (currentIdIfChanged(this.props, prevProps) !== NO_CHANGE) {
      this.loadOrder();
    }
  }

  loadOrder = () => {
    this.props.orderAdminStore
      .show(idFromProps(this.props), {
        loaderTag: LOADER_TAG_ADMIN_ORDERS,
        alertGroup: ADMIN_ORDER_ALERT_GROUP
      })
      .catch(() => {
        this.props.history.push("/admin/orders", { wasRedirect: true });
      });
  };

  get loading() {
    return this.props.uiStore.loadingByTag(LOADER_TAG_ADMIN_ORDERS);
  }

  onShip = () => {
    this.loadOrder();
  };

  render() {
    const { order } = this.props.orderAdminStore;
    const idParam = idFromProps(this.props);

    return (
      <AdminSite>
        <LoadingText cond={this.loading} isLeft />
        {order && (
          <Fragment>
            <Breadcrumbs
              items={[
                { to: "/admin", label: "Admin" },
                { to: "/admin/orders", label: "Orders" },
                {
                  to: `/admin/orders/${idParam}`,
                  label: `Order #${idParam}`
                }
              ]}
            />
            <PageHeading>Order #{order.id}</PageHeading>
            {order.shipment === "unmarked" && (
              <Fragment>
                <Shipper order={order} onShip={this.onShip} />
              </Fragment>
            )}
            <table className="table is-bordered">
              <tbody>
                <tr>
                  <td>
                    <strong>Placed At</strong>
                  </td>
                  <td>
                    {formatMomentLocalLLL(parseMomentUTC(order.created_at))}
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>User</strong>
                  </td>
                  <td>
                    {React.createElement(cellUser, { value: order.user })}
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Square Order Id</strong>
                  </td>
                  <td>
                    <small>{order.square_order_id}</small>
                  </td>
                </tr>
              </tbody>
            </table>
            <OrderCard order={order} showDetailsLink={false} />
          </Fragment>
        )}
      </AdminSite>
    );
  }
}

export default compose(
  inject("orderAdminStore", "uiStore"),
  observer
)(OrderViewer);
