import React from "react";
import { observer, inject } from "mobx-react";
import _startsWith from "lodash.startswith";

import PageHeading from "../../components/PageHeading";
import NarrowCenter from "../../components/NarrowCenter";
import AdminSite from "../../components/AdminSite";
import StationSite from "../../components/StationSite";

import { compose } from "../../utils";
import roleCheck from "../../roleCheck";
import { ROLE_ADMIN, ROLE_STATION } from "../../constants";

class NotFound extends React.Component {
  render() {
    const { location, authStore } = this.props;

    const { pathname } = location;
    let Wrapper;
    if (
      _startsWith(pathname, "/admin") &&
      authStore.isAuthenticated &&
      roleCheck(authStore.user, ROLE_ADMIN)
    ) {
      Wrapper = AdminSite;
    } else if (
      _startsWith(pathname, "/station") &&
      authStore.isAuthenticated &&
      roleCheck(authStore.user, ROLE_STATION)
    ) {
      Wrapper = StationSite;
    } else {
      Wrapper = NarrowCenter;
    }

    return (
      <Wrapper>
        <PageHeading>Page Not Found</PageHeading>
        <p>That page doesn't exist anymore.</p>
      </Wrapper>
    );
  }
}

export default compose(
  inject("authStore"),
  observer
)(NotFound);
