/**
 * Admin nav item config
 */
const items = [
  { to: "/admin/reports", label: "Reports" },
  { to: "/admin/fields", label: "Field Editor" },
  { to: "/admin/users", label: "Users" },
  { to: "/admin/memberships", label: "Memberships" },
  { to: "/admin/rentals", label: "Rentals" },
  { to: "/admin/shopvisits", label: "Shop Visits" },
  { to: "/admin/items", label: "Items" },
  { to: "/admin/orders", label: "Orders" },
  { to: "/admin/pages", label: "Pages" },
  { to: "/admin/bikes", label: "Bikes" },
  { to: "/admin/recdons", label: "R/D Events" },
  { to: "/admin/bikeparts", label: "Parts" }
];
export default items;
