import React from "react";

import PublicSite from "./PublicSite";
import NarrowCenterInner from "./NarrowCenterInner";

const NarrowCenter = ({ children }) => {
  return (
    <PublicSite>
      <NarrowCenterInner>{children}</NarrowCenterInner>
    </PublicSite>
  );
};

export default NarrowCenter;
