import React from "react";
import { observer, inject } from "mobx-react";
import { Title } from "bloomer";

import { compose } from "../../../utils";
import { LOADER_TAG_ACCOUNT_ORDERS } from "../../../constants";

import OrderCard from "../../../components/OrderCard";

import Wrapper from "./common/Wrapper";
import LoadingText from "../../../components/LoadingText";

class _OrdersInner extends React.Component {
  get store() {
    return this.props.orderOwnStore;
  }

  get loading() {
    return this.props.uiStore.loadingByTag(LOADER_TAG_ACCOUNT_ORDERS);
  }

  componentDidMount() {
    this.store.loadAllOrders();
  }

  componentWillUnmount() {
    this.store.clearAll();
  }

  render() {
    const { orders } = this.store;

    return (
      <div>
        <Title isSize={3}>My Orders</Title>
        {this.loading ? (
          <LoadingText />
        ) : orders.length ? (
          orders.map(order => <OrderCard key={order.id} order={order} />)
        ) : (
          <p>You haven't placed any orders yet.</p>
        )}
      </div>
    );
  }
}

const OrdersInner = compose(
  inject("uiStore", "orderOwnStore"),
  observer
)(_OrdersInner);

export default class Orders extends React.Component {
  render() {
    return <Wrapper>{() => <OrdersInner />}</Wrapper>;
  }
}
