import React, { PureComponent } from "react";
import { Message, MessageHeader, MessageBody } from "bloomer";

export default class BColorWarningMessage extends PureComponent {
  render() {
    return (
      <Message isColor="warning">
        <MessageHeader>
          <p>Editing Bike Colors</p>
        </MessageHeader>
        <MessageBody>
          Most of these bike color values are <strong>hard-coded</strong> into
          the codebase, allowing them to be displayed on labels with a matching
          background color. It's recommend that you only <i>create</i> new bike
          colors and not edit the existing values. If you want to edit a bike
          color, or want to add styling for a new color, please ask Andrew.
        </MessageBody>
      </Message>
    );
  }
}
