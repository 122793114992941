import React from "react";

import MediumCenterInner from "../MediumCenterInner";
import Markdown from "../Markdown";
import TitleHelmet from "../seo/TitleHelmet";
import DescriptionSEO from "../seo/DescriptionSEO";
import PageHeading from "../PageHeading";
import PublicSite from "../PublicSite";
import FullWidthImage from "../FullWidthImage";
import DonateBikesDumbHoursMessage from "../DonateBikesDumbHoursMessage";

// (actually using medium center now)

const NarrowCenterTemplate = ({ page }) => {
  const { title, description, content } = page;
  const { body, header_image } = content;

  return (
    <PublicSite hasPaddingTop={!header_image}>
      <DescriptionSEO description={description} />
      {header_image && <FullWidthImage src={header_image} />}
      <MediumCenterInner>
        {/* Title overrides title in BaseRoute */}
        <TitleHelmet title={title} />

        <PageHeading>{title}</PageHeading>
        {body.includes("$INCLUDE_DUMB_DONORS") && (
          <DonateBikesDumbHoursMessage />
        )}
        <Markdown
          source={body.replace("$INCLUDE_DUMB_DONORS", "")}
          isSize="medium"
        />
      </MediumCenterInner>
    </PublicSite>
  );
};

export default NarrowCenterTemplate;
