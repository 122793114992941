import React from "react";
import { Field } from "react-final-form";
import { Field as FieldStyle, Label, Control, Input, Help } from "bloomer";

import { deriveFieldError } from "../../utils";

const Textarea = ({ isColor = undefined, ...restProps }) => (
  <textarea
    className={`textarea ${isColor ? `is-${isColor}` : ""}`}
    {...restProps}
  />
);

/**
 * Helper for react-final-form
 *
 * extraHelp can either be:
 * - string/element (as child of Help)
 * - props object that includes `children` (of Help (bloomer) props)
 */
class TextFieldWithErrors extends React.PureComponent {
  render() {
    const {
      autoFocus,
      disabled,
      name,
      label,
      type = "text",
      placeholder,
      isTextarea,
      rows,
      extraHelp = null
    } = this.props;
    return (
      <Field
        name={name}
        subscription={{
          value: true,
          error: true,
          submitError: true,
          touched: true,
          dirtySinceLastSubmit: true
        }}
      >
        {({ input, meta }) => {
          const derivedError = deriveFieldError(meta);
          return (
            <FieldStyle>
              <Label>{label}</Label>
              <Control>
                {React.createElement(isTextarea ? Textarea : Input, {
                  autoFocus,
                  disabled,
                  ...input,
                  type,
                  placeholder,
                  rows,
                  isColor: derivedError ? "danger" : undefined
                })}
              </Control>
              {extraHelp &&
                (React.isValidElement(extraHelp) ||
                typeof extraHelp === "string" ? (
                  <Help children={extraHelp} />
                ) : (
                  <Help {...extraHelp} />
                ))}
              {derivedError && <Help isColor="danger">{derivedError}</Help>}
            </FieldStyle>
          );
        }}
      </Field>
    );
  }
}

export default TextFieldWithErrors;
