import React from "react";
import { decorate, computed } from "mobx";
import { observer, inject } from "mobx-react";
import {
  Select,
  Field,
  Control,
  Label,
  Button,
  Input,
  Help,
  Title
} from "bloomer";

import { compose } from "../../../utils";
import { FIELD_EDITOR } from "../../../config";
import {
  LOADER_TAG_ADMIN_FIELDS,
  FIELD_EDITOR_ACTION_UPDATE,
  FIELD_EDITOR_ACTION_DELETE,
  FIELD_EDITOR_ACTION_CREATE
} from "../../../constants";

import AdminSite from "../../../components/AdminSite";
import PageHeading from "../../../components/PageHeading";

import styles from "./field-editor.module.scss";
import BColorWarningMessage from "./BColorWarningMessage";

const selectOptions = Object.keys(FIELD_EDITOR).map(fieldName => {
  const fieldConfig = FIELD_EDITOR[fieldName];
  return (
    <option key={fieldName} value={fieldName}>
      {fieldConfig.title}
    </option>
  );
});

const ItemCreator = ({ disabled, value, onChange, onSave }) => (
  <Field hasAddons>
    <Control>
      <Input
        value={value || ""}
        onChange={e => onChange(e.target.value || null)}
        disabled={disabled}
        placeholder="New Item"
      />
    </Control>
    <Control>
      <Button isColor="success" onClick={onSave} disabled={disabled || !value}>
        Create
      </Button>
    </Control>
  </Field>
);

const ItemEditor = ({
  disabled,
  errorMessage,
  name,
  onEdit,
  onSave,
  onCancel
}) => (
  <div className={styles.editorWrapper}>
    <Field hasAddons>
      <Control>
        <Input value={name} onChange={onEdit} disabled={disabled} />
      </Control>
      <Control>
        <Button isColor="success" onClick={onSave} disabled={disabled}>
          Save
        </Button>
      </Control>
      <Control>
        <Button isColor="light" onClick={onCancel} disabled={disabled}>
          Cancel
        </Button>
      </Control>
    </Field>
    {errorMessage && <Help isColor="danger">{errorMessage}</Help>}
  </div>
);

const ItemRemover = ({ disabled, name, onRemove, onCancel }) => (
  <span>
    Are you sure you want to delete {`'${name}'`}
    ?&nbsp;
    <div className="buttons" style={{ display: "inline-block" }}>
      <Button
        isColor="danger"
        style={{ verticalAlign: "middle" }}
        onClick={onRemove}
        disabled={disabled}
      >
        Confirm Delete
      </Button>
      <Button
        isColor="light"
        style={{ verticalAlign: "middle" }}
        onClick={onCancel}
        disabled={disabled}
      >
        Cancel
      </Button>
    </div>
  </span>
);

/**
 * Field editor -- [c]r[u][d] resources that have only a 'name'
 * Have a select box. No default.
 * When selected, the editor and store will reset completely for the particular field.
 * Create/update/delete abilities for each field can be specified in the config file.
 */
class FieldEditor extends React.Component {
  componentWillUnmount() {
    this.props.fieldEditorAdminStore.resetAll();
  }

  get loading() {
    return this.props.uiStore.loadingByTag(LOADER_TAG_ADMIN_FIELDS);
  }

  onSelectField = e => {
    if (!this.loading) {
      this.props.fieldEditorAdminStore.setField(e.target.value || null);
    }
  };

  startEditing = id => {
    if (!this.loading) {
      this.props.fieldEditorAdminStore.startEditing(id);
    }
  };

  onEdit = e => {
    if (!this.loading) {
      this.props.fieldEditorAdminStore.edit(e.target.value);
    }
  };

  save = () => {
    if (!this.loading) {
      this.props.fieldEditorAdminStore.save();
    }
  };

  cancel = () => {
    if (!this.loading) {
      this.props.fieldEditorAdminStore.resetEditing();
      this.props.fieldEditorAdminStore.resetRemoving();
    }
  };

  startRemoving = id => {
    if (!this.loading) {
      this.props.fieldEditorAdminStore.startRemoving(id);
    }
  };

  remove = () => {
    if (!this.loading) {
      this.props.fieldEditorAdminStore.remove();
    }
  };

  get list() {
    const {
      editing,
      removing,
      field,
      results,
      errorMessage
    } = this.props.fieldEditorAdminStore;

    const loading = this.loading;

    if (!results.length) {
      return loading ? "Loading list..." : "None yet!";
    }

    return results.map(result => {
      const fieldConfig = FIELD_EDITOR[field];

      const isRemovingThis = removing && removing.id === result.id;
      if (isRemovingThis) {
        return (
          <li key={result.id}>
            <ItemRemover
              disabled={loading}
              name={removing.name}
              onRemove={this.remove}
              onCancel={this.cancel}
            />
          </li>
        );
      }

      const isEditingThis = editing && editing.id === result.id;
      return (
        <li key={result.id}>
          {isEditingThis ? (
            <ItemEditor
              disabled={loading}
              errorMessage={errorMessage}
              name={editing.name}
              onEdit={this.onEdit}
              onSave={this.save}
              onCancel={this.cancel}
            />
          ) : (
            <span>
              {result.name}
              &nbsp;
              <div className="buttons" style={{ display: "inline-block" }}>
                {fieldConfig.can.includes(FIELD_EDITOR_ACTION_UPDATE) && (
                  <Button
                    isSize="small"
                    isOutlined
                    isColor="info"
                    style={{ verticalAlign: "middle" }}
                    onClick={() => this.startEditing(result.id)}
                    disabled={loading}
                  >
                    Edit
                  </Button>
                )}
                {fieldConfig.can.includes(FIELD_EDITOR_ACTION_DELETE) && (
                  <Button
                    isSize="small"
                    isOutlined
                    isColor="danger"
                    style={{ verticalAlign: "middle" }}
                    onClick={() => this.startRemoving(result.id)}
                    disabled={loading}
                  >
                    Delete
                  </Button>
                )}
              </div>
            </span>
          )}
        </li>
      );
    });
  }

  get creator() {
    const {
      creating,
      createOnChange,
      createSave,
      field
    } = this.props.fieldEditorAdminStore;
    const fieldConfig = FIELD_EDITOR[field];
    if (!fieldConfig.can.includes(FIELD_EDITOR_ACTION_CREATE)) {
      return null;
    }
    return (
      <ItemCreator
        disabled={this.loading}
        value={creating}
        onChange={createOnChange}
        onSave={createSave}
      />
    );
  }

  render() {
    const { field } = this.props.fieldEditorAdminStore;
    const loading = this.loading;

    return (
      <AdminSite>
        <PageHeading>Field Editor</PageHeading>
        <Field>
          <Label>Field to Edit</Label>
          <Control>
            <Select
              onChange={this.onSelectField}
              value={field || ""}
              disabled={loading}
            >
              <option value={null} />
              {selectOptions}
            </Select>
          </Control>
        </Field>
        {field && (
          <div style={{ marginTop: "1.5rem" }}>
            <Title isSize="4">{FIELD_EDITOR[field].title}</Title>
            {field === "bcolor" && <BColorWarningMessage />}
            {this.creator}
            {<ul className={styles.list}>{this.list}</ul>}
          </div>
        )}
      </AdminSite>
    );
  }
}

decorate(FieldEditor, {
  saving: computed
});

export default compose(
  inject("uiStore", "fieldEditorAdminStore"),
  observer
)(FieldEditor);
