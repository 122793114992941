import React from "react";
import { isObservableArray } from "mobx";
import { Field } from "react-final-form";
import { Field as FieldStyle, Label, Control, Help } from "bloomer";
import { mapReactSelectValueToField, deriveFieldError } from "../../utils";

/**
 * Helper for react-final-form
 */
class FancySelectFieldWithErrors extends React.PureComponent {
  onChange = ({ onChange: realOnChange, isMulti }) => value => {
    if (!value || value.length === 0) {
      // empty array, null, or undefined
      realOnChange(isMulti ? [] : null);
    } else if (Array.isArray(value) || isObservableArray(value)) {
      // this is a multiselect
      realOnChange(value.map(mapReactSelectValueToField));
    } else {
      // single-value
      realOnChange(mapReactSelectValueToField(value));
    }
  };

  render() {
    const { name, label, component, componentProps } = this.props;
    return (
      <Field
        name={name}
        subscription={{
          value: true,
          error: true,
          submitError: true,
          touched: true,
          dirtySinceLastSubmit: true
        }}
      >
        {({ input, meta }) => {
          const derivedError = deriveFieldError(meta);
          return (
            <FieldStyle>
              <Label>{label}</Label>
              <Control>
                {React.createElement(component, {
                  ...input,
                  onChange: this.onChange(input),
                  ...componentProps
                })}
              </Control>
              {derivedError && <Help isColor="danger">{derivedError}</Help>}
            </FieldStyle>
          );
        }}
      </Field>
    );
  }
}

export default FancySelectFieldWithErrors;
