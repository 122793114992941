import React from "react";

import FilterWrapper from "./support/FilterWrapper";
import { ENUM_SHIPMENT_STATUSES } from "../../config";

import SelectUser from "../../components/selects/throttled/SelectUser";
import SelectShippingService from "../selects/immediate/SelectShippingService";

const fields = [
  {
    name: "user",
    label: "User",
    type: "fancy-select",
    component: SelectUser,
    isMulti: true // for SelectUser
  },
  {
    name: "square_order_id",
    label: "Square Order Id",
    type: "text"
  },
  {
    name: "square_transaction_id",
    label: "Square Transaction Id",
    type: "text"
  },
  {
    name: "shipments",
    label: "Shipment Status",
    type: "checkbox-multi",
    options: ENUM_SHIPMENT_STATUSES
  },
  {
    name: "shipment_tracking",
    label: "Shipment Tracking",
    type: "text"
  },
  {
    name: "shippingservice",
    label: "Shipping Service",
    type: "fancy-select",
    component: SelectShippingService,
    isMulti: true // for SelectShippingService
  }
];

export default class OrderFilters extends React.Component {
  render() {
    return <FilterWrapper fields={fields} {...this.props} />;
  }
}
