import { inject, observer } from "mobx-react";
import { BrowserRouter } from "react-router-dom";
import { compose } from "../utils";

/**
 * This is a custom BrowserRouter that subscribes to history
 * and keeps uiStore alerts in check.
 */
class CustomBrowserRouter extends BrowserRouter {
  constructor(props) {
    super(props);
    this.history.listen(location => {
      // Don't clear if this was a redirect (set by CustomRedirect)
      if (!location.state || !location.state.wasRedirect) {
        // Clear alerts
        this.props.uiStore.clearAlertsAfterRouteChange();
      }
    });
  }
}

export default compose(
  inject("uiStore"),
  observer
)(CustomBrowserRouter);
