import React from "react";
import { observer, inject } from "mobx-react";
import { Field as FieldStyle, Control, Select, Label } from "bloomer";
import _pick from "lodash.pick";

import AdminSite from "../../../components/AdminSite";

import agent from "../../../agent";
import { compose, idFromProps } from "../../../utils";
import { LOADER_TAG_ADMIN_PAGES } from "../../../constants";
import GenericEditor from "../../../components/GenericEditor";
import GenericResourceEditorStore from "../../../stores/special/GenericResourceEditorStore";
import {
  makeRecordValidator,
  makeFieldValidator,
  minLength,
  maxLength
} from "../../../validators";
import TextFieldWithErrors from "../../../components/fields/TextFieldWithErrors";
import templateConfig from "./templateConfig";
import TemplateStore from "../../../stores/special/TemplateStore";
import Breadcrumbs from "../../../components/Breadcrumbs";

const initialFormValuesFromPage = page => {
  return _pick(page, ["title", "description", "content"]);
};

const newStructure = {
  title: null,
  description: null,
  content: null
};

const templateStore = new TemplateStore();

const pageEditorStore = new GenericResourceEditorStore(
  "page",
  LOADER_TAG_ADMIN_PAGES,
  "page-editor",
  "page",
  agent.AdminPages.show,
  agent.AdminPages.create,
  agent.AdminPages.update,
  agent.AdminPages.remove,
  newStructure,
  fields => {
    // insert template
    return { template: templateStore.template, ...fields };
  },
  "Page saved successfully!",
  "Page deleted successfully!"
);

const validate = makeRecordValidator(
  makeFieldValidator("title", "required"),
  makeFieldValidator("description", minLength(50), maxLength(300))
  // content validation is handled by backend
);

const templateSelectOptions = Object.keys(templateConfig).map(name => ({
  value: name,
  label: templateConfig[name].title
}));

class PageCrud extends React.Component {
  get loading() {
    return this.props.uiStore.loadingByTag(LOADER_TAG_ADMIN_PAGES);
  }

  resetTemplateFromCurrentPage() {
    templateStore.setTemplate(pageEditorStore.resource.template);
  }

  onSelectTemplate = e => {
    const template = e.target.value || null;
    templateStore.setTemplate(template);
  };

  get editorConfig() {
    return templateConfig[templateStore.template] || null;
  }

  get templateEditor() {
    const ec = this.editorConfig;
    const component = ec && ec.templateEditor;
    if (component) {
      return React.createElement(component, {
        page: pageEditorStore.resource
      });
    } else {
      return <p>Please choose a valid template.</p>;
    }
  }

  render() {
    const idParam = idFromProps(this.props);
    const isNew = idParam === "new";

    return (
      <AdminSite>
        <Breadcrumbs
          items={[
            { to: "/admin", label: "Admin" },
            { to: "/admin/pages", label: "Pages" },
            {
              to: `/admin/pages/${isNew ? "new" : idParam}`,
              label: isNew ? "New Page" : `Page ${idParam}`
            }
          ]}
        />
        <FieldStyle>
          <Label>Template</Label>
          <Control>
            <Select
              onChange={this.onSelectTemplate}
              value={templateStore.template || ""}
              disabled={this.loading}
            >
              <option value="" />
              {templateSelectOptions.map(({ value, label }) => (
                <option key={value} value={value}>
                  {label}
                </option>
              ))}
            </Select>
          </Control>
        </FieldStyle>
        <GenericEditor
          name="page"
          nameForTitle="Page"
          basePath="/admin/pages"
          store={pageEditorStore}
          loaderTag={LOADER_TAG_ADMIN_PAGES}
          recordValidator={validate}
          initialFormValuesFn={initialFormValuesFromPage}
          afterReset={this.resetTemplateFromCurrentPage}
        >
          <TextFieldWithErrors name="title" label="Title" />
          <TextFieldWithErrors
            isTextarea
            name="description"
            label="SEO Description"
            rows="2"
          />
          {this.templateEditor}
        </GenericEditor>
      </AdminSite>
    );
  }
}

export default compose(
  inject("uiStore"),
  observer
)(PageCrud);
