import React from "react";
import { Columns, Column } from "bloomer";

const NarrowCenterInner = ({ children }) => {
  return (
    <Columns isCentered>
      <Column isSize={{ tablet: 10, widescreen: 6 }}>{children}</Column>
    </Columns>
  );
};

export default NarrowCenterInner;
