import React, { Component } from "react";
import { Button } from "bloomer";

export default class DeleteConfirm extends Component {
  state = { exposed: false };

  onSafetyClick = () => {
    this.setState({ exposed: true });
  };

  onCancelClick = () => {
    this.setState({ exposed: false });
  };

  onRealClick = e => {
    this.props.onClick(e);
  };

  render() {
    const { descriptor, name, isSize, disabled } = this.props;
    const { exposed } = this.state;

    let identifier = null;
    if (descriptor && name) {
      identifier = `${descriptor} '${name}'`;
    } else if (descriptor && !name) {
      identifier = `this ${descriptor}`;
    } else if (!descriptor && name) {
      identifier = `'${descriptor}'`;
    } else {
      identifier = `this?`;
    }

    if (exposed) {
      return (
        <span>
          Are you sure you want to delete {identifier}
          ?&nbsp;
          <Button
            onClick={this.onRealClick}
            isColor="danger"
            isSize={isSize}
            disabled={disabled}
            style={{ verticalAlign: "middle" }}
          >
            Confirm Delete
          </Button>
          &nbsp; &nbsp;
          <Button
            onClick={this.onCancelClick}
            isColor="light"
            isSize={isSize}
            disabled={disabled}
            style={{ verticalAlign: "middle" }}
          >
            Cancel
          </Button>
        </span>
      );
    }

    return (
      <span>
        <Button
          onClick={this.onSafetyClick}
          isColor="danger"
          isSize={isSize}
          disabled={disabled}
        >
          Delete
        </Button>
      </span>
    );
  }
}
