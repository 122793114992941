import React, { Component, Fragment } from "react";
import { observer, inject } from "mobx-react";
import { Button } from "bloomer";
import _get from "lodash.get";

import { compose } from "../../../utils";
import agent from "../../../agent";
import { LOADER_TAG_ADMIN_REPORTS_EMAIL_TOP_VOLUNTEERS } from "../../../constants";

const ADMIN_REPORTS_EMAIL_TOP_VOLUNTEERS_ALERT_GROUP =
  "reports.email-top-volunteers";

class EmailTopVolunteers extends Component {
  constructor(props) {
    super(props);

    this.state = {
      emailedNames: [],
      prepared: false,
      finished: false
    };
  }

  prepare = () => {
    this.setState({
      prepared: true
    });
  };

  start = () => {
    return agent.AdminReports.emailTopVolunteers(
      {},
      {
        loaderTag: LOADER_TAG_ADMIN_REPORTS_EMAIL_TOP_VOLUNTEERS,
        alertGroup: ADMIN_REPORTS_EMAIL_TOP_VOLUNTEERS_ALERT_GROUP
      }
    ).then(body => {
      this.props.uiStore.addAlert(
        "success",
        "Top volunteers were emailed successfully! See the list below.",
        { group: ADMIN_REPORTS_EMAIL_TOP_VOLUNTEERS_ALERT_GROUP }
      );

      const names = body.data;

      if (Array.isArray(names)) {
        this.setState({ emailedNames: names });
      }

      this.setState({ finished: true });
    });
  };

  get loading() {
    return this.props.uiStore.loadingByTag(
      LOADER_TAG_ADMIN_REPORTS_EMAIL_TOP_VOLUNTEERS
    );
  }

  render() {
    const { emailedNames, prepared, finished } = this.state;
    const { volunteersDeliverables } = this.props;

    const volunteersMaybe = _get(volunteersDeliverables, "data.top_volunteers");

    const preparedNamesMaybe =
      volunteersMaybe && volunteersMaybe.map(v => v.user.name);

    return (
      <Fragment>
        {!prepared ? (
          <p>
            <Button isColor="info" onClick={this.prepare}>
              Prepare to Send
            </Button>
          </p>
        ) : (
          <Fragment>
            <p>Really send? This will email the following volunteers:</p>
            <ul className="bulleted">
              {preparedNamesMaybe.map((name, i) => (
                <li key={`name-${i}`}>{name}</li>
              ))}
            </ul>
            <br />
            <p>
              <Button
                isColor="info"
                onClick={this.start}
                isLoading={this.loading}
                disabled={finished || this.loading}
              >
                Confirm Send
              </Button>
            </p>
          </Fragment>
        )}
        {emailedNames.length > 0 && (
          <Fragment>
            <p>Successfully emailed the following volunteers:</p>
            <ul className="bulleted">
              {emailedNames.map((name, i) => (
                <li key={`name-${i}`}>{name}</li>
              ))}
            </ul>
          </Fragment>
        )}
      </Fragment>
    );
  }
}

export default compose(inject("uiStore"), observer)(EmailTopVolunteers);
