import React from "react";
import { Redirect } from "react-router-dom";
import _set from "lodash.set";
import _get from "lodash.get";

/**
 * This is a wrapper around Redirect (from react-router) that:
 * - Sets the flag wasRedirect on the location state (if not already set).
 *   The flag is picked up by CustomBrowserRouter for alert logic.
 * - If supplied, will store previous path for use after successful login
 *
 * @param string|object to
 * @param string|object redirectAfterLogin
 */
const CustomRedirect = ({ to, redirectAfterLogin, ...restProps }) => {
  const toObject = typeof to === "object" ? to : { pathname: to };

  _set(toObject, ["state", "wasRedirect"], true);

  if (redirectAfterLogin && !_get(toObject, ["state", "redirectAfterLogin"])) {
    _set(toObject, ["state", "redirectAfterLogin"], redirectAfterLogin);
  }

  return <Redirect to={toObject} {...restProps} />;
};

export default CustomRedirect;
