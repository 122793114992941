import React from "react";
import { observable, decorate, action, runInAction, computed } from "mobx";
import { Link } from "react-router-dom";
import agent from "../agent";
import { LOADER_TAG_CART } from "../constants";
import uiStore from "./ui";

const cartAlertGroup = "cart";

export class CartStore {
  cart = null;

  get hasShippables() {
    return (
      this.cart &&
      this.cart.items &&
      this.cart.items.some(item => item.is_shippable)
    );
  }

  clear() {
    this.cart = null;
  }

  loadCart() {
    this.clear();

    return agent.Cart.show({ loaderTag: LOADER_TAG_CART })
      .then(this.setCartFromResponseBody)
      .catch(error => {
        this.clear();
        return Promise.reject(error); // re-throw
      });
  }

  add(item_id, quantity, variation) {
    return agent.Cart.add(
      { item_id, quantity, variation },
      {
        alertGroup: cartAlertGroup,
        loaderTag: LOADER_TAG_CART,
        notFoundName: "item"
      }
    )
      .then(this.setCartFromResponseBody)
      .then(() => {
        uiStore.addAlert(
          "success",
          <span>
            Item added to cart! <Link to="/cart">View Cart / Check out</Link>
          </span>,
          {
            group: cartAlertGroup
          }
        );
      });
  }

  update(item_id, quantity, variation) {
    return agent.Cart.update(
      { item_id, quantity, variation },
      {
        alertGroup: cartAlertGroup,
        loaderTag: LOADER_TAG_CART,
        notFoundName: "item"
      }
    )
      .then(this.setCartFromResponseBody)
      .then(() => {
        uiStore.addAlert("success", "Item quantity was updated successfully.", {
          group: cartAlertGroup
        });
      });
  }

  remove(item_id, variation) {
    return agent.Cart.remove(
      { item_id, variation },
      {
        alertGroup: cartAlertGroup,
        loaderTag: LOADER_TAG_CART,
        notFoundName: "item"
      }
    )
      .then(this.setCartFromResponseBody)
      .then(() => {
        uiStore.addAlert(
          "success",
          "Item was removed from your cart successfully.",
          { group: cartAlertGroup }
        );
      });
  }

  // helper action
  setCartFromResponseBody(body) {
    const cart = body.data;
    runInAction(() => {
      this.cart = cart;
    });
  }
}

decorate(CartStore, {
  cart: observable,
  hasShippables: computed,
  clear: action.bound,
  loadCart: action.bound,
  add: action.bound,
  update: action.bound,
  remove: action.bound,
  setCartFromResponseBody: action.bound
});

export default new CartStore();
