import React from "react";
import _get from "lodash.get";
import { dateTimeColumn } from "./helpers";
import LinkButton from "../LinkButton";

/**
 * Common column configs
 */

export const idColumn = {
  Header: "Id",
  accessor: "id",
  width: 70
};

export const createdAtColumn = {
  Header: "Created At",
  accessor: "created_at",
  ...dateTimeColumn
};

export const updatedAtColumn = {
  Header: "Updated At",
  accessor: "updated_at",
  ...dateTimeColumn
};

export const titleColumn = {
  Header: "Title",
  accessor: "title",
  width: 400
};

export const makeActionsColumn = (
  resourcePath,
  label = "Edit",
  width = 52,
  accessor = "id"
) => ({
  // Header: "Actions",
  Cell: ({ original }) => {
    const id = _get(original, accessor);
    if (!id) {
      return null;
    }
    return (
      <div>
        <LinkButton to={`${resourcePath}/${id}`} isColor="info" isSize="small">
          {label}
        </LinkButton>
      </div>
    );
  },
  width,
  sortable: false
});
