import React from "react";

import FilterWrapper from "./support/FilterWrapper";
import { ENUM_VISIT_TYPES } from "../../config";

const fields = [
  {
    name: "types",
    label: "Types",
    type: "checkbox-multi",
    options: ENUM_VISIT_TYPES
  },
  {
    name: "date",
    label: "Date",
    type: "date-range",
    fromRequired: true
  }
];

export default class ShopVisitFilters extends React.Component {
  render() {
    return <FilterWrapper fields={fields} {...this.props} />;
  }
}
