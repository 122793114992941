import React from "react";

import TextFieldWithErrors from "../../../../components/fields/TextFieldWithErrors";
import { Help } from "bloomer";

const NarrowCenterTemplateEditor = () => (
  <div>
    <TextFieldWithErrors
      type="text"
      name="content.header_image"
      label="Header Image URL"
    />
    <TextFieldWithErrors
      type="text"
      name="content.body"
      label="Content"
      isTextarea
      rows="15"
    />
    <Help>Accepts Markdown</Help>
  </div>
);

export default NarrowCenterTemplateEditor;
