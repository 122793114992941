import React from "react";
import { Columns, Column } from "bloomer";

const MediumCenterInner = ({ children }) => {
  return (
    <Columns isCentered>
      <Column isSize={{ tablet: 12, desktop: 10, widescreen: 8 }}>
        {children}
      </Column>
    </Columns>
  );
};

export default MediumCenterInner;
