import React, { Component } from "react";
import { Field, Label, Control, Input, Help } from "bloomer";

export default class IntegerRangeFilterField extends Component {
  onChange = (e, fromOrTo) => {
    const value = e.target.value;
    this.props.onChange(value || null, {
      filterNameOverride: `${this.props.name}_${fromOrTo}`,
      fromOrTo
    });
  };

  render() {
    const { label, error, value } = this.props;

    return (
      <div>
        <Field>
          <Label>{label}</Label>
          <Control>
            <Input
              onChange={e => this.onChange(e, "from")}
              value={value.from || ""}
              placeholder="From"
            />
          </Control>
        </Field>
        <Field>
          <Control>
            <Input
              onChange={e => this.onChange(e, "to")}
              value={value.to || ""}
              placeholder="To"
            />
          </Control>
        </Field>
        {error && <Help isColor="danger">{error}</Help>}
      </div>
    );
  }
}
