/**
 * ServerError
 */
class ServerError {
  constructor(message) {
    this.name = "ServerError";
    this.alertConfigFinal = { group: "server" };
    this.message = message;
    this.stack = new Error().stack; // Optional
  }
}
ServerError.prototype = Object.create(Error.prototype);

export default ServerError;
