import { Component } from "react";
import { scrollTop } from "../utils";

export default class ScrollToTopOnMount extends Component {
  componentDidMount() {
    scrollTop();
  }

  render() {
    return null;
  }
}
