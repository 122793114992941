import agent from "../agent";
import { mapSomeFieldsToId, makeBikePartsRequestData } from "../utils";
import GenericResourceEditorStore from "../stores/special/GenericResourceEditorStore";
import {
  LOADER_TAG_ADMIN_BIKES,
  BIKE_EDITOR_RECIPIENT_TYPE_USER
} from "../constants";
import { formatMomentLocalYMD, momentNowLocal } from "../datetime";

export const newStructure = {
  received_at: formatMomentLocalYMD(momentNowLocal()),
  fixed_at: null,
  sold_at: null,
  status: "new",
  condition: null,
  list_online: false,
  fix_cost: null,
  fix_time: null, // not editable
  is_nofix: false,
  price_asking: null,
  price_sold: null,
  notes_admin: null,
  notes_bike: null,
  notes_sale: null,
  notes_public: null,
  acquisition: null,
  donoremail: null,
  recipient: null,
  demographicset: null,
  receivingevent: null,
  donatingevent: null,
  organization_donating: null,
  organization_receiving: null,
  bmake: null,
  bmodel: null,
  bstyle: null,
  bsize: null,
  bcolors: null,
  bikeparts: []
};

export const preSave = fields => {
  const { recipientType, recipient, demographicset, ...restFields } = fields;

  const fieldsExclusiveRecipient =
    recipientType === BIKE_EDITOR_RECIPIENT_TYPE_USER
      ? { ...restFields, recipient, demographicset: null }
      : {
          ...restFields,
          demographicset: mapSomeFieldsToId(demographicset || {}, [
            "dneighborhood",
            "dincomerange",
            "dagerange",
            "dgender",
            "drace",
            "dnationality",
            "dresidence",
            "dhouseholdcount",
            "demploymentstatus",
            "dbikeuses"
          ]),
          recipient: null
        };

  const mapped = mapSomeFieldsToId(fieldsExclusiveRecipient, [
    "acquisition",
    "recipient",
    "receivingevent",
    "donatingevent",
    "organization_donating",
    "organization_receiving",
    "bmake",
    "bmodel",
    "bstyle",
    "bsize",
    "bcolors"
  ]);

  // Handle bike parts seperately
  mapped["bikeparts"] = makeBikePartsRequestData(mapped["bikeparts"]);

  return mapped;
};

const bikeEditorStore = new GenericResourceEditorStore(
  "bike",
  LOADER_TAG_ADMIN_BIKES,
  "bike-editor",
  "bike",
  agent.AdminBikes.show,
  agent.AdminBikes.create,
  agent.AdminBikes.update,
  agent.AdminBikes.remove,
  newStructure,
  preSave,
  "Bike saved successfully!",
  "Bike deleted successfully!"
);

export default bikeEditorStore;
