import React from "react";
import { observer, inject } from "mobx-react";
import { Title } from "bloomer";

import { compose } from "../../../utils";
import { LOADER_TAG_ACCOUNT_VISITS } from "../../../constants";

import Wrapper from "./common/Wrapper";
import ShopVisitTable from "../../../components/tables/ShopVisitTable";
import LoadingText from "../../../components/LoadingText";

class _VisitsInner extends React.Component {
  get store() {
    return this.props.shopVisitOwnStore;
  }

  get loading() {
    return this.props.uiStore.loadingByTag(LOADER_TAG_ACCOUNT_VISITS);
  }

  componentDidMount() {
    this.store.loadOwnVisits();
  }

  componentWillUnmount() {
    this.store.clear();
  }

  render() {
    const { visits } = this.store;

    return (
      <div>
        <Title isSize={3}>My Shop Visits</Title>
        {this.loading ? (
          <LoadingText />
        ) : visits.length ? (
          <ShopVisitTable showUserColumn={false} data={visits} />
        ) : (
          <p>You haven't visited the shop yet.</p>
        )}
      </div>
    );
  }
}

const VisitsInner = compose(
  inject("uiStore", "shopVisitOwnStore"),
  observer
)(_VisitsInner);

export default class Visits extends React.Component {
  render() {
    return <Wrapper>{() => <VisitsInner />}</Wrapper>;
  }
}
