import React from "react";
import { observer, inject } from "mobx-react";

import OrderTable from "../../../components/tables/OrderTable";
import OrderFilters from "../../../components/filters/OrderFilters";
import AdminTableSection from "../../../components/AdminTableSection";
import AdminSite from "../../../components/AdminSite";

import { compose } from "../../../utils";
import { LOADER_TAG_ADMIN_ORDERS } from "../../../constants";
import PageHeading from "../../../components/PageHeading";
import QuickResourceJump from "../../../components/QuickResourceJump";

class Orders extends React.Component {
  render() {
    return (
      <AdminSite>
        <PageHeading>Orders</PageHeading>
        <QuickResourceJump
          pathPrefix="/admin/orders"
          inputProps={{
            placeholder: "e.g. 1203"
          }}
        />
        <AdminTableSection
          store={this.props.orderAdminStore}
          getter={store => store.orders.slice()}
          loadAction={store => store.loadFiltered}
          loaderTag={LOADER_TAG_ADMIN_ORDERS}
          clearAction={store => store.clear}
          filterComponent={OrderFilters}
          tableComponent={OrderTable}
        />
      </AdminSite>
    );
  }
}

export default compose(
  inject("orderAdminStore"),
  observer
)(Orders);
