import React, { Component } from "react";
import { Title } from "bloomer";

import { standardFinalFormSubscriptions } from "../../../config";
import CustomFinalForm from "../../../components/CustomFinalForm";
import FancySelectFieldWithErrors from "../../../components/fields/FancySelectFieldWithErrors";
import SelectUser from "../../../components/selects/throttled/SelectUser";
import { makeRecordValidator, makeFieldValidator } from "../../../validators";
import TextFieldWithErrors from "../../../components/fields/TextFieldWithErrors";
import FinalSubmit from "../../../components/fields/FinalSubmit";
import formValidationErrorLayer from "../../../errors/formValidationErrorLayer";
import agent from "../../../agent";
import { LOADER_TAG_ADMIN_BIKE_WORK } from "../../../constants";
import { observer, inject } from "mobx-react";
import { compose } from "../../../utils";
import DurationFieldWithErrors from "../../../components/fields/DurationFieldWithErrors";

const validate = makeRecordValidator(
  makeFieldValidator("user", "required"),
  makeFieldValidator("minutes", "required", "int", "minZero", "maxMysqlInt"),
  makeFieldValidator("note", "required")
);

const WORK_ALERT_GROUP = "work";

class AddMechanic extends Component {
  get loading() {
    return this.props.uiStore.loadingByTag(LOADER_TAG_ADMIN_BIKE_WORK);
  }

  onSubmit = ({ user, minutes, note }) => {
    return formValidationErrorLayer(
      agent.AdminBikes.worked(
        {
          id: this.props.bike.id,
          user_id: user.id,
          minutes,
          note
        },
        {
          loaderTag: LOADER_TAG_ADMIN_BIKE_WORK,
          alertGroup: WORK_ALERT_GROUP,
          notFoundName: "bike or user" // not great, but unlikely anyways
        }
      ).then(() => {
        // success

        this.props.uiStore.addAlert(
          "success",
          "Bike work was recorded successfully.",
          { group: WORK_ALERT_GROUP }
        );

        // reset form
        this.resetForm();

        // trigger reload of bike in bikeEditorStore (for work history table refresh)
        this.props.triggerReload();
      })
    ).catch(() => null);
  };

  render() {
    return (
      <div>
        <Title isSize={4}>Add Mechanic</Title>
        <CustomFinalForm
          initialValues={{ user: null, minutes: null, note: null }}
          validate={validate}
          onSubmit={this.onSubmit}
          subscription={standardFinalFormSubscriptions}
          render={({ handleSubmit, ...renderProps }) => {
            this.resetForm = renderProps.form.reset;
            return (
              <form onSubmit={handleSubmit}>
                <FancySelectFieldWithErrors
                  name="user"
                  label="User"
                  component={SelectUser}
                />
                <DurationFieldWithErrors name="minutes" label="Time Spent" />
                <TextFieldWithErrors
                  isTextarea
                  type="text"
                  name="note"
                  label="Notes"
                  extraHelp="Describe the work they did, the parts they used, etc."
                />
                <FinalSubmit
                  {...renderProps}
                  loading={this.loading}
                  isSize="normal"
                />
              </form>
            );
          }}
        />
      </div>
    );
  }
}

export default compose(
  inject("uiStore"),
  observer
)(AddMechanic);
