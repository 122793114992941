import React, { Component } from "react";
import { Field, Label, Control, Input, Help } from "bloomer";

export default class TextFilterField extends Component {
  onChange = e => {
    const value = e.target.value;
    this.props.onChange(value || null); // cast empty string to null
  };

  render() {
    const { label, error, value } = this.props;

    return (
      <Field>
        <Label>{label}</Label>
        <Control>
          <Input onChange={this.onChange} value={value || ""} />
        </Control>
        {error && <Help isColor="danger">{error}</Help>}
      </Field>
    );
  }
}
