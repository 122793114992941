import React, { Component } from "react";
import { Field, Label, Control, Help } from "bloomer";
import Datepicker from "../../fields/Datepicker";

export default class DateRangeFilterField extends Component {
  onChange = (value, fromOrTo) => {
    this.props.onChange(value || null, {
      filterNameOverride: `${this.props.name}_${fromOrTo}`,
      fromOrTo
    });
  };

  render() {
    const { label, error, value } = this.props;

    return (
      <div>
        <Field>
          <Label>{label}</Label>
          <Control>
            <Datepicker
              onChange={value => this.onChange(value, "from")}
              value={value.from || ""}
              placeholder="From"
            />
          </Control>
        </Field>
        <Field>
          <Control>
            <Datepicker
              onChange={value => this.onChange(value, "to")}
              value={value.to || ""}
              placeholder="To"
            />
          </Control>
          {error && <Help isColor="danger">{error}</Help>}
        </Field>
      </div>
    );
  }
}
