import React, { Component } from "react";
import { Box, Button } from "bloomer";
import { observer, inject } from "mobx-react";

import { compose, makeBikePartsRequestData } from "../../../utils";
import agent from "../../../agent";
import StationSite from "../../../components/StationSite";
import MediumCenterInner from "../../../components/MediumCenterInner";
import CustomFinalForm from "../../../components/CustomFinalForm";
import TextFieldWithErrors from "../../../components/fields/TextFieldWithErrors";
import { makeRecordValidator, makeFieldValidator } from "../../../validators";
import formValidationErrorLayer from "../../../errors/formValidationErrorLayer";
import PageHeading from "../../../components/PageHeading";
import SearchUsers from "../common/SearchUsers";
import { LOADER_TAG_STATION_RECORD_WORK } from "../../../constants";
import { standardFinalFormSubscriptions } from "../../../config";
import FinalSubmit from "../../../components/fields/FinalSubmit";
import BikePartsField from "../../../components/fields/BikePartsField";
import DurationFieldWithErrors from "../../../components/fields/DurationFieldWithErrors";
import StationBikeDisplay from "../common/StationBikeDisplay";

/**
 * Station: Record work done on a bike
 *
 * Flow:
 * 1. select user (SelectUsers)
 * 2. enter bike id
 * 3a. Display bike details and history + form
 * 3b. Show form with minutes+notes fields, as well as bike part UI
 */

const WORK_ALERT_GROUP = "record-work";

const validateBike = makeRecordValidator(
  makeFieldValidator("bike_id", "required", "int", "minZero")
);

const validateWorked = makeRecordValidator(
  makeFieldValidator("minutes", "required", "int", "minZero", "maxMysqlInt"),
  makeFieldValidator("note", "required")
  // bikeparts -- rely on backend for now
);

class RecordWork extends Component {
  state = { selectedUser: null, selectedBike: null };

  onSubmitBike = ({ bike_id }) => {
    return formValidationErrorLayer(
      agent.StationBike.show(
        { id: bike_id },
        {
          loaderTag: LOADER_TAG_STATION_RECORD_WORK,
          alertGroup: WORK_ALERT_GROUP,
          notFoundName: "bike"
        }
      ).then(body => {
        // success
        const bike = body.data;
        this.setState({ selectedBike: bike });
      })
    ).catch(() => null);
  };

  onSubmitWorked = ({ minutes, note, bikeparts }) => {
    return formValidationErrorLayer(
      agent.StationBike.worked(
        {
          user_id: this.state.selectedUser.id,
          id: this.state.selectedBike.id,
          minutes,
          note,
          bikeparts: makeBikePartsRequestData(bikeparts)
        },
        {
          loaderTag: LOADER_TAG_STATION_RECORD_WORK,
          alertGroup: WORK_ALERT_GROUP,
          notFoundName: "bike or user" // not great, but unlikely anyways
        }
      ).then(() => {
        // success

        this.props.uiStore.addAlert(
          "success",
          "Bike work was recorded successfully. Thank you!",
          { group: WORK_ALERT_GROUP }
        );

        // reset wizard
        this.setState({ selectedUser: null, selectedBike: null });

        // redirect
        this.props.history.push("/station", { wasRedirect: true });
      })
    ).catch(() => null);
  };

  onSelectUser = (user, isCheckedIn) => {
    if (!isCheckedIn) {
      // not checked in; show error
      this.props.uiStore.addAlert(
        "error",
        "Please check in before recording work."
      );
      return;
    }

    this.setState({ selectedUser: user });
  };

  get loading() {
    return this.props.uiStore.loadingByTag(LOADER_TAG_STATION_RECORD_WORK);
  }

  render() {
    const { selectedUser, selectedBike } = this.state;
    return (
      <StationSite>
        <MediumCenterInner>
          <Box>
            <PageHeading>Record Bike Work</PageHeading>
            {/* STEP ONE */}
            {!selectedUser && !selectedBike && (
              <div>
                <p>Please check in if you haven't already.</p>
                <p>Then, search for your account and select it below.</p>
                <SearchUsers
                  onForceSelect={this.onSelectUser}
                  renderActions={user => {
                    const isCheckedIn = user.current_visit;

                    return isCheckedIn ? (
                      <div>
                        <Button
                          onClick={() => this.onSelectUser(user, isCheckedIn)}
                          isColor="primary"
                        >
                          Select
                        </Button>
                      </div>
                    ) : (
                      <b>Not Checked In</b>
                    );
                  }}
                />
              </div>
            )}
            {/* STEP TWO */}
            {selectedUser && !selectedBike && (
              <div>
                <p>
                  Recording bike work as{" "}
                  <b>{selectedUser.name || selectedUser.email}</b>
                </p>
                <p>Enter the bike id (found on its tag).</p>
                <CustomFinalForm
                  onSubmit={this.onSubmitBike}
                  validate={validateBike}
                  initialValues={{ bike_id: null }}
                  subscription={standardFinalFormSubscriptions}
                  render={renderProps => {
                    const { handleSubmit } = renderProps;
                    return (
                      <form onSubmit={handleSubmit}>
                        <TextFieldWithErrors
                          type="text"
                          name="bike_id"
                          label="Bike ID"
                          autoFocus
                        />
                        <FinalSubmit
                          {...renderProps}
                          isSize="normal"
                          label="Next"
                          loading={this.loading}
                        />
                      </form>
                    );
                  }}
                />
              </div>
            )}
            {/* STEP THREE */}
            {selectedUser && selectedBike && (
              <div>
                <StationBikeDisplay bike={selectedBike} />
                <br />
                <p>
                  <strong>Record Your Work</strong>
                </p>
                <CustomFinalForm
                  onSubmit={this.onSubmitWorked}
                  validate={validateWorked}
                  initialValues={{
                    minutes: null,
                    note: null,
                    bikeparts: selectedBike.bikeparts
                  }}
                  subscription={standardFinalFormSubscriptions}
                  render={renderProps => {
                    const { handleSubmit } = renderProps;
                    return (
                      <form onSubmit={handleSubmit}>
                        <DurationFieldWithErrors
                          name="minutes"
                          label="Time spent on this bike today"
                        />
                        <TextFieldWithErrors
                          isTextarea
                          type="text"
                          name="note"
                          label="Notes"
                          extraHelp="Briefly describe the work you did."
                        />
                        <BikePartsField
                          isStation={true}
                          help={
                            <p>
                              Add or adjust the quantities of any bike parts you
                              used in this session. Please ask if you need any
                              help before submitting!
                            </p>
                          }
                        />
                        <FinalSubmit {...renderProps} loading={this.loading} />
                      </form>
                    );
                  }}
                />
              </div>
            )}
          </Box>
        </MediumCenterInner>
      </StationSite>
    );
  }
}

export default compose(inject("uiStore"), observer)(RecordWork);
