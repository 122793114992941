import React, { Component } from "react";
import SelectFieldImmediate from "../SelectFieldImmediate";
import agent from "../../../agent";
import { LOADER_TAG_ADMIN_FIELD_BSIZE } from "../../../constants";

export default class SelectBSize extends Component {
  render() {
    return (
      <SelectFieldImmediate
        getFn={() =>
          agent.AdminFields.allOfType(
            { field: "bsize" },
            { loaderTag: LOADER_TAG_ADMIN_FIELD_BSIZE }
          )
        }
        loaderTag={LOADER_TAG_ADMIN_FIELD_BSIZE}
        {...this.props}
      />
    );
  }
}
