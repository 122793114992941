import React, { Fragment } from "react";
import { observer } from "mobx-react";
import _pick from "lodash.pick";
import { Title } from "bloomer";

import AdminSite from "../../../components/AdminSite";

import agent from "../../../agent";
import { compose, idFromProps, mapSomeFieldsToId } from "../../../utils";
import { LOADER_TAG_ADMIN_RENTALBIKES } from "../../../constants";
import GenericEditor from "../../../components/GenericEditor";
import GenericResourceEditorStore from "../../../stores/special/GenericResourceEditorStore";
import { makeRecordValidator, makeFieldValidator } from "../../../validators";
import TextFieldWithErrors from "../../../components/fields/TextFieldWithErrors";
import SingleCheckboxFieldWithErrors from "../../../components/fields/SingleCheckboxFieldWithErrors";
import Breadcrumbs from "../../../components/Breadcrumbs";
import FancySelectFieldWithErrors from "../../../components/fields/FancySelectFieldWithErrors";
import SelectBSize from "../../../components/selects/immediate/SelectBSize";
import RentalPeriodTable from "../../../components/tables/RentalPeriodTable";
import PageHeading from "../../../components/PageHeading";
import TitleHelmet from "../../../components/seo/TitleHelmet";

const initialFormValuesFromRentalBike = rentalbike => {
  return _pick(rentalbike, ["serial", "is_missing", "notes", "bsize"]);
};

const newStructure = {
  serial: null,
  is_missing: false,
  notes: null,
  bsize: null
};

const rentalBikeEditorStore = new GenericResourceEditorStore(
  "rentalbike",
  LOADER_TAG_ADMIN_RENTALBIKES,
  "rentalbike-editor",
  "rentalbike",
  agent.AdminRentalBikes.show,
  agent.AdminRentalBikes.create,
  agent.AdminRentalBikes.update,
  agent.AdminRentalBikes.remove,
  newStructure,
  fields => mapSomeFieldsToId(fields, ["bsize"]),
  "Rental bike saved successfully!",
  "Rental bike deleted successfully!"
);

const validate = makeRecordValidator(
  makeFieldValidator("serial", "required"),
  makeFieldValidator("bsize", "required")
);

class RentalCrud extends React.Component {
  render() {
    const idParam = idFromProps(this.props);
    const isNew = idParam === "new";

    const title = isNew ? "New Rental Bike" : `Manage Rental Bike ${idParam}`;

    return (
      <AdminSite>
        <Breadcrumbs
          items={[
            { to: "/admin", label: "Admin" },
            { to: "/admin/rentals", label: "Rentals" },
            {
              to: `/admin/rentals/${isNew ? "new" : idParam}`,
              label: isNew ? "New Rental Bike" : `Rental Bike ${idParam}`
            }
          ]}
        />
        <GenericEditor
          name="rental bike"
          basePath="/admin/rentals"
          store={rentalBikeEditorStore}
          loaderTag={LOADER_TAG_ADMIN_RENTALBIKES}
          recordValidator={validate}
          initialFormValuesFn={initialFormValuesFromRentalBike}
        >
          {<PageHeading>{title}</PageHeading>}
          {<TitleHelmet title={title} />}

          {rentalBikeEditorStore.resource &&
            rentalBikeEditorStore.resource.id && (
              <Fragment>
                <Title isSize={4}>Rental History</Title>
                {!rentalBikeEditorStore.resource.rentalperiods.length ? (
                  <p>
                    <i>None yet.</i>
                  </p>
                ) : (
                  <RentalPeriodTable
                    rentalBikeId={rentalBikeEditorStore.resource.id}
                    data={rentalBikeEditorStore.resource.rentalperiods}
                  />
                )}
                <br />
              </Fragment>
            )}

          <TextFieldWithErrors name="serial" label="Serial" />
          <SingleCheckboxFieldWithErrors name="is_missing" label="Missing?" />
          <TextFieldWithErrors name="notes" label="General Notes" isTextarea />
          <FancySelectFieldWithErrors
            name="bsize"
            label="Size"
            component={SelectBSize}
          />
        </GenericEditor>
      </AdminSite>
    );
  }
}

export default compose(observer)(RentalCrud);
