import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { withRouter } from "react-router-dom";

import PageHeading from "../../../components/PageHeading";
import AdminSite from "../../../components/AdminSite";
import TabNav from "../../../components/TabNav";

import { compose, idFromProps, userOneLiner } from "../../../utils";
import Breadcrumbs from "../../../components/Breadcrumbs";

/**
 * User CRUD Wrapper
 */
class Wrapper extends Component {
  componentDidMount() {
    this.pullUserMaybe();
  }

  componentDidUpdate() {
    this.pullUserMaybe();
  }

  pullUserMaybe = () => {
    const { userEditorStore } = this.props;
    const { resource } = userEditorStore;

    if (!this.props.renderWithUser) {
      return;
    }

    const id = idFromProps(this.props);

    if (id === "new") {
      this.props.uiStore.addAlert(
        "warning",
        "Cannot create new users from admin."
      );
      this.props.history.push("/admin/users", {
        wasRedirect: true
      });
      return;
    }

    if (
      resource &&
      resource.id &&
      parseInt(resource.id, 10) === parseInt(id, 10)
    ) {
      // no need to re-fetch
      return;
    }

    userEditorStore.initializeEdit(id).catch(() => {
      this.props.history.push("/admin/users", {
        wasRedirect: true
      });
    });
  };

  render() {
    const { userEditorStore, children, renderWithUser } = this.props;
    const idParam = idFromProps(this.props);
    const user = userEditorStore.resource;

    return (
      <AdminSite>
        <Breadcrumbs
          items={[
            { to: "/admin", label: "Admin" },
            { to: "/admin/users", label: "Users" },
            {
              to: `/admin/users/${idParam}`,
              label: userOneLiner(user)
            }
          ]}
        />
        <PageHeading>Manage User #{idParam}</PageHeading>
        <TabNav
          tabs={[
            { to: `/admin/users/${idParam}`, exact: true, label: "Editor" },
            { to: `/admin/users/${idParam}/bikes`, label: "Bikes" },
            { to: `/admin/users/${idParam}/orders`, label: "Orders" },
            { to: `/admin/users/${idParam}/visits`, label: "Visits" },
            { to: `/admin/users/${idParam}/membership`, label: "Membership" }
          ]}
        />
        {children}
        {renderWithUser && (user ? renderWithUser(user) : null)}
      </AdminSite>
    );
  }
}

export default compose(
  withRouter,
  inject("uiStore", "userEditorStore"),
  observer
)(Wrapper);
