import React, { Component } from "react";
import Select from "react-select";
import _throttle from "lodash.throttle";

import agent from "../../agent";
import { getCurrentReactSelectValue } from "../../utils";

/**
 * SelectField is for both fields (bike fields, demographicset fields, organization)
 * as well as receiving and donating events and users.
 * Provide it with a `field` prop that is either one of the field names,
 * or 'receiving-event' or 'donating-event' or 'user'.
 * Accepts `isMulti` for multi-select.
 */

const startRequest = (field, search) => {
  if (field === "receiving-event") {
    return agent.AdminRecdons.select(
      { type: "receiving", search },
      { showLoader: false }
    );
  } else if (field === "donating-event") {
    return agent.AdminRecdons.select(
      { type: "donating", search },
      { showLoader: false }
    );
  } else if (field === "user") {
    return agent.AdminUsers.select({ search }, { showLoader: false });
  }

  return agent.AdminFields.select({ field, search }, { showLoader: false });
};

const noOptionsMessage = ({ inputValue }) =>
  inputValue ? undefined : "Start typing...";

export default class SelectFieldThrottled extends Component {
  state = {
    options: [],
    loading: false
  };

  constructor(props) {
    super(props);

    this.onInputChangeThrottled = _throttle(this.onInputChange, 500);
  }

  resetOptions = () => {
    this.setState({ options: [] });
  };

  componentWillReceiveProps(nextProps) {
    if (this.props.field !== nextProps.field) {
      this.resetOptions();
    }
  }

  onInputChange = (search, { action }) => {
    if (action !== "input-change") {
      // don't do anything on blur, menu-close, or set-value
      return;
    }

    if (!search) {
      this.resetOptions();
      return;
    }

    this.setState({
      loading: true
    });

    startRequest(this.props.field, search)
      .then(fields =>
        fields.data.map(field => ({
          value: field.id,
          label: this.props.labelMaker
            ? this.props.labelMaker(field)
            : field.name
        }))
      )
      .then(options => {
        this.setState({ options });
      })
      .catch(() => this.resetOptions())
      .finally(() => {
        this.setState({
          loading: false
        });
      });
  };

  render() {
    const { field, value, ...restProps } = this.props;

    return (
      <Select
        {...restProps}
        value={getCurrentReactSelectValue(value)}
        isLoading={this.state.loading}
        options={this.state.options}
        noOptionsMessage={noOptionsMessage}
        onInputChange={this.onInputChangeThrottled}
      />
    );
  }
}
