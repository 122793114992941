import { decorate, observable, action, runInAction } from "mobx";
import _find from "lodash.find";
import _get from "lodash.get";
import agent from "../agent";
import { createViewModel } from "mobx-utils";
import { LOADER_TAG_ADMIN_FIELDS } from "../constants";
import ValidationError from "../errors/ValidationError";
import uiStore from "./ui";

export class FieldEditorAdminStore {
  field = null;

  results = observable.array();

  editing = null;

  errorMessage = null;

  removing = null;

  creating = null;

  // action
  setField(field) {
    this.field = field || null;
    this.pullNewResults();
  }

  // action
  resetAll() {
    this.field = null;
    this.resetAllButField();
  }

  // action
  resetAllButField() {
    this.results = [];
    this.resetEditing();
    this.resetRemoving();
    this.resetCreating();
  }

  // action
  resetErrorMessage() {
    this.errorMessage = null;
  }

  // action
  resetEditing() {
    this.editing = null;
    this.resetErrorMessage();
  }

  // action
  resetRemoving() {
    this.removing = null;
  }

  // action
  resetCreating() {
    this.creating = null;
  }

  // action
  startEditing(id) {
    this.resetRemoving();
    this.resetEditing();
    const result = _find(this.results, result => result.id === id);
    if (result) {
      this.editing = createViewModel(result);
    } else {
      this.resetAllButField();
    }
  }

  // action
  edit(value) {
    if (this.editing) {
      this.editing.name = value;
    }
  }

  // action
  startRemoving(id) {
    this.resetEditing();
    this.resetRemoving();
    const result = _find(this.results, result => result.id === id);
    if (result) {
      this.removing = result;
    } else {
      this.resetAllButField();
    }
  }

  // action
  remove() {
    if (this.removing) {
      uiStore.clearAlertsAfterSubmit();
      const value = this.removing.name;
      agent.AdminFields.remove(
        { field: this.field, id: this.removing.id },
        { loaderTag: LOADER_TAG_ADMIN_FIELDS }
      )
        .then(() => {
          // filter from results
          runInAction(() => {
            this.results.replace(
              this.results.filter(result => result.id !== this.removing.id)
            );
          });
          this.resetRemoving();
          uiStore.addAlert(
            "success",
            `Field item '${value}' was removed successfully!`
          );
        })
        .catch(error => {
          this.resetRemoving();
        });
    }
  }

  // action
  save() {
    this.resetErrorMessage();
    uiStore.clearAlertsAfterSubmit();
    if (this.editing) {
      if (this.editing.isDirty) {
        const value = this.editing.name;
        agent.AdminFields.update(
          {
            field: this.field,
            id: this.editing.id,
            value
          },
          { loaderTag: LOADER_TAG_ADMIN_FIELDS, displayError: false }
        )
          .then(body => {
            const newField = body.data;
            runInAction(() => {
              // almost certainly the same, but just to be sure...
              this.editing.name = newField.name;
              // update original mobx view model
              this.editing.submit();
              // reset interface
              this.resetEditing();
            });
            uiStore.addAlert(
              "success",
              `Field item '${value}' was saved successfully!`
            );
          })
          .catch(error => {
            const errorMessageMaybe =
              error instanceof ValidationError && _get(error, "fields.value.0");
            if (errorMessageMaybe) {
              runInAction(() => {
                this.errorMessage = errorMessageMaybe;
              });
            } else {
              // not a relevant validation error, so report it to user as alert
              uiStore.addAlert("error", error);
            }
          });
      } else {
        // no changes
        this.resetEditing();
        uiStore.addAlert("info", `No change`);
      }
    }
  }

  // action
  createOnChange(value) {
    this.creating = value || null;
  }

  // action
  createSave() {
    if (this.creating) {
      uiStore.clearAlertsAfterSubmit();
      const value = this.creating;
      agent.AdminFields.create(
        {
          field: this.field,
          value
        },
        {
          loaderTag: LOADER_TAG_ADMIN_FIELDS,
          displayError: false
        }
      )
        .then(() => {
          this.pullNewResults();
        })
        .then(() => {
          uiStore.addAlert(
            "success",
            `Field item '${value}' was added successfully!`
          );
        })
        .catch(error => {
          const errorMessageMaybe =
            error instanceof ValidationError && _get(error, "fields.value.0");
          if (errorMessageMaybe) {
            uiStore.addAlert("error", errorMessageMaybe);
          } else {
            // not a relevant validation error, so report it to user as alert
            uiStore.addAlert("error", error);
          }
        });
    }
  }

  // action
  pullNewResults() {
    this.resetAllButField();
    uiStore.clearAlertsAfterRouteChange();
    if (this.field) {
      agent.AdminFields.allOfType(
        { field: this.field },
        { loaderTag: LOADER_TAG_ADMIN_FIELDS }
      )
        .then(body => {
          const results = body.data;
          runInAction(() => {
            this.results = results;
          });
          return results; // for convenience if needed
        })
        .catch(error => {
          this.resetAllButField();
          return Promise.reject(error); // re-throw
        });
    }
  }
}

decorate(FieldEditorAdminStore, {
  field: observable,
  results: observable,
  editing: observable,
  errorMessage: observable,
  removing: observable,
  creating: observable,
  setField: action.bound,
  resetAll: action.bound,
  resetAllButField: action.bound,
  resetEditing: action.bound,
  resetErrorMessage: action.bound,
  resetRemoving: action.bound,
  resetCreating: action.bound,
  startEditing: action.bound,
  startRemoving: action.bound,
  remove: action.bound,
  edit: action.bound,
  save: action.bound,
  createOnChange: action.bound,
  createSave: action.bound,
  pullNewResults: action.bound
});

export default new FieldEditorAdminStore();
