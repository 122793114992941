import React, { Component } from "react";
import { Tabs, TabList, Tab } from "bloomer";
import { Link, Route } from "react-router-dom";

// Essentially an implementation of NavLink that supports the bulma tab html structure
// See https://github.com/ReactTraining/react-router/blob/v4.3.1/packages/react-router-dom/modules/NavLink.js
const AwareTabNavLink = ({ to, exact, label }) => {
  const path = typeof to === "object" ? to.pathname : to;
  // Regex taken from: https://github.com/pillarjs/path-to-regexp/blob/master/index.js#L202
  const escapedPath = path && path.replace(/([.+*?=^!:${}()[\]|/\\])/g, "\\$1");

  return (
    <Route
      exact={exact}
      path={escapedPath}
      children={({ match }) => {
        return (
          <Tab isActive={!!match}>
            <Link to={to}>
              <span>{label}</span>
            </Link>
          </Tab>
        );
      }}
    />
  );
};

/**
 * Tab Nav
 */
export default class TabNav extends Component {
  render() {
    const { tabs } = this.props;

    return (
      <Tabs>
        <TabList>
          {tabs.filter(a => a).map(tab => (
            <AwareTabNavLink key={tab.to} {...tab} />
          ))}
        </TabList>
      </Tabs>
    );
  }
}
