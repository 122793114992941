import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Level, LevelLeft, LevelRight, LevelItem, Button } from "bloomer";

import styles from "./cart.module.scss";
import { formatMoney } from "../../utils";

export default class CartItem extends Component {
  onUpdate = quantity => {
    const { item } = this.props;
    const { id, cart_item, is_quantifiable } = item;
    const { variation } = cart_item;
    this.props.onUpdate(
      id,
      is_quantifiable ? quantity : null,
      variation || null
    );
  };

  incrementQuantity = () => {
    const quantity = this.props.item.cart_item.quantity;
    this.onUpdate(quantity + 1);
  };

  decrementQuantity = () => {
    const quantity = this.props.item.cart_item.quantity;
    if (quantity > 1) {
      this.onUpdate(quantity - 1);
    }
  };

  onRemove = () => {
    const { item } = this.props;
    const { id, cart_item } = item;
    const { variation } = cart_item;
    this.props.onRemove(id, variation || null);
  };

  render() {
    const { item, onUpdate, onRemove } = this.props;
    const { id, cart_item, title, is_quantifiable, price } = item;
    const { quantity, variation } = cart_item;

    return (
      <div className={styles.item}>
        <Level isMarginless>
          <LevelLeft>
            <LevelItem>
              <Link to={`/store/${id}`}>{title}</Link>
            </LevelItem>
            {variation && (
              <LevelItem>
                <span className="has-text-grey">({variation})</span>
              </LevelItem>
            )}
          </LevelLeft>
          <LevelRight>
            <LevelItem>
              <strong>Quantity:</strong>
              &nbsp;
              {quantity || 1}
            </LevelItem>
            {onUpdate &&
              is_quantifiable && (
                <LevelItem>
                  <Button
                    isSize="small"
                    isColor="light"
                    onClick={this.decrementQuantity}
                    disabled={quantity === 1}
                  >
                    -
                  </Button>
                </LevelItem>
              )}
            {onUpdate &&
              is_quantifiable && (
                <LevelItem>
                  <Button
                    isSize="small"
                    isColor="light"
                    onClick={this.incrementQuantity}
                  >
                    +
                  </Button>
                </LevelItem>
              )}
            {onRemove && (
              <LevelItem>
                <Button isSize="small" isColor="danger" onClick={this.onRemove}>
                  Remove
                </Button>
              </LevelItem>
            )}
          </LevelRight>
        </Level>
        <Level style={{ marginTop: 5 }}>
          <LevelLeft />
          <LevelRight>
            <LevelItem>
              <strong>Price each:</strong>
              &nbsp;
              {formatMoney(price)}
            </LevelItem>
          </LevelRight>
        </Level>
      </div>
    );
  }
}
