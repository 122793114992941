import React from "react";
import { Columns, Column, Title } from "bloomer";

import PublicSite from "../../components/PublicSite";
import PageHeading from "../../components/PageHeading";
import MediumCenterInner from "../../components/MediumCenterInner";
import LinkButton from "../../components/LinkButton";
import FullWidthImage from "../../components/FullWidthImage";
import DonateBikesDumbHoursMessage from "../../components/DonateBikesDumbHoursMessage";
import DonorBox from "./DonorBox";

export default class Donate extends React.Component {
  render() {
    return (
      <PublicSite hasPaddingTop={false}>
        <FullWidthImage
          src="/img/content/donate/kids-front.jpg"
          alt="Hanging out in front of BSBC"
        />
        <MediumCenterInner>
          <PageHeading>Donate</PageHeading>

          <DonateBikesDumbHoursMessage />

          <p>
            The Bradley Street Bicycle Co-op is a community bike shop working
            towards an equitable New Haven by getting people on bikes.
          </p>

          <p>
            Your donation to the <em>Bradley Street Bicycle Co-op</em> (our
            community bike shop) and the{" "}
            <em>Connecticut Community Bicycle Workshop</em> (our non-profit
            partner organization) helps us <strong>give bikes away</strong> to
            people in need, <strong>run educational programs</strong>, and{" "}
            <strong>repair bikes</strong> all over the state of Connecticut.
          </p>

          <br />

          <Columns>
            <Column>
              <Title isSize={4}>Monetary Donation</Title>

              <p>
                Financial contributions help pay our rent, keep the heat running
                all winter, buy parts for bikes in need, increase programming,
                and get more bikes back on the street. The Co-op is a big
                community of people helping in all kinds of ways; your belief
                and ($$) support helps us build a strong community. Thank you
                for being a part of it!
              </p>

              <DonorBox />
            </Column>
            <Column>
              <Title isSize={4}>Donate a Bike</Title>
              <p>
                Donated bicycles will be tuned up and either donated to a person
                in need or sold to support our mission. Every donated bike
                contributes to a cleaner environment, healthier people, reduced
                congestion and pollution, and accessible transportation in our
                city and our state.
              </p>
              <p>
                We gratefully accept bikes in any condition, as well as bike
                parts and tools. The best time to drop off bicycle donations is
                on Monday (for volunteering only), Tuesday, or Thursday from 4-8pm at 138 Bradley
                Street, New Haven, CT 06511. No appointment necessary. We
                heavily encourage dropping bikes off at our space, but reach out
                if you cannot get it to us. Thank you for your support!
              </p>
              <p>
                We accept donations of bicycles or components in any condition.
                Please bring it to BSBC during shop hours! We are open
                Monday (for volunteering), Tuesday, and Thursday 4-8pm.
              </p>
              <p>
                <LinkButton to="/about/bicycle-recycling" isColor="info">
                  Learn More
                </LinkButton>
              </p>
            </Column>
          </Columns>
          <p>
            <em>
              Connecticut Community Bicycle Workshop is the partner organization
              of the Bradley Street Bicycle Co-op. CCBW is a 501(c)(3) non
              profit organization and all donations to CCBW are tax deductible
              to the fullest extent permitted by law. Our federal EIN number is
              83-2709160.
            </em>
          </p>
        </MediumCenterInner>
      </PublicSite>
    );
  }
}
