import React from "react";
import { observer, inject } from "mobx-react";
import { reaction } from "mobx";

import BaseRoute from "./BaseRoute";
import CustomRedirect from "../CustomRedirect";
import { LOGIN_PATH, NOTFOUND_PATH } from "../../config";
import { compose } from "../../utils";
import roleCheck from "../../roleCheck";
import { ROLE_NOT_STATION } from "../../constants";

/**
 * AuthRoute:
 * - checks for auth
 * - can check for user role using userRole prop
 *   (must equal one of the keys in roleMap)
 * - will redirect to login if not logged in (including if just logged out)
 *   (though intelligently only shows user error if not logging out)
 */
class AuthRoute extends React.Component {
  state = {
    justLoggedOut: false
  };

  constructor(props) {
    super(props);

    this.disposeReaction = reaction(
      () => this.props.authStore.isAuthenticated,
      isAuthenticated => {
        this.setState({ justLoggedOut: !isAuthenticated });
      }
    );
  }

  componentDidUpdate() {
    if (this.state.justLoggedOut) {
      // justLoggedOut should be "used" by render once then flipped back off
      this.setState({ justLoggedOut: false });
    }
  }

  componentWillUnmount() {
    if (this.disposeReaction) {
      this.disposeReaction();
    }
  }

  render() {
    const {
      authStore,
      uiStore,
      component,
      userRole = ROLE_NOT_STATION,
      ...rest
    } = this.props;
    const { justLoggedOut } = this.state;

    // ! deref in main render so mobx can react to changes !
    const isAuthenticated = authStore.isAuthenticated;
    const user = authStore.user;

    return (
      <BaseRoute
        {...rest}
        render={props => {
          if (isAuthenticated) {
            // if role is constrained, check it
            if (!userRole || roleCheck(user, userRole)) {
              // ok
              return React.createElement(component, props);
            } else {
              // rolecheck failed
              uiStore.addAlert(
                "error",
                "You aren't authorized to access that page.",
                { group: "auth" }
              );
              return <CustomRedirect to={NOTFOUND_PATH} />;
            }
          } else {
            // If the user was on an auth route but then logged out,
            // they shouldn't be shown any error.
            if (!justLoggedOut) {
              // User is attempting to access a page requiring auth.
              // Show alert and redirect to login page
              // (with redirectAfterLogin set to currently-requested pathname)
              uiStore.addAlert("warning", "Please login to access that page.", {
                group: "auth"
              });
            }

            const redirectAfterLogin = props.location.pathname;
            return (
              <CustomRedirect
                to={LOGIN_PATH}
                redirectAfterLogin={redirectAfterLogin}
              />
            );
          }
        }}
      />
    );
  }
}

export default compose(
  inject("authStore", "uiStore"),
  observer
)(AuthRoute);
