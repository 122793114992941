/**
 * AuthorizationError
 * In practice, this should never show up due to protected routes
 */
class AuthorizationError {
  constructor(message) {
    this.name = "AuthorizationError";
    this.alertConfigFinal = { group: "authorization" };
    this.message = message;
    this.stack = new Error().stack; // Optional
  }
}
AuthorizationError.prototype = Object.create(Error.prototype);

export default AuthorizationError;
