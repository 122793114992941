import React, { Component } from "react";
import SelectFieldImmediate from "../SelectFieldImmediate";
import agent from "../../../agent";
import { LOADER_TAG_ADMIN_FIELD_BMAKE } from "../../../constants";

export default class SelectBMake extends Component {
  onCreateOption = value => {
    return agent.AdminFields.create(
      { field: "bmake", value },
      { loaderTag: LOADER_TAG_ADMIN_FIELD_BMAKE }
    );
  };

  render() {
    const { isCreateable = false } = this.props;

    return (
      <SelectFieldImmediate
        getFn={() =>
          agent.AdminFields.allOfType(
            { field: "bmake" },
            { loaderTag: LOADER_TAG_ADMIN_FIELD_BMAKE }
          )
        }
        loaderTag={LOADER_TAG_ADMIN_FIELD_BMAKE}
        {...this.props}
        {...isCreateable && { onCreateOption: this.onCreateOption }}
      />
    );
  }
}
