import React from "react";

import FilterWrapper from "./support/FilterWrapper";
import SelectRole from "../selects/immediate/SelectRole";
import SelectVolunteerLevel from "../selects/immediate/SelectVolunteerLevel";

const fields = [
  {
    name: "name",
    label: "Search Name",
    type: "text"
  },
  {
    name: "email",
    label: "Search Email",
    type: "text"
  },
  {
    name: "newsletter",
    label: "Subscribed to newsletter?",
    type: "nullable-boolean"
  },
  {
    name: "has_active_membership",
    label: "Active membership?",
    type: "nullable-boolean"
  },
  {
    name: "created_at",
    label: "Created At",
    type: "date-range"
  },
  {
    name: "role",
    label: "Role",
    type: "fancy-select",
    component: SelectRole,
    isMulti: true // for SelectRole
  },
  {
    name: "volunteerlevel",
    label: "Volunteer Level",
    type: "fancy-select",
    component: SelectVolunteerLevel,
    isMulti: true // for SelectVolunteerLevel
  }
];

export default class UserFilters extends React.Component {
  render() {
    return <FilterWrapper fields={fields} {...this.props} />;
  }
}
