import { observable, decorate, runInAction, action } from "mobx";
import agent from "../agent";
import { LOADER_TAG_PUBLIC_ITEMS } from "../constants";

export class ItemPublicStore {
  items = observable.array();

  item = null;

  clearItem() {
    this.item = null;
  }

  clearAll() {
    this.items.clear();
    this.clearItem();
  }

  loadAll() {
    return agent.Items.all({ loaderTag: LOADER_TAG_PUBLIC_ITEMS }).then(
      body => {
        const items = body.data;
        runInAction(() => {
          this.items.replace(items);
        });
      }
    );
  }

  show(id) {
    this.clearItem();
    return agent.Items.show(
      { id },
      {
        loaderTag: LOADER_TAG_PUBLIC_ITEMS,
        notFoundName: "item"
      }
    ).then(body => {
      const item = body.data;
      runInAction(() => {
        this.item = item;
      });
    });
  }
}

decorate(ItemPublicStore, {
  items: observable,
  item: observable,
  clearAll: action.bound,
  loadAll: action.bound,
  show: action.bound
});

export default new ItemPublicStore();
