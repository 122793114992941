import { momentNowLocal } from "../../../../datetime";
import randomColor from "randomcolor";
import moment from "moment";
import _memoize from "lodash.memoize";
import _pick from "lodash.pick";
import _cloneDeep from "lodash.clonedeep";

/**
 * Chart utils
 */

const monthIntToShortString = _memoize(month =>
  moment()
    .month(month)
    .format("MMM")
);

export function runReportByYearMonthly(
  records,
  dateField,
  transformRecords,
  sorter = null
) {
  // Get biggest applicable date (now)
  const nowDate = momentNowLocal();
  const nowYear = nowDate.year();

  // Ensure records have date field
  const recordsFiltered = records.filter(r => r[dateField]);

  // Get smallest applicable date
  const fromDate = recordsFiltered.reduce((carry, bike) => {
    const recordDateString = bike[dateField]; // (YYYY-MM-DD HH:mm:ss)
    if (recordDateString < carry) {
      return recordDateString;
    }
    return carry;
  }, nowDate.format("YYYY-MM-DD HH:mm:ss"));
  const fromYear = new Date(fromDate).getFullYear();

  // Initalize empty object { year => { month => undefined, ... }, ... }
  const yms = {};
  for (let i = fromYear; i <= nowYear; i++) {
    yms[i] = {};
    const from = i === fromYear ? new Date(fromDate).getMonth() : 0;
    const to = i === nowYear ? new Date(nowDate).getMonth() : 11;
    for (let v = from; v <= to; v++) {
      yms[i][v] = undefined;
    }
  }

  const ymsFinal = _cloneDeep(yms);

  // Make years list
  const years = Object.keys(yms).sort();

  // Sort records into yms object
  const realSorter =
    sorter ||
    (record => {
      const recordDate = new Date(record[dateField]);
      return [[recordDate.getFullYear(), recordDate.getMonth()]];
    });

  recordsFiltered.forEach(record => {
    realSorter(record).forEach(([year, month]) => {
      if (typeof yms[year][month] === "undefined") {
        yms[year][month] = [];
      }
      yms[year][month].push(record);
    });
  });

  // Run transformations on each nested array
  years.forEach(year => {
    const monthsObject = yms[year];
    Object.keys(monthsObject).forEach(month => {
      ymsFinal[year][month] = transformRecords(
        monthsObject[month], // records this month
        month === 0 ? undefined : ymsFinal[year][month - 1], // previous result
        {
          // records this year until this month (inclusive)
          // TODO used anywhere?
          getRecordsUntilThisMonthInclusive: () =>
            _pick(monthsObject, [...Array(parseInt(month, 10) + 1).keys()]),
          // results this year until this month (exclusive)
          // TODO used anywhere?
          getResultsUntilThisMonth: () =>
            _pick(ymsFinal[year], [...Array(parseInt(month, 10)).keys()]),
          // last last result this year that wasn't undefined
          getLastRealResult: () => {
            for (let m = month - 1; m >= 0; m--) {
              const result = ymsFinal[year][m];
              if (typeof result !== "undefined") {
                return result;
              }
            }
            return undefined;
          }
        }
      );
    });
  });

  // Pack up for Recharts
  const data = Array.from(Array(12).keys()).map(month => {
    return {
      month: monthIntToShortString(month),
      ...years.reduce((carry, year) => {
        const result = ymsFinal[year][month];
        if (typeof result === "undefined") {
          return carry;
        }
        return { ...carry, [year]: result };
      }, {})
    };
  });

  return { data, years };
}

// TODO: faster string-only implementation
export function monthSorterBetweenTwoDates(
  dateLowFieldOrFieldGetter,
  dateHighField
) {
  return record => {
    const dateLowField =
      typeof dateLowFieldOrFieldGetter === "function"
        ? dateLowFieldOrFieldGetter(record)
        : dateLowFieldOrFieldGetter;

    const dateLowRaw = record[dateLowField];
    if (!dateLowRaw) {
      // considered invalid
      return [];
    }
    const dateLow = new Date(dateLowRaw);

    const dateHighRaw = record[dateHighField];
    const dateHigh = dateHighRaw ? new Date(dateHighRaw) : new Date(); // default to now
    if (dateHigh < dateLow) {
      // considered invalid
      return [];
    }

    const result = [];
    // copy low date but use start of month
    const i = new Date(dateLow.valueOf());
    i.setDate(1);
    i.setSeconds(0);
    i.setMilliseconds(0);
    while (i <= dateHigh) {
      const year = i.getFullYear();
      const month = i.getMonth();
      result.push([year, month]);

      // increment
      i.setMonth(month + 1); // (> 11 is fine)
    }

    return result;
  };
}

const definedYearColors = {
  2016: "#e41a1c",
  2017: "#377eb8",
  2018: "#4daf4a",
  2019: "#984ea3",
  2020: "#ff7f00",
  2021: "#ffff33",
  2022: "#a65628",
  2023: "#f781bf",
  2024: "#999999"
};
export const getYearColor = _memoize(y => {
  const year = typeof y === "number" ? y : parseInt(y, 10);

  return (
    definedYearColors[year] ||
    randomColor({
      seed: year,
      hue: "random",
      luminosity: "random"
    })
  );
});
