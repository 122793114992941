import React, { PureComponent } from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Label
} from "recharts";

export default class AreaVisitsPerVisitor extends PureComponent {
  render() {
    const { visitCountMap, labelYAxis = "Visitors" } = this.props;

    if (!visitCountMap) {
      return "n/a";
    }

    const data = Object.entries(visitCountMap).reduce(
      (acc, [visits, { count, ratio }]) => [
        ...acc,
        {
          visits,
          count,
          ratio
        }
      ],
      []
    );

    return (
      <ResponsiveContainer width="100%" height={350}>
        <AreaChart data={data}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="visits"
            type="number"
            height={50}
            domain={[1, "dataMax"]}
            tickCount={0} // not sure this is a valid input, but it does the trick
          >
            <Label>Visits</Label>
          </XAxis>
          <YAxis>
            <Label angle={-90}>{labelYAxis}</Label>
          </YAxis>
          <Tooltip />
          <Area
            dataKey="count"
            stroke="#8884d8"
            fill="#8884d8"
            fillOpacity={0.3}
          />
          <Area
            dataKey="ratio"
            stroke="#00C49F"
            fill="#00C49F"
            fillOpacity={0.3}
          />
        </AreaChart>
      </ResponsiveContainer>
    );
  }
}
