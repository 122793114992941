import React, { PureComponent } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Label
} from "recharts";

import { ENUM_BIKE_CONDITIONS } from "../../../../config";
import { BIKE_STATUS_NEW } from "../../../../constants";

export default class BarBikesCurrentUnfixedByCondition extends PureComponent {
  render() {
    const { bikes } = this.props;

    if (!bikes) {
      return "n/a";
    }

    const data = Object.entries(
      bikes
        .filter(bike => bike.status === BIKE_STATUS_NEW) // only include "new" bikes
        .reduce(
          (acc, bike) => {
            const { condition } = bike;
            if (!acc[condition]) {
              // init count for condition if it doesn't exist, but it should
              acc[condition] = 0;
            }
            // add to count
            acc[condition] += 1;
            return acc;
          },
          // init condition buckets
          ENUM_BIKE_CONDITIONS.reduce((a, c) => ({ ...a, [c]: 0 }), {})
        )
    ).reduce((acc, [condition, count]) => {
      return [...acc, { condition, count }];
    }, []);

    return (
      <ResponsiveContainer width="100%" height={300}>
        <BarChart data={data}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="condition" />
          <YAxis>
            <Label angle={-90}>Count</Label>
          </YAxis>
          <Tooltip />
          <Bar dataKey="count" fill="#8884d8" />
        </BarChart>
      </ResponsiveContainer>
    );
  }
}
