import React from "react";
import {
  Card,
  CardHeader,
  CardHeaderTitle,
  CardContent,
  Columns,
  Column
} from "bloomer";

import { PLAN_DISPLAY_NAMES, ENUM_QUANTIFIABLE_PLANS } from "../../config";
import { parseMomentTimezoneConfig, formatMomentLocalLL } from "../../datetime";

import styles from "./membershipcard.module.scss";
import { userOneLiner } from "../../utils";

/**
 * Membership Card
 * (used in user account's membership page)
 *
 * Types of incoming periods
 * ===
 *
 * - non-renewable
 *   - renew_index will be null
 * - tails of two+ element renewable chains
 *   - renew_index will be non-null
 *   - WILL have 'root' set
 * - tails (that are also the roots/heads) of one-element renewable chains
 *   - renew_index will be non-null (0)
 *   - will NOT have 'root' set
 *
 * View notes
 * ===
 *
 * All views show:
 * - id
 * - plan
 * - status (active/queued/deactivated)
 * - start date (if not queued)
 * - end date (if plan !== life)
 * - acquisition (exactly one of):
 *   - order [order id]
 *   - gift [no additional fields]
 *   - granter [user name and email]
 *
 * Fields depending on plan:
 * - Life [no additional fields]
 * - Onetime
 *   - onetime_amount
 * - Renewable [is root]
 *   - renew_index
 *   - is_marked_for_cancel
 * - Renewable [is not root]
 *   - [all of the above in Renewable]
 *   - ROOT start_date
 *
 * Incoming fields with notes:
 * ===
 *
 * // BASIC //
 * 'id'
 * 'plan'
 * 'is_active'
 * 'is_queued'
 * 'onetime_amount'
 *     always set (even for renewables)?
 *     though shouldn't be displayed for renewables
 *
 * // DATES //
 * 'start_date'
 *     null if queued
 * 'end_date'
 *     null if plan=life and active
 * 'deactivation_date'
 *     null if active or queued
 * 'created_at'
 *     always set
 *
 * // RELATIONS //
 * 'granter'
 *     set if granted (and not purchased or gifted)
 * 'gift'
 *     set if gifted (and not purchased or gift)
 * 'order'
 *     set if purchased (and not granted or gift)
 *
 * // RELATING TO RENEWABLES //
 * 'renew_index'
 *     set if renewable, null if not
 *     (=0 if root)
 * 'is_marked_for_cancel'
 *     maybe set if renewable
 * 'root'
 *     set if the tail of 2+ element renewable chain
 *     implies it's the result of an auto-renew
 */
const MembershipCard = ({
  period: {
    id,
    plan,
    is_active,
    is_queued,
    start_date,
    end_date,
    deactivation_date,
    onetime_amount,
    order,
    gift,
    granter,
    is_marked_for_cancel,
    renew_index,
    root
  }
}) => (
  <Card className={styles.card}>
    <CardHeader>
      <CardHeaderTitle>Membership Period #{id}</CardHeaderTitle>
    </CardHeader>
    <CardContent>
      <Columns>
        <Column>
          {/* Active/queued status */}
          <p>
            <strong>Status:</strong>
            &nbsp;
            {is_active ? (
              <span>
                <span className="has-text-success">Active</span>
                {is_marked_for_cancel && (
                  <span>
                    &nbsp;&ndash;&nbsp;
                    <span className="has-text-warning">
                      Marked for cancellation
                    </span>
                    &nbsp;(will not renew after end date)
                  </span>
                )}
              </span>
            ) : is_queued ? (
              <span className="has-text-info">Queued</span>
            ) : (
              <span className="has-text-grey">Deactivated</span>
            )}
          </p>
          {/* Plan */}
          <p>
            <strong>Plan:</strong>
            &nbsp;
            {PLAN_DISPLAY_NAMES[plan]}
            {ENUM_QUANTIFIABLE_PLANS[plan] && ` (x${onetime_amount})`}
          </p>
          {/* Acquisition */}
          <p>
            <strong>Acquisition:</strong>
            &nbsp;
            {order ? (
              <span>Purchased (Order #{order.id})</span>
            ) : gift ? (
              <span>Redeemed from gift code</span>
            ) : granter ? (
              <span>Granted (by {userOneLiner(granter)})</span>
            ) : (
              "Invalid"
            )}
          </p>
        </Column>
        <Column>
          {/* Start Date */}
          {!is_queued && (
            <p>
              <strong>Started on:</strong>
              &nbsp;
              {formatMomentLocalLL(parseMomentTimezoneConfig(start_date))}
            </p>
          )}
          {/* End date */}
          {end_date && (
            <p>
              <strong>{deactivation_date ? "Ended on" : "Will End On"}:</strong>
              &nbsp;
              {formatMomentLocalLL(parseMomentTimezoneConfig(end_date))}
            </p>
          )}
          {/* Renewal Info */}
          {renew_index !== null && (
            <p>
              <strong>Renewal:</strong>
              &nbsp;#
              {renew_index}
              {root && (
                <span>
                  &nbsp;(renew chain started on&nbsp;
                  {formatMomentLocalLL(
                    parseMomentTimezoneConfig(root.start_date)
                  )}
                  )
                </span>
              )}
            </p>
          )}
        </Column>
      </Columns>
    </CardContent>
  </Card>
);

export default MembershipCard;
