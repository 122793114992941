import React from "react";
import { observer, inject } from "mobx-react";

import { makeRecordValidator, makeFieldValidator } from "../../../validators";
import formValidationErrorLayer from "../../../errors/formValidationErrorLayer";
import PublicSite from "../../../components/PublicSite";
import SimpleFormPage from "../../../components/SimpleFormPage";
import TextFieldWithErrors from "../../../components/fields/TextFieldWithErrors";
import CustomFinalForm from "../../../components/CustomFinalForm";
import { compose, queryParamsFromWithRouterProps } from "../../../utils";
import CustomRedirect from "../../../components/CustomRedirect";
import { LOGIN_PATH, standardFinalFormSubscriptions } from "../../../config";
import FinalSubmit from "../../../components/fields/FinalSubmit";

const validate = makeRecordValidator(
  makeFieldValidator("email", "required", "email")
);

class Forgot extends React.Component {
  resetForm = null;

  state = {
    done: false
  };

  onSubmit = ({ email }) => {
    return formValidationErrorLayer(
      this.props.authStore
        .forgotPassword(email)
        .then(() => {
          // reset form
          this.resetForm();
        })
        .then(() => {
          this.setState({ done: true });
        })
    );
  };

  render() {
    if (this.state.done) {
      return <CustomRedirect to={LOGIN_PATH} />;
    }

    // Parse pre-set email from query string if set.
    const query = queryParamsFromWithRouterProps(this.props);
    const initialEmail = query.email || null;

    return (
      <PublicSite>
        <SimpleFormPage title="Forgot/Set Password">
          <CustomFinalForm
            onSubmit={this.onSubmit}
            validate={validate}
            initialValues={{
              email: initialEmail
            }}
            subscription={standardFinalFormSubscriptions}
            render={renderProps => {
              const { handleSubmit, form } = renderProps;
              this.resetForm = form.reset;
              return (
                <form onSubmit={handleSubmit}>
                  <TextFieldWithErrors
                    type="text"
                    name="email"
                    label="Email"
                    placeholder="e.g. alexsmith@gmail.com"
                  />
                  <FinalSubmit {...renderProps} isSize="normal" />
                </form>
              );
            }}
          />
        </SimpleFormPage>
      </PublicSite>
    );
  }
}

export default compose(
  inject("authStore"),
  observer
)(Forgot);
