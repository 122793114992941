import React, { Component } from "react";
import { isObservableArray } from "mobx";
import { Field, Label, Control } from "bloomer";
import { mapReactSelectValueToField } from "../../../utils";

export default class FancySelectFilterField extends Component {
  onChange = value => {
    if (!value || value.length === 0) {
      // empty array, null, or undefined
      this.props.onChange(this.props.isMulti ? [] : null);
    } else if (Array.isArray(value) || isObservableArray(value)) {
      // this is a multiselect
      this.props.onChange(value.map(mapReactSelectValueToField));
    } else {
      // single-value
      this.props.onChange(mapReactSelectValueToField(value));
    }
  };

  render() {
    const { label, component } = this.props;

    return (
      <Field>
        <Label>{label}</Label>
        <Control>
          {React.createElement(component, {
            ...this.props,
            onChange: this.onChange
          })}
        </Control>
      </Field>
    );
  }
}
