import { observable, decorate, runInAction, action } from "mobx";
import agent from "../agent";
import { LOADER_TAG_PUBLIC_BIKES } from "../constants";

export class BikePublicStore {
  bikes = observable.array();

  clear() {
    this.bikes.clear();
  }

  loadAll() {
    return agent.Bikes.all({ loaderTag: LOADER_TAG_PUBLIC_BIKES }).then(
      body => {
        const bikes = body.data;
        runInAction(() => {
          this.bikes.replace(bikes);
        });
      }
    );
  }
}

decorate(BikePublicStore, {
  bikes: observable,
  clear: action.bound,
  loadAll: action.bound
});

export default new BikePublicStore();
