import React, { Component } from "react";
import { Form } from "react-final-form";
import { Columns, Column, Button } from "bloomer";

import TextFieldWithErrors from "../../../components/fields/TextFieldWithErrors";
import DateFieldWithErrors from "../../../components/fields/DateFieldWithErrors";
import SelectFieldWithErrors from "../../../components/fields/SelectFieldWithErrors";
import FancySelectFieldWithErrors from "../../../components/fields/FancySelectFieldWithErrors";

import SelectAcquisition from "../../../components/selects/immediate/SelectAcquisition";
import SelectOrganization from "../../../components/selects/immediate/SelectOrganization";
import SelectBColor from "../../../components/selects/immediate/SelectBColor";
import SelectBMake from "../../../components/selects/immediate/SelectBMake";
import SelectBSize from "../../../components/selects/immediate/SelectBSize";
import SelectBStyle from "../../../components/selects/immediate/SelectBStyle";
import SelectBModel from "../../../components/selects/throttled/SelectBModel";
import SelectReceivingEvent from "../../../components/selects/throttled/SelectReceivingEvent";

import LoadingText from "../../../components/LoadingText";

import bikeValidator from "../BikeCrud/bikeValidator";
import { preSave as bikePreSave } from "../../../stores/bikeEditor";

import styles from "./bike-batch.module.scss";
import {
  BIKE_STATUS_NEW,
  BIKE_STATUS_SCRAPPED,
  LOADER_TAG_ADMIN_BIKES
} from "../../../constants";
import {
  standardFinalFormSubscriptions,
  ENUM_BIKE_CONDITIONS
} from "../../../config";
import FinalFormErrors from "../../../components/FinalFormErrors";
import agent from "../../../agent";
import formValidationErrorLayer from "../../../errors/formValidationErrorLayer";
import SavedBikeRow from "./SavedBikeRow";

/**
 * CompactBikeEditorRow
 *
 * Fields
 *
 * - status (new or scrapped)
 * - condition
 * - received at date
 * - notes (admin)
 * - notes (bike)
 * - make
 * - model
 * - style
 * - size
 * - colors
 * - acquisition
 * - receiving event
 * - donating organization
 */

export default class CompactBikeEditorRow extends Component {
  state = { bproto: null, savedBike: null };

  onRemove = () => {
    if (this.props.id) {
      this.props.onRemove(this.props.id);
    }
  };

  onSubmit = fields => {
    return formValidationErrorLayer(
      agent.AdminBikes.create(
        { bike: bikePreSave(fields) },
        { loaderTag: LOADER_TAG_ADMIN_BIKES }
      ).then(body => {
        const bike = body.data;
        this.setState({ savedBike: bike });
      })
    );
  };

  componentDidMount() {
    // save from initial prop in case it changes
    this.setState({ bproto: this.props.bproto });
  }

  render() {
    const { bproto, savedBike } = this.state;

    if (savedBike) {
      // ensure submit handler is unset if saved
      this.props.forwardHandleSubmit(null);

      return <SavedBikeRow bike={savedBike} />;
    }

    return (
      <div className={styles.row}>
        <Button
          className={styles.removeButton}
          onClick={this.onRemove}
          isColor="danger"
          isSize="small"
          isOutlined
        >
          Remove
        </Button>
        <Form
          initialValues={bproto}
          validateOnBlur
          validate={bikeValidator}
          onSubmit={this.onSubmit}
          subscription={standardFinalFormSubscriptions}
          render={({ handleSubmit, ...renderProps }) => {
            this.props.forwardHandleSubmit(handleSubmit);

            return (
              <form onSubmit={handleSubmit}>
                {renderProps.submitting && (
                  <div className={styles.loadingOverlay}>
                    <LoadingText noMargin />
                  </div>
                )}
                <Columns>
                  <Column isSize={"1/3"}>
                    <FancySelectFieldWithErrors
                      name="bmake"
                      label="Make"
                      component={SelectBMake}
                      componentProps={{ isCreateable: true }}
                    />
                    <FancySelectFieldWithErrors
                      name="bmodel"
                      label="Model"
                      component={SelectBModel}
                      componentProps={{ isCreateable: true }}
                    />
                    <FancySelectFieldWithErrors
                      name="bstyle"
                      label="Style"
                      component={SelectBStyle}
                    />
                    <FancySelectFieldWithErrors
                      name="bsize"
                      label="Size"
                      component={SelectBSize}
                    />
                    <FancySelectFieldWithErrors
                      name="bcolors"
                      label="Color(s)"
                      component={SelectBColor}
                      componentProps={{ isMulti: true }}
                    />
                  </Column>
                  <Column isSize={"2/3"}>
                    <Columns>
                      <Column>
                        <SelectFieldWithErrors name="status" label="Status">
                          {[BIKE_STATUS_NEW, BIKE_STATUS_SCRAPPED].map(
                            status => (
                              <option key={status} name={status}>
                                {status}
                              </option>
                            )
                          )}
                        </SelectFieldWithErrors>
                      </Column>
                      <Column>
                        <SelectFieldWithErrors
                          name="condition"
                          label="Incoming Condition"
                        >
                          <option />
                          {ENUM_BIKE_CONDITIONS.map(condition => (
                            <option key={condition} name={condition}>
                              {condition}
                            </option>
                          ))}
                        </SelectFieldWithErrors>
                      </Column>
                      <Column>
                        <DateFieldWithErrors
                          name="received_at"
                          label="Received At"
                          hasTodayButton
                        />
                      </Column>
                    </Columns>
                    <Columns isMultiline>
                      <Column isSize={{ tablet: "1/2", desktop: "1/3" }}>
                        <FancySelectFieldWithErrors
                          name="acquisition"
                          label="Acquisition"
                          component={SelectAcquisition}
                        />
                      </Column>
                      <Column isSize={{ tablet: "1/2", desktop: "1/3" }}>
                        <FancySelectFieldWithErrors
                          name="receivingevent"
                          label="Receiving Event"
                          component={SelectReceivingEvent}
                          componentProps={{ isClearable: true }}
                        />
                      </Column>
                      <Column isSize={{ tablet: "1/2", desktop: "1/3" }}>
                        <FancySelectFieldWithErrors
                          name="organization_donating"
                          label="Donating Organization"
                          component={SelectOrganization}
                          componentProps={{ isClearable: true }}
                        />
                      </Column>
                    </Columns>
                    <Columns>
                      <Column>
                        <TextFieldWithErrors
                          isTextarea
                          name="notes_admin"
                          label="Notes (Admin)"
                        />
                      </Column>
                      <Column>
                        <TextFieldWithErrors
                          isTextarea
                          name="notes_bike"
                          label="Notes (Bike)"
                        />
                      </Column>
                    </Columns>
                  </Column>
                </Columns>
                <FinalFormErrors {...renderProps} />
              </form>
            );
          }}
        />
      </div>
    );
  }
}
