import React from "react";

import LinkButton from "../components/LinkButton";

const ResourceCreateLinkButton = ({
  to,
  label,
  isColor = "info",
  isMarginless = false
}) => {
  const cn = isMarginless ? "" : "has-standard-mb";
  return (
    <div className={cn}>
      <LinkButton to={to} isColor={isColor}>
        {label}
      </LinkButton>
    </div>
  );
};

export default ResourceCreateLinkButton;
