import React from "react";
import { Link } from "react-router-dom";

/**
 * BSBC Logo
 *
 * Uses srcset attribute to load 2x image on retina displays
 */
const Logo = ({ to = "/" }) => (
  <div style={{ width: 114, height: 114, alignSelf: "center", flexShrink: 0 }}>
    <Link to={to}>
      <img
        src="/img/logo-1x.png"
        srcSet="/img/logo-2x.png 2x"
        alt="Bradley Street Bicycle Co-op"
      />
    </Link>
  </div>
);

export default Logo;
