import React from "react";
import { Helmet } from "react-helmet";

const TitleHelmet = ({ title }) => (
  <Helmet>
    <title>{title}</title>
    <meta property="og:title" content={title} />
    <meta name="twitter:title" content={title} />
  </Helmet>
);

export default TitleHelmet;
