import React, { Component } from "react";
import classNames from "classnames";

import styles from "./full-width-image.module.scss";

export default class FullWidthImage extends Component {
  render() {
    const { noMargin = false, ...restProps } = this.props;

    return (
      <img
        {...restProps}
        alt={this.props.alt}
        className={classNames(
          styles.fullWidthImage,
          !noMargin && styles.marginBottom,
          this.props.className
        )}
      />
    );
  }
}
