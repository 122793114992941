import React, { PureComponent } from "react";
import { Menu as MenuStyle, MenuLabel, MenuList } from "bloomer";
import { NavLink } from "react-router-dom";

const AwareMenuLink = props => (
  <li>
    <NavLink activeClassName="is-active" {...props} />
  </li>
);

export default class Menu extends PureComponent {
  render() {
    return (
      <MenuStyle>
        <MenuLabel>Account</MenuLabel>
        <MenuList>
          <AwareMenuLink exact to="/account">
            Dashboard
          </AwareMenuLink>
          <AwareMenuLink to="/account/membership">Membership</AwareMenuLink>
          <AwareMenuLink to="/account/settings">Settings</AwareMenuLink>
          <AwareMenuLink to="/account/password">Password</AwareMenuLink>
        </MenuList>

        <MenuLabel>Activity</MenuLabel>
        <MenuList>
          <AwareMenuLink to="/account/orders">Orders</AwareMenuLink>
          <AwareMenuLink to="/account/visits">Shop Visits</AwareMenuLink>
          <AwareMenuLink to="/account/bikes">Bikes Worked On</AwareMenuLink>
        </MenuList>
      </MenuStyle>
    );
  }
}
