import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { Button } from "bloomer";

import { compose } from "../../../utils";
import agent from "../../../agent";
import { LOADER_TAG_ADMIN_REPORTS_REGENERATE } from "../../../constants";

const ADMIN_REPORTS_REGENERATE_ALERT_GROUP = "reports.regenerate";

class Regenerate extends Component {
  start = () => {
    const { onDone } = this.props;

    return agent.AdminReports.generateAll(
      {},
      {
        loaderTag: LOADER_TAG_ADMIN_REPORTS_REGENERATE,
        alertGroup: ADMIN_REPORTS_REGENERATE_ALERT_GROUP
      }
    ).then(() => {
      this.props.uiStore.addAlert(
        "success",
        "New reports were generated successfully!",
        { group: ADMIN_REPORTS_REGENERATE_ALERT_GROUP }
      );

      // bubble up
      onDone && onDone();
    });
  };

  get loading() {
    return this.props.uiStore.loadingByTag(LOADER_TAG_ADMIN_REPORTS_REGENERATE);
  }

  render() {
    return (
      <p>
        <Button
          isColor="info"
          onClick={this.start}
          isLoading={this.loading}
          disabled={this.loading}
        >
          Regenerate All
        </Button>
      </p>
    );
  }
}

export default compose(
  inject("uiStore"),
  observer
)(Regenerate);
