import React from "react";
import { observer, inject } from "mobx-react";

import ShopVisitTable from "../../../components/tables/ShopVisitTable";
import ShopVisitFilters from "../../../components/filters/ShopVisitFilters";
import AdminTableSection from "../../../components/AdminTableSection";
import AdminSite from "../../../components/AdminSite";

import { compose } from "../../../utils";
import { LOADER_TAG_ADMIN_SHOPVISITS } from "../../../constants";
import PageHeading from "../../../components/PageHeading";

class ShopVisits extends React.Component {
  render() {
    return (
      <AdminSite>
        <PageHeading>Shop Visits</PageHeading>
        <AdminTableSection
          store={this.props.shopVisitAdminStore}
          getter={store => store.visits.slice()}
          loadAction={store => store.loadFiltered}
          loaderTag={LOADER_TAG_ADMIN_SHOPVISITS}
          clearAction={store => store.clear}
          filterComponent={ShopVisitFilters}
          tableComponent={ShopVisitTable}
        />
      </AdminSite>
    );
  }
}

export default compose(
  inject("shopVisitAdminStore"),
  observer
)(ShopVisits);
