import React from "react";
import { inject, observer } from "mobx-react";
import { reaction } from "mobx";
import classNames from "classnames";

import styles from "./frozenloader.module.scss";
import { compose } from "../../utils";

class FrozenLoader extends React.Component {
  state = {
    fading: false
  };

  constructor(props) {
    super(props);

    this.disposeReaction = reaction(
      () => this.props.uiStore.frozen,
      frozen => {
        if (!frozen) {
          this.setState({ fading: true });
          this.timerId = setTimeout(() => {
            this.setState({ fading: false });
          }, 300); // NOTE must match css animation time!
        } else {
          // edge case: make sure timer is cleared
          // if frozen went true while fading
          if (this.timerId) {
            clearTimeout(this.timerId);
            this.timerId = null;
            this.setState({ fading: false });
          }
        }
      }
    );
  }

  componentWillUnmount() {
    if (this.disposeReaction) {
      this.disposeReaction();
    }
  }

  render() {
    if (!this.props.uiStore.frozen && !this.state.fading) {
      return null;
    }
    return (
      <div
        className={classNames(styles.frozen, {
          [styles.fading]: this.state.fading
        })}
      >
        <div className={styles.wheel}>
          <div className={styles.spoke} />
          <div className={styles.spoke} />
          <div className={styles.spoke} />
          <div className={styles.spoke} />
          <div className={styles.spoke} />
          <div className={styles.spoke} />
          <div className={styles.spoke} />
          <div className={styles.spoke} />
        </div>
        <div className={styles.written}>Loading...</div>
      </div>
    );
  }
}

export default compose(
  inject("uiStore"),
  observer
)(FrozenLoader);
