import React from "react";
import { inject, observer } from "mobx-react";

import { nameToTemplate } from "./pageTemplates";
import { compose, locationToPageId } from "../utils";
import NotFound from "../pages/NotFound";
import PublicSite from "./PublicSite";
import LoadingText from "./LoadingText";

class RemotePage extends React.Component {
  state = {
    page: null,
    template: null
  };

  componentWillMount() {
    // Initial pull
    this.getNewPageByLocation(this.props.location);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.location !== nextProps.location) {
      // A new page is being requested
      this.getNewPageByLocation(nextProps.location);
    }
  }

  getNewPageByLocation(location) {
    // reset page and template
    this.setState({ page: null, template: null });

    // look up page id from location
    const pageId = locationToPageId(location);

    if (!pageId) {
      this.setState({ page: false });
      return;
    }

    return this.props.pagePublicStore
      .getPage(pageId, { displayError: false })
      .then(page => {
        // set page and template
        const template = nameToTemplate(page.template);
        if (template) {
          this.setState({ page, template });
          return page;
        } else {
          this.setState({ page: false });
        }
      })
      .catch(error => {
        this.setState({ page: false });
      });
  }

  render() {
    const { page, template } = this.state;

    if (page === false) {
      // Render NotFound page (don't redirect to NOTFOUND_PATH in this case,
      // so that user can inspect current incorrect url e.g. to make changes)
      return <NotFound {...this.props} />;
    } else if (page === null) {
      // Currently loading, so display shell of public site
      return (
        <PublicSite>
          <LoadingText />
        </PublicSite>
      );
    }

    return React.createElement(template, {
      ...this.props,
      page
    });
  }
}

export default compose(
  inject("pagePublicStore"),
  observer
)(RemotePage);
