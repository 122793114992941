import React from "react";
import { observer, inject } from "mobx-react";

import {
  REDIRECT_AFTER_LOGIN_DEFAULT,
  standardFinalFormSubscriptions
} from "../../../config";
import {
  makeRecordValidator,
  makeFieldValidator,
  minLength
} from "../../../validators";
import formValidationErrorLayer from "../../../errors/formValidationErrorLayer";
import PublicSite from "../../../components/PublicSite";
import SimpleFormPage from "../../../components/SimpleFormPage";
import TextFieldWithErrors from "../../../components/fields/TextFieldWithErrors";
import CustomFinalForm from "../../../components/CustomFinalForm";
import SingleCheckboxFieldWithErrors from "../../../components/fields/SingleCheckboxFieldWithErrors";
import CustomRedirect from "../../../components/CustomRedirect";
import { compose } from "../../../utils";
import FinalSubmit from "../../../components/fields/FinalSubmit";

const validate = makeRecordValidator(
  makeFieldValidator("name", "required", minLength(3)),
  makeFieldValidator("email", "required", "email"),
  makeFieldValidator("password", "required", minLength(6), [
    "matches",
    "password_confirmation"
  ]),
  makeFieldValidator("password_confirmation", "required"),
  makeFieldValidator("newsletter")
);

class Register extends React.Component {
  onSubmit = ({ name, email, password, password_confirmation, newsletter }) => {
    return formValidationErrorLayer(
      this.props.authStore.register(
        name,
        email,
        password,
        password_confirmation,
        newsletter
      )
    );
  };

  render() {
    if (this.props.authStore.isAuthenticated) {
      return <CustomRedirect to={REDIRECT_AFTER_LOGIN_DEFAULT} />;
    }

    return (
      <PublicSite>
        <SimpleFormPage title="Register">
          <CustomFinalForm
            onSubmit={this.onSubmit}
            validate={validate}
            initialValues={{ newsletter: true }}
            subscription={standardFinalFormSubscriptions}
            render={renderProps => {
              const { handleSubmit } = renderProps;
              return (
                <form onSubmit={handleSubmit}>
                  <TextFieldWithErrors
                    type="text"
                    name="name"
                    label="Name"
                    placeholder="e.g. Alex Smith"
                  />
                  <TextFieldWithErrors
                    type="text"
                    name="email"
                    label="Email"
                    placeholder="e.g. alexsmith@gmail.com"
                  />
                  <TextFieldWithErrors
                    type="password"
                    name="password"
                    label="Password"
                    placeholder="Password"
                    extraHelp="Must be at least 6 characters"
                  />
                  <TextFieldWithErrors
                    type="password"
                    name="password_confirmation"
                    label="Password (Again)"
                  />
                  <SingleCheckboxFieldWithErrors
                    name="newsletter"
                    label="Subscribe to monthly newsletter"
                  />
                  <FinalSubmit
                    {...renderProps}
                    label="Sign Up"
                    isSize="normal"
                  />
                </form>
              );
            }}
          />
        </SimpleFormPage>
      </PublicSite>
    );
  }
}

export default compose(
  inject("authStore"),
  observer
)(Register);
