import React, { Component } from "react";
import SelectFieldImmediate from "../SelectFieldImmediate";
import agent from "../../../agent";
import { LOADER_TAG_ADMIN_FIELD_ACQUISITION } from "../../../constants";

export default class SelectAcquisition extends Component {
  render() {
    return (
      <SelectFieldImmediate
        getFn={() =>
          agent.AdminAcquisitions.all({
            loaderTag: LOADER_TAG_ADMIN_FIELD_ACQUISITION
          })
        }
        loaderTag={LOADER_TAG_ADMIN_FIELD_ACQUISITION}
        {...this.props}
      />
    );
  }
}
