import React from "react";
import { Field } from "react-final-form";
import { Field as FieldStyle, Label, Control, Help, Button } from "bloomer";
import _pick from "lodash.pick";

import Datepicker from "./Datepicker";
import { deriveFieldError } from "../../utils";
import { momentNowLocal, formatMomentLocalYMD } from "../../datetime";

/**
 * Helper for react-final-form
 */
export default class DateFieldWithErrors extends React.PureComponent {
  render() {
    const { name, label, hasTodayButton = false } = this.props;
    return (
      <Field
        name={name}
        subscription={{
          value: true,
          error: true,
          submitError: true,
          touched: true,
          dirtySinceLastSubmit: true
        }}
      >
        {({ input, meta }) => {
          const derivedError = deriveFieldError(meta);
          return (
            <FieldStyle>
              <Label>{label}</Label>
              <FieldStyle hasAddons={hasTodayButton}>
                <Control>
                  {React.createElement(Datepicker, {
                    ..._pick(input, ["value", "onChange"]),
                    inputProps: _pick(input, ["name", "onBlur", "onFocus"]),
                    isColor: derivedError ? "danger" : null
                  })}
                </Control>
                {hasTodayButton && (
                  <Control>
                    <Button
                      title="Set to today"
                      onClick={() => {
                        input.onChange(formatMomentLocalYMD(momentNowLocal()));
                      }}
                    >
                      Δ
                    </Button>
                  </Control>
                )}
              </FieldStyle>
              {derivedError && <Help isColor="danger">{derivedError}</Help>}
            </FieldStyle>
          );
        }}
      </Field>
    );
  }
}
