import _get from "lodash.get";

import uiStore from "../../stores/ui";
import { TRANSLATE_ERROR_MESSAGES } from "../../config";

import ValidationError from "../../errors/ValidationError";
import UserError from "../../errors/UserError";
import NetworkError from "../../errors/NetworkError";
import ServerError from "../../errors/ServerError";
import AuthenticationError from "../../errors/AuthenticationError";
import AuthorizationError from "../../errors/AuthorizationError";
import NotFoundError from "../../errors/NotFoundError";
import TooManyRequestsError from "../../errors/TooManyRequestsError";

export default function attachErrorInterceptor(ax) {
  // Handle errors (see /doc/errors.md)
  ax.interceptors.response.use(
    response => response,
    error => {
      if (error.isRetryRequestError) {
        // Special case: the refresh retry request failed, so we're going to
        // re-throw and let the original "outer" error interceptor handle it
        delete error.isRetryRequestError;
        return Promise.reject(error);
      }

      const { config = {} } = error;
      const statusMaybe = _get(error, ["response", "status"]);

      // Parse error into one of our custom error classes
      // depending on status code and body content
      let parsedError;
      if (!error.response) {
        // Network error (no response body)
        parsedError = new NetworkError(
          "Could not reach server. Please make sure your internet is working and try again. If the problem persists, please contact us."
        );
      } else {
        const { status, data } = error.response;

        // attempt to translate generic messages
        const message = TRANSLATE_ERROR_MESSAGES[data.message] || data.message;

        if (status === 422) {
          if (data.errors) {
            // Validation error
            parsedError = new ValidationError(message, data.errors);
          } else {
            // User error
            parsedError = new UserError(message);
          }
        } else if (status >= 500 && status < 600) {
          // Server error
          parsedError = new ServerError("An unknown error occurred.");
        } else if (status === 401) {
          // Authentication error
          parsedError = new AuthenticationError("Please login to do that.");
        } else if (status === 403) {
          // Authorization error
          parsedError = new AuthorizationError(
            "You don't have the privileges to do that."
          );
        } else if (status === 404) {
          // Not Found error
          // Make message based on config.notFoundName (if set)
          const name = config.notFoundName || "resource";
          parsedError = new NotFoundError(
            `The requested ${name} was not found.`
          );
        } else if (status === 429) {
          // Not Found error
          parsedError = new TooManyRequestsError(
            "You're doing that too much. Please wait five minutes before trying again."
          );
        } else {
          // Catch-all
          // log real error
          console.error(error);
          parsedError = new Error("An unknown error occurred.");
        }
      }

      // send off to ui as alert? default yes
      if (
        (!config.hasOwnProperty("displayError") || config.displayError) &&
        (statusMaybe !== 422 || config.dontAlert422 !== true) // respect dontAlert422 config option
      ) {
        const alertConfig = {};
        if (config.hasOwnProperty("errorGroup")) {
          alertConfig.group = config.errorGroup;
        }
        if (config.hasOwnProperty("errorClearableByUser")) {
          alertConfig.clearableByUser = config.errorClearableByUser;
        }
        if (config.hasOwnProperty("errorClearOnRouteChange")) {
          alertConfig.clearOnRouteChange = config.errorClearOnRouteChange;
        }
        if (config.hasOwnProperty("errorClearOnSubmit")) {
          alertConfig.clearOnSubmit = config.errorClearOnSubmit;
        }

        uiStore.addAlert("error", parsedError, alertConfig);
      }

      // re-throw error in promise chain? default yes
      if (!config.hasOwnProperty("rethrowError") || config.rethrowError) {
        if (config.isRetryRequest) {
          // Attach flag directly on error (is picked up on original ("outer") error handler)
          parsedError.isRetryRequestError = true;
        }
        // Throw parsed error...
        return Promise.reject(parsedError);
      }

      // Resolve with nothing (NOT the default, see above)
      return Promise.resolve();
    }
  );

  return ax;
}
