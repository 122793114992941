import React, { Component } from "react";
import classNames from "classnames";

import styles from "./user-badge.module.scss";
import {
  VOLUNTEER_LEVEL_BASIC,
  VOLUNTEER_LEVEL_COORDINATOR,
  VOLUNTEER_LEVEL_SUPER
} from "../../../../constants";

const levelToClass = {
  [VOLUNTEER_LEVEL_BASIC]: "level-basic",
  [VOLUNTEER_LEVEL_SUPER]: "level-super",
  [VOLUNTEER_LEVEL_COORDINATOR]: "level-coordinator"
};

export default class UserBadge extends Component {
  render() {
    const { user } = this.props;

    return (
      <div
        className={classNames(
          styles.badge,
          styles[levelToClass[user.volunteerlevel.name]]
        )}
      >
        {user.name}
      </div>
    );
  }
}
