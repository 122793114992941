import React from "react";
import ReactMarkdown from "react-markdown";
import { Content } from "bloomer";

const renderers = {};

const plugins = [];

const Markdown = ({ source, isSize }) => (
  <Content isSize={isSize}>
    <ReactMarkdown source={source} renderers={renderers} plugins={plugins} />
  </Content>
);

export default Markdown;
