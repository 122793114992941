import { decorate } from "mobx";

export class UserPublicStore {
  //
}

decorate(UserPublicStore, {
  //
});

export default new UserPublicStore();
