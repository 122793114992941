import React, { Component } from "react";
import { Button, Box } from "bloomer";
import { observer, inject } from "mobx-react";

import { compose } from "../../../utils";
import agent from "../../../agent";
import StationSite from "../../../components/StationSite";
import MediumCenterInner from "../../../components/MediumCenterInner";
import PageHeading from "../../../components/PageHeading";
import CurrentlyCheckedInUsersTable from "../common/CurrentlyCheckedInUsersTable";
import { LOADER_TAG_STATION_USERS } from "../../../constants";
import RfidListen from "../common/RfidListen";

const CHECK_OUT_ALERT_GROUP = "check-out";

class CheckOut extends Component {
  onCheckOut = userId => {
    agent.StationPunch.checkOut(
      { id: userId },
      {
        loaderTag: LOADER_TAG_STATION_USERS,
        alertGroup: CHECK_OUT_ALERT_GROUP,
        notFoundName: "user"
      }
    )
      .then(() => {
        this.props.uiStore.addAlert(
          "success",
          "You were checked out successfully. Thanks for your help today!",
          { group: CHECK_OUT_ALERT_GROUP }
        );
      })
      .finally(() => {
        // force reload currently checked in
        this.props.userStationStore.loadCurrentlyCheckedIn();

        // redirect
        this.props.history.push("/station", { wasRedirect: true });
      });
  };

  render() {
    return (
      <StationSite>
        <RfidListen
          onSuccess={userId => {
            this.onCheckOut(userId);
          }}
        />
        <MediumCenterInner>
          <Box>
            <PageHeading>Check Out</PageHeading>
            <p>
              Please don't check out other people unless they are leaving at the
              same time. Visits that aren't checked out will be counted as one
              hour.
            </p>
            <CurrentlyCheckedInUsersTable
              renderActions={user => {
                return (
                  <Button
                    onClick={() => this.onCheckOut(user.id)}
                    isColor="warning"
                  >
                    Check Out
                  </Button>
                );
              }}
            />
          </Box>
        </MediumCenterInner>
      </StationSite>
    );
  }
}

export default compose(
  inject("userStationStore", "uiStore"),
  observer
)(CheckOut);
