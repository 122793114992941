import { observable, decorate, runInAction, action } from "mobx";
import agent from "../agent";
import { LOADER_TAG_ACCOUNT_ORDERS } from "../constants";

export class OrderOwnStore {
  orders = observable.array();

  order = null;

  clearAll() {
    this.orders.clear();
    this.order = null;
  }

  loadAllOrders() {
    return agent.Orders.all({ loaderTag: LOADER_TAG_ACCOUNT_ORDERS })
      .then(body => {
        const orders = body.data;
        runInAction(() => {
          this.orders.replace(orders);
        });
      })
      .catch(error => {
        this.clearAll();
        return Promise.reject(error); // re-throw
      });
  }

  loadOrder(id) {
    this.order = null;

    return agent.Orders.show(
      { id },
      {
        loaderTag: LOADER_TAG_ACCOUNT_ORDERS,
        notFoundName: "order"
      }
    ).then(body => {
      const order = body.data;
      runInAction(() => {
        this.order = order;
      });
    });
  }
}

decorate(OrderOwnStore, {
  orders: observable,
  order: observable,
  clearAll: action.bound,
  loadAllOrders: action.bound,
  loadOrder: action.bound
});

export default new OrderOwnStore();
