import React, { Fragment } from "react";
import { observer, inject } from "mobx-react";
import { Link, withRouter } from "react-router-dom";
import {
  Breadcrumb,
  BreadcrumbItem,
  Level,
  LevelLeft,
  LevelItem,
  Button
} from "bloomer";

import { compose, formatMoney } from "../../utils";
import { LOADER_TAG_CART } from "../../constants";

import PageHeading from "../../components/PageHeading";

import LoadingText from "../../components/LoadingText";
import MediumCenterInner from "../../components/MediumCenterInner";
import Markdown from "../../components/Markdown";
import ImageFromMedialibrary from "../../components/ImageFromMedialibrary";
import DescriptionSEO from "../../components/seo/DescriptionSEO";
import UrlSEO from "../../components/seo/UrlSEO";

class _ItemView extends React.Component {
  state = {
    variation: null
  };

  onSelectVariation = e => {
    const variation = e.target.value || null;

    this.setState({
      variation
    });
  };

  componentDidUpdate(prevProps) {
    // if item changed, reset variation
    if (
      !!prevProps.item !== !!this.props.item ||
      (prevProps.item &&
        this.props.item &&
        prevProps.item.id !== this.props.item.id)
    ) {
      this.setState({ variation: null });
    }
  }

  get cartLoading() {
    return this.props.uiStore.loadingByTag(LOADER_TAG_CART);
  }

  get addToCartEnabled() {
    // only enable button if item doesn't require variations, or
    // a variation is selected and it is one of the item's variations

    const { item } = this.props;

    if (!item) {
      return false;
    }

    const { variations } = item;

    if (!variations) {
      return true;
    }

    // requires a variation
    return (
      this.state.variation && variations.indexOf(this.state.variation) > -1
    );
  }

  render() {
    const { loading, item, cartStore, authStore } = this.props;

    const isAuthenticated = authStore.isAuthenticated;

    return (
      <Fragment>
        {loading ? (
          <LoadingText />
        ) : !item ? (
          <div />
        ) : (
          <MediumCenterInner>
            <UrlSEO url={`/store/${item.id}`} />
            <DescriptionSEO description={item.description} />
            <Breadcrumb>
              <ul>
                <BreadcrumbItem>
                  <Link to="/store">Store</Link>
                </BreadcrumbItem>
                <BreadcrumbItem isActive>
                  <Link to={`/store/${item.id}`}>{item.title}</Link>
                </BreadcrumbItem>
              </ul>
            </Breadcrumb>
            <PageHeading>{item.title}</PageHeading>
            <Level>
              <LevelLeft>
                {item.variations && (
                  <LevelItem>
                    <div
                      className="select is-medium"
                      style={{ verticalAlign: "middle" }}
                    >
                      <select
                        value={this.state.variation || ""}
                        onChange={this.onSelectVariation}
                      >
                        <option value={""}>Select variation...</option>
                        {item.variations.map(variation => (
                          <option key={variation} value={variation}>
                            {variation}
                          </option>
                        ))}
                      </select>
                    </div>
                  </LevelItem>
                )}
                <LevelItem>
                  <Button
                    onClick={() =>
                      cartStore.add(
                        item.id,
                        item.is_quantifiable ? 1 : null,
                        this.state.variation
                      )
                    }
                    isColor="primary"
                    isSize="medium"
                    isLoading={this.cartLoading}
                    disabled={
                      !isAuthenticated ||
                      this.cartLoading ||
                      !this.addToCartEnabled
                    }
                  >
                    <span className="fas fa-plus" />
                    &nbsp; Add to Cart
                  </Button>
                </LevelItem>
                <LevelItem>
                  <span className="is-size-4">{formatMoney(item.price)}</span>
                </LevelItem>
              </LevelLeft>
            </Level>
            {!isAuthenticated && (
              <p>
                Please{" "}
                <Link
                  to={{
                    pathname: "/auth/login",
                    state: { redirectAfterLogin: this.props.location.pathname }
                  }}
                >
                  login
                </Link>{" "}
                or <Link to="/account/register">register</Link> to add products
                to your cart.
              </p>
            )}
            {item.description && (
              <Markdown source={item.description} isSize="medium" />
            )}
            {item.image && <ImageFromMedialibrary image={item.image} />}
          </MediumCenterInner>
        )}
      </Fragment>
    );
  }
}

const ItemView = compose(
  withRouter,
  inject("cartStore", "uiStore", "authStore"),
  observer // also observes loading/item props
)(_ItemView);

export default ItemView;
