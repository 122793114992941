import React, { Component } from "react";
import SelectFieldImmediate from "../SelectFieldImmediate";
import agent from "../../../agent";
import { LOADER_TAG_ADMIN_FIELD_BCOLOR } from "../../../constants";
import { COLORS } from "../../../config";

export default class SelectBColor extends Component {
  render() {
    return (
      <SelectFieldImmediate
        getFn={() =>
          agent.AdminFields.allOfType(
            { field: "bcolor" },
            { loaderTag: LOADER_TAG_ADMIN_FIELD_BCOLOR }
          )
        }
        loaderTag={LOADER_TAG_ADMIN_FIELD_BCOLOR}
        styles={{
          option: (styles, { data }) => {
            const color = data.label;
            const colorObjectMaybe = color && COLORS[color.toLowerCase()];
            return {
              ...styles,
              ...colorObjectMaybe
            };
          },
          multiValue: (styles, { data }) => {
            const color = data.label;
            const colorObjectMaybe = color && COLORS[color.toLowerCase()];
            return {
              ...styles,
              ...colorObjectMaybe
            };
          },
          multiValueLabel: (styles, { data }) => {
            const color = data.label;
            const colorObjectMaybe = color && COLORS[color.toLowerCase()];
            const textColorObjectMaybe = colorObjectMaybe &&
              colorObjectMaybe.color && { color: colorObjectMaybe.color };
            return {
              ...styles,
              ...textColorObjectMaybe
            };
          }
        }}
        {...this.props}
      />
    );
  }
}
