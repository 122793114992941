import React, { Component } from "react";
import SelectFieldImmediate from "../SelectFieldImmediate";
import agent from "../../../agent";
import { LOADER_TAG_ADMIN_FIELD_VOLUNTEER_LEVEL } from "../../../constants";

export default class SelectVolunteerLevel extends Component {
  render() {
    return (
      <SelectFieldImmediate
        getFn={() =>
          agent.AdminVolunteerLevels.all({
            loaderTag: LOADER_TAG_ADMIN_FIELD_VOLUNTEER_LEVEL
          })
        }
        loaderTag={LOADER_TAG_ADMIN_FIELD_VOLUNTEER_LEVEL}
        {...this.props}
      />
    );
  }
}
