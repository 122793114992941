import React from "react";
import Helmet from "react-helmet";
import { DONORBOX_EMBED_URL } from "../../config";

export default function DonorBox() {
  return (
    <div>
      <Helmet>
        <script
          src="https://donorbox.org/widget.js"
          {...{ paypalExpress: "false" }}
        />
      </Helmet>
      <iframe
        src={DONORBOX_EMBED_URL}
        style={{
          height: 685,
          width: "100%",
          maxWidth: 500,
          minWidth: 310,
          maxHeight: "none !important"
        }}
        seamless="seamless"
        name="donorbox"
        frameBorder="0"
        scrolling="no"
        allowpaymentrequest="true"
        title="BSBC DonorBox"
      />
    </div>
  );
}
