import React from "react";
import BaseSortTable from "./BaseSortTable";
import { cellMoney, cellBoolean, cellStringArray } from "./helpers";
import {
  idColumn,
  createdAtColumn,
  titleColumn,
  makeActionsColumn
} from "./columns";

const columns = [
  makeActionsColumn("/admin/items"),
  idColumn,
  {
    Header: "Available?",
    accessor: "is_available",
    Cell: cellBoolean,
    width: 100
  },
  titleColumn,
  {
    Header: "Variations",
    accessor: "variations",
    Cell: cellStringArray
  },
  {
    Header: "Price",
    accessor: "price",
    Cell: cellMoney,
    width: 100
  },
  {
    Header: "Shippable?",
    accessor: "is_shippable",
    Cell: cellBoolean,
    width: 100
  },
  {
    Header: "Quantifiable?",
    accessor: "is_quantifiable",
    Cell: cellBoolean,
    width: 120
  },
  createdAtColumn
];

class ItemTable extends React.Component {
  render() {
    return <BaseSortTable columns={columns} {...this.props} />;
  }
}

export default ItemTable;
