import React, { Component } from "react";
import Select from "react-select";
import { PLAN_DISPLAY_NAMES, ENUM_GRANTABLE_PLANS } from "../../../config";
import { getCurrentReactSelectValue } from "../../../utils";

const options = ENUM_GRANTABLE_PLANS.map(name => ({
  value: name,
  label: PLAN_DISPLAY_NAMES[name]
}));

export default class SelectGrantablePlan extends Component {
  render() {
    return (
      <Select
        {...this.props}
        value={getCurrentReactSelectValue(this.props.value)}
        options={options}
      />
    );
  }
}
