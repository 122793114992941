import React, { Component } from "react";
import { Provider } from "mobx-react";
import { Switch } from "react-router-dom";

import stores from "../../stores";
import { REMOTE_PAGES } from "../../config";
import Analytics from "../Analytics";

import CustomBrowserRouter from "../CustomBrowserRouter";
import AuthRoute from "./AuthRoute";
import Route from "./BaseRoute";
import RemotePage from "../RemotePage";
import BaseHelmet from "./BaseHelmet";
import FrozenLoader from "../FrozenLoader";

// Public
import NotFound from "../../pages/NotFound";
import Home from "../../pages/Home";
import Contact from "../../pages/Contact";
import BikesForSale from "../../pages/BikesForSale";
import Store from "../../pages/Store";
import Events from "../../pages/Events";
import Donate from "../../pages/Donate";

// Auth and Account
import Login from "../../pages/authAndAccount/Login";
import Register from "../../pages/authAndAccount/Register";
import AccountDashboard from "../../pages/authAndAccount/Account/Dashboard";
import AccountMembership from "../../pages/authAndAccount/Account/Membership";
import AccountSettings from "../../pages/authAndAccount/Account/Settings";
import AccountUpdatePassword from "../../pages/authAndAccount/Account/UpdatePassword";
import AccountOrders from "../../pages/authAndAccount/Account/Orders";
import AccountOrder from "../../pages/authAndAccount/Account/Order";
import AccountVisits from "../../pages/authAndAccount/Account/Visits";
import AccountBikes from "../../pages/authAndAccount/Account/Bikes";
import Forgot from "../../pages/authAndAccount/Forgot";
import Reset from "../../pages/authAndAccount/Reset";

// Cart and Checkout
import Cart from "../../pages/Cart";
import Checkout from "../../pages/Checkout";

// Admin
import Dashboard from "../../pages/admin/Dashboard";
import FieldEditor from "../../pages/admin/FieldEditor";
import Users from "../../pages/admin/Users";
import UserCrudEditor from "../../pages/admin/UserCrud/Editor";
import UserCrudBikes from "../../pages/admin/UserCrud/Bikes";
import UserCrudOrders from "../../pages/admin/UserCrud/Orders";
import UserCrudVisits from "../../pages/admin/UserCrud/Visits";
import UserCrudMembership from "../../pages/admin/UserCrud/Membership";
import Memberships from "../../pages/admin/Memberships";
import MembershipGrant from "../../pages/admin/MembershipGrant";
import ShopVisits from "../../pages/admin/ShopVisits";
import Items from "../../pages/admin/Items";
import ItemCrud from "../../pages/admin/ItemCrud";
import Orders from "../../pages/admin/Orders";
import OrderViewer from "../../pages/admin/OrderViewer";
import Pages from "../../pages/admin/Pages";
import PageCrud from "../../pages/admin/PageCrud";
import Bikes from "../../pages/admin/Bikes";
import BikeBatch from "../../pages/admin/BikeBatch";
import BikeCrudEditor from "../../pages/admin/BikeCrud/Editor";
import BikeCrudHistory from "../../pages/admin/BikeCrud/WorkHistory";
import BikeParts from "../../pages/admin/BikeParts";
import BikePartCrud from "../../pages/admin/BikePartCrud";
import Recdons from "../../pages/admin/Recdons";
import ReceivingEventCrud from "../../pages/admin/ReceivingEventCrud";
import DonatingEventCrud from "../../pages/admin/DonatingEventCrud";
import Rentals from "../../pages/admin/Rentals";
import RentalCrud from "../../pages/admin/RentalCrud";
import RentalPeriodCrud from "../../pages/admin/RentalPeriodCrud";
import Reports from "../../pages/admin/Reports";

// Station
import StationDashboard from "../../pages/station/Dashboard";
import StationCheckIn from "../../pages/station/CheckIn";
import StationCheckOut from "../../pages/station/CheckOut";
import StationRecordWork from "../../pages/station/RecordWork";
import StationSignUp from "../../pages/station/SignUp";
import StationWaiver from "../../pages/station/Waiver";
import StationRentalWaiver from "../../pages/station/RentalWaiver";
import StationSubscribeToNewsletter from "../../pages/station/SubscribeToNewsletter";

class App extends Component {
  render() {
    return (
      <Provider {...stores}>
        <div>
          <FrozenLoader />
          <CustomBrowserRouter>
            <Analytics>
              <div>
                <BaseHelmet />
                <Switch>
                  <Route
                    exact
                    path="/"
                    component={Home}
                    title="Home"
                    description="The Bradley Street Bicycle Co-op is a community bike shop working towards an equitable New Haven by getting people on bikes."
                  />

                  {/* Unauthenticated Auth and Account Pages */}
                  <Route
                    exact
                    path="/auth/login"
                    component={Login}
                    title="Login"
                  />
                  <Route
                    exact
                    path="/account/register"
                    component={Register}
                    title="Register"
                  />
                  <Route
                    exact
                    path="/account/forgot"
                    component={Forgot}
                    title="Forgot Password"
                  />
                  <Route
                    exact
                    path="/account/reset"
                    component={Reset}
                    title="Reset Password"
                  />
                  {/* Authenticated Account Pages */}
                  <AuthRoute
                    exact
                    path="/account"
                    component={AccountDashboard}
                    title="Account Dashboard"
                  />
                  <AuthRoute
                    exact
                    path="/account/membership"
                    component={AccountMembership}
                    title="My Membership"
                  />
                  <AuthRoute
                    exact
                    path="/account/settings"
                    component={AccountSettings}
                    title="Account Settings"
                  />
                  <AuthRoute
                    exact
                    path="/account/password"
                    component={AccountUpdatePassword}
                    title="Update Password"
                  />
                  <AuthRoute
                    exact
                    path="/account/orders"
                    component={AccountOrders}
                    title="My Orders"
                  />
                  <AuthRoute
                    exact
                    path="/account/orders/:id"
                    component={AccountOrder}
                    title="Order Details"
                  />
                  <AuthRoute
                    exact
                    path="/account/visits"
                    component={AccountVisits}
                    title="My Shop Visits"
                  />
                  <AuthRoute
                    exact
                    path="/account/bikes"
                    component={AccountBikes}
                    title="Bikes I've Worked On"
                  />

                  {/* Misc Public Pages */}
                  <Route
                    exact
                    path="/donate"
                    component={Donate}
                    title="Donate"
                    description="Your donation helps us give bikes away to people in need, run educational shop hours, and repair bikes all over the state of Connecticut."
                  />
                  <Route
                    exact
                    path="/contact"
                    component={Contact}
                    title="Contact"
                    description="We’re located at 138 Bradley St, New Haven, CT 06511 and are open 4-8pm on Mondays for volunteering, and Tuesdays and Thursdays for DIY Open Shop hours."
                  />
                  <Route
                    exact
                    path="/events"
                    component={Events}
                    title="Events"
                    description="Check out upcoming events!"
                  />

                  {/* Store, Cart, Checkout */}
                  <Route
                    exact
                    path="/store/bikes"
                    component={BikesForSale}
                    title="Bikes For Sale"
                    description="Need an affordable bike? We have a collection of fully tuned-up bikes. Prices range between $250-$350 for most bikes."
                  />
                  <Route
                    exact
                    path="/store"
                    component={Store}
                    title="Online Store"
                    description="Buy merch and memberships (and other cool stuff)!"
                  />
                  <Route
                    exact
                    path="/store/:id"
                    component={Store}
                    title="Item Details"
                  />
                  <AuthRoute
                    exact
                    path="/cart"
                    component={Cart}
                    title="My Cart"
                  />
                  <AuthRoute
                    exact
                    path="/checkout"
                    component={Checkout}
                    title="Check Out"
                  />

                  {/* Remote Content Pages */}
                  {Object.keys(REMOTE_PAGES).map(path => (
                    <Route
                      key={path}
                      path={path}
                      exact
                      component={RemotePage}
                      title={undefined}
                    />
                  ))}

                  {/* Station */}
                  <AuthRoute
                    exact
                    userRole="station"
                    path="/station"
                    component={StationDashboard}
                    title="Dashboard"
                  />
                  <AuthRoute
                    exact
                    userRole="station"
                    path="/station/check-in"
                    component={StationCheckIn}
                    title="Check In"
                  />
                  <AuthRoute
                    exact
                    userRole="station"
                    path="/station/check-out"
                    component={StationCheckOut}
                    title="Check Out"
                  />
                  <AuthRoute
                    exact
                    userRole="station"
                    path="/station/record-work"
                    component={StationRecordWork}
                    title="Record Bike Work"
                  />
                  <AuthRoute
                    exact
                    userRole="station"
                    path="/station/sign-up"
                    component={StationSignUp}
                    title="Sign Up"
                  />
                  <AuthRoute
                    exact
                    userRole="station"
                    path="/station/waiver"
                    component={StationWaiver}
                    title="Waiver"
                  />
                  <AuthRoute
                    exact
                    userRole="station"
                    path="/station/rentalwaiver"
                    component={StationRentalWaiver}
                    title="Rental Waiver"
                  />
                  <AuthRoute
                    exact
                    userRole="station"
                    path="/station/newsletter"
                    component={StationSubscribeToNewsletter}
                    title="Subscribe to Newsletter"
                  />

                  {/* Admin */}
                  <AuthRoute
                    exact
                    userRole="admin"
                    path="/admin"
                    component={Dashboard}
                    title="Dashboard"
                  />
                  <AuthRoute
                    userRole="admin"
                    path="/admin/reports"
                    component={Reports}
                    title="Reports"
                  />
                  <AuthRoute
                    exact
                    userRole="admin"
                    path="/admin/fields"
                    component={FieldEditor}
                    title="Field Editor"
                  />
                  <AuthRoute
                    exact
                    userRole="admin"
                    path="/admin/users"
                    component={Users}
                    title="Users"
                  />
                  <AuthRoute
                    exact
                    userRole="admin"
                    path="/admin/users/:id"
                    component={UserCrudEditor}
                    title="Edit User"
                  />
                  <AuthRoute
                    exact
                    userRole="admin"
                    path="/admin/users/:id/bikes"
                    component={UserCrudBikes}
                    title="User Bike History"
                  />
                  <AuthRoute
                    exact
                    userRole="admin"
                    path="/admin/users/:id/orders"
                    component={UserCrudOrders}
                    title="User Orders"
                  />
                  <AuthRoute
                    exact
                    userRole="admin"
                    path="/admin/users/:id/visits"
                    component={UserCrudVisits}
                    title="User Shop Visits"
                  />
                  <AuthRoute
                    exact
                    userRole="admin"
                    path="/admin/users/:id/membership"
                    component={UserCrudMembership}
                    title="User Membership"
                  />
                  <AuthRoute
                    exact
                    userRole="admin"
                    path="/admin/memberships"
                    component={Memberships}
                    title="Memberships"
                  />
                  <AuthRoute
                    exact
                    userRole="admin"
                    path="/admin/memberships/grant"
                    component={MembershipGrant}
                    title="Grant New Membership"
                  />
                  <AuthRoute
                    exact
                    userRole="admin"
                    path="/admin/shopvisits"
                    component={ShopVisits}
                    title="Shop Visits"
                  />
                  <AuthRoute
                    exact
                    userRole="admin"
                    path="/admin/items"
                    component={Items}
                    title="Items"
                  />
                  <AuthRoute
                    exact
                    userRole="admin"
                    path="/admin/items/:id"
                    component={ItemCrud}
                    title="Edit Item"
                  />
                  <AuthRoute
                    exact
                    userRole="admin"
                    path="/admin/orders"
                    component={Orders}
                    title="Orders"
                  />
                  <AuthRoute
                    exact
                    userRole="admin"
                    path="/admin/orders/:id"
                    component={OrderViewer}
                    title="View Order"
                  />
                  <AuthRoute
                    exact
                    userRole="admin"
                    path="/admin/pages"
                    component={Pages}
                    title="Pages"
                  />
                  <AuthRoute
                    exact
                    userRole="admin"
                    path="/admin/pages/:id"
                    component={PageCrud}
                    title="Edit Page"
                  />
                  <AuthRoute
                    exact
                    userRole="admin"
                    path="/admin/bikes"
                    component={Bikes}
                    title="Bikes"
                  />
                  <AuthRoute
                    exact
                    userRole="admin"
                    path="/admin/bikes/batch"
                    component={BikeBatch}
                    title="Bike Batch Creator"
                  />
                  <AuthRoute
                    exact
                    userRole="admin"
                    path="/admin/bikes/:id"
                    component={BikeCrudEditor}
                    title="Edit Bike"
                  />
                  <AuthRoute
                    exact
                    userRole="admin"
                    path="/admin/bikes/:id/work"
                    component={BikeCrudHistory}
                    title="Bike Work History"
                  />
                  <AuthRoute
                    exact
                    userRole="admin"
                    path="/admin/recdons"
                    component={Recdons}
                    title="Receiving/Donating Events"
                  />
                  <AuthRoute
                    exact
                    userRole="admin"
                    path="/admin/receiving-events/:id"
                    component={ReceivingEventCrud}
                    title="Edit Receiving Event"
                  />
                  <AuthRoute
                    exact
                    userRole="admin"
                    path="/admin/donating-events/:id"
                    component={DonatingEventCrud}
                    title="Edit Donating Event"
                  />
                  <AuthRoute
                    exact
                    userRole="admin"
                    path="/admin/bikeparts"
                    component={BikeParts}
                    title="Bike Parts"
                  />
                  <AuthRoute
                    exact
                    userRole="admin"
                    path="/admin/bikeparts/:id"
                    component={BikePartCrud}
                    title="Edit Bike Part"
                  />
                  <AuthRoute
                    exact
                    userRole="admin"
                    path="/admin/rentals"
                    component={Rentals}
                    title="Rentals"
                  />
                  <AuthRoute
                    exact
                    userRole="admin"
                    path="/admin/rentals/:id"
                    component={RentalCrud}
                    title="View Rental Bike"
                  />
                  <AuthRoute
                    exact
                    userRole="admin"
                    path="/admin/rentals/periods/:id"
                    component={RentalPeriodCrud}
                    title="View Rental Period"
                  />

                  {/* Not found (explicit and catch-all) */}
                  <Route
                    exact
                    path="/not-found"
                    component={NotFound}
                    title="Page Not Found"
                  />
                  <Route component={NotFound} title="Page Not Found" />
                </Switch>
              </div>
            </Analytics>
          </CustomBrowserRouter>
        </div>
      </Provider>
    );
  }
}

export default App;
