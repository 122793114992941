import _pick from "lodash.pick";

import {
  BIKE_EDITOR_RECIPIENT_TYPE_USER,
  BIKE_EDITOR_RECIPIENT_TYPE_HEADLESS
} from "../../../constants";
import {
  formatMomentLocalYMD,
  parseMomentTimezoneConfig
} from "../../../datetime";

const initialFormValuesFromBike = bike => {
  const picked = _pick(bike, [
    "received_at",
    "fixed_at",
    "sold_at",
    "status",
    "condition",
    "list_online",
    "fix_cost",
    "fix_time", // not editable
    "is_nofix",
    "price_asking",
    "price_sold",
    "notes_admin",
    "notes_bike",
    "notes_sale",
    "notes_public",
    "acquisition",
    "donoremail",
    "recipient",
    "demographicset",
    "receivingevent",
    "donatingevent",
    "organization_donating",
    "organization_receiving",
    "bmake",
    "bmodel",
    "bstyle",
    "bsize",
    "bcolors",
    "bikeparts"
  ]);

  // Parse dates
  if (picked.received_at) {
    picked.received_at = formatMomentLocalYMD(
      parseMomentTimezoneConfig(picked.received_at)
    );
  }
  if (picked.fixed_at) {
    picked.fixed_at = formatMomentLocalYMD(
      parseMomentTimezoneConfig(picked.fixed_at)
    );
  }
  if (picked.sold_at) {
    picked.sold_at = formatMomentLocalYMD(
      parseMomentTimezoneConfig(picked.sold_at)
    );
  }

  // Add recipient type (NOTE: not persisted -- see preSave logic)
  picked.recipientType = picked.recipient
    ? BIKE_EDITOR_RECIPIENT_TYPE_USER
    : BIKE_EDITOR_RECIPIENT_TYPE_HEADLESS;

  // Pick demographicset fields
  picked.demographicset =
    picked.demographicset &&
    _pick(picked.demographicset, [
      "name",
      "email",
      "context",
      "birth_date",
      "health_mental",
      "health_physical",
      "health_economic",
      "dneighborhood",
      "dincomerange",
      "dagerange",
      "dgender",
      "drace",
      "dnationality",
      "dresidence",
      "dhouseholdcount",
      "demploymentstatus",
      "dbikeuses"
    ]);

  return picked;
};

export default initialFormValuesFromBike;
