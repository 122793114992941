import React, { Fragment } from "react";
import { observer, inject } from "mobx-react";
import _pick from "lodash.pick";
import { Title } from "bloomer";

import GenericEditor from "../../../components/GenericEditor";
import TextFieldWithErrors from "../../../components/fields/TextFieldWithErrors";
import SingleCheckboxFieldWithErrors from "../../../components/fields/SingleCheckboxFieldWithErrors";
import DemographicSetFields from "../../../components/fields/DemographicSetFields";
import Wrapper from "./Wrapper";

import { compose } from "../../../utils";
import { LOADER_TAG_ADMIN_USERS } from "../../../constants";
import {
  makeRecordValidator,
  makeFieldValidator,
  minLength
} from "../../../validators";
import ImageUpload from "../../../components/fields/ImageUpload";
import { runInAction } from "mobx";
import agent from "../../../agent";
import FancySelectFieldWithErrors from "../../../components/fields/FancySelectFieldWithErrors";

import SelectVolunteerLevel from "../../../components/selects/immediate/SelectVolunteerLevel";

/**
 * User editor
 */

const initialFormValuesFromUser = user => {
  const picked = _pick(user, [
    "name",
    "email",
    "newsletter",
    "show_volunteer_profile",
    "profile_title",
    "profile_description",
    "admin_notes",
    "rfid",
    "demographicset",
    "volunteerlevel"
  ]);

  // Pick demographicset fields
  picked.demographicset =
    picked.demographicset &&
    _pick(picked.demographicset, [
      // "name",
      // "birth_date",
      // "email",
      // "context",
      "health_mental",
      "health_physical",
      "health_economic",
      "dneighborhood",
      "dincomerange",
      "dagerange",
      "dgender",
      "drace",
      "dnationality",
      "dresidence",
      "dhouseholdcount",
      "demploymentstatus",
      "dbikeuses"
    ]);

  return picked;
};

const validate = makeRecordValidator(
  makeFieldValidator("name", "required", minLength(3)),
  makeFieldValidator("email", "required", "email"),
  makeFieldValidator("volunteerlevel", "required"),
  makeFieldValidator("demographicset") // none
);

class Editor extends React.Component {
  render() {
    const { userEditorStore } = this.props;

    return (
      <Wrapper>
        <GenericEditor
          name="user"
          basePath="/admin/users"
          store={userEditorStore}
          loaderTag={LOADER_TAG_ADMIN_USERS}
          recordValidator={validate}
          initialFormValuesFn={initialFormValuesFromUser}
          canCreateNew={false}
          shouldClearStoreOnUnmount={false}
        >
          <TextFieldWithErrors name="name" label="Name" />
          <TextFieldWithErrors name="email" label="Email" />
          <SingleCheckboxFieldWithErrors
            name="newsletter"
            label="Newsletter?"
          />
          <FancySelectFieldWithErrors
            name="volunteerlevel"
            label="Volunteer Level"
            component={SelectVolunteerLevel}
          />
          <SingleCheckboxFieldWithErrors
            name="show_volunteer_profile"
            label="Show volunteer profile online?"
          />

          <TextFieldWithErrors name="profile_title" label="Profile Title" />
          <TextFieldWithErrors
            name="profile_description"
            label="Profile Description"
            isTextarea
          />
          <TextFieldWithErrors
            name="admin_notes"
            label="Admin Notes"
            isTextarea
          />
          <TextFieldWithErrors
            name="rfid"
            label="Station RFID"
            extraHelp="10 digits"
          />
          <br />
          <Title isSize={5}>Demographics</Title>
          <DemographicSetFields isForUser={true} />

          {userEditorStore.resource &&
            userEditorStore.resource.id && (
              <Fragment>
                <Title isSize={4}>Image</Title>
                <ImageUpload
                  current={userEditorStore.resource.image}
                  onPick={image =>
                    agent.AdminUsers.uploadImage({
                      id: userEditorStore.resource.id,
                      file: image
                    })
                  }
                  onRemove={() => {
                    const user = userEditorStore.resource;
                    // set user image to null
                    runInAction(() => {
                      user.image = null;
                    });
                    return agent.AdminUsers.removeImage({
                      id: user.id
                    });
                  }}
                />
              </Fragment>
            )}
        </GenericEditor>
      </Wrapper>
    );
  }
}

export default compose(
  inject("userEditorStore"),
  observer
)(Editor);
