import React from "react";
import { Column, Button } from "bloomer";
import Hotkeys from "react-hot-keys";

import { VISIT_DISPLAY_NAMES } from "../../../config";

const VisitTypeButtonColumn = ({
  visitType,
  buttonColor,
  onCheckIn,
  loading,
  children,
  hotkey,
  buttonSize = "large"
}) => (
  <Column isSize={4}>
    {hotkey && (
      <Hotkeys keyName={hotkey} onKeyDown={() => onCheckIn(visitType)} />
    )}
    <Button
      isColor={buttonColor}
      isSize={buttonSize}
      isFullWidth
      onClick={() => onCheckIn(visitType)}
      disabled={loading}
    >
      {VISIT_DISPLAY_NAMES[visitType]}
    </Button>
    <p className={buttonSize === "large" ? "is-size-5" : "is-size-6"}>
      {children}
      {hotkey && (
        <span>
          &nbsp;
          <span className="has-soft-yellow-bg">{hotkey}</span>
        </span>
      )}
    </p>
  </Column>
);

export default VisitTypeButtonColumn;
