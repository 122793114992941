import { decorate, observable, action, runInAction } from "mobx";
import agent from "../agent";
import { LOADER_TAG_STATION_USERS } from "../constants";

export class UserStationStore {
  checkedInUsers = observable.array();
  checkedInUsersLoaded = false;

  searchResults = observable.array();

  clearCheckedInUsers() {
    this.checkedInUsersLoaded = false;
    this.checkedInUsers.clear();
  }

  clearSearchResults() {
    this.searchResults.clear();
  }

  // action
  // hard reload
  loadCurrentlyCheckedIn() {
    return agent.StationPunch.currentlyCheckedIn({
      loaderTag: LOADER_TAG_STATION_USERS
    })
      .then(body => {
        const users = body.data;
        runInAction(() => {
          this.checkedInUsersLoaded = true;
          this.checkedInUsers.replace(users);
        });
        return users;
      })
      .catch(error => {
        this.clearCheckedInUsers();
        return Promise.reject(error); // re-throw
      });
  }

  // action
  // doesn't reload if already marked as loaded
  loadCurrentlyCheckedInOnce() {
    if (!this.checkedInUsersLoaded) {
      return this.loadCurrentlyCheckedIn();
    } else {
      return Promise.resolve(this.checkedInUsers);
    }
  }

  // NOT AN ACTION, DOES NOT CHANGE STATE
  forceShow(id) {
    return agent.StationAccount.show(
      { id },
      {
        loaderTag: LOADER_TAG_STATION_USERS,
        displayError: false // don't show alert
      }
    ).then(body => {
      const user = body.data;
      return user;
    });
  }

  // action
  search({ name, email }) {
    return agent.StationAccount.search(
      { name, email },
      {
        loaderTag: LOADER_TAG_STATION_USERS
      }
    )
      .then(body => {
        const users = body.data;
        runInAction(() => {
          this.searchResults.replace(users);
        });
        return users;
      })
      .catch(error => {
        this.clearSearchResults();
        return Promise.reject(error); // re-throw
      });
  }
}

decorate(UserStationStore, {
  checkedInUsers: observable,
  searchResults: observable,
  clearCheckedInUsers: action.bound,
  clearSearchResults: action.bound,
  loadCurrentlyCheckedIn: action.bound,
  loadCurrentlyCheckedInOnce: action.bound,
  search: action.bound
});

export default new UserStationStore();
