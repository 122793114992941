/**
 * NetworkError
 */
class NetworkError {
  constructor(message) {
    this.name = "NetworkError";
    this.alertConfigFinal = { group: "network" };
    this.message = message;
    this.stack = new Error().stack; // Optional
  }
}
NetworkError.prototype = Object.create(Error.prototype);

export default NetworkError;
