import _get from "lodash.get";
import {
  ROLE_ADMIN,
  ROLE_STATION,
  ROLE_NOT_STATION,
  ROLE_ANY
} from "./constants";

const isAdmin = user => _get(user, ["role", "name"]) === ROLE_ADMIN;
const isStation = user => _get(user, ["role", "name"]) === ROLE_STATION;
const isNotStation = user => _get(user, ["role", "name"]) !== ROLE_STATION;
const isAny = user => !!user;

const roleMap = {
  [ROLE_ADMIN]: isAdmin,
  [ROLE_STATION]: isStation,
  [ROLE_NOT_STATION]: isNotStation,
  [ROLE_ANY]: isAny
};

const roleCheck = (user, role) => {
  const checker = roleMap[role];
  const r = checker && checker(user);
  return r;
};

export default roleCheck;
