import React from "react";
import { observer, inject } from "mobx-react";
import { Title } from "bloomer";

import { compose } from "../../../utils";
import { LOADER_TAG_ACCOUNT_MEMBERSHIP } from "../../../constants";

import Wrapper from "./common/Wrapper";
import MembershipCard from "../../../components/MembershipCard";
import LoadingText from "../../../components/LoadingText";

/**
 * Membership Period Listing
 *
 * NOTE: Not all of a user's membership periods are returned.
 */
const MembershipPeriods = ({ periods }) => (
  <div>
    {periods.map(period => (
      <MembershipCard key={period.id} period={period} />
    ))}
  </div>
);

class _MembershipInner extends React.Component {
  get store() {
    return this.props.membershipPeriodOwnStore;
  }

  get loading() {
    return this.props.uiStore.loadingByTag(LOADER_TAG_ACCOUNT_MEMBERSHIP);
  }

  componentDidMount() {
    this.props.authStore.pullUser({ freeze: false }); // make sure has_active_membership cache is up to date
    this.store.loadOwnPeriods();
  }

  componentWillUnmount() {
    this.store.clearAll();
  }

  render() {
    const { authStore } = this.props;
    const { user } = authStore;

    const { periods } = this.store;

    return (
      <div>
        <Title isSize={3}>My Membership</Title>
        <p>
          <span className="is-size-4">
            Status:{" "}
            {user.has_active_membership ? (
              <span className="has-text-success">Active</span>
            ) : (
              <span className="has-text-warning">Not Active</span>
            )}
          </span>
        </p>
        {this.loading ? (
          <LoadingText isLeft />
        ) : periods.length ? (
          <MembershipPeriods periods={periods} />
        ) : null}
      </div>
    );
  }
}

const MembershipInner = compose(
  inject("uiStore", "authStore", "membershipPeriodOwnStore"),
  observer
)(_MembershipInner);

export default class Membership extends React.Component {
  render() {
    return <Wrapper>{() => <MembershipInner />}</Wrapper>;
  }
}
