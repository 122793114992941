import React from "react";
import moment from "moment";

import styles from "./station-site.module.scss";

const year = moment().format("Y");

const Footer = () => {
  return (
    <footer className={styles.footer}>
      &copy;
      {year} Bradley Street Bicycle Co-op
      <br />
      Station app by Andrew Suzuki
      <div className={styles.secret}>
        <a href="/station/rentalwaiver">rwaiver</a>
      </div>
    </footer>
  );
};

export default Footer;
