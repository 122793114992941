import { observable, decorate, action, runInAction } from "mobx";
import agent from "../agent";
import { LOADER_TAG_ADMIN_DONATING_EVENTS } from "../constants";

export class DonatingEventAdminStore {
  donatingevents = observable.array();

  clear() {
    this.donatingevents.clear();
  }

  // action
  loadAll() {
    return agent.AdminRecdons.allDonating({
      loaderTag: LOADER_TAG_ADMIN_DONATING_EVENTS
    }).then(body => {
      const donatingevents = body.data;
      runInAction(() => {
        this.donatingevents.replace(donatingevents);
      });
      return donatingevents;
    });
  }
}

decorate(DonatingEventAdminStore, {
  donatingevents: observable,
  clear: action.bound,
  loadAll: action.bound
});

export default new DonatingEventAdminStore();
