import { observable, decorate, action } from "mobx";

export class TemplateStore {
  template = null;

  setTemplate(template) {
    this.template = template;
  }

  clear() {
    this.template = null;
  }
}

decorate(TemplateStore, {
  template: observable,
  setTemplate: action.bound,
  clear: action.bound
});

export default TemplateStore;
