import { observable, decorate, runInAction, action } from "mobx";
import agent from "../agent";
import { LOADER_TAG_ACCOUNT_VISITS } from "../constants";

export class ShopVisitOwnStore {
  visits = observable.array();

  clear() {
    this.visits.clear();
  }

  loadOwnVisits() {
    return agent.Visits.all({ loaderTag: LOADER_TAG_ACCOUNT_VISITS })
      .then(body => {
        const visits = body.data;
        runInAction(() => {
          this.visits.replace(visits);
        });
      })
      .catch(error => {
        this.clear();
        return Promise.reject(error); // re-throw
      });
  }
}

decorate(ShopVisitOwnStore, {
  visits: observable,
  clear: action,
  pullOwnVisits: action
});

export default new ShopVisitOwnStore();
