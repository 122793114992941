import React from "react";
import { observer, inject } from "mobx-react";
import { Columns, Title } from "bloomer";

import PublicSite from "../../components/PublicSite";
import { compose } from "../../utils";
import OneThirdColumn from "./OneThirdColumn";
import MailingListSignUp from "../../components/MailingListSignUp";
import FullWidthImage from "../../components/FullWidthImage";
import Mission from "../../components/Mission";
import PlainInfoParagraph from "../../components/PlainInfoParagraph";
import MediumCenterInner from "../../components/MediumCenterInner";

class Home extends React.Component {
  render() {
    return (
      <PublicSite hasPaddingTop={false}>
        <FullWidthImage
          // src="/img/content/P8307582_cropped.jpg"
          src="/img/content/home/header.jpg"
          alt="BSBC Header"
        />
        {/* <Title isSize={4}>Mission</Title> */}
        <Mission>
          The Bradley Street Bicycle Co-op (BSBC) is a community bike shop working
          towards an equitable New Haven by getting people on bikes.
        </Mission>

        <MediumCenterInner>
          <Title isSize={4}>Who We Are</Title>
          <PlainInfoParagraph isCentered={false}>
            From the outside, we are an old brick building with a small sign
            above our door, a glowing 'BSBC' sign illuminating the window at
            night. But inside we are warmed under lights, friends, knowledge,
            dirty hands, and the betterment of our city. We make decisions based
            on principles and beliefs, not based on what makes us more money. We
            take care of each other and love meeting new friends. We always show
            up; to the shop, to events, to things that mean something to one
            another.
          </PlainInfoParagraph>
          <PlainInfoParagraph isCentered={false}>
            We are a community learning with our hands, laughing with strangers,
            and building a better world together.
          </PlainInfoParagraph>
        </MediumCenterInner>

        <br />

        <Title isSize={4}>Visit Us</Title>
        <Columns>
          <OneThirdColumn
            title="DIY Open Shop Hours"
            link="/about/shop-hours"
            imgSrc="/img/content/home/open_shop_hours.jpg"
          >
            <p>
              Repair your bike at our DIY Open Shop Hours for $10. Get your hands
              dirty and learn how your bike works with the help of volunteers.
            </p>
            <p>DIY Open Shop hours are on Tuesdays and Thursdays from 4-8pm.</p>
            <p>We are also open for volunteering on Mondays from 4-8pm.</p>
          </OneThirdColumn>
          <OneThirdColumn
            title="Used Bike Shop"
            link="/store/bikes"
            imgSrc="/img/content/home/used_bike_shop.jpg"
          >
            <p>
              Buy a used bike from our Used Bike Shop. Our bikes range from
              $250-$350 and are all tuned up and ready to go.
            </p>
          </OneThirdColumn>
          <OneThirdColumn
            title="Donate Your Bike"
            link="/about/bicycle-recycling"
            imgSrc="/img/content/home/donate_your_bike.jpg"
          >
            <p>
              Donate your old bike to our Recycling Program so we can get that
              bike out the door to someone who needs it.
            </p>
          </OneThirdColumn>
        </Columns>
        <MailingListSignUp />
        <FullWidthImage
          src="/img/content/BSBC_Cookie.jpg"
          alt="BSBC Cookie"
          noMargin
        />
      </PublicSite>
    );
  }
}

export default compose(inject(), observer)(Home);
