/**
 * UserError holds a message the user should see
 */
class UserError {
  constructor(message) {
    this.name = "UserError";
    this.message = message;
    this.stack = new Error().stack; // Optional
  }
}
UserError.prototype = Object.create(Error.prototype);

export default UserError;
