import React, { Component, Fragment } from "react";
import { observer, inject } from "mobx-react";
import _get from "lodash.get";

import { LOADER_TAG_CHECKOUT, LOADER_TAG_CART } from "../../constants";
import { compose } from "../../utils";
import agent from "../../agent";
import { SQUARE_APPLICATION_ID, SQUARE_LOCATION_ID } from "../../config";
import ValidationError from "../../errors/ValidationError";
import UserError from "../../errors/UserError";

import PublicSite from "../../components/PublicSite";
import NarrowCenterInner from "../../components/NarrowCenterInner";
import PageHeading from "../../components/PageHeading";
import CartInner from "../Cart/CartInner";
import PlainInfoParagraph from "../../components/PlainInfoParagraph";

import PaymentForm from "./PaymentForm";
import AddressForm from "./AddressForm";

const alertGroup = "checkout";

/**
 * Checkout
 *
 * The only things we need to collect are:
 * - square card nonce, collected by Square's embeddable SqPaymentForm
 * - address (if cart has shippables)
 *
 * Can only get here from cart page. Protect it by:
 * - linking here from cart page with link that sets a flag in react-router state
 * - if a user accesses /checkout directly, redirect back to cart (without alerts)
 */

class Checkout extends Component {
  state = {
    address: null
  };

  get checkingOut() {
    return this.props.uiStore.loadingByTag(LOADER_TAG_CHECKOUT);
  }

  get cartLoading() {
    return this.props.uiStore.loadingByTag(LOADER_TAG_CART);
  }

  componentDidMount() {
    const { location, history } = this.props;
    // User is attempting to access this page directly
    // (without special link from cart page).
    // Redirect back to cart page
    if (!_get(location, "state.didGetHereFromCart")) {
      history.replace("/cart");
    }

    if (!this.props.cartStore.cart) {
      this.props.cartStore.loadCart();
    }
  }

  onNonceSuccess = nonce => {
    const { hasShippables } = this.props.cartStore;

    agent.Checkout.checkout(
      {
        square_card_nonce: nonce,
        address: hasShippables && this.state.address
      },
      {
        loaderTag: LOADER_TAG_CHECKOUT,
        dontAlert422: true,
        errorGroup: alertGroup
      }
    )
      .then(body => {
        const order = body.data;
        this.props.uiStore.addAlert(
          "success",
          "Your order was placed successfully.",
          { group: alertGroup }
        );
        this.props.history.push(`/account/orders/${order.id}`, {
          wasRedirect: true
        });
      })
      .catch(e => {
        // NOTE: payment form will reset automatically due to re-render (unmount/re-mount)

        // Manually handle 422 alerts (since we used the dontAlert422 config option)
        if (e instanceof ValidationError || e instanceof UserError) {
          // Check if this is a card error
          // e.g. {"message":"The given data was invalid.","errors":{"card":["Card was declined; please verify the CVV."]}}
          const cardErrorMessage = _get(e, ["fields", "card", 0]);
          if (cardErrorMessage) {
            // Card error
            this.props.uiStore.addAlert("error", cardErrorMessage, {
              group: alertGroup
            });
          } else {
            // Normal error
            this.props.uiStore.addAlert("error", e, { group: alertGroup });
          }
        }

        // (Don't re-throw)
      });
  };

  onNonceFail = errors => {
    console.error(errors);
    this.props.uiStore.clearAlertsAfterSubmit();
    this.props.uiStore.addAlert(
      "error",
      errors.map(error => error.message).join("; "),
      { group: alertGroup }
    );
  };

  onPaymentScriptError = error => {
    console.error(error);
    this.props.uiStore.addAlert(
      "error",
      "An unknown error occurred, please contact us for assistance.",
      { group: alertGroup }
    );
  };

  onUnsupportedBrowser = () => {
    this.props.uiStore.addAlert(
      "error",
      "Your web browser is not supported. Please contact us for assistance.",
      { group: alertGroup }
    );
  };

  onSubmitAddress = address => {
    this.setState({ address });
  };

  render() {
    const { cart, hasShippables } = this.props.cartStore;

    return (
      <PublicSite>
        <NarrowCenterInner>
          <PageHeading>Checkout</PageHeading>
          {this.checkingOut ? (
            <PlainInfoParagraph>
              Placing order, please wait...
            </PlainInfoParagraph>
          ) : (
            <div>
              {this.cartLoading ? (
                <i>Loading items...</i>
              ) : (
                <CartInner cart={cart} showCheckoutButton={false} />
              )}
              <br />
              {hasShippables && !this.state.address ? (
                <Fragment>
                  <p>Please enter your shipping address.</p>
                  <AddressForm onSubmit={this.onSubmitAddress} />
                </Fragment>
              ) : (
                <Fragment>
                  <p>Please enter your payment details below.</p>
                  <PaymentForm
                    appId={SQUARE_APPLICATION_ID}
                    locationId={SQUARE_LOCATION_ID}
                    onGetAppIdError={this.onPaymentScriptError}
                    onScriptError={this.onPaymentScriptError}
                    onNonceGenerated={this.onNonceSuccess}
                    onNonceError={this.onNonceFail}
                    onUnsupportedBrowserDetected={this.onUnsupportedBrowser}
                  />
                </Fragment>
              )}
            </div>
          )}
        </NarrowCenterInner>
      </PublicSite>
    );
  }
}

export default compose(
  inject("uiStore", "cartStore"),
  observer
)(Checkout);
