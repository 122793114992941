import React from "react";
import { Columns, Column } from "bloomer";
import PageHeading from "./PageHeading";

const SimpleFormPage = ({ title, children }) => (
  <div>
    <PageHeading hasTextAlign="centered">{title}</PageHeading>
    <Columns isCentered>
      <Column isSize={{ tablet: 6, widescreen: 4 }}>{children}</Column>
    </Columns>
  </div>
);

export default SimpleFormPage;
