import React from "react";
import BaseSortTable from "./BaseSortTable";

import { userColumn, plainLocalDateColumn, cellBooleanYesNo } from "./helpers";
import { makeActionsColumn } from "./columns";

const makeColumns = rentalBikeId => [
  makeActionsColumn(`/admin/rentals/periods`, "View", 58),
  {
    Header: "Start",
    accessor: "start_date",
    ...plainLocalDateColumn
  },
  {
    Header: "Due",
    accessor: "due_date",
    ...plainLocalDateColumn
  },
  {
    Header: "Returned?",
    accessor: "is_returned",
    Cell: cellBooleanYesNo,
    width: 100
  },
  {
    Header: "Square Order Id",
    accessor: "square_order_id",
    width: 150
  },
  {
    Header: "Renter",
    accessor: "user",
    ...userColumn
  },
  {
    Header: "Renter Phone",
    accessor: "renter_phone",
    width: 150
  }
];

class RentalPeriodTable extends React.Component {
  render() {
    const { rentalBikeId } = this.props;

    return (
      <BaseSortTable columns={makeColumns(rentalBikeId)} {...this.props} />
    );
  }
}

export default RentalPeriodTable;
