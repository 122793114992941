import React, { Component } from "react";
import { Field, Control } from "bloomer";
import FancySelectFilterField from "./FancySelectFilterField";
import BooltypeSelect from "./BooltypeSelect";

export default class FancySelectWithBooltypeFilterField extends Component {
  onChange = (value, isBooltype = false) => {
    this.props.onChange(value, {
      filterNameOverride: `${this.props.name}${isBooltype ? "_booltype" : ""}`
    });
  };

  render() {
    const { value } = this.props;

    return (
      <div>
        <FancySelectFilterField
          {...this.props}
          value={value.select}
          onChange={value => this.onChange(value, false)}
        />
        <Field>
          <Control>
            <BooltypeSelect
              value={value.booltype}
              onChange={e => this.onChange(e.target.value, true)}
            />
          </Control>
        </Field>
      </div>
    );
  }
}
