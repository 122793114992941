import React, { PureComponent } from "react";
import { GOOGLE_CALENDAR_ID } from "../../config";

export default class Calendar extends PureComponent {
  render() {
    if (!GOOGLE_CALENDAR_ID) {
      return <p>Calendar not available</p>;
    }

    return (
      <div style={{ maxWidth: "100%", overflowX: "auto" }}>
        <div style={{ width: "100%", minWidth: 500 }}>
          <iframe
            src={`https://calendar.google.com/calendar/embed?src=${encodeURIComponent(
              GOOGLE_CALENDAR_ID
            )}&ctz=America%2FNew_York`}
            style={{ border: 0, width: "100%", height: 700 }}
            frameBorder="0"
            scrolling="no"
            title="BSBC Event Calendar"
          />
        </div>
      </div>
    );
  }
}
