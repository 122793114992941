import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { withRouter } from "react-router-dom";
import { Columns, Column } from "bloomer";

import PageHeading from "../../../components/PageHeading";
import AdminSite from "../../../components/AdminSite";
import TabNav from "../../../components/TabNav";
import Breadcrumbs from "../../../components/Breadcrumbs";

import { compose, idFromProps } from "../../../utils";
import BikeQuickJump from "../Bikes/BikeQuickJump";

/**
 * Bike CRUD Wrapper
 */
class Wrapper extends Component {
  componentDidMount() {
    this.pullBikeMaybe();
  }

  componentDidUpdate() {
    this.pullBikeMaybe();
  }

  pullBikeMaybe = () => {
    const { bikeEditorStore } = this.props;
    const { resource } = bikeEditorStore;

    if (!this.props.renderWithBike) {
      return;
    }

    const id = idFromProps(this.props);

    if (id === "new") {
      return;
    }

    if (
      resource &&
      resource.id &&
      parseInt(resource.id, 10) === parseInt(id, 10)
    ) {
      // no need to re-fetch
      return;
    }

    this.triggerReload(id);
  };

  triggerReload = id => {
    this.props.bikeEditorStore.initializeEdit(id).catch(() => {
      this.props.history.push("/admin/bikes", {
        wasRedirect: true
      });
    });
  };

  render() {
    const { bikeEditorStore, children, renderWithBike } = this.props;
    const idParam = idFromProps(this.props);
    const isNew = idParam === "new";
    const bike = bikeEditorStore.resource;

    return (
      <AdminSite>
        <Columns>
          <Column className="is-flex-tablet" style={{ alignItems: "center" }}>
            <Breadcrumbs
              items={[
                { to: "/admin", label: "Admin" },
                { to: "/admin/bikes", label: "Bikes" },
                {
                  to: `/admin/bikes/${isNew ? "new" : idParam}`,
                  label: isNew ? "New Bike" : `Bike ${idParam}`
                }
              ]}
            />
          </Column>
          <Column
            className="is-flex-tablet"
            style={{ justifyContent: "flex-end" }}
          >
            <BikeQuickJump />
          </Column>
        </Columns>
        <PageHeading>
          {isNew ? "New Bike" : `Manage Bike #${idParam}`}
        </PageHeading>
        {!isNew && (
          <TabNav
            tabs={[
              { to: `/admin/bikes/${idParam}`, exact: true, label: "Editor" },
              {
                to: `/admin/bikes/${idParam}/work`,
                label: "Work History"
              }
            ]}
          />
        )}
        {children}
        {renderWithBike &&
          (bike
            ? renderWithBike(bike, () => this.triggerReload(bike.id))
            : null)}
      </AdminSite>
    );
  }
}

export default compose(
  withRouter,
  inject("uiStore", "bikeEditorStore"),
  observer
)(Wrapper);
