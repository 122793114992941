import React, { PureComponent } from "react";
import { withRouter } from "react-router-dom";
import { Field, Control, Button, Input } from "bloomer";
import _get from "lodash.get";

class QuickResourceJump extends PureComponent {
  onSubmit = e => {
    e.preventDefault();
    const id = _get(e, "target.requestedId.value");
    if (id) {
      this.props.history.push(`${this.props.pathPrefix}/${id}`);
    }
  };

  render() {
    const { isMarginless = false, inputProps = {} } = this.props;

    const cn = isMarginless ? "" : "has-standard-mb";

    return (
      <form onSubmit={this.onSubmit} className={cn}>
        <Field hasAddons>
          <Control>
            <Input
              type="number"
              min="0"
              step="1"
              required
              name="requestedId"
              {...inputProps}
            />
          </Control>
          <Control>
            <Button type="submit" isColor="primary">
              Go
            </Button>
          </Control>
        </Field>
      </form>
    );
  }
}

export default withRouter(QuickResourceJump);
