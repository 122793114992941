import React, { Component } from "react";
import { components } from "react-select";
import SelectFieldImmediate from "../SelectFieldImmediate";
import agent from "../../../agent";
import {
  LOADER_TAG_ADMIN_FIELD_BIKE_PART,
  LOADER_TAG_STATION_FIELD_BIKE_PART
} from "../../../constants";
import { Tag } from "bloomer";

const CustomOption = props => {
  const type = props.value.filled_type;

  const children = (
    <div style={{ display: "flex", alignItems: "center" }}>
      <Tag
        isColor={type === "new" ? "success" : "link"}
        style={{ marginRight: "0.5rem", textTransform: "capitalize" }}
      >
        {type}
      </Tag>
      <span>{props.children}</span>
    </div>
  );

  return <components.Option {...{ ...props, children }} />;
};

export default class SelectBikePart extends Component {
  render() {
    const { ignore = {}, isStation = false } = this.props;

    const agentGetFn = isStation
      ? () =>
          agent.StationBike.allBikeParts({
            loaderTag: LOADER_TAG_STATION_FIELD_BIKE_PART
          })
      : () =>
          agent.AdminBikeParts.all({
            loaderTag: LOADER_TAG_ADMIN_FIELD_BIKE_PART
          });

    return (
      <SelectFieldImmediate
        components={{
          Option: CustomOption
        }}
        getFn={agentGetFn}
        loaderTag={LOADER_TAG_ADMIN_FIELD_BIKE_PART}
        prepareOptions={bikeParts => {
          // create new and/or used versions of each incoming bikePart
          const newAndUsedBikeParts = [];
          bikeParts.forEach(bikePart => {
            if (bikePart.cost_new !== null) {
              newAndUsedBikeParts.push({
                ...bikePart,
                filled_type: "new"
              });
            }
            if (bikePart.cost_used !== null) {
              newAndUsedBikeParts.push({
                ...bikePart,
                filled_type: "used"
              });
            }
          });
          return newAndUsedBikeParts
            .filter(
              bikePart =>
                !(ignore[bikePart.id] || []).includes(bikePart.filled_type)
            )
            .map(bikePart => ({
              value: bikePart,
              label: bikePart.title
            }));
        }}
        {...this.props}
      />
    );
  }
}
