import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { observer, inject } from "mobx-react";
import { Table, Columns, Column } from "bloomer";
import Hotkeys from "react-hot-keys";

import { compose, queryParamsFromWithRouterProps } from "../../../../utils";
import { LOADER_TAG_STATION_USERS } from "../../../../constants";
import CustomFinalForm from "../../../../components/CustomFinalForm";
import TextFieldWithErrors from "../../../../components/fields/TextFieldWithErrors";
import {
  makeRecordValidator,
  makeFieldValidator,
  minLength
} from "../../../../validators";
import formValidationErrorLayer from "../../../../errors/formValidationErrorLayer";
import {
  standardFinalFormSubscriptions,
  VOLUNTEER_JUMP_CODES
} from "../../../../config";
import FinalSubmit from "../../../../components/fields/FinalSubmit";
import LoadingText from "../../../../components/LoadingText";
import PlainInfoParagraph from "../../../../components/PlainInfoParagraph";
import Cip2w from "../Cip2w";
import ChooseUserFromFrequent from "./ChooseUserFromFrequent";
import RfidListen from "../RfidListen";

// const xor = (a, b) => {
//   const aTrimmed = a && a.trim();
//   const bTrimmed = b && b.trim();
//   if (!aTrimmed && !bTrimmed) {
//     return "Either name or email is required";
//   } else if (aTrimmed && bTrimmed) {
//     return "Cannot search by both name and email";
//   }
//   return undefined;
// };
// const validate = makeRecordValidator(
//   makeFieldValidator("name", minLength(2), [xor, "email"]),
//   makeFieldValidator("email", [xor, "name"], "email")
// );
const validate = makeRecordValidator(
  makeFieldValidator("name", "required", minLength(2))
);

const MODE_SEARCH = "mode-search";
const MODE_JUMP = "mode-jump";

/**
 * Search users
 */
class SearchUsers extends Component {
  state = {
    mode: MODE_SEARCH,
    fetchedOnce: false,
    lastTriedForcedUserId: null
  };

  get loading() {
    return this.props.uiStore.loadingByTag(LOADER_TAG_STATION_USERS);
  }

  checkForForcedUserId = () => {
    const query = queryParamsFromWithRouterProps(this.props);
    const id = (query && query.forcedUserId) || null;

    if (id !== this.state.lastTriedForcedUserId) {
      this.setState({ lastTriedForcedUserId: id });

      if (id) {
        this.props.userStationStore
          .forceShow(id)
          .then(user => {
            this.props.onForceSelect(user);
          })
          .catch(() => {
            // fail silently (show table as if forced user wasn't requested)
          });
      }
    }
  };

  componentDidMount() {
    this.checkForForcedUserId();

    // ensure currently checked in is loaded
    this.props.userStationStore.loadCurrentlyCheckedInOnce();
  }

  componentDidUpdate() {
    this.checkForForcedUserId();
  }

  componentWillUnmount() {
    this.props.userStationStore.clearSearchResults();
  }

  onBacktick = (key, e) => {
    e.preventDefault();
    this.props.userStationStore.clearSearchResults();
    this.setState({
      mode: this.state.mode === MODE_SEARCH ? MODE_JUMP : MODE_SEARCH,
      // reset remaining state
      fetchedOnce: false,
      lastTriedForcedUserId: false
    });
  };

  onVolunteerJumpCode = (key, e) => {
    e.preventDefault();
    const userId = VOLUNTEER_JUMP_CODES[key];
    this.forceShow(userId);
  };

  forceShow = userId => {
    this.props.userStationStore.forceShow(userId).then(user => {
      const isCheckedIn = this.props.userStationStore.checkedInUsers.some(
        user => user.id === userId && user.current_visit
      );

      this.props.onForceSelect(user, isCheckedIn);
    });
  };

  onSubmit = fields => {
    this.setState({ fetchedOnce: true });
    return formValidationErrorLayer(this.props.userStationStore.search(fields));
  };

  get innerRender() {
    const { userStationStore, renderActions } = this.props;
    const { searchResults } = userStationStore;

    if (this.loading) {
      return <LoadingText />;
    }

    if (searchResults.length === 0) {
      if (this.state.fetchedOnce) {
        return (
          <p style={{ marginTop: "1rem" }}>
            <i>No results</i>
          </p>
        );
      } else {
        return null;
      }
    }

    return (
      <div>
        <br />
        <Table isBordered isStriped>
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              {renderActions && <th>&nbsp;</th>}
            </tr>
          </thead>
          <tbody>
            {searchResults.map(user => (
              <tr key={user.id}>
                <td>{user.name}</td>
                <td>{user.email}</td>
                {renderActions && <td>{renderActions(user)}</td>}
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    );
  }

  get renderCip2w() {
    return this.loading ? null : (
      <Cip2w
        component={ChooseUserFromFrequent}
        componentProps={{ onChoose: user => this.forceShow(user.id) }}
      />
    );
  }

  render() {
    const { mode } = this.state;

    return (
      <div>
        <Hotkeys keyName="`" onKeyDown={this.onBacktick} />
        <RfidListen
          onSuccess={userId => {
            this.forceShow(userId);
          }}
        />
        {mode === MODE_JUMP && (
          <div>
            <Hotkeys
              keyName={Object.keys(VOLUNTEER_JUMP_CODES).join(",")}
              onKeyDown={this.onVolunteerJumpCode}
            />
            {this.loading ? (
              <LoadingText />
            ) : (
              <PlainInfoParagraph>
                Enter your volunteer jump code now.
              </PlainInfoParagraph>
            )}
          </div>
        )}
        {mode === MODE_SEARCH && (
          <div>
            <CustomFinalForm
              onSubmit={this.onSubmit}
              validate={validate}
              initialValues={{ name: null, email: null }}
              subscription={standardFinalFormSubscriptions}
              render={renderProps => {
                const { handleSubmit } = renderProps;
                return (
                  <form onSubmit={handleSubmit}>
                    {!this.loading && (
                      <div>
                        <Columns>
                          <Column>
                            <TextFieldWithErrors
                              type="text"
                              name="name"
                              label="Search by Name"
                              placeholder="e.g. alex"
                              // autoFocus
                            />
                          </Column>
                          <Column>
                            &nbsp;
                            {/* <TextFieldWithErrors
                              type="text"
                              name="email"
                              label="Search by Email (exact)"
                              placeholder="e.g. alexsmith@gmail.com"
                            /> */}
                          </Column>
                        </Columns>
                        <FinalSubmit
                          {...renderProps}
                          label="Search"
                          isSize="normal"
                          loading={this.loading}
                        />
                      </div>
                    )}
                  </form>
                );
              }}
            />
            {this.innerRender}
            {this.renderCip2w}
          </div>
        )}
      </div>
    );
  }
}

export default compose(
  withRouter,
  inject("userStationStore", "uiStore"),
  observer
)(SearchUsers);
