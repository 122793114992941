import React from "react";
import BaseSortTable from "./BaseSortTable";
import { cellUser, dateTimeColumn } from "./helpers";
import { idColumn } from "./columns";
import { VISIT_DISPLAY_NAMES } from "../../config";

const columns = [
  idColumn,
  {
    Header: "Start",
    accessor: "time_start",
    ...dateTimeColumn
  },
  {
    Header: "End",
    accessor: "time_end",
    ...dateTimeColumn
  },
  {
    Header: "Type",
    accessor: "type",
    width: 150,
    Cell: ({ value }) => {
      return VISIT_DISPLAY_NAMES[value];
    }
  }
];

const columnsWithUser = [
  ...columns,
  {
    Header: "User",
    accessor: "user",
    Cell: cellUser
  }
];

class ShopVisitTable extends React.Component {
  render() {
    const { showUserColumn = true } = this.props;
    return (
      <BaseSortTable
        columns={showUserColumn ? columnsWithUser : columns}
        {...this.props}
      />
    );
  }
}

export default ShopVisitTable;
