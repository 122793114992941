import React, { Component } from "react";
import { observer } from "mobx-react";
import {
  Card,
  CardHeader,
  CardHeaderTitle,
  CardHeaderIcon,
  CardImage,
  CardContent,
  CardFooter,
  CardFooterItem
} from "bloomer";

import { formatMoney } from "../../utils";
import Markdown from "../../components/Markdown";
import ImageFromMedialibrary from "../../components/ImageFromMedialibrary";

class BikeCard extends Component {
  render() {
    const { bike } = this.props;
    const {
      notes_public,
      price_asking,
      image,

      bmake,
      bmodel,
      bsize,
      bstyle
    } = bike;

    return (
      <Card>
        <CardHeader>
          <CardHeaderTitle>
            {bmake.name} {bmodel.name}
          </CardHeaderTitle>
          <CardHeaderIcon tag="span" style={{ cursor: "inherit" }}>
            {formatMoney(price_asking)}
          </CardHeaderIcon>
        </CardHeader>
        {image && (
          <CardImage>
            <ImageFromMedialibrary image={image} />
          </CardImage>
        )}
        {notes_public && (
          <CardContent>
            <Markdown source={notes_public} />
          </CardContent>
        )}
        <CardFooter>
          <CardFooterItem
            hasTextAlign="centered"
            className="is-uppercase is-size-7"
          >
            Style: {bstyle.name}
          </CardFooterItem>
          <CardFooterItem
            hasTextAlign="centered"
            className="is-uppercase is-size-7"
          >
            Size: {bsize.name}
          </CardFooterItem>
        </CardFooter>
      </Card>
    );
  }
}

export default observer(BikeCard);
