import React from "react";
import { inject, observer } from "mobx-react";
import { Form } from "react-final-form";
import { compose } from "../utils";

/**
 * This is a custom BrowserRouter that wraps onSubmit
 * to call uiStore.clearAlertsAfterSubmit
 */
const CustomFinalForm = ({ uiStore, onSubmit, ...restProps }) => {
  const onSubmitReal = (...args) => {
    uiStore.clearAlertsAfterSubmit();
    return onSubmit ? onSubmit(...args) : undefined;
  };

  return <Form {...restProps} onSubmit={onSubmitReal} />;
};

export default compose(
  inject("uiStore"),
  observer
)(CustomFinalForm);
