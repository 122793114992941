import authStore from "../../stores/auth";
import uiStore from "../../stores/ui";

/**
 * This axios interceptor catches requests that fail due to 401 errors
 * while currently authenticated, which in most cases means the stored
 * jwt (json web token) has expired.
 *
 * Stall original request promise and attempt to refresh token,
 * then resume original if it succeeds.
 *
 * Since there could be multiple requests attempted before token
 * is refreshed, we keep track of refresh subscribers and
 * notify them after the refresh response.
 */
export default function attachRefreshInterceptor(ax) {
  const refreshSubscribers = [];

  // Add a refresh subscriber
  function addAfterRefreshSubscriber(resolve, reject) {
    refreshSubscribers.push({ resolve, reject });
  }

  // Notify subscribers of refresh success (accepts bool)
  function onAfterRefresh(success) {
    refreshSubscribers.forEach(
      ({ resolve, reject }) => (success ? resolve() : reject())
    );
    // Clear subscribers
    refreshSubscribers.length = 0;
  }

  ax.interceptors.response.use(
    response => response,
    error => {
      const { config } = error;

      // There was an error. Check if it was a 401 error
      if (error.response && error.response.status === 401) {
        if (authStore.isAuthenticated) {
          // Token expired
          if (refreshSubscribers.length === 0) {
            // No existing refresh subscribers, so start the refresh attempt.
            // Mocks loader tag of failed request for any loading state, however,
            // note that it only uses the first failed request (if there are any
            // other requests, their loading states will be lost)
            authStore
              .refreshMaybe(config.loaderTag)
              .then(() => {
                // Refresh success; notify subscribers
                onAfterRefresh(true);
              })
              .catch(refreshError => {
                // Refresh fail; notify subscribers
                onAfterRefresh(false);

                // Notify recently-unauthenticated user
                uiStore.addAlert(
                  "info",
                  "You were logged out due to inactivity, please log in again."
                );

                // (don't re-throw -- actual request promises are thrown)
              });
          }

          // The error happens when the outer promise below rejects. When it rejects,
          // both the remaining original response interceptors are called,
          // and all of the retry response interceptors.

          // Return new promise in original request chain that waits for refresh
          return new Promise((resolve, reject) => {
            addAfterRefreshSubscriber(
              () => {
                // Refresh success, so retry original request with config

                // Sets isRetryRequest flag due to case where the retry request
                // fails (for whatever reason, likely NOT 401 again) and thus
                // goes causes some trouble in going through the two interceptor branches.
                // This flag is picked up by the error response interceptors,
                // and the retry error is re-thrown AS-IS for handling by the original
                // response interceptors (see attachErrorInterceptor)

                resolve(ax({ ...config, isRetryRequest: true }));
              },
              () => {
                // Refresh fail, so re-throw original [401] error, but don't
                // display it because we already displayed an error (above)
                error.config.displayError = false;
                reject(error);
              }
            );
          });
        }
      }

      // wasn't a relevant error, so re-throw
      return Promise.reject(error);
    }
  );

  return ax;
}
