import React, { Component } from "react";
import { Columns, Column, Title } from "bloomer";

import BColorTags from "../../../components/BColorTags";

import styles from "./bike-batch.module.scss";

const FieldSection = ({ label, value }) => (
  <Column isSize={{ tablet: 3, desktop: 2 }}>
    {!value ? (
      <div />
    ) : (
      <div className="has-standard-mb">
        <Title isSize={6} isMarginless>
          {label}
        </Title>
        {value}
      </div>
    )}
  </Column>
);

export default class SavedBikeRow extends Component {
  render() {
    const { bike } = this.props;

    return (
      <div className={styles.row}>
        <Columns isMultiline>
          <FieldSection
            label="Id"
            value={
              <span className="is-size-5">
                <a
                  href={`/admin/bikes/${bike.id}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {bike.id}
                </a>
              </span>
            }
          />
          <FieldSection label="Make" value={bike.bmake.name} />
          <FieldSection label="Model" value={bike.bmodel.name} />
          <FieldSection label="Style" value={bike.bstyle.name} />
          <FieldSection label="Size" value={bike.bsize.name} />
          <FieldSection
            label="Color(s)"
            value={<BColorTags bcolors={bike.bcolors} />}
          />
        </Columns>
      </div>
    );
  }
}
