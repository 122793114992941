import React, { Fragment } from "react";
import { observer, inject } from "mobx-react";
import { Columns, Column } from "bloomer";

import { compose, currentIdIfChanged, idFromProps } from "../../utils";
import { LOADER_TAG_PUBLIC_ITEMS, NO_CHANGE } from "../../constants";

import PublicSite from "../../components/PublicSite";
import PageHeading from "../../components/PageHeading";
import PlainInfoParagraph from "../../components/PlainInfoParagraph";

import ItemCard from "./ItemCard";
import ItemView from "./ItemView";
import LoadingText from "../../components/LoadingText";

class _ItemListing extends React.Component {
  render() {
    const { loading, items } = this.props;
    return (
      <Fragment>
        <PageHeading hasTextAlign="centered">Store</PageHeading>
        {loading ? (
          <LoadingText />
        ) : items.length === 0 ? (
          <PlainInfoParagraph>
            There aren't any items for sale right now.
          </PlainInfoParagraph>
        ) : (
          <div>
            <Columns isMultiline>
              {items.map(item => (
                <Column key={item.id} isSize={{ tablet: 6, widescreen: 4 }}>
                  <ItemCard item={item} />
                </Column>
              ))}
            </Columns>
          </div>
        )}
      </Fragment>
    );
  }
}
const ItemListing = observer(_ItemListing);

class Store extends React.Component {
  get store() {
    return this.props.itemPublicStore;
  }

  get loading() {
    return this.props.uiStore.loadingByTag(LOADER_TAG_PUBLIC_ITEMS);
  }

  componentDidMount() {
    this.loadBasedOnParam();
  }

  componentDidUpdate(prevProps) {
    if (currentIdIfChanged(this.props, prevProps) !== NO_CHANGE) {
      this.loadBasedOnParam();
    }
  }

  componentWillUnmount() {
    this.store.clearAll();
  }

  loadBasedOnParam = () => {
    const itemIdMaybe = idFromProps(this.props);

    if (itemIdMaybe) {
      this.store.show(itemIdMaybe).catch(() => {
        this.props.history.push("/store", { wasRedirect: true });
      });
    } else {
      this.store.loadAll();
    }
  };

  render() {
    const { items, item } = this.store;
    const itemIdMaybe = idFromProps(this.props);
    const loading = this.loading;

    return (
      <PublicSite>
        {itemIdMaybe ? (
          <ItemView loading={loading} item={item} />
        ) : (
          <ItemListing loading={loading} items={items} />
        )}
      </PublicSite>
    );
  }
}

export default compose(
  inject("itemPublicStore", "uiStore"),
  observer
)(Store);
