import React from "react";
import { Column, Title } from "bloomer";
import { Link } from "react-router-dom";

import LinkButton from "../../components/LinkButton";

const OneThirdColumn = ({ title, link, imgSrc, children }) => (
  <Column isSize={{ tablet: "1/3", desktop: "1/3", widescreen: "1/3" }}>
    <Link to={link}>
      {/* 1270 x 952 */}
      <img src={imgSrc} alt={title} className="has-standard-mb" />
    </Link>
    <Title isSize={5} className="has-standard-mb">
      {title}
    </Title>
    {children}
    <p>
      <LinkButton to={link} isColor="info" isOutlined>
        Learn More
      </LinkButton>
    </p>
  </Column>
);

export default OneThirdColumn;
