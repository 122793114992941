import React, { Component } from "react";
import _get from "lodash.get";
import { Button, Title, Box, Columns, Column } from "bloomer";
import { inject, observer } from "mobx-react";

import agent from "../../../agent";
import {
  LOADER_TAG_ADMIN_ORDER_SHIP,
  ADMIN_ORDER_ALERT_GROUP
} from "../../../constants";
import formValidationErrorLayer from "../../../errors/formValidationErrorLayer";
import { standardFinalFormSubscriptions } from "../../../config";
import { makeRecordValidator, makeFieldValidator } from "../../../validators";
import { compose, mapSomeFieldsToId } from "../../../utils";

import CustomFinalForm from "../../../components/CustomFinalForm";
import TextFieldWithErrors from "../../../components/fields/TextFieldWithErrors";
import FancySelectFieldWithErrors from "../../../components/fields/FancySelectFieldWithErrors";
import FinalSubmit from "../../../components/fields/FinalSubmit";
import SelectShippingService from "../../../components/selects/immediate/SelectShippingService";

const validate = makeRecordValidator(
  makeFieldValidator("cost", "required", "number", "minZero"),
  makeFieldValidator("tracking", "required"),
  makeFieldValidator("shippingservice", "required")
);

class Shipper extends Component {
  resetForm = null;

  state = {
    show: false
  };

  toggleShow = () => {
    this.setState({
      show: !this.state.show
    });
  };

  get loading() {
    return this.props.uiStore.loadingByTag(LOADER_TAG_ADMIN_ORDER_SHIP);
  }

  onSubmit = ({ cost, tracking, shippingservice }) => {
    const { order, onShip } = this.props;

    return formValidationErrorLayer(
      agent.AdminOrders.ship(
        mapSomeFieldsToId(
          {
            id: order.id,
            cost,
            tracking,
            shippingservice
          },
          ["shippingservice"]
        ),
        {
          loaderTag: LOADER_TAG_ADMIN_ORDER_SHIP,
          alertGroup: ADMIN_ORDER_ALERT_GROUP
        }
      ).then(() => {
        this.props.uiStore.addAlert(
          "success",
          "You shipped this order successfully!",
          { group: ADMIN_ORDER_ALERT_GROUP }
        );

        // reset form
        this.resetForm();

        // bubble up
        onShip && onShip();
      })
    );
  };

  render() {
    const { order } = this.props;

    return !this.state.show ? (
      <div>
        <Button isSize="medium" isColor="info" onClick={this.toggleShow}>
          Ship
        </Button>
        <br />
        <br />
      </div>
    ) : (
      <Columns>
        <Column isSize="1/2">
          <Box>
            <Title isSize={5}>
              Ship Order&nbsp;
              <Button
                isSize="small"
                onClick={this.toggleShow}
                disabled={this.loading}
                style={{ verticalAlign: "middle" }}
              >
                Cancel
              </Button>
            </Title>
            <p>
              <a
                href="https://paypal.com/shipnow"
                title="Paypal Shipper"
                target="_blank"
                rel="noopener noreferrer"
              >
                Purchase Label
              </a>
            </p>
            <CustomFinalForm
              onSubmit={this.onSubmit}
              validate={validate}
              initialValues={{
                cost: null,
                tracking: null,
                // get default shipping service that
                // was set when order was created
                shippingservice: _get(order, "shippingservice", null)
              }}
              subscription={standardFinalFormSubscriptions}
              render={renderProps => {
                const { handleSubmit, form } = renderProps;
                this.resetForm = form.reset;
                return (
                  <form onSubmit={handleSubmit}>
                    <TextFieldWithErrors
                      type="text"
                      name="cost"
                      label="Actual Cost ($)"
                      extraHelp="For bookkeeping"
                    />
                    <TextFieldWithErrors
                      type="text"
                      name="tracking"
                      label="Tracking Number"
                    />
                    <FancySelectFieldWithErrors
                      name="shippingservice"
                      label="Shipping Service"
                      component={SelectShippingService}
                    />
                    <FinalSubmit
                      {...renderProps}
                      isSize="normal"
                      label="Ship"
                    />
                    <small>
                      Shipping will email customer an order update with tracking
                      information.
                    </small>
                  </form>
                );
              }}
            />
          </Box>
        </Column>
      </Columns>
    );
  }
}

export default compose(
  inject("uiStore"),
  observer
)(Shipper);
