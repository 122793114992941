import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { Box, Title, Level, LevelLeft, LevelRight, Select, Tag } from "bloomer";

import { compose } from "../../../../utils";

import LoadingText from "../../../../components/LoadingText";

import styles from "./leaderboard.module.scss";
import { LEADERBOARD_DISREGARD_USERS } from "../../../../config";
import { VISIT_TYPE_VOLUNTEER } from "../../../../constants";
import { parseMomentUTC, msToReadableHoursMinutes } from "../../../../datetime";

const userTime = user =>
  user.shopvisits
    .filter(visit => visit.type === VISIT_TYPE_VOLUNTEER)
    .reduce((carry, { time_start, time_end }) => {
      const startM = parseMomentUTC(time_start);
      const endM = time_end
        ? parseMomentUTC(time_end)
        : startM.clone().add(1, "hour"); // count visits without end date as 1 hour
      return carry + endM.diff(startM); // diff (milliseconds)
    }, 0);

const StreakTag = ({ streakMaybe }) => {
  if (!streakMaybe) {
    return null;
  }

  return (
    <span>
      &nbsp;&nbsp;
      <Tag isColor="white">
        {streakMaybe}
        -day streak!
      </Tag>
    </span>
  );
};

class Leaderboard extends Component {
  componentDidMount() {
    // load streaks
    this.props.streaksStationStore.loadOnce();
  }

  get streaks() {
    // disregard loading state
    return this.props.streaksStationStore.streaks;
  }

  render() {
    const { loading, users } = this.props;

    const streaks = this.streaks;

    const usersFinal = users
      // filter out some hard-coded users
      .filter(user => !LEADERBOARD_DISREGARD_USERS.includes(user.id))
      // filter out non-volunteers
      .filter(user =>
        user.shopvisits.some(visit => visit.type === VISIT_TYPE_VOLUNTEER)
      )
      // sort by volunteer visit count
      .sort((a, b) => {
        const alen = userTime(a);
        const blen = userTime(b);
        if (alen === blen) {
          return 0;
        }
        return alen < blen ? 1 : -1;
      })
      // take top 10
      .slice(0, 10);

    return (
      <div className={styles.leaderboard}>
        <Box>
          <Level>
            <LevelLeft>
              <Title isSize={4}>Leaderboard</Title>
            </LevelLeft>
            <LevelRight>
              <Select>
                <option>Past Two Weeks</option>
              </Select>
            </LevelRight>
          </Level>
          {loading ? (
            <LoadingText isLeft noMargin />
          ) : usersFinal.length === 0 ? (
            "No results!"
          ) : (
            <div className={styles.users}>
              {usersFinal.map((user, i) => {
                const streakMaybe = streaks.get(`${user.id}`); // (string key)

                return (
                  <div className={styles.user} key={user.id}>
                    #{i + 1}
                    &nbsp;&nbsp;
                    <strong>{user.name}</strong>
                    <br />
                    <Tag isColor="white">
                      {msToReadableHoursMinutes(userTime(user))}
                    </Tag>
                    <StreakTag streakMaybe={streakMaybe} />
                  </div>
                );
              })}
            </div>
          )}
        </Box>
      </div>
    );
  }
}

export default compose(
  inject("streaksStationStore"),
  observer
)(Leaderboard);
