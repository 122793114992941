import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Title, Columns, Column } from "bloomer";
import { Form, FormSpy } from "react-final-form";

import TextFieldWithErrors from "../../../components/fields/TextFieldWithErrors";
import DateFieldWithErrors from "../../../components/fields/DateFieldWithErrors";
import FancySelectFieldWithErrors from "../../../components/fields/FancySelectFieldWithErrors";

import SelectAcquisition from "../../../components/selects/immediate/SelectAcquisition";
import SelectReceivingEvent from "../../../components/selects/throttled/SelectReceivingEvent";
import SelectOrganization from "../../../components/selects/immediate/SelectOrganization";

const FieldColumn = ({ children }) => {
  return (
    <Column isSize={{ tablet: "1/2", desktop: "1/3", widescreen: "1/4" }}>
      {children}
    </Column>
  );
};

export default class BikePrototype extends Component {
  onFormChange = ({ values }) => {
    this.props.onChange(values);
  };

  render() {
    const { value } = this.props;

    return (
      <div className="has-standard-mb">
        <Title isSize={4}>Prototype</Title>
        <p>
          You can define a <i>prototype</i> that the new bikes will extend from
          by default.
        </p>
        <p>
          If this is from a new{" "}
          <Link to="/admin/receiving-events/new">receiving event</Link> or{" "}
          <Link to="/admin/fields">organization</Link>, make sure to create them
          first.
        </p>
        <Form
          initialValues={value}
          onSubmit={() => undefined}
          render={() => {
            return (
              <form>
                <FormSpy
                  subscription={{ values: true }}
                  onChange={this.onFormChange}
                />
                <Columns isMultiline>
                  <FieldColumn>
                    <FancySelectFieldWithErrors
                      name="acquisition"
                      label="Acquisition"
                      component={SelectAcquisition}
                      componentProps={{ isClearable: true }}
                    />
                  </FieldColumn>
                  <FieldColumn>
                    <FancySelectFieldWithErrors
                      name="receivingevent"
                      label="Receiving Event"
                      component={SelectReceivingEvent}
                      componentProps={{ isClearable: true }}
                    />
                  </FieldColumn>
                  <FieldColumn>
                    <FancySelectFieldWithErrors
                      name="organization_donating"
                      label="Donating Organization"
                      component={SelectOrganization}
                      componentProps={{ isClearable: true }}
                    />
                  </FieldColumn>
                  <FieldColumn>
                    <DateFieldWithErrors
                      name="received_at"
                      label="Received At"
                      hasTodayButton
                    />
                  </FieldColumn>
                  <FieldColumn>
                    <TextFieldWithErrors
                      name="notes_admin"
                      label="Admin Notes"
                    />
                  </FieldColumn>
                </Columns>
              </form>
            );
          }}
        />
      </div>
    );
  }
}
