import React, { Component } from "react";
import { Breadcrumb, BreadcrumbItem } from "bloomer";
import { Link } from "react-router-dom";

export default class Breadcrumbs extends Component {
  render() {
    const { items } = this.props;

    return (
      <Breadcrumb>
        <ul>
          {items.map(({ to, label }, i) => (
            <BreadcrumbItem key={to} isActive={i === items.length - 1}>
              <Link to={to}>{label}</Link>
            </BreadcrumbItem>
          ))}
        </ul>
      </Breadcrumb>
    );
  }
}
