import React, { Component, Fragment } from "react";
import { observer } from "mobx-react";
import { Title } from "bloomer";

import { compose } from "../../../utils";
import BikeMechanicWorkedTable from "../../../components/tables/BikeMechanicWorkedTable";
import Wrapper from "./Wrapper";
import AddMechanic from "./AddMechanic";

class WorkHistory extends Component {
  render() {
    return (
      <Wrapper
        renderWithBike={(bike, triggerReload) => (
          <Fragment>
            <BikeMechanicWorkedTable data={bike.mechanics} />
            <br />
            <AddMechanic bike={bike} triggerReload={triggerReload} />
          </Fragment>
        )}
      >
        <Title isSize={3}>Work History</Title>
      </Wrapper>
    );
  }
}

export default compose(observer)(WorkHistory);
