import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { Table } from "bloomer";

import { compose } from "../../../utils";
import { LOADER_TAG_STATION_USERS } from "../../../constants";
import { formatMomentLocalLT, parseMomentUTC } from "../../../datetime";
import { VISIT_DISPLAY_NAMES } from "../../../config";
import LoadingText from "../../../components/LoadingText";

/**
 * Currently checked-in users
 */
class CurrentlyCheckedInUsersTable extends Component {
  get loading() {
    return this.props.uiStore.loadingByTag(LOADER_TAG_STATION_USERS);
  }

  componentDidMount() {
    this.props.userStationStore.loadCurrentlyCheckedInOnce();
  }

  componentWillUnmount() {
    // don't clear store, as it can be re-used
  }

  render() {
    const { userStationStore, renderActions } = this.props;
    const { checkedInUsers } = userStationStore;

    if (this.loading) {
      return <LoadingText />;
    }

    if (checkedInUsers.length === 0) {
      return <p>No one is checked in right now.</p>;
    }

    return (
      <Table isBordered isStriped>
        <thead>
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th>Type</th>
            <th>Time In</th>
            {renderActions && <th>&nbsp;</th>}
          </tr>
        </thead>
        <tbody>
          {checkedInUsers.map(user => (
            <tr key={user.id}>
              <td>{user.name}</td>
              <td>{user.email}</td>
              <td>{VISIT_DISPLAY_NAMES[user.current_visit.type]}</td>
              <td>
                {formatMomentLocalLT(
                  parseMomentUTC(user.current_visit.time_start)
                )}
              </td>
              {renderActions && <td>{renderActions(user)}</td>}
            </tr>
          ))}
        </tbody>
      </Table>
    );
  }
}

export default compose(
  inject("userStationStore", "uiStore"),
  observer
)(CurrentlyCheckedInUsersTable);
