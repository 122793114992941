import React, { Fragment } from "react";
import { observer, inject } from "mobx-react";
import _get from "lodash.get";
import { Columns, Column, Title, Subtitle, Box } from "bloomer";
import { Route } from "react-router-dom";

import AdminSite from "../../../components/AdminSite";
import PageHeading from "../../../components/PageHeading";
import LoadingText from "../../../components/LoadingText";
import TabNav from "../../../components/TabNav";

import {
  compose,
  ratioToPrettyPercent,
  formatMoney,
  formatFixed2
} from "../../../utils";
import { LOADER_TAG_ADMIN_REPORTS } from "../../../constants";

import YearSelector from "./YearSelector";
import LastUpdated from "./LastUpdated";
import DonorSpecific from "./DonorSpecific";
import EmailTopVolunteers from "./EmailTopVolunteers";
import Regenerate from "./Regenerate";

import BarPerYear from "./charts/BarPerYear";
import BarBikesPerOrganization from "./charts/BarBikesPerOrganization";
import LinePerMonth from "./charts/LinePerMonth";
import PieBikesStatus from "./charts/PieBikesStatus";
import PieBikesCondition from "./charts/PieBikesCondition";
import PieBikesFromTopOrganizations from "./charts/PieBikesFromTopOrganizations";
import AreaVisitsPerVisitor from "./charts/AreaVisitsPerVisitor";
import BarBikePerMonthGroupedYear from "./charts/BarBikePerMonthGroupedYear";
import YTDBikesAccumulative from "./charts/YTDBikesAccumulative";
import YTDBikesMonthly from "./charts/YTDBikesMonthly";
import CountAndRatioLinePerMonth from "./charts/CountAndRatioLinePerMonth";
import { monthSorterBetweenTwoDates } from "./charts/utils";
import BarBikesFixCostOrTimeByCondition from "./charts/BarBikesFixCostOrTimeByCondition";
import BarBikesCurrentUnfixedByCondition from "./charts/BarBikesCurrentUnfixedByCondition";
import BarBikesPerDemographic from "./charts/BarBikesPerDemographic";

const standardNumericReports = [
  {
    name: "bikes_fixed",
    title: "# Fixed (w/ No-fix)",
    cellFn: ({ bikes_with_fixed_at, bikes_total }) =>
      `${bikes_with_fixed_at} (${bikes_total})`
  },
  { name: "bikes_total", title: "# Sold or Donated" },
  { name: "bikes_sold", title: "# Sold" },
  { name: "bikes_donated", title: "# Donated" },
  {
    name: "bikes_scrapped",
    title: "# Scrapped",
    cellFn: ({ scrapped, ratio }) =>
      `${scrapped} (${ratioToPrettyPercent(ratio)})`
  },
  {
    name: "bikes_fix_cost",
    title: "Mean Fix Cost (w/ No-fix)",
    cellFn: ({ average_fix_cost_only_fix, average_fix_cost }) =>
      `${formatMoney(average_fix_cost_only_fix)} (${formatMoney(
        average_fix_cost
      )})`
  },
  {
    name: "bikes_sold_price",
    title: "Mean Asking Price (>$100)",
    cellFn: ({ average_asking_price, sd_asking_price }) =>
      `${formatMoney(average_asking_price)} (σ ${formatFixed2(
        sd_asking_price
      )})`
  },
  {
    name: "bikes_sold_price",
    title: "Mean Final Price (>$100)",
    cellFn: ({ average_sold_price, sd_sold_price }) =>
      `${formatMoney(average_sold_price)} (σ ${formatFixed2(sd_sold_price)})`
  },
  {
    name: "bikes_sold_price",
    title: "Mean Discount (>$100)",
    cellFn: ({ average_discount }) => `${formatMoney(average_discount)}`
  },
  {
    name: "bikes_revenue",
    title: "Revenue",
    cellFn: revenue => `${formatMoney(revenue)}`
  }
];

const getYearsListForReport = (reportsObj, name) =>
  Object.keys(_get(reportsObj, `${name}.data.years`) || []).sort();

const getRelevantYearsListForNumericReports = reportsObj =>
  standardNumericReports
    .reduce(
      (acc, nr) =>
        getYearsListForReport(reportsObj, nr.name).reduce(
          (acc, year) => (acc.indexOf(year) < 0 ? [...acc, year] : acc),
          acc
        ),
      []
    )
    .sort();

class Reports extends React.Component {
  loadAll = () => {
    // clear first
    this.props.reportAdminStore.clear();
    this.props.reportAdminStore.loadAll();
  };

  componentDidMount() {
    this.loadAll();
  }

  componentWillUnmount() {
    this.props.reportAdminStore.clear();
  }

  get loading() {
    return this.props.uiStore.loadingByTag(LOADER_TAG_ADMIN_REPORTS);
  }

  render() {
    const { reports } = this.props.reportAdminStore;

    const reportsObj = reports.toJSON();

    return (
      <AdminSite>
        <PageHeading>Reports</PageHeading>
        <LoadingText cond={this.loading} isLeft />
        {!reports.size ? null : (
          <div>
            <TabNav
              tabs={[
                { to: `/admin/reports`, exact: true, label: "Main" },
                { to: `/admin/reports/bikes-by-org`, label: "Bikes By Org" },
                { to: `/admin/reports/visits`, label: "Visits" },
                { to: `/admin/reports/volunteers`, label: "Volunteers" }
              ]}
            />
            {/*
             * Tab: Main
             */}
            <Route
              path="/admin/reports"
              exact
              render={() => (
                <Fragment>
                  <Regenerate onDone={this.loadAll} />
                  <Columns>
                    <Column isSize="1/2">
                      <YearSelector
                        years={getRelevantYearsListForNumericReports(
                          reportsObj
                        )}
                        renderChart={(year, selector) => (
                          <table className="table is-bordered">
                            <thead>
                              <tr>
                                <th>
                                  <strong>Bikes</strong>
                                </th>
                                <th>All Time</th>
                                <th>{selector}</th>
                              </tr>
                            </thead>
                            <tbody>
                              {standardNumericReports.map((nr, i) => {
                                const { name, title, cellFn = a => a } = nr;

                                if (!_get(reportsObj, name)) {
                                  return null;
                                }

                                const displayer = a =>
                                  a != null ? cellFn(a) : <small>n/a</small>;

                                return (
                                  <tr key={`${name}${i}`}>
                                    <td>{title}</td>
                                    <td>
                                      {displayer(
                                        _get(reportsObj, `${name}.data.all`)
                                      )}
                                    </td>
                                    <td>
                                      {displayer(
                                        _get(
                                          reportsObj,
                                          `${name}.data.years.${year}`
                                        )
                                      )}
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        )}
                      />
                    </Column>
                    <Column isSize="1/2">
                      <table className="table is-bordered">
                        <tbody>
                          <tr>
                            <td># Active Memberships</td>
                            <td>
                              {_get(
                                reportsObj,
                                "memberships_active.data.count"
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td># Bikes To Be Fixed</td>
                            <td>
                              {_get(
                                reportsObj,
                                "bikes_currently_new.data.count"
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td># Bikes In Showroom</td>
                            <td>
                              {_get(
                                reportsObj,
                                "bikes_currently_available.data.count"
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td># Rental Bikes Out / Total</td>
                            <td>
                              {_get(
                                reportsObj,
                                "rental_bikes_out_total.data.out"
                              )}{" "}
                              /{" "}
                              {_get(
                                reportsObj,
                                "rental_bikes_out_total.data.total_not_missing"
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td># Rental Bikes Overdue</td>
                            <td>
                              {_get(
                                reportsObj,
                                "rental_bikes_overdue.data.count"
                              )}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </Column>
                  </Columns>
                  <Columns>
                    <Column isSize="1/2">
                      <Title isSize={4}>Bikes Sold vs. Donated</Title>
                      <Subtitle>All Time</Subtitle>
                      <PieBikesStatus
                        statuses={{
                          sold: _get(reportsObj, "bikes_sold.data.all"),
                          donated: _get(reportsObj, "bikes_donated.data.all")
                        }}
                      />
                      <LastUpdated report={_get(reportsObj, "bikes_sold")} />
                    </Column>
                    <Column isSize="1/2">
                      <Title isSize={4}>Bikes Sold vs. Donated</Title>
                      <YearSelector
                        years={getYearsListForReport(reportsObj, "bikes_sold")}
                        renderChart={(year, selector) => (
                          <Fragment>
                            <Subtitle>{selector}</Subtitle>
                            {!year ? (
                              <em>n/a</em>
                            ) : (
                              <PieBikesStatus
                                statuses={{
                                  sold: _get(
                                    reportsObj,
                                    `bikes_sold.data.years.${year}`
                                  ),
                                  donated: _get(
                                    reportsObj,
                                    `bikes_donated.data.years.${year}`
                                  )
                                }}
                              />
                            )}
                          </Fragment>
                        )}
                      />
                      <LastUpdated report={_get(reportsObj, "bikes_sold")} />
                    </Column>
                  </Columns>
                  <Columns isMultiline>
                    <Column isSize="1/2">
                      <Title isSize={4}>Bikes Sold/Donated By Year</Title>
                      <BarPerYear
                        yearMap={_get(reportsObj, "bikes_total.data.years")}
                        dataKey="bikes"
                      />
                      <LastUpdated report={_get(reportsObj, "bikes_total")} />
                    </Column>
                    <Column isSize="1/2">
                      <Title isSize={4}>Bikes Sold By Year</Title>
                      <BarPerYear
                        yearMap={_get(reportsObj, "bikes_sold.data.years")}
                        dataKey="bikes"
                      />
                      <LastUpdated report={_get(reportsObj, "bikes_sold")} />
                    </Column>
                    <Column isSize="1/2">
                      <Title isSize={4}>Bikes Donated By Year</Title>
                      <BarPerYear
                        yearMap={_get(reportsObj, "bikes_donated.data.years")}
                        dataKey="bikes"
                      />
                      <LastUpdated report={_get(reportsObj, "bikes_donated")} />
                    </Column>
                  </Columns>
                  <Columns>
                    <Column>
                      <Title isSize={4}>Bikes Sold Per Month</Title>
                      <BarBikePerMonthGroupedYear
                        field="count"
                        monthBikeMap={_get(
                          reportsObj,
                          "bikes_sold_per_month.data.months"
                        )}
                      />
                      <LastUpdated
                        report={_get(reportsObj, "bikes_sold_per_month")}
                      />
                    </Column>
                  </Columns>
                  <Columns>
                    <Column>
                      <Title isSize={4}>Bike Revenue Per Month</Title>
                      <Subtitle>Donated and Sold</Subtitle>
                      <BarBikePerMonthGroupedYear
                        field="revenue"
                        monthBikeMap={_get(
                          reportsObj,
                          "bikes_revenue_per_month.data.months"
                        )}
                      />
                      <LastUpdated
                        report={_get(reportsObj, "bikes_revenue_per_month")}
                      />
                    </Column>
                  </Columns>

                  <Columns>
                    <Column>
                      <Title isSize={4}>
                        Bikes Sold/Donated YTD (Accumulative)
                      </Title>
                      <YTDBikesAccumulative
                        bikes={_get(reportsObj, "all_bikes_raw.data.bikes")}
                        statuses={["sold", "donated"]}
                        dateField="sold_at"
                      />
                      <LastUpdated
                        report={_get(reportsObj, "all_bikes_raw")}
                        isLive
                      />
                    </Column>
                  </Columns>
                  <Columns>
                    <Column>
                      <Title isSize={4}>Bikes Sold YTD (Accumulative)</Title>
                      <YTDBikesAccumulative
                        bikes={_get(reportsObj, "all_bikes_raw.data.bikes")}
                        statuses={["sold"]}
                        dateField="sold_at"
                      />
                      <LastUpdated
                        report={_get(reportsObj, "all_bikes_raw")}
                        isLive
                      />
                    </Column>
                  </Columns>
                  <Columns>
                    <Column>
                      <Title isSize={4}>Bikes Donated YTD (Accumulative)</Title>
                      <YTDBikesAccumulative
                        bikes={_get(reportsObj, "all_bikes_raw.data.bikes")}
                        statuses={["donated"]}
                        dateField="sold_at"
                      />
                      <LastUpdated
                        report={_get(reportsObj, "all_bikes_raw")}
                        isLive
                      />
                    </Column>
                  </Columns>

                  <Columns>
                    <Column>
                      <Title isSize={4}>Bikes Received Per Month</Title>
                      <YTDBikesMonthly
                        bikes={_get(reportsObj, "all_bikes_raw.data.bikes")}
                        sorter={null}
                      />
                      <LastUpdated
                        report={_get(reportsObj, "all_bikes_raw")}
                        isLive
                      />
                    </Column>
                  </Columns>

                  <Columns>
                    <Column>
                      <Title isSize={4}>Bikes Unfixed YTD (By Month)</Title>
                      <YTDBikesMonthly
                        bikes={_get(reportsObj, "all_bikes_raw.data.bikes")}
                        bikeFilter={bike =>
                          !["scrapped", "missing", "returned"].includes(
                            bike.status
                          ) &&
                          // is_nofix don't have a fixed_at date
                          bike.is_nofix === false &&
                          // Unfortunately, need to filter out bikes from the
                          // v1 tracker with bad data (sold/donated,
                          // but no fixed_at date)
                          !(
                            ["sold", "donated"].includes(bike.status) &&
                            bike.fixed_at === null
                          )
                        }
                        sorter={monthSorterBetweenTwoDates(
                          "received_at",
                          "fixed_at"
                        )}
                      />
                      <LastUpdated
                        report={_get(reportsObj, "all_bikes_raw")}
                        isLive
                      />
                    </Column>
                  </Columns>

                  <Columns>
                    <Column>
                      <Title isSize={4}>Bikes In Showroom YTD (By Month)</Title>
                      <YTDBikesMonthly
                        bikes={_get(reportsObj, "all_bikes_raw.data.bikes")}
                        bikeFilter={bike =>
                          !["scrapped", "missing", "returned"].includes(
                            bike.status
                          )
                        }
                        sorter={monthSorterBetweenTwoDates(
                          bike => (bike.is_nofix ? "received_at" : "fixed_at"),
                          "sold_at"
                        )}
                      />
                      <LastUpdated
                        report={_get(reportsObj, "all_bikes_raw")}
                        isLive
                      />
                    </Column>
                  </Columns>

                  <Columns>
                    <Column>
                      <Title isSize={4}>Bikes Fixed Per Month</Title>
                      <YTDBikesMonthly
                        bikes={_get(reportsObj, "all_bikes_raw.data.bikes").map(
                          bike =>
                            // for is_nofix bikes, use received_at as fixed_at
                            bike.is_nofix
                              ? { ...bike, fixed_at: bike.received_at }
                              : bike
                        )}
                        bikeFilter={bike => bike.fixed_at}
                        dateField="fixed_at"
                        sorter={null}
                      />
                      <LastUpdated
                        report={_get(reportsObj, "all_bikes_raw")}
                        isLive
                      />
                    </Column>
                  </Columns>

                  <Columns>
                    <Column>
                      <Title isSize={4}>
                        Bike Fix Time By Incoming Condition
                      </Title>
                      <Subtitle>Available, Donated, or Sold</Subtitle>
                      <BarBikesFixCostOrTimeByCondition
                        bikes={_get(reportsObj, "all_bikes_raw.data.bikes")}
                        costOrTime="time"
                      />
                      <LastUpdated
                        report={_get(reportsObj, "all_bikes_raw")}
                        isLive
                      />
                    </Column>
                  </Columns>
                  <Columns>
                    <Column>
                      <Title isSize={4}>
                        Bike Fix Cost By Incoming Condition
                      </Title>
                      <Subtitle>Available, Donated, or Sold</Subtitle>
                      <BarBikesFixCostOrTimeByCondition
                        bikes={_get(reportsObj, "all_bikes_raw.data.bikes")}
                        costOrTime="cost"
                      />
                      <LastUpdated
                        report={_get(reportsObj, "all_bikes_raw")}
                        isLive
                      />
                    </Column>
                  </Columns>
                  <Columns>
                    <Column>
                      <Title isSize={4}>
                        Current New (Unfixed) Bike Conditions
                      </Title>
                      <BarBikesCurrentUnfixedByCondition
                        bikes={_get(reportsObj, "all_bikes_raw.data.bikes")}
                      />
                      <LastUpdated
                        report={_get(reportsObj, "all_bikes_raw")}
                        isLive
                      />
                    </Column>
                  </Columns>
                </Fragment>
              )}
            />
            {/*
             * Tab: Bikes By Org
             */}
            <Route
              path="/admin/reports/bikes-by-org"
              exact
              render={props => (
                <Fragment>
                  <Title isSize={4}>Bikes Donated By Organization</Title>
                  <Columns>
                    <Column isSize="1/2">
                      <Title isSize={5}>Top Donors, All Time</Title>
                      <PieBikesFromTopOrganizations
                        topN={7}
                        organizationBikeMap={_get(
                          reportsObj,
                          "bikes_by_donating_organization.data.all"
                        )}
                      />
                      <LastUpdated
                        report={_get(
                          reportsObj,
                          "bikes_by_donating_organization"
                        )}
                      />
                    </Column>
                    <Column isSize="1/2">
                      <YearSelector
                        years={getYearsListForReport(
                          reportsObj,
                          "bikes_by_donating_organization"
                        )}
                        renderChart={(year, selector) => (
                          <Fragment>
                            <Title isSize={5}>Top Donors, {selector}</Title>
                            {!year ? (
                              <em>n/a</em>
                            ) : (
                              <Fragment>
                                <PieBikesFromTopOrganizations
                                  organizationBikeMap={_get(
                                    reportsObj,
                                    `bikes_by_donating_organization.data.years.${year}`
                                  )}
                                />
                                <LastUpdated
                                  report={_get(
                                    reportsObj,
                                    "bikes_by_donating_organization"
                                  )}
                                />
                              </Fragment>
                            )}
                          </Fragment>
                        )}
                      />
                    </Column>
                  </Columns>
                  <Columns>
                    <Column>
                      <Title isSize={5}>Counts, All Time</Title>
                      <BarBikesPerOrganization
                        organizationBikeMap={_get(
                          reportsObj,
                          "bikes_by_donating_organization.data.all"
                        )}
                        organizationDataKey="count"
                      />
                      <LastUpdated
                        report={_get(
                          reportsObj,
                          "bikes_by_donating_organization"
                        )}
                      />
                      <Title isSize={5}>Average Sale Price, All Time</Title>
                      <BarBikesPerOrganization
                        organizationBikeMap={_get(
                          reportsObj,
                          "bikes_by_donating_organization.data.all"
                        )}
                        organizationDataKey="average_sale_price"
                        countMinForColor={10}
                        removeZeroes
                      />
                      <p className="is-size-7 has-text-grey-light has-text-centered">
                        Organizations with fewer than <b>10</b> bikes are
                        grayed-out
                      </p>
                      <LastUpdated
                        report={_get(
                          reportsObj,
                          "bikes_by_donating_organization"
                        )}
                      />

                      <YearSelector
                        years={getYearsListForReport(
                          reportsObj,
                          "bikes_by_donating_organization"
                        )}
                        renderChart={(year, selector) => (
                          <Fragment>
                            <Subtitle>{selector}</Subtitle>
                            {!year ? (
                              <em>n/a</em>
                            ) : (
                              <Fragment>
                                <Title isSize={5}>Counts, {year}</Title>
                                <BarBikesPerOrganization
                                  organizationBikeMap={_get(
                                    reportsObj,
                                    `bikes_by_donating_organization.data.years.${year}`
                                  )}
                                  organizationDataKey="count"
                                />
                                <LastUpdated
                                  report={_get(
                                    reportsObj,
                                    "bikes_by_donating_organization"
                                  )}
                                />
                                <Title isSize={5}>
                                  Average Sale Price, {year}
                                </Title>
                                <BarBikesPerOrganization
                                  organizationBikeMap={_get(
                                    reportsObj,
                                    `bikes_by_donating_organization.data.years.${year}`
                                  )}
                                  organizationDataKey="average_sale_price"
                                  countMinForColor={5}
                                  removeZeroes
                                />
                                <p className="is-size-7 has-text-grey-light has-text-centered">
                                  Organizations with fewer than <b>5</b> bikes
                                  are grayed-out
                                </p>
                                <LastUpdated
                                  report={_get(
                                    reportsObj,
                                    "bikes_by_donating_organization"
                                  )}
                                />
                              </Fragment>
                            )}
                          </Fragment>
                        )}
                      />
                    </Column>
                  </Columns>

                  {/* Donor-Specific Reports */}
                  <Box>
                    <DonorSpecific
                      donors={_get(reportsObj, "donor_specific.data")}
                      renderDonor={(donor, selector) => {
                        return (
                          <Fragment>
                            <Columns isMultiline>
                              <Column isSize="full">
                                <Title isSize={4}>Donor: {selector}</Title>
                                {!donor && (
                                  <p>
                                    <em>
                                      Select a donor from the dropdown above.
                                    </em>
                                  </p>
                                )}
                              </Column>
                              {donor && (
                                <Fragment>
                                  <Column isSize="full">
                                    <p>
                                      Bikes donated (all time):{" "}
                                      {_get(donor, "bikes_count.all")}
                                    </p>
                                    <Title isSize={5}>
                                      Bikes Donated Per Year
                                    </Title>
                                    <BarPerYear
                                      yearMap={_get(donor, "bikes_count.years")}
                                      dataKey="bikes"
                                    />
                                    <LastUpdated
                                      report={_get(
                                        reportsObj,
                                        "donor_specific"
                                      )}
                                    />
                                  </Column>

                                  {/* By status */}
                                  <Column isSize="1/2">
                                    <Title isSize={5}>
                                      Bikes By Status, All Time
                                    </Title>
                                    <PieBikesStatus
                                      statuses={_get(
                                        donor,
                                        "bikes_by_status.all"
                                      )}
                                    />
                                    <LastUpdated
                                      report={_get(
                                        reportsObj,
                                        "donor_specific"
                                      )}
                                    />
                                  </Column>
                                  <Column isSize="1/2">
                                    <YearSelector
                                      years={Object.keys(
                                        _get(donor, "bikes_by_status.years")
                                      )}
                                      renderChart={(year, selector) => (
                                        <Fragment>
                                          <Title isSize={5}>
                                            Bikes By Status {selector}
                                          </Title>
                                          {!year ? (
                                            <em>n/a</em>
                                          ) : (
                                            <PieBikesStatus
                                              statuses={_get(
                                                donor,
                                                `bikes_by_status.years.${year}`
                                              )}
                                            />
                                          )}
                                        </Fragment>
                                      )}
                                    />
                                    <LastUpdated
                                      report={_get(
                                        reportsObj,
                                        "donor_specific"
                                      )}
                                    />
                                  </Column>

                                  {/* By condition */}
                                  <Column isSize="1/2">
                                    <Title isSize={5}>
                                      Bikes By Condition, All Time
                                    </Title>
                                    <PieBikesCondition
                                      conditions={_get(
                                        donor,
                                        "bikes_by_condition.all"
                                      )}
                                    />
                                    <LastUpdated
                                      report={_get(
                                        reportsObj,
                                        "donor_specific"
                                      )}
                                    />
                                  </Column>
                                  <Column isSize="1/2">
                                    <YearSelector
                                      years={Object.keys(
                                        _get(donor, "bikes_by_condition.years")
                                      )}
                                      renderChart={(year, selector) => (
                                        <Fragment>
                                          <Title isSize={5}>
                                            Bikes By Condition {selector}
                                          </Title>
                                          {!year ? (
                                            <em>n/a</em>
                                          ) : (
                                            <PieBikesCondition
                                              conditions={_get(
                                                donor,
                                                `bikes_by_condition.years.${year}`
                                              )}
                                            />
                                          )}
                                        </Fragment>
                                      )}
                                    />
                                    <LastUpdated
                                      report={_get(
                                        reportsObj,
                                        "donor_specific"
                                      )}
                                    />
                                  </Column>
                                </Fragment>
                              )}
                            </Columns>
                          </Fragment>
                        );
                      }}
                    />
                  </Box>

                  {/* Receving-Specific Reports (reuses DonorSpecific wrapper component) */}
                  <Box>
                    <DonorSpecific
                      donors={_get(reportsObj, "receiving_specific.data")}
                      renderDonor={(receiver, selector) => {
                        return (
                          <Fragment>
                            <Columns isMultiline>
                              <Column isSize="full">
                                <Title isSize={4}>Receiver: {selector}</Title>
                                {!receiver && (
                                  <p>
                                    <em>
                                      Select a receiver from the dropdown above.
                                    </em>
                                  </p>
                                )}
                              </Column>
                              {receiver && (
                                <Fragment>
                                  <Column isSize="full">
                                    <p>
                                      Bikes received (all time):{" "}
                                      {_get(receiver, "bikes_count.all")}
                                    </p>
                                    <Title isSize={5}>
                                      Bikes Received Per Year
                                    </Title>
                                    <BarPerYear
                                      yearMap={_get(
                                        receiver,
                                        "bikes_count.years"
                                      )}
                                      dataKey="bikes"
                                    />
                                    <LastUpdated
                                      report={_get(
                                        reportsObj,
                                        "receiving_specific"
                                      )}
                                    />
                                  </Column>

                                  {/* Demographic Breakdown */}
                                  <Column isSize="full">
                                    <Title isSize={5}>Demographics</Title>
                                    <BarBikesPerDemographic
                                      demographics={_get(
                                        receiver,
                                        "bikes_per_demographic"
                                      )}
                                    />
                                    <LastUpdated
                                      report={_get(
                                        reportsObj,
                                        "receiving_specific"
                                      )}
                                    />
                                  </Column>

                                  {/* By condition */}
                                  <Column isSize="1/2">
                                    <Title isSize={5}>
                                      Bikes By Condition, All Time
                                    </Title>
                                    <PieBikesCondition
                                      conditions={_get(
                                        receiver,
                                        "bikes_by_condition.all"
                                      )}
                                    />
                                    <LastUpdated
                                      report={_get(
                                        reportsObj,
                                        "receiving_specific"
                                      )}
                                    />
                                  </Column>
                                  <Column isSize="1/2">
                                    <YearSelector
                                      years={Object.keys(
                                        _get(
                                          receiver,
                                          "bikes_by_condition.years"
                                        )
                                      )}
                                      renderChart={(year, selector) => (
                                        <Fragment>
                                          <Title isSize={5}>
                                            Bikes By Condition {selector}
                                          </Title>
                                          {!year ? (
                                            <em>n/a</em>
                                          ) : (
                                            <PieBikesCondition
                                              conditions={_get(
                                                receiver,
                                                `bikes_by_condition.years.${year}`
                                              )}
                                            />
                                          )}
                                        </Fragment>
                                      )}
                                    />
                                    <LastUpdated
                                      report={_get(
                                        reportsObj,
                                        "receiving_specific"
                                      )}
                                    />
                                  </Column>
                                </Fragment>
                              )}
                            </Columns>
                          </Fragment>
                        );
                      }}
                    />
                  </Box>
                </Fragment>
              )}
            />
            {/*
             * Tab: Visits
             */}
            <Route
              path="/admin/reports/visits"
              exact
              render={props => (
                <Fragment>
                  <Columns>
                    <Column>
                      <Title isSize={4}>Visits By Month</Title>
                      <Subtitle>Last 12 months</Subtitle>
                      <LinePerMonth
                        monthVisitMap={_get(
                          reportsObj,
                          "visits_per_month.data.months"
                        )}
                      />
                      <LastUpdated
                        report={_get(reportsObj, "visits_per_month")}
                      />
                    </Column>
                  </Columns>
                  <Columns>
                    <Column>
                      <Title isSize={4}>Visits Per Visitor</Title>
                      <Subtitle>Last 30 Days</Subtitle>
                      <AreaVisitsPerVisitor
                        visitCountMap={_get(
                          reportsObj,
                          "visits_per_visitor_last_month.data.visit_counts"
                        )}
                      />
                      <LastUpdated
                        report={_get(
                          reportsObj,
                          "visits_per_visitor_last_month"
                        )}
                      />
                    </Column>
                  </Columns>
                </Fragment>
              )}
            />
            {/*
             * Tab: Volunteers
             */}
            <Route
              path="/admin/reports/volunteers"
              exact
              render={props => (
                <Fragment>
                  <p>
                    Unique volunteers (all time):{" "}
                    {_get(reportsObj, "volunteers.data.all")}
                  </p>
                  <Columns>
                    <Column>
                      <Title isSize={4}>Total Volunteer Hours Per Month</Title>
                      <Subtitle>Last 12 months</Subtitle>
                      <LinePerMonth
                        monthVisitMap={_get(
                          reportsObj,
                          "volunteers_per_month.data.months"
                        )}
                        dataKey="hours"
                      />
                      <LastUpdated
                        report={_get(reportsObj, "volunteers_per_month")}
                      />
                    </Column>
                  </Columns>
                  <Columns>
                    <Column>
                      <Title isSize={4}>Volunteers Per Year</Title>
                      <BarPerYear
                        yearMap={_get(reportsObj, "volunteers.data.years")}
                        dataKey="volunteers"
                      />
                      <LastUpdated report={_get(reportsObj, "volunteers")} />
                    </Column>
                  </Columns>
                  <Columns>
                    <Column>
                      <Title isSize={4}>Unique Volunteers Per Month</Title>
                      <Subtitle>Last 12 months</Subtitle>
                      <LinePerMonth
                        monthVisitMap={_get(
                          reportsObj,
                          "volunteers_per_month.data.months"
                        )}
                        dataKey="count"
                      />
                      <LastUpdated
                        report={_get(reportsObj, "volunteers_per_month")}
                      />
                    </Column>
                  </Columns>
                  <Columns>
                    <Column>
                      <Title isSize={4}>
                        Retained Volunteers Per Month (From Previous Month)
                      </Title>
                      <Subtitle>Last 12 months</Subtitle>
                      <CountAndRatioLinePerMonth
                        monthVisitMap={_get(
                          reportsObj,
                          "retained_volunteers_per_month.data.months"
                        )}
                      />
                      <LastUpdated
                        report={_get(
                          reportsObj,
                          "retained_volunteers_per_month"
                        )}
                      />
                    </Column>
                  </Columns>
                  <Columns>
                    <Column>
                      <Title isSize={4}>New Volunteers Per Month</Title>
                      <Subtitle>Last 12 months</Subtitle>
                      <LinePerMonth
                        monthVisitMap={_get(
                          reportsObj,
                          "new_volunteers_per_month.data.months"
                        )}
                        dataKey="count"
                      />
                      <LastUpdated
                        report={_get(reportsObj, "new_volunteers_per_month")}
                      />
                    </Column>
                  </Columns>
                  <Columns>
                    <Column>
                      <Title isSize={4}>New Volunteer Visits</Title>
                      <Subtitle>Last 90 Days</Subtitle>
                      <AreaVisitsPerVisitor
                        visitCountMap={_get(
                          reportsObj,
                          "new_volunteer_visits.data.visit_counts"
                        )}
                        labelYAxis="Volunteers"
                      />
                      <LastUpdated
                        report={_get(reportsObj, "new_volunteer_visits")}
                      />
                    </Column>
                  </Columns>
                  <Columns>
                    <Column>
                      <Title isSize={4}>Email Top Volunteer Deliverables</Title>
                      <EmailTopVolunteers
                        volunteersDeliverables={_get(
                          reportsObj,
                          "volunteers_deliverables"
                        )}
                      />
                    </Column>
                  </Columns>
                </Fragment>
              )}
            />
          </div>
        )}
      </AdminSite>
    );
  }
}

export default compose(
  inject("uiStore", "reportAdminStore"),
  observer
)(Reports);
