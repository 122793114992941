import React from "react";
import BaseSortTable from "./BaseSortTable";
import {
  userColumn,
  dateTimeColumn,
  minutesDurationColumn,
  wrapColumn,
  cellNullIfFalsey
} from "./helpers";

const columns = [
  {
    Header: "Mechanic",
    id: "mechanic",
    accessor: row => row, // the user is the row itself
    ...userColumn
  },
  {
    Header: "Recorded At",
    accessor: "work.recorded_at",
    ...dateTimeColumn
  },
  {
    Header: "Time",
    accessor: "work.minutes_worked",
    ...minutesDurationColumn
  },
  {
    Header: "Note",
    accessor: "work.note",
    Cell: cellNullIfFalsey,
    ...wrapColumn
  }
];

class BikeMechanicWorkedTable extends React.Component {
  render() {
    return <BaseSortTable columns={columns} {...this.props} />;
  }
}

export default BikeMechanicWorkedTable;
