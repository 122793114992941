import React from "react";

export default class DonorSpecific extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      donorId: null
    };
  }

  onSelectDonor = e => {
    const donorId = e.target.value;
    this.setState({ donorId });
  };

  render() {
    const { donors, renderDonor } = this.props;

    const selector = (
      <div className="select is-rounded" style={{ verticalAlign: "middle" }}>
        <select onChange={this.onSelectDonor} value={this.state.donorId}>
          <option />
          {donors &&
            Object.values(donors)
              .sort((a, b) => (a.name > b.name ? 1 : -1)) // sort donors by name
              .map(donor => (
                <option key={donor.id} value={donor.id}>
                  {donor.name}
                </option>
              ))}
        </select>
      </div>
    );

    return renderDonor(
      (donors && donors[this.state.donorId]) || null,
      selector
    );
  }
}
