import React from "react";
import { Title } from "bloomer";

export default function Address({ title = "Address", address }) {
  if (!address) {
    return "No address specified";
  }

  return (
    <div>
      <Title isSize={6}>{title}</Title>
      <ul>
        {address.name && <li>{address.name}</li>}
        {address.line_1 && <li>{address.line_1}</li>}
        {address.line_2 && <li>{address.line_2}</li>}
        <li>
          {address.city} {address.state} {address.postal}
        </li>
        {address.country && <li>{address.country}</li>}
        {address.phone && <li>{address.phone}</li>}
      </ul>
    </div>
  );
}
