import moment from "moment-timezone";
import isPlainObject from "is-plain-object";
import { DATE_TIMEZONE } from "./config";

// Constants

export const MOMENT_FORMAT_YMD = "YYYY-MM-DD"; // e.g. 2018-02-10

export const MOMENT_FORMAT_LLL = "lll"; // e.g. Sep 4, 1986 8:30 PM

export const MOMENT_FORMAT_LL = "ll"; // e.g. Sep 4, 1986

export const MOMENT_FORMAT_LT = "LT"; // e.g. 8:30 PM

// Helpers

/**
 * Laravel returns some dates as serialized json carbon instances.
 * This checks for an object with a string `date` property and returns that,
 * if not it just returns the argument as-is
 */
export const getRawDate = x =>
  isPlainObject(x) && x.hasOwnProperty("date") ? x.date : x;

/**
 * Now
 */
export const momentNowLocal = () => moment();

// Parse

/**
 * Parse utc string as moment date
 */
export const parseMomentUTC = (date, format, strict = false) =>
  moment.utc(getRawDate(date) || moment.invalid(), format, "en", strict);

/**
 * Parse string as date in configured timezone (likely America/New_York)
 */
export const parseMomentTimezoneConfig = (date, format, strict = false) =>
  moment.tz(
    getRawDate(date) || moment.invalid(),
    format,
    "en",
    strict,
    DATE_TIMEZONE
  );

/**
 * Parse as strict YYYY-MM-DD moment (using timezone config)
 */
export const parseYMDMomentTimezoneConfig = date =>
  parseMomentTimezoneConfig(getRawDate(date), MOMENT_FORMAT_YMD, true);

// Format

export const formatMomentLocalYMD = m => m.local().format(MOMENT_FORMAT_YMD);

export const formatMomentLocalLLL = m => m.local().format(MOMENT_FORMAT_LLL);

export const formatMomentLocalLL = m => m.local().format(MOMENT_FORMAT_LL);

export const formatMomentLocalLT = m => m.local().format(MOMENT_FORMAT_LT);

// Durations

/**
 * Format milliseconds to minutes if < 1 hr, or hours if > 1 (rounded)
 */
export const msToReadableHoursMinutes = ms => {
  if (!ms) {
    return "zero";
  }

  const duration = moment.duration(ms, "ms");
  const durationHours = duration.asHours();

  if (durationHours < 1) {
    return `${Math.round(duration.asMinutes())} mins`;
  }

  return `${Math.round(durationHours)} hrs`;
};

/**
 * Format minutes to something more readable.
 * NOTE: not very precise. For example, 310 => "5 hours", not 5 hours 10 minutes.
 */
export const minutesToHumanDuration = minutes =>
  minutes ? moment.duration(minutes, "minutes").humanize() : "zero";

export const minutesToHumanDurationShorter = minutes =>
  minutesToHumanDuration(minutes)
    .replace("second", "sec")
    .replace("minute", "min")
    .replace("hour", "hr");
