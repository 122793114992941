import { observable, decorate, action, runInAction } from "mobx";
import agent from "../agent";
import { LOADER_TAG_ADMIN_RECEIVING_EVENTS } from "../constants";

export class ReceivingEventAdminStore {
  receivingevents = observable.array();

  clear() {
    this.receivingevents.clear();
  }

  // action
  loadAll() {
    return agent.AdminRecdons.allReceiving({
      loaderTag: LOADER_TAG_ADMIN_RECEIVING_EVENTS
    }).then(body => {
      const receivingevents = body.data;
      runInAction(() => {
        this.receivingevents.replace(receivingevents);
      });
      return receivingevents;
    });
  }
}

decorate(ReceivingEventAdminStore, {
  receivingevents: observable,
  clear: action.bound,
  loadAll: action.bound
});

export default new ReceivingEventAdminStore();
