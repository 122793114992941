import { observable, decorate, action, runInAction, toJS } from "mobx";
import agent from "../agent";
import { LOADER_TAG_ADMIN_USERS } from "../constants";
import { mapSomeFieldsToId } from "../utils";

export class UserAdminStore {
  users = observable.array();

  filters = observable.map();

  constructor() {
    this.resetFilters();
  }

  // action
  loadFiltered() {
    const finalFilters = mapSomeFieldsToId(toJS(this.filters), [
      "role",
      "volunteerlevel"
    ]);
    return agent.AdminUsers.filter(
      { filters: finalFilters },
      { loaderTag: LOADER_TAG_ADMIN_USERS }
    )
      .then(body => {
        const users = body.data;
        runInAction(() => {
          this.users.replace(users);
        });
        return users;
      })
      .catch(error => {
        this.clear();
        return Promise.reject(error); // re-throw
      });
  }

  // action
  clear() {
    this.users.clear();
    this.resetFilters();
  }

  // action
  resetFilters() {
    this.filters.replace({
      sort_field: "created_at",
      sort_direction: "desc",

      name: null,
      email: null,
      newsletter: null,
      has_active_membership: null,
      created_at_from: null,
      created_at_to: null,

      role: []
    });
  }

  // action
  setFilter(field, value) {
    this.filters.set(field, value);
  }
}

decorate(UserAdminStore, {
  users: observable,
  filters: observable,
  loadFiltered: action.bound,
  clear: action.bound,
  resetFilters: action,
  setFilter: action
});

export default new UserAdminStore();
