import React, { Component } from "react";
import { Select } from "bloomer";

const OPTION_AND = "and";
const OPTION_OR = "or";

export default class BooltypeSelect extends Component {
  onChange = e => {
    this.props.onChange(e);
  };

  render() {
    const { value } = this.props;

    return (
      <Select onChange={this.onChange} value={value}>
        <option value={OPTION_AND}>AND</option>
        <option value={OPTION_OR}>OR</option>
      </Select>
    );
  }
}
