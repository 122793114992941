import React, { PureComponent } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Label
} from "recharts";
import {
  BIKE_STATUS_AVAILABLE,
  BIKE_STATUS_DONATED,
  BIKE_STATUS_SOLD
} from "../../../../constants";
import { ENUM_BIKE_CONDITIONS } from "../../../../config";

function mean(list) {
  return list.length
    ? list.reduce((acc, a) => acc + a, 0) / list.length
    : undefined;
}

export default class BarBikesFixCostOrTimeByCondition extends PureComponent {
  render() {
    const { bikes, costOrTime = "cost" } = this.props;

    if (!bikes) {
      return "n/a";
    }

    const data = Object.entries(
      bikes
        .filter(bike =>
          [
            // Only include available, donated, and sold bikes.
            // These bikes will have fix_cost, and might have fix_time.
            // Missing and returned bikes *might* have a fix_cost, but
            // they're excluded from this report
            BIKE_STATUS_AVAILABLE,
            BIKE_STATUS_DONATED,
            BIKE_STATUS_SOLD
          ].includes(bike.status)
        )
        .reduce(
          (acc, bike) => {
            const { condition } = bike;
            if (!condition || condition === "unknown") {
              // skip unknown condition
              return acc;
            }
            if (!acc[condition]) {
              // init bucket if it doesn't exist, but it should
              acc[condition] = [];
            }
            // add bike to bucket
            acc[condition].push(bike);
            return acc;
          },
          // init condition buckets
          ENUM_BIKE_CONDITIONS.filter(c => c !== "unknown").reduce(
            (a, c) => ({ ...a, [c]: [] }),
            {}
          )
        )
    ).reduce((acc, [condition, bikes]) => {
      const cost = mean(
        bikes.map(
          ({ fix_cost, total_bike_part_costs }) =>
            (fix_cost || 0) + total_bike_part_costs
        )
      );

      const time = mean(bikes.map(({ fix_time }) => fix_time || 0));

      return [...acc, { condition, cost, time }];
    }, []);

    return (
      <ResponsiveContainer width="100%" height={300}>
        <BarChart data={data}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="condition" />
          <YAxis>
            <Label angle={-90}>
              {costOrTime === "cost" ? "Cost" : "Time (Minutes)"}
            </Label>
          </YAxis>
          <Tooltip />
          <Bar dataKey={costOrTime} fill="#8884d8" />
        </BarChart>
      </ResponsiveContainer>
    );
  }
}
