import { inject, observer } from "mobx-react";
import React from "react";
import { Container, Notification, Delete } from "bloomer";
import classNames from "classnames";

import { ALERT_COLORS } from "../../config";
import { compose } from "../../utils";

import styles from "./errorbag.module.scss";

class ErrorBag extends React.Component {
  render() {
    if (!this.props.uiStore.hasAlerts) {
      return null;
    }
    const alerts = this.props.uiStore.alerts;
    return (
      <div className={classNames(styles.errorBag, this.props.mb && styles.mb)}>
        <Container>
          {Array.from(alerts.values()).map(({ id, type, message, config }) => {
            const color = ALERT_COLORS[type] || ALERT_COLORS["default"];
            return (
              <Notification key={id} isColor={color}>
                {config.clearableByUser && (
                  <Delete
                    onClick={() => this.props.uiStore.clearAlertById(id)}
                  />
                )}
                {message}
              </Notification>
            );
          })}
        </Container>
      </div>
    );
  }
}

export default compose(
  inject("uiStore"),
  observer
)(ErrorBag);
